// Packages
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, Auth } from "aws-amplify";
import { Usertype } from "../../../models";
import { logInUser } from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import EventService from "../../../services/EventService/event.service";
import UserService from "../../../services/User/user.service";
import { InstitutionAuth } from "../../institution/layout/Authentication/InstitutionAuth";
import SignIn from "../../pages/SignIn";
import SignUp from "../../pages/SignUp";
import VerifyAccount from "../../pages/VerifyAccount";
import ResetPassword from "../../pages/ResetPassword";
import ForgotPassword from "../../pages/ForgotPassword";
import SuccessScreen from "../../pages/SuccessScreen";
import { Spinner } from "react-bootstrap";
import { updateUser } from "../../../graphql/mutations";

declare global {
  interface Window {
    pendo: any;
  }
}

export type ScreenTypes =
  | "ForgotPassword"
  | "ResetPassword"
  | "SignIn"
  | "SignUp"
  | "Verify"
  | "Success";

export const ReskinAuthenticator = ({ children }: any) => {
  const dispatch = useDispatch();
  // user logged in or not via redux
  const { loggedIn, userType } = useSelector((state: RootState) => state.user);

  // loading state when checking authenticated
  const [isLoading, setLoading] = React.useState<boolean>(true);

  // which component to show
  const InitialState: ScreenTypes =
    (sessionStorage.getItem("AuthState") as ScreenTypes) || "SignIn";
  const [AuthState, setAuthState] = React.useState<{ screen: ScreenTypes }>({
    screen: InitialState,
  });

  React.useEffect(() => {
    if (loggedIn) setLoading(false);
  }, [loggedIn]);

  React.useEffect(() => {
    if (!loggedIn) {
      checkAuth();
    }

    async function checkAuth() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const { sub: userId, email } = user.attributes;

        // get user details from backend
        const dsUser = await UserService.getDSUser(userId);
        const result = await API.graphql({
          query: updateUser,
          variables: {
            input: {
              userId: userId,
              lastLogin: new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              }),
              userType: userType,
              _version: dsUser._version,
            },
          },
        });
        
        if (!dsUser) throw new Error("DSUserNotFound: User not found.");

        if (!UserService.canUserProceed(dsUser, userType as any)) {
          await Auth.signOut();
          window.location.href = "/";
          return;
        }

        // get user group
        let loggedInUserGroup = null;
        if (user.signInUserSession.idToken.payload["cognito:groups"]) {
          const groups =
            user.signInUserSession.idToken.payload["cognito:groups"];

          if (groups && groups.length > 0) {
            loggedInUserGroup = groups[0];
          }
        }

        if (!loggedInUserGroup) {
          loggedInUserGroup = userType;
        }

        // identify the user with our analytics
        EventService.identify(dsUser.userId, {
          assessmentId: dsUser.assessmentState?.assessmentId,
          sessionId: dsUser.assessmentState?.sessionId,
          userGroup: loggedInUserGroup,
        });

        window.pendo.initialize({
          visitor: {
            id: dsUser.userId,
          },
        });

        dispatch(logInUser(dsUser, email, userType as any));
      } catch (err) {
        // user is not authenticated
        console.warn(err);
        setLoading(false);
      }
    }
  });

  const renderState = () => {
    const { screen } = AuthState;

    const studentComponents: { [K in ScreenTypes]?: JSX.Element } = {
      ForgotPassword: <ForgotPassword changeAuthState={setAuthState} />,
      ResetPassword: (
        <ResetPassword changeAuthState={setAuthState} origin="forgot" />
      ),
      SignIn: <SignIn changeAuthState={setAuthState} userType={userType} />,
      SignUp: <SignUp changeAuthState={setAuthState} userType={userType} />,
      Verify: <VerifyAccount changeAuthState={setAuthState} origin="verify" />,
      Success: <SuccessScreen changeAuthState={setAuthState} />,
    };

    const studentComponent =
      studentComponents[screen] || studentComponents["SignIn"];

    return userType === Usertype.STUDENT ? (
      studentComponent
    ) : (
      <InstitutionAuth
        changeAuthState={setAuthState}
        userType={userType}
        form={screen}
      />
    );
  };

  if (isLoading)
    return <Spinner className="spinner" animation="border" role="status" />;

  return (
    <>
      {!isLoading && (
        <>
          {loggedIn && <>{children}</>}

          {!loggedIn && renderState()}
        </>
      )}
    </>
  );
};

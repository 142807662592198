import "./_index.scss";

import SupportPill from "../../../components/SupportPill";

interface ProfileImageLeftCellProps {
  renderedImage: JSX.Element;
  renderedText: JSX.Element;
  size?: string;
  supportStatus?: string;
}

export function ProfileImageLeftCell({
  renderedImage,
  size,
  renderedText,
  supportStatus = "none",
}: ProfileImageLeftCellProps) {
  const sizeClass = size ? size : "col";

  const renderSupportPill = () => {
    if (supportStatus !== "none") {
      return <SupportPill status={supportStatus} />;
    }

    return;
  };

  return (
    <td className={`${sizeClass} cell `}>
      {/* <div className="cellImageContainer p-0 me-2">{renderedImage}</div> */}
      <div>
        <div>{renderedText}</div>
        {renderSupportPill()}
      </div>
    </td>
  );
}

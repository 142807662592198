// Packages
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReflectionModal from "./ReflectionModal";
// Components
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";

// Styles
import "./Reflections.scss";

//Redux
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../../redux/reducers";
import { createLearningSelfReflection } from "../../../graphql/mutations";
import EventService from "../../../services/EventService/event.service";

function Reflections({ assessmentStateId }: any) {
  const {
    userId,
    assessmentState: { sessionId, assessmentId },
    institutionId,
  } = useSelector((state: RootState) => state.user);
  const [show, setShow] = useState(false);
  const [reflection1, setReflection1] = useState(""); // State for Reflection 1 text
  const [reflection2, setReflection2] = useState(""); // State for Reflection 2 text
  let navigate = useNavigate();

  const handleReflection1Change = (event: any) => {
    setReflection1(event.target.value);
  };

  const handleReflection2Change = (event: any) => {
    setReflection2(event.target.value);
  };

  const submitSelfReflections = async () => {
    const input = {
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      takeawayComment: reflection1 ? reflection1 : null,
      applicationComment: reflection2 ? reflection2 : null,
      commentDate: new Date().toISOString(),
      institution: institutionId,
    };
    await API.graphql(
      graphqlOperation(createLearningSelfReflection, { input: input }),
    );
  };
  const handleSubmit = () => {
    EventService.track({
      event_type: "user",
      event_name: "insight_reflection_submit",
      user_type: "student",
      event_data: {
        takeawayComment: reflection1,
        applicationComment: reflection2,
      },
    });
    submitSelfReflections();
    // Open the modal
    setShow(true);
  };

  const canSubmit =
    reflection1.length > 0 || reflection2.length > 0 ? true : false;

  useEffect(() => {
    EventService.page({
      type: "enter",
      page: "Reflections",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "Reflections",
      });
    };
  }, []);

  return (
    <div className="reskin-reflections">
      <div className="top-container">
        <div className="title">Reflection</div>
        <div className="Accordian">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Reflection 1</Accordion.Header>
              <Accordion.Body>
                What is a key takeaway from your learning today?
                <Form className="mt-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      placeholder="Type Here"
                      value={reflection1}
                      onChange={handleReflection1Change}
                    />
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Reflection 2</Accordion.Header>
              <Accordion.Body>
                How can you apply what you’ve learned today? Think ways to
                practice these skills within your coursework, at work, or in
                your community!
                <Form className="mt-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      placeholder="Type Here"
                      value={reflection2}
                      onChange={handleReflection2Change}
                    />
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="button-container">
        <ReskinButton
          variant="primary"
          text="Submit All"
          size="grow"
          customClasses="top-button"
          tabIndex={3}
          onClick={handleSubmit}
          disabled={!canSubmit}
        />
        <ReskinButton
          variant="secondary"
          text="Skip"
          size="grow"
          tabIndex={2}
          onClick={() => navigate(-1)}
        />
      </div>
      <ReflectionModal show={show} close={setShow} />
    </div>
  );
}

export default Reflections;

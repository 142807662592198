interface IconTabProps {
  renderedIcon: JSX.Element;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const IconTab = ({
  text,
  renderedIcon,
  onClick,
  disabled = false,
}: IconTabProps) => {
  return (
    <button disabled={disabled} onClick={onClick} className="d-flex icon-tab">
      <span className="tab-content-container d-flex w-100 h-100">
        <span className="d-flex text-start tab-text flex-grow-1">{text}</span>
        <span className="d-flex align-items-center justify-content-center rendered-icon">
          {renderedIcon}
        </span>
      </span>
    </button>
  );
};

export default IconTab;

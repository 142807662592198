// Packages
import React from "react";
import { Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import {
  VerificationCodeEmail,
  VerificationNumberInputs,
  PasswordInput,
} from "../../components";

export const ResetPassword = ({
  changeAuthState,
  email = sessionStorage.getItem("email"),
  origin = "forgot",
}: any) => {
  const [errors, setErrors] = React.useState<any>({ code: "", password: "" });
  const [formData, setFormData] = React.useState<any>({
    code: "",
    password: "",
  });

  const handleInputChange = (e: any) => {
    let { value, dataset } = e.target;
    const { prop } = dataset;

    setFormData({
      ...formData,
      [prop]: value,
    });

    // reset specific prop user is in
    setErrors({ ...errors, [prop]: "" });
  };

  const checkValidity = () => {
    let errors = 0;
    let errorValues = { code: "", password: "" };
    if (formData.code.trim() === "") {
      errorValues.code = "Enter the code sent to you.";
      errors++;
    }

    if (formData.password.trim() === "") {
      errorValues.password = "Please specify a valid password";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (checkValidity()) {
      try {
        const attempt = await Auth.forgotPasswordSubmit(
          email,
          formData.code,
          formData.password,
        );
        
        sessionStorage.setItem("AuthState", "ResetPassword");
        changeAuthState({ screen: "Success"});
      } catch (error: unknown) {
        console.log(error);

        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];
        const errorHandling2 = customError
          .toString()
          .toString()
          .split(":")
          .slice(1)
          .join(":");

        switch (errorHandling) {
          case "CodeMismatchException":
            setErrors({ ...errors, code: "Enter the code sent to you." });
            break;

          case "LimitExceededException":
            setErrors({ ...errors, code: "Limit exceed, try again later." });
            break;

          case "InvalidPasswordException":
            setErrors({ ...errors, password: errorHandling2 });
            break;

          default:
            setErrors({ code: "An error occured, try again.", password: "" });
            break;
        }
      }
    }
  };

  const goBack = () => {
    changeAuthState({
      screen: origin === "forgot" ? "ForgotPassword" : "MyAccount",
    });
  };

  const handleCodeChanges = (code: string) => {
    setFormData({ ...formData, code });
  };

  const resetCodeValidity = () => {
    if (errors.code.length > 0) {
      setErrors({ ...errors, code: "" });
    }
  };

  return (
    <div className="form-container">
      <h1 className="form-title">Reset Password</h1>
      <h1 className="callout">
        Enter the email associated with your account. A link will be sent to
        your email for you to reset your password.
      </h1>
      <Form className="form" noValidate>
        <Form.Group>
          <VerificationCodeEmail email={email} />
          <VerificationNumberInputs
            isInvalid={errors.code.length > 0 ? true : false}
            codes={formData.code.split("")}
            handleCodeChanges={handleCodeChanges}
            origin={origin}
            userIdentifier={email}
            resetValidity={resetCodeValidity}
            error={errors.code}
          />
          <PasswordInput
            handleInputChange={handleInputChange}
            error={errors.password}
            disableAutoComplete={true}
          />
        </Form.Group>

        <Form.Group className="buttons">
          <ReskinButton
            variant="primary"
            customClasses="left-btn"
            onClick={handleSave}
            type="button"
            text="Send"
          />
          <ReskinButton
            variant="secondary"
            onClick={goBack}
            type="button"
            text="Cancel"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

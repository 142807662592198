import {
  TableHeaderWithHamburger,
  TableHeader,
} from "../../../../../components/Table/headerCells";

// Utils
import getHamburgerMenuItems from "../../../student-list/StudentTable/HeaderRow/getHamburgerMenuItems";

interface HeaderRowProps {
  sortingHandler: Function;
}

const HeaderRow = ({ sortingHandler }: HeaderRowProps) => {
  return (
    <tr className="row flex-nowrap headerRow">
      <TableHeaderWithHamburger
        size="col-2"
        text="Name"
        isActive={true}
        onHamburgerClick={() => {}}
        dropdownReadableLabel="Filter names by"
        dropdownId="name-order-dropdown"
        menuItems={getHamburgerMenuItems("name", sortingHandler)}
      />
      <TableHeaderWithHamburger
        size="col-2"
        text="Leadership"
        isActive={true}
        onHamburgerClick={() => {}}
        dropdownReadableLabel="Filter leadership support requests by"
        dropdownId="leadership-order-dropdown"
        menuItems={[
          {
            text: "Requested",
            onClick: () =>
              sortingHandler({
                propertyKey: "leadership",
                sortDirection: "requested",
              }),
          },
          {
            text: "Sent",
            onClick: () =>
              sortingHandler({
                propertyKey: "leadership",
                sortDirection: "sent",
              }),
          },
        ]}
      />
      <TableHeaderWithHamburger
        size="col-2"
        text="Perseverance"
        isActive={true}
        onHamburgerClick={() => {}}
        dropdownReadableLabel="Filter perseverance support requests by"
        dropdownId="perseverance-order-dropdown"
        menuItems={[
          {
            text: "Requested",
            onClick: () =>
              sortingHandler({
                propertyKey: "perseverance",
                sortDirection: "requested",
              }),
          },
          {
            text: "Sent",
            onClick: () =>
              sortingHandler({
                propertyKey: "perseverance",
                sortDirection: "sent",
              }),
          },
        ]}
      />
      <TableHeaderWithHamburger
        size="col-2"
        text="Effective Communication"
        isActive={true}
        onHamburgerClick={() => {}}
        dropdownReadableLabel="Filter effective communication support requests by"
        dropdownId="effective-communication-order-dropdown"
        menuItems={[
          {
            text: "Requested",
            onClick: () =>
              sortingHandler({
                propertyKey: "effectiveCommunication",
                sortDirection: "requested",
              }),
          },
          {
            text: "Sent",
            onClick: () =>
              sortingHandler({
                propertyKey: "effectiveCommunication",
                sortDirection: "sent",
              }),
          },
        ]}
      />
      <TableHeaderWithHamburger
        size="col-2"
        text="Innovative Thinking"
        isActive={true}
        onHamburgerClick={() => {}}
        dropdownReadableLabel="Filter innovative thinking support requests by"
        dropdownId="innovative-thinking-order-dropdown"
        menuItems={[
          {
            text: "Requested",
            onClick: () =>
              sortingHandler({
                propertyKey: "innovativeThinking",
                sortDirection: "requested",
              }),
          },
          {
            text: "Sent",
            onClick: () =>
              sortingHandler({
                propertyKey: "innovativeThinking",
                sortDirection: "sent",
              }),
          },
        ]}
      />
      <TableHeader size="col-2" text="Send Support" />
    </tr>
  );
};

export default HeaderRow;

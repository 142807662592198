// Packages
import React from "react";
import { useIdleTimer } from "react-idle-timer";

// Components
import ReskinButton from "../../Buttons/ReskinButton/ReskinButton";
import TwoButtonModal from "../TwoButtonModal/TwoButtonModal";
import SessionTimeoutModal from "../SessionTimeoutModal/SessionTimeoutModal";

// Redux
import { useDispatch } from "react-redux";
import { initiateResetPassword } from "../../../../redux/actions";

interface ResetPasswordModalProptype {
  handleClose: Function;
  show: boolean;
}

const ResetPasswordModal = ({ handleClose, show }: ResetPasswordModalProptype) => {
  const [isUserInactive, setIsUserInactive] = React.useState(false);
  const dispatch = useDispatch();

  useIdleTimer({
    onIdle: () => setIsUserInactive(true),
    timeout: 60000,
    startOnMount: true,
  });

  const ResetPasswordModalBody = (
    <>
      <p>Are you sure you want to reset your password?</p>
    </>
  );
  const ResetPasswordCancelButton = (
    <ReskinButton
      variant="secondary"
      size="small"
      onClick={handleClose}
      text="Cancel"
    />
  );
  const ResetPasswordButton = (
    <ReskinButton
      variant="primary"
      size="small"
      onClick={() => dispatch(initiateResetPassword())}
      text="Reset"
    />
  );

  if (isUserInactive) {
    return <SessionTimeoutModal show={show} handleClose={handleClose} />;
  }

  return (
    <TwoButtonModal
      onClose={handleClose}
      show={show}
      headerText="Reset Password"
      bodyContent={ResetPasswordModalBody}
      LeftButton={ResetPasswordCancelButton}
      RightButton={ResetPasswordButton}
    />
  );
};

export default ResetPasswordModal;

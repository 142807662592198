// Packages
import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Spinner } from "react-bootstrap";

// Data
import * as customQueries from "../../../graphql/custom-queries";
import { getOrList } from "../../../components/dynamicqueries";
import { usersByInstitutionIdAndUserTypeAndUserId } from "../../../graphql/queries";
import LollipopChart from "./StudentHighlight/Lollipop";

// Components
import StudentHighlight from "./StudentHighlight";
import StudentByCategory from "./StudentByCategory";

// Services
import EventService from "../../../services/EventService/event.service";

// Utilities
import { ucWords } from "../../../utilities";

//Image
import { ReactComponent as LeadershipImage } from "../../../assets/fullsummary/Leadership.svg";
import { ReactComponent as PerseveranceImage } from "../../../assets/fullsummary/Perseverance.svg";
import { ReactComponent as CommunicationImage } from "../../../assets/fullsummary/Communication.svg";
import { ReactComponent as InnovativeImage } from "../../../assets/fullsummary/Innovative.svg";
import StudentPage from "../student-list";
import { Usertype } from "../../../models";
const LEADERSHIP_SCORE_NAME = "leadershipScore";
const COMMUNICATION_SCORE_NAME = "communicationScore";
const INNOVATIVE_THINKING_SCORE_NAME = "innovativeThinkingScore";
const PERSEVERANCE_SCORE_NAME = "perseveranceScore";

const HIGH_SCORE_BAND = 2;
const LOW_SCORE_BAND = 0;
const MEDIUM_SCORE_BAND = 1;

const DEMONSTRATING_LOWER_BOUND = 6;
const DEMONSTRATING_UPPER_BOUND = 8;

const DEVELOPING_LOWER_BOUND = 0;
const DEVELOPING_UPPER_BOUND = 3;

function Overview({
  setSelectedTab,
  institutionId,
  studentCount,
  totalRegisteredStudentCount,
}: any) {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [studentRequests, setStudentRequests] = React.useState<any>([]);
  const [activeToday, setActiveToday] = React.useState<number>(0);

  React.useEffect(() => {
    if (isLoading === true) {
      getStudentRequests();
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "HEI Overview",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "HEI Overview",
      });
    };
  }, []);

  const getStudentRequests = async () => {
    const filterVariable = {
      filter: {
        institutionId: { eq: institutionId },
        responseSentToStudent: { eq: false },
      },
      limit: 10000,
    };
    const studentRequestData: any = await getOrList(
      customQueries.searchStudentRequests,
      "searchStudentRequests",
      filterVariable,
    );

    const result = await API.graphql(
      graphqlOperation(usersByInstitutionIdAndUserTypeAndUserId, {
        institutionId: institutionId,
        filter: {
          and: [
            {
              lastLogin: {
                beginsWith: new Date()
                  .toLocaleString("en-US", { timeZone: "America/New_York" })
                  .substring(0, 10),
              },
            },
            { userType: { eq: "STUDENT" } },
          ],
        },
      }),
    );
    console.log("result", result);
    setActiveToday(
      (result as any).data.usersByInstitutionIdAndUserTypeAndUserId.items
        .length,
    );
    const traitName = [
      ["leadership", <LeadershipImage />],
      ["perseverance", <PerseveranceImage />],
      ["effective communication", <CommunicationImage />],
      ["innovative thinking", <InnovativeImage />],
    ];
    const traitStudentRequest: any = [];
    let i = 0;
    traitName.forEach((val: any) => {
      const requestData = studentRequestData.filter((value: any) => {
        return value.traitName === val[0] && value.studentUserSession != null;
      });
      i = requestData.length > 0 ? i : ++i;
      const ImageData = requestData.filter(
        (value: any, index: any) => index < 4,
      );
      traitStudentRequest.push({
        trait: ucWords(val[0]),
        icon: val[1],
        imageData: ImageData,
        requests: requestData,
      });
    });
    setStudentRequests(i != 4 ? traitStudentRequest : []);
  };

  async function getOverallLowPerformers() {
    let performersScores = await searchOverallPerformers(
      DEVELOPING_LOWER_BOUND,
      DEVELOPING_UPPER_BOUND,
      "asc",
      institutionId,
    );

    return performersScores;
  }

  async function getOverallHighPerformers() {
    let performersScores = await searchOverallPerformers(
      DEMONSTRATING_LOWER_BOUND,
      DEMONSTRATING_UPPER_BOUND,
      "desc",
      institutionId,
    );

    return performersScores;
  }

  async function getLeaderShipCounts() {
    const response = await getUserScoresCount(
      LEADERSHIP_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.leadershipScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.leadershipScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.leadershipScore === LOW_SCORE_BAND,
    ).length;
    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highPercentage: ((highCount / totalCount) * 100).toFixed(2),
      medPercentage: ((medCount / totalCount) * 100).toFixed(2),
      lowPercentage: ((lowCount / totalCount) * 100).toFixed(2),
    };
    return scoreData;
  }

  async function getCommunicationCounts() {
    const response = await getUserScoresCount(
      COMMUNICATION_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.communicationScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.communicationScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.communicationScore === LOW_SCORE_BAND,
    ).length;

    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highPercentage: ((highCount / totalCount) * 100).toFixed(2),
      medPercentage: ((medCount / totalCount) * 100).toFixed(2),
      lowPercentage: ((lowCount / totalCount) * 100).toFixed(2),
    };
    return scoreData;
  }

  async function getInnovationThinkingCounts() {
    const response = await getUserScoresCount(
      INNOVATIVE_THINKING_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.innovativeThinkingScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.innovativeThinkingScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.innovativeThinkingScore === LOW_SCORE_BAND,
    ).length;

    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highPercentage: ((highCount / totalCount) * 100).toFixed(2),
      medPercentage: ((medCount / totalCount) * 100).toFixed(2),
      lowPercentage: ((lowCount / totalCount) * 100).toFixed(2),
    };
    return scoreData;
  }

  async function getPerserveranceCounts() {
    const response = await getUserScoresCount(
      PERSEVERANCE_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.perseveranceScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.perseveranceScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.perseveranceScore === LOW_SCORE_BAND,
    ).length;

    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highPercentage: ((highCount / totalCount) * 100).toFixed(2),
      medPercentage: ((medCount / totalCount) * 100).toFixed(2),
      lowPercentage: ((lowCount / totalCount) * 100).toFixed(2),
    };
    return scoreData;
  }

  async function getLeadershipHighPerformers() {
    const performers = await searchCategoryPerformers(
      LEADERSHIP_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  async function getLeadershipLowPerformers() {
    const performers = await searchCategoryPerformers(
      LEADERSHIP_SCORE_NAME,
      LOW_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  async function getCommunicationHighPerformers() {
    const performers = await searchCategoryPerformers(
      COMMUNICATION_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  async function getCommunicationLowPerformers() {
    const performers = await searchCategoryPerformers(
      COMMUNICATION_SCORE_NAME,
      LOW_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  //

  async function getInnovationThinkingHighPerformers() {
    const performers = await searchCategoryPerformers(
      INNOVATIVE_THINKING_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  async function getInnovationThinkingLowPerformers() {
    const performers = await searchCategoryPerformers(
      INNOVATIVE_THINKING_SCORE_NAME,
      LOW_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  async function getPerserveranceHighPerformers() {
    const performers = await searchCategoryPerformers(
      PERSEVERANCE_SCORE_NAME,
      HIGH_SCORE_BAND,
      institutionId,
    );

    return performers;
  }

  async function getPerserveranceLowPerformers() {
    const performers = await searchCategoryPerformers(
      PERSEVERANCE_SCORE_NAME,
      LOW_SCORE_BAND,
      institutionId,
    );

    return performers;
  }
  console.log("getLeadershipHighPerformers", getLeaderShipCounts());

  const getCurrentDate = () => {
    var today = new Date();
    var dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    var mm =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    var year = today.getFullYear().toString().slice(2);

    return `${mm}/${dd}/${year}`;
  };
  return (
    <section className="hei-overview container">
      {isLoading && (
        <>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span>
        </>
      )}
      {!isLoading && (
        <>
          {/* <StudentHighlight
            setSelectedTab={setSelectedTab}
            studentRequests={studentRequests}
            performers={{
              low: getOverallLowPerformers(),
              high: getOverallHighPerformers(),
            }}
          /> */}
          <div className="grid-container">
            <div className="grid1">
              <h1>Insights Report</h1>
              <p>
                This chart illustrates the percentage of students demonstrating
                these skills
              </p>

              <div className="d-flex justify-content-evenly ">
                <LollipopChart
                  trait={"Leadership"}
                  data={getLeaderShipCounts()}
                />
                <LollipopChart
                  trait={"Effective Communication"}
                  data={getCommunicationCounts()}
                />
                <LollipopChart
                  trait="Perseverance"
                  data={getPerserveranceCounts()}
                />
                <LollipopChart
                  trait="Innovative Thinking"
                  data={getInnovationThinkingCounts()}
                />
              </div>
            </div>

            <div className="grid2">
              <StudentByCategory
                setSelectedTab={setSelectedTab}
                counts={{
                  leadership: getLeaderShipCounts(),
                  communication: getCommunicationCounts(),
                  innovativeThinking: getInnovationThinkingCounts(),
                  perseverance: getPerserveranceCounts(),
                }}
                performers={{
                  leadership: {
                    high: getLeadershipHighPerformers(),
                    low: getLeadershipLowPerformers(),
                  },
                  communication: {
                    high: getCommunicationHighPerformers(),
                    low: getCommunicationLowPerformers(),
                  },
                  innovativeThinking: {
                    high: getInnovationThinkingHighPerformers(),
                    low: getInnovationThinkingLowPerformers(),
                  },
                  perseverance: {
                    high: getPerserveranceHighPerformers(),
                    low: getPerserveranceLowPerformers(),
                  },
                }}
              />
            </div>
            <div className="grid3">
              <div className="card card1">
                <div className="body">
                  <h5 className="card-title">Students in Program</h5>
                  <p className="card-text">{totalRegisteredStudentCount}</p>
                </div>
              </div>
              <div className="card card2">
                <div className="body">
                  <h5 className="card-title">Total Completers</h5>
                  <p className="card-text">{studentCount}</p>
                </div>
              </div>
              <div className="card card3">
                <div className="body">
                  <h5 className="card-title">Data Last Updated</h5>
                  <p className="card-text">{getCurrentDate()}</p>
                </div>
              </div>
              <div className="card card4">
                <div className="body">
                  <h5 className="card-title">Active Students Today</h5>
                  <p className="card-text">{activeToday}</p>
                </div>
              </div>
            </div>
          </div>

          <StudentPage
            setSelectedTab={setSelectedTab}
            institutionId={institutionId}
            isDashboard={true}
            //sorting students alphabetically for dashboard
            initialSortAlgorithm={{
              propertyKey: "name",
              sortDirection: "ascending",
            }}
            rowLimit={5}
          />
        </>
      )}
    </section>
  );
}

export default Overview;

async function getUserScoresCount(
  category: any,
  scoreBand: number,
  institutionId: any,
) {
  let filter = {
    or: [
      {
        and: [
          { [category]: { eq: LOW_SCORE_BAND } },
          { overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
      {
        and: [
          { [category]: { eq: MEDIUM_SCORE_BAND }, overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
      {
        and: [
          { [category]: { eq: HIGH_SCORE_BAND }, overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
    ],
  };

  const response = await API.graphql({
    query: customQueries.searchUserScoresCount,
    variables: { filter: filter },
  });

  const responseObject = JSON.parse(JSON.stringify(response));
  const result = responseObject.data.searchUserScores;
  return result;
}

async function searchOverallPerformers(
  lowerBound: any,
  upperBound: any,
  sortOrder: any,
  institutionId: any,
) {
  let filter = {
    and: [
      { overallScore: { gte: lowerBound } },
      { overallScore: { lte: upperBound } },
      { institution: { eq: institutionId } },
    ],
  };
  let sort = {
    field: "overallScore",
    direction: sortOrder,
  };

  const variable = { filter: filter, sort: sort };

  const listOfUsers = await getOrList(
    customQueries.searchOverviewsUserScore,
    "searchUserScores",
    variable,
  );

  return listOfUsers;
}

async function searchCategoryPerformers(
  category: any,
  scoreBand: number,
  institutionId: any,
) {
  let filter = {
    and: [
      { [category]: { eq: scoreBand } },
      { overallScore: { gte: 0 } },
      { institution: { eq: institutionId } },
    ],
  };

  const variable = { filter: filter, limit: 5 };

  const listOfUsers = await getOrList(
    customQueries.searchOverviewsUserScore,
    "searchUserScores",
    variable,
  );

  return listOfUsers;
}

import "./EffectiveCommunicationTip.scss";

interface EffectiveCommunicationTipProps {
  tipNumber: number;
  header: string;
  text: string;
  headerVariant: "blue" | "green" | "grey";
  customClasses?: string;
}

const EffectiveCommunicationTip = ({
  tipNumber,
  header,
  text,
  headerVariant,
  customClasses,
}: EffectiveCommunicationTipProps) => {
  return (
    <div className={`communication-tip ${customClasses}`}>
      <section className="card-header">
        <div className={`number-container ${headerVariant}`}>{tipNumber}</div>
        <div className={`header-container ${headerVariant}`}>{header}</div>
      </section>
      <section className="card-body">{text}</section>
    </div>
  );
};

export default EffectiveCommunicationTip;

export const onUserScoresUpdate = /* GraphQL */ `
  subscription OnUpdateUserScores {
    onUpdateUserScores {
      id
      userSessionId
      userId
    }
  }
`;

export const onUserScoresCreate = /* GraphQL */ `
  subscription onCreateUserScores {
    onCreateUserScores {
      id
      userSessionId
      userId
    }
  }
`;

export const onCreateEventTracking = /* GraphQL */ `
  subscription onCreateEventTracking {
    onCreateEventTracking {
      id
      userSessionId
      userId
      eventName
      eventStatus
      _version
    }
  }
`;

export const onUpdateEventTracking = /* GraphQL */ `
  subscription onUpdateEventTracking {
    onUpdateEventTracking {
      id
      userSessionId
      userId
      eventName
      eventStatus
      _version
    }
  }
`;

export const onUpdateUserResponses = /* GraphQL */ `
  subscription onUpdateUserResponses {
    onUpdateUserResponses {
      id
      userId
      userSessionId
      _version
    }
  }
`;

export const onCreateHeiComments = /* GraphQL */ `
  subscription OnCreateHeiComments(
    $filter: ModelSubscriptionHeiCommentsFilterInput
  ) {
    onCreateHeiComments(filter: $filter) {
      id
      userId
      username
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        heiComments {
          nextToken
          startedAt
        }
      }
    }
  }
`;

function Attempts({ max, attempt }: any) {
  return (
    <div className="attempts-controller controller">
      <div className="pt-1 pb-1">Attempt: {attempt}</div>
      <div>Max: {max}</div>
    </div>
  );
}

export default Attempts;

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */

// Packages
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { updateUserAssessmentState } from "../../redux/actions";
import { AssessmentStatusType } from "../../models";
import { listSectionItems } from "../../graphql/queries";
import { getOrList } from "../../components/dynamicqueries";
import { RootState } from "../../redux/reducers";
import { UserResponses } from "../../models";
import { useNavigate } from "react-router-dom";
import { updateUserResponses } from "../../graphql/mutations";
import { API, Auth, graphqlOperation } from "aws-amplify";
import EventService from "../../services/EventService/event.service";
import env from "../../configs/env";
import {
  EventBridgeClient,
  PutEventsCommand,
} from "@aws-sdk/client-eventbridge";

// Components
import AssessmentHeader from "../../reskin/components/Header/AssessmentHeader/AssessmentHeader";
import HireupProgressBarFooter from "./Footers/HireupProgressBarFooter";
import PairsButtonConfigured from "../../ui-components/PairsButtonConfigured";

// Styles
import "./ChooseStatement.scss";

type TripletsType = {
  storedResponses: UserResponses;
};

export default function ChooseStatement({ storedResponses }: TripletsType) {
  const { id, sections } = useSelector((state: RootState) => state.assessment);
  const {
    userId,
    assessmentState: { UserAssessmentStateId, sessionId },
  } = useSelector((state: RootState) => state.user);
  const [isReady, setReady] = React.useState<boolean>(false);
  const [pairs, setPairs] = React.useState([]);
  const [triplets, setTriplets] = React.useState<any>([]);
  const [isCompleted, setCompleted] = React.useState<any>(false);
  const [index, setIndex] = React.useState<any>({
    triplet: storedResponses.tripletResponse
      ? storedResponses.tripletResponse.length / 2 + 1
      : 1,
    item: storedResponses.tripletResponse
      ? storedResponses.tripletResponse.length
      : 0,
    error: false,
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleContinue = () => {
    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.SAMPLE_INSTRUCTION_COMPLETE,
      }),
    );
  };

  React.useEffect(() => {
    // only get items if not already stored in local state
    if (id !== "" && triplets.length === 0) getSectionItems();
    async function getSectionItems() {
      const items = (
        await getOrList(listSectionItems, "listSectionItems", { limit: 10000 })
      )
        .filter((c: any) => c.section.id === sections[1].id)
        .sort((a: any, b: any) => a.itemSequence - b.itemSequence);
      setTriplets(items);
      setReady(true);
    }
  }, [id, sections]);

  const apiSubmission = async (userResponse: any, existingResponses: any) => {
    const original: any = storedResponses;
    let version: any = sessionStorage.getItem("version");
    if (version == null) {
      sessionStorage.setItem("version", original["_version"]);
      version = original["_version"];
    }

    try {
      if (original) {
        // save the response
        const storedResponse = sessionStorage.getItem("userresponse");
        const tripletResponse: any =
          storedResponse === null || storedResponse === "null"
            ? JSON.stringify(userResponse)
            : storedResponse;

        const input = {
          id: storedResponses.id,
          tripletResponse: JSON.parse(tripletResponse),
          userSessionId: sessionId,
          _version: version,
        };

        const resultData = await API.graphql(
          graphqlOperation(updateUserResponses, { input: input }),
        );
        const responseObject = JSON.parse(JSON.stringify(resultData));
        version = responseObject.data.updateUserResponses._version;

        sessionStorage.setItem("version", version);

        // event
        EventService.track({
          event_type: "user",
          event_name: "pair_response_submit",
          user_type: "student",
          event_data: {
            section: "SkillTrack",
            quesiton_sequence: `${index.triplet}-${triplets.length / 2}`,
            response: JSON.parse(tripletResponse).slice(-2),
          },
        });

        if (index.triplet === 1) {
          // update state to in progress
          dispatch(
            updateUserAssessmentState({
              userId,
              UserAssessmentStateId,
              statusType: AssessmentStatusType.TRIPLET_INPROGRESS,
            }),
          );
        }
      }
    } catch (err) {
      alert("An error occured, try again.");
      console.log("API SUBMISSION ERROR LOG", err);
      sessionStorage.setItem("userresponse", existingResponses);
      setIndex({ ...index, error: true });
    }
  };

  const tripletSubmission = async (userResponse: any) => {
    const original: any = sessionStorage.getItem("userresponse");

    if (!isCompleted) {
      let newResp = [];
      try {
        // if it's not the user's first response
        if (original !== null && original !== "null") {
          newResp = userResponse;
          const result = JSON.parse(original);
          // make a copy of the original response so we can add to it
          let copy = result.map((x: any) => x);
          // push the changes to the copy
          copy.push(...newResp);
          // set the copy back to the new response
          newResp = copy;
          sessionStorage.setItem("userresponse", JSON.stringify(newResp));
          // event
        } else {
          if (storedResponses.tripletResponse != null) {
            let newResponse = storedResponses.tripletResponse;
            newResponse.push(...userResponse);

            sessionStorage.setItem("userresponse", JSON.stringify(newResponse));
          } else {
            sessionStorage.setItem(
              "userresponse",
              JSON.stringify(userResponse),
            );
          }
        }
      } catch (err) {
        console.log("TRIPLET SUBMISSION ERROR LOG", err);
        alert("An error occured, try again.");
        setIndex({ ...index, error: true });
      }
      if (index.triplet !== triplets.length / 2) {
        apiSubmission(newResp, original);
        setIndex({
          triplet: index.triplet + 1,
          item: index.item + 2,
          error: false,
        });
      } else {
        apiSubmission(newResp, original);
        handleTripletsCompletion();
      }
    }
  };

  async function invokeTripletScoringApi(retries = 2) {
    const responseObject: any = sessionStorage.getItem("userresponse");
    const studentFinalTripletResponses = JSON.parse(responseObject);

    let requestPayload = {
      assessmentId: storedResponses?.assessmentId,
      assessmentName: env.assessmentName,
      userId: storedResponses?.userId,
      userSessionId: storedResponses?.userSessionId,
      ItemResponses: studentFinalTripletResponses,
    };

    const user = await Auth.currentUserCredentials();
    const client = new EventBridgeClient({
      region: "us-east-1",
      credentials: {
        accessKeyId: user.accessKeyId,
        secretAccessKey: user.secretAccessKey,
        sessionToken: user.sessionToken,
      },
    });

    const params = {
      Entries: [
        {
          EventBusName: "psq",
          Detail: JSON.stringify(requestPayload),
          DetailType: "pairsResponse",
          Source: "org.ets.psq." + process.env.REACT_APP_ENV,
        },
      ],
    };

    const EventBridgeEvent = new PutEventsCommand(params);
    try {
      const submitForScoring = await client.send(EventBridgeEvent);
      sessionStorage.removeItem("userresponse");
      sessionStorage.removeItem("version");
      EventService.track({
        event_type: "system",
        event_name: "section_score_request",
        user_type: "student",
        event_data: {
          section: "SkillTrack",
          score_input: requestPayload,
          response: submitForScoring,
        },
      });
    } catch (error: unknown) {
      // typescript doesn't like those unknowns
      const customError = error as string;
      console.log(error);
      if (retries === 0) {
        console.log("Failed to process the request on multiple retries");
      } else {
        await invokeTripletScoringApi(--retries);
      }

      EventService.track({
        event_type: "system",
        event_name: "section_score_request_error",
        user_type: "student",
        event_data: {
          section: "SkillTrack",
          score_input: requestPayload,
          error: customError.toString(),
        },
      });
    }
  }

  const handleTripletsCompletion = async () => {
    setCompleted(true);

    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.TRIPLET_COMPLETE,
      }),
    );

    await invokeTripletScoringApi();

    navigate("/assessment/completion");
  };

  return (
    <div className="reskin-pairs-page">
      <AssessmentHeader isTransparent={false} />
      <div className="choose-statement-content-container">
        <div className="header-wrapper">
          <h1 className="header-text">Employability Skills</h1>
        </div>

        <div className="subheader-wrapper">
          <p className="subheader-text">
            Choose the statement that is <strong>most</strong> like you.
          </p>
        </div>

        <div className="pair-buttons-container">
          <PairsButtonConfigured
            items={triplets.slice(index.item, index.item + 2)}
            handleSubmission={tripletSubmission}
            isCompleted={isCompleted}
          />
        </div>
      </div>

      <HireupProgressBarFooter
        itemsCompleted={index.item / 2 + 1}
        itemsTotal={triplets.length / 2}
        itemName="Questions"
      />
    </div>
  );
}

import { ReactComponent as ApproachingBar } from "../../../../assets/icons/bar/approaching-bar-reskin.svg";
import { ReactComponent as DemonstratingBar } from "../../../../assets/icons/bar/demonstrating-bar-reskin.svg";
import { ReactComponent as DevelopingBar } from "../../../../assets/icons/bar/developing-bar-reskin.svg";

function Graph({ traitScore }: any) {
  const determineScoreName = (value: number) => {
    switch (value) {
      case 0:
        return "developing";
      case 1:
        return "approaching";
      case 2:
        return "demonstrating";
    }
  };

  const determineIcon = (value: number) => {
    switch (value) {
      case 0:
        return <DevelopingBar />;
      case 1:
        return <ApproachingBar />;
      case 2:
        return <DemonstratingBar />;
    }
  };

  const renderBars = () => {
    return traitScore.data.map((subtrait: any, index: number) => {
      return (
        <div className="subtrait secondary" key={index}>
          <div className="subtrait-name">{subtrait.dimension}</div>
          <div
            className={`subtrait-value  ${traitScore.name.toLowerCase().split(" ").join("")} ${traitScore.name} ${
              subtrait.value >= 0 ? "filled" : ""
            } ${determineScoreName(subtrait.value)}`}
          >
            <div className="bar" />
          </div>
          <div
            className={`subtrait-value ${traitScore.name.toLowerCase().split(" ").join("")} ${
              subtrait.value >= 1 ? "filled" : ""
            } ${determineScoreName(subtrait.value)}`}
          >
            <div className="bar" />
          </div>
          <div
            className={`subtrait-value  ${traitScore.name.toLowerCase().split(" ").join("")} ${
              subtrait.value >= 2 ? "filled" : ""
            } ${determineScoreName(subtrait.value)}`}
          >
            <div className="bar" />
          </div>
          <div className="subtrait-value "></div>
        </div>
      );
    });
  };

  return (
    <>
      <div className={`trait-score-container`}>
        <div className={`traitBlade`}>
          <div
            className={`trait-header ${traitScore.name.toLowerCase().split(" ").join("")}`}
          >
            <div
              className={`content ${traitScore.name.toLowerCase().split(" ").join("")}`}
            >
              {traitScore.image}
            </div>
            <strong>{traitScore.name}</strong>
          </div>

          <div className="trait-name-icon">
            {determineIcon(traitScore.value)}
            {determineScoreName(traitScore.value)}
          </div>
        </div>
        <div className="trait-score-level">
          <div>
            <div
              className={`low-level ${traitScore.name.toLowerCase().split(" ").join("")}-background-developing`}
            ></div>
            <div>Developing</div>
          </div>
          <div>
            <div
              className={`medium-level ${traitScore.name.toLowerCase().split(" ").join("")}-background-approaching`}
            ></div>
            <div>Approaching</div>
          </div>
          <div>
            <div
              className={`high-level ${traitScore.name.toLowerCase().split(" ").join("")}-background`}
            ></div>
            <div>Demonstrating</div>
          </div>
        </div>
        <div className="subtraitGraphs">
          <div className="subtrait master">
            <div className="subtrait-name"></div>
            <div className="subtrait-value dashed-right"></div>
            <div className="subtrait-value dashed-right"></div>
            <div className="subtrait-value dashed-right"></div>
            <div className="subtrait-value"></div>
          </div>

          {renderBars()}
        </div>
      </div>
    </>
  );
}

export default Graph;

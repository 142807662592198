// Packages
import { Navbar, Nav } from "react-bootstrap";
import "./institution-footer.scss";

export const InstitutionFooter = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <Navbar className="institution-footer">
        <div className="footer-left">
          Copyright &copy; {year} by Educational Testing Service.
          <br />
          All rights reserved. All trademarks are the property of their
          respective owners.
        </div>
        <div className="footer-right">
          <Nav.Link className="link" href="/privacypolicy">Privacy Policy</Nav.Link>
          <Nav.Link className="link" href="/termsconditions">Terms of Service</Nav.Link>
        </div>
      </Navbar>
    </>
  );
};

export const locale = window.navigator.language;
export const platform = window.navigator.platform;
export const isSafari =
  navigator.userAgent.indexOf("Safari") !== -1 &&
  navigator.userAgent.indexOf("Chrome") === -1;

export const isChrome =
  navigator.userAgent.indexOf("Safari") === -1 &&
  navigator.userAgent.indexOf("Chrome") !== -1;

export const isIOS = () => {};

export const mimeType = isSafari ? `video/mp4` : `video/webm;codecs=h264`;

const deviceInfo = {
  locale,
  platform,
  isSafari,
  userAgent: navigator.userAgent,
  mimeType,
};

export default deviceInfo;

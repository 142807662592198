import "./card-content.scss";

type BulletedStatementPropType = {
  statement: string;
  bullets: string[];
};

const BulletedStatement = ({
  statement,
  bullets,
}: BulletedStatementPropType) => {
  const RenderedBullets = bullets.map((bulletPoint, index) => {
    return <li key={`${index} - bullet`}>{bulletPoint}</li>;
  });

  return (
    <>
      <p className="m-0">{statement}</p>
      <ul className="bulleted-list">{RenderedBullets}</ul>
    </>
  );
};

export default BulletedStatement;

// Types
// import { AssessmentType, AssessmentActionTypes } from "../types";
import { SupportType, SupportActionTypes } from "../types";

const initialSupportState: SupportType = {
  unreadCount: 0,
};

export function supportReducer(
  state: SupportType = initialSupportState,
  action: SupportActionTypes,
): SupportType {
  switch (action.type) {
    case "STORE_SUPPORT":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

// Student Resource Hub Sorting

const getSortByStringAscending = (property) => {
  return (a, b) => {
    const valueA = a[property];
    const valueB = b[property];

    // Null checking values. Null values go to the back
    if (!valueA) return 1;
    if (!valueB) return -1;

    var normalizedValueA = valueA.toUpperCase(); // ignore upper and lowercase
    var normalizedValueB = valueB.toUpperCase(); // ignore upper and lowercase

    if (!normalizedValueA) {
      return 1;
    }

    if (!normalizedValueB) {
      return -1;
    }

    if (normalizedValueA < normalizedValueB) {
      return -1;
    }
    if (normalizedValueA > normalizedValueB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
};

const getSortByStringDescending = (property) => {
  return (a, b) => {
    const valueA = a[property];
    const valueB = b[property];

    // Null checking values. Null values go to the back

    if (!valueA) return 1;
    if (!valueB) return -1;

    var normalizedValueA = valueA.toUpperCase(); // ignore upper and lowercase
    var normalizedValueB = valueB.toUpperCase();

    if (!normalizedValueA) {
      return 1;
    }

    if (!normalizedValueB) {
      return -1;
    }

    if (normalizedValueA < normalizedValueB) {
      return 1;
    }
    if (normalizedValueA > normalizedValueB) {
      return -1;
    }

    // names must be equal
    return 0;
  };
};

const getSortBySentRequests = (property) => {
  return (a, b) => {
    const valueA = a[property]?.status;
    const valueB = b[property]?.status;

    // Null checking values. Null values go to the back

    if (!valueA) return 1;
    if (!valueB) return -1;

    var normalizedValueA = valueA.toLowerCase(); // ignore upper and lowercase
    var normalizedValueB = valueB.toLowerCase();

    if (!normalizedValueA) {
      return 1;
    }

    if (!normalizedValueB) {
      return -1;
    }

    const isValueASent = normalizedValueA === "sent";
    const isValueBSent = normalizedValueB === "sent";

    if (isValueASent && !isValueBSent) {
      return 1;
    }
    if (!isValueASent && isValueBSent) {
      return -1;
    }

    // statuses must be equal
    return 0;
  };
};

const getSortByOpenRequests = (property) => {
  return (a, b) => {
    const valueA = a[property]?.status;
    const valueB = b[property]?.status;

    // Null checking values. Null values go to the back

    if (!valueA) return 1;
    if (!valueB) return -1;

    var normalizedValueA = valueA.toLowerCase(); // ignore upper and lowercase
    var normalizedValueB = valueB.toLowerCase();

    if (!normalizedValueA) {
      return 1;
    }

    if (!normalizedValueB) {
      return -1;
    }

    const isValueAOpen = normalizedValueA === "requested";
    const isValueBOpen = normalizedValueB === "requested";

    if (isValueAOpen && !isValueBOpen) {
      return 1;
    }
    if (!isValueAOpen && isValueBOpen) {
      return -1;
    }

    // statuses must be equal
    return 0;
  };
};

const supportStatusPropertyKeys = [
  "leadership",
  "perseverance",
  "effectiveCommunication",
  "innovativeThinking",
];

const handleSorting = ({ studentsList, propertyKey, sortDirection }) => {
  let rowsToSort = Object.values(studentsList);
  let sortedRows = [];

  if (!propertyKey || !sortDirection) {
    return studentsList;
  }

  // Handle Name and Agegroup using sortByString functions.
  if (propertyKey === "name") {
    if (sortDirection === "ascending") {
      const sortingHandler = getSortByStringAscending(propertyKey);
      sortedRows = rowsToSort.sort(sortingHandler);
    } else {
      const sortingHandler = getSortByStringDescending(propertyKey);
      sortedRows = rowsToSort.sort(sortingHandler);
    }

    // Handle any numerical properties using sortByNumber functions.
  } else if (supportStatusPropertyKeys.includes(propertyKey)) {
    if (sortDirection === "sent") {
      // SORTING BY SENT SUPPORT
      const sortingHandler = getSortBySentRequests(propertyKey);
      sortedRows = rowsToSort.sort(sortingHandler);
    } else {
      // SORTING BY REQUESTED
      const sortingHandler = getSortByOpenRequests(propertyKey);
      sortedRows = rowsToSort.sort(sortingHandler);
    }
  }

  const result = {};
  sortedRows.forEach((row) => {
    const { userId } = row;
    result[userId] = row;
  });

  return result;
};

export default handleSorting;

// Packages
import React from "react";
import { Auth } from "aws-amplify";

// Components
import { Button, Form } from "react-bootstrap";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";
import VerificationCodeEmail from "../../../components/CustomAuthenticator/VerificationCodeEmail";
// import VerificationNumberInputs from "../../../components/CustomAuthenticator/VerificationNumberInputs";
import VerificationNumberInputs from "../../components/VerificationNumberInputs/VerificationNumberInputs";

// Styles
import "./VerifyAccount.scss";

/**
 * VerifyAccount.
 *
 * Handles when a user wants to reset their password; either logged in or not logged in. Prompts user to specify the verification code sent to them and a new password.
 *
 * @changeAuthState Allows for us to change the state in the CustomAuthenticator.
 * @email The users email address.
 * @origin Either 'forgot' or 'reset'. Default is 'forgot'. This param drives the 'Send New Code' feature within the form.
 */

function VerifyAccount({ changeAuthState, origin = "forgot" }: any) {
  // previously stored from create
  const email = sessionStorage.getItem("email") || "";
  const userId = sessionStorage.getItem("userId") || "";

  // set so we avoid losing on refresh
  sessionStorage.setItem("AuthState", "Verify");
  const [errors, setErrors] = React.useState<any>({ code: "" });
  const [formData, setFormData] = React.useState<any>({
    code: "",
    // password: ''
  });

  const [codes, setCodes] = React.useState<string>("");

  const checkValidity = () => {
    let errors = 0;
    let errorValues = { code: "" };
    if (formData.code.trim() === "") {
      errorValues.code = "Enter the code sent to you.";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }

    return true;
  };

  // const handleSave = async () => {
  //   if (checkValidity()) {
  //     try {
  //       let x = sessionStorage.setItem('code', formData.code);
  //

  //       const confirmStatus = Auth.forgotPasswordSubmit(email, formData.code, '')
  //         .then((data) => console.log(data))
  //         .catch((err) => console.log(err));

  //

  //       changeAuthState({ screen: "ResetPassword" });
  //     } catch (error) {
  //       console.log("error confirming", error);
  //     }
  //   }
  // };

  const handleVerify = async () => {
    try {
      const confirmStatus = await Auth.confirmSignUp(userId, codes);

      if (confirmStatus === "SUCCESS") {
        // reset storage and redirect
        sessionStorage.removeItem("userId");

        changeAuthState({ screen: "Success" });
      } else {
        // setError(true);
      }
    } catch (error) {
      console.log("error confirming", error);
      // setError(true);
    }
  };

  // const handleCodeChanges = (code: string) => {
  //   setFormData({ ...formData, code });
  // };

  const handleCodeChanges = (code: string) => {
    setCodes(code);
  };

  const resetCodeValidity = () => {
    if (errors.code.length > 0) {
      setErrors({ ...errors, code: "" });
    }
  };

  //create back button
  const handleBack = () => {
    changeAuthState({ screen: "SignIn" });
  };

  return (
    <div className="reskin-verify-account-container">
      <div className="ImageContainer">
        <div className="TopContainer">
          <div className="text-container header-container">
            <p className="header-text m-0">SkillTrack</p>
          </div>
          <div className="text-container subtext-container">
            <p className="subtitle-text m-0">
              SkillTrack will help you identify your strengths in employability
              skills.
            </p>
          </div>
        </div>
        <div className="BottomContainer">
          <p className="boldText">Verify Account</p>
          <div className="InputContainer">
            <section className="reset">
              <div className="wrapper container pb-4">
                <Form className="form" noValidate>
                  <div className="items">
                    <VerificationCodeEmail email={email} />
                    <VerificationNumberInputs
                      isInvalid={errors.code.length > 0 ? true : false}
                      codes={codes}
                      handleCodeChanges={handleCodeChanges}
                      origin={origin}
                      userIdentifier={email}
                      resetValidity={resetCodeValidity}
                      setFullCode={setCodes}
                    />
                  </div>

                  <div className="controls-container">
                    <ReskinButton
                      variant="primary"
                      text="Verify"
                      customClasses="verify-button button-control"
                      onClick={handleVerify}
                    />
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyAccount;

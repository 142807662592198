import "./trait-card.scss";

interface TraitCardProps {
  title: string;
  subtitle: string;
  content: string;
}

export const TraitCard = ({ title, subtitle, content }: TraitCardProps) => {
  return (
    <div className="trait-card">
      <div className="trait-card-header">
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
      </div>
      <div className="content">{content}</div>
    </div>
  );
};

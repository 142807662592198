interface TextCellProps {
  text: string | any;
  size?: string;
  ariaLabel?: string;
}

export function TextCell({ size, text, ariaLabel }: TextCellProps) {
  const sizeClass = size ? size : "col";

  return (
    <td className={`${sizeClass} cell text-cell`}>
      <span
        className="m-0"
        tabIndex={ariaLabel ? 0 : -1}
        aria-label={ariaLabel ? ariaLabel : ""}
      >
        {text}
      </span>
    </td>
  );
}

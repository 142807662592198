// Packages
import React from "react";
import { useDispatch } from "react-redux";

// Redux
import { logoutUser } from "../../../../redux/actions";
import { useSelector } from "react-redux";

// Store
import { RootState } from "../../../../redux/reducers";

// Components
import ReskinMenu from "../ReskinMenu/ReskinMenu";
import TwoButtonModal from "../../Modals/TwoButtonModal/TwoButtonModal";
import ReskinButton from "../../Buttons/ReskinButton/ReskinButton";
import SignOutModal from "../../Modals/SignOutModal/SignOutModal";

// Assets
import { ReactComponent as NavPersonCircle } from "../../../../assets/reskin/icons/nav-person-circle-charcoal.svg";
import { ReactComponent as QuestionMark } from "../../../../assets/reskin/icons/nav-question-circle-charcoal.svg";
import { ReactComponent as SignOut } from "../../../../assets/reskin/icons/sign-out-charcoal.svg";
import { ReactComponent as TrashCan } from "../../../../assets/reskin/icons/charcoal-trash-can.svg";
import {
  EventBridgeClient,
  PutEventsCommand,
} from "@aws-sdk/client-eventbridge";

// GraphQL
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const AssessmentMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useSelector((state: RootState) => state.user);

  // Delete Account Modal
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    React.useState(false);
  const DeleteAccountModalBody = (
    <>
      <p>Are you sure you want Delete Account?</p>
    </>
  );
  const DeleteAccountButton = (
    <ReskinButton
      variant="secondary"
      size="small"
      onClick={async () => {
        // TODO: Waiting for full user deletion mutation.
        const user = await Auth.currentUserCredentials();
        const client = new EventBridgeClient({
          region: "us-east-1",
          credentials: {
            accessKeyId: user.accessKeyId,
            secretAccessKey: user.secretAccessKey,
            sessionToken: user.sessionToken,
          },
        });

        let requestPayload = {
          userId: userId,
        };

        const params = {
          Entries: [
            {
              EventBusName: "psq",
              Detail: JSON.stringify(requestPayload),
              DetailType: "deleteAccount",
              Source: "org.ets.psq." + process.env.REACT_APP_ENV,
            },
          ],
        };
        const EventBridgeEvent = new PutEventsCommand(params);

        try {
          const submitForScoring = await client.send(EventBridgeEvent);
        } catch (Error: unknown) {
          console.log("error", Error);
        }

        await deleteUser();
        dispatch(logoutUser());
      }}
      text="Delete Account"
    />
  );
  const CancelDeleteButton = (
    <ReskinButton
      variant="primary"
      size="small"
      onClick={() => setShowSignOutModal(false)}
      text="Do Not Delete"
    />
  );

  // Sign Out Modal
  const [showSignOutModal, setShowSignOutModal] = React.useState(false);

  // Help Modal
  const emailUs = () => {
    window.location.href = "mailto:skilltracksupport@ets.org";
  };
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const HelpModalBody = (
    <>
      <p className="m-0">Need support? Reach out to us at:</p>
      <p>skilltracksupport@ets.org</p>
    </>
  );
  const HelpCancelButton = (
    <ReskinButton
      variant="secondary"
      size="small"
      onClick={() => setShowHelpModal(false)}
      text="Cancel"
    />
  );
  const HelpEmailButton = (
    <ReskinButton
      variant="primary"
      size="small"
      onClick={() => emailUs()}
      text="Email Us"
    />
  );

  const NavPersonIcon = <NavPersonCircle />;
  const QuestionMarkIcon = <QuestionMark />;
  const SignOutIcon = <SignOut />;
  const TrashCanIcon = <TrashCan />;

  const assessmentMenuItems = [
    {
      Icon: NavPersonIcon,
      onClick: () => navigate("/profile/my-account"),
      label: "My Account",
      isFirstItem: true,
    },
    {
      Icon: QuestionMarkIcon,
      onClick: () => setShowHelpModal(true),
      label: "Help",
    },
    {
      Icon: TrashCanIcon,
      onClick: () => setShowDeleteAccountModal(true),
      label: "Delete Account",
    },
    {
      Icon: SignOutIcon,
      onClick: () => setShowSignOutModal(true),
      label: "Sign Out",
      isLastItem: true,
    },
  ];

  return (
    <>
      <ReskinMenu menuItems={assessmentMenuItems} />
      <TwoButtonModal
        onClose={() => setShowHelpModal(false)}
        show={showHelpModal}
        headerText="Help"
        bodyContent={HelpModalBody}
        LeftButton={HelpCancelButton}
        RightButton={HelpEmailButton}
      />
      <TwoButtonModal
        onClose={() => setShowDeleteAccountModal(false)}
        show={showDeleteAccountModal}
        headerText="Delete Account?"
        bodyContent={DeleteAccountModalBody}
        LeftButton={DeleteAccountButton}
        RightButton={CancelDeleteButton}
      />
      {showSignOutModal && (
        <SignOutModal
          show={showSignOutModal}
          handleClose={() => setShowSignOutModal(false)}
        />
      )}
    </>
  );
};

async function deleteUser() {
  try {
    const result = await Auth.deleteUser();
    console.log(result);
  } catch (error) {
    console.log("Error deleting user", error);
  }
}

export default AssessmentMenu;

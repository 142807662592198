/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Icon, Text, View } from "@aws-amplify/ui-react";
export default function PairsButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Text: {},
        OuterRing: {},
        CircleButton: {},
        ContentWrapper: {},
        Vector: {},
        "Icon/Check": {},
        PairsButton: {},
      },
      variantValues: { isSelected: "true" },
    },
    {
      overrides: {
        Text: { color: "rgba(53,60,62,1)" },
        OuterRing: {},
        CircleButton: {},
        ContentWrapper: {},
        Vector: {
          paths: [
            {
              d: "M17.5 1.41L5.5 13.41L0 7.91L1.41 6.5L5.5 10.58L16.09 0L17.5 1.41Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Icon/Check": {},
        PairsButton: { backgroundColor: "rgba(242,242,242,1)" },
      },
      variantValues: { isSelected: "false" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {},
  );
  return (
    <>
      <View
        width="320px"
        height="152px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        // overflow="hidden"
        position="relative"
        borderRadius="16px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(48,57,108,1)"
        {...getOverrideProps(overrides, "PairsButton")}
        {...rest}
      >
        <View
          padding="0px 0px 0px 0px"
          width="328px"
          height="60px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="26px"
          left="16px"
          {...getOverrideProps(overrides, "ContentWrapper")}
        >
          <Text
            fontFamily="Open Sans"
            fontSize="14px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="235px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="64px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Even in situations where I feel I have something important to say, I wait to be called on to speak."
            {...getOverrideProps(overrides, "Text")}
          ></Text>
          <View
            padding="0px 0px 0px 0px"
            width="40px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="10px"
            left="0px"
            {...getOverrideProps(overrides, "CircleButton")}
          >
            <Icon
              width="40px"
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M38.5 20C38.5 30.2173 30.2173 38.5 20 38.5L20 41.5C31.8741 41.5 41.5 31.8741 41.5 20L38.5 20ZM20 38.5C9.78273 38.5 1.5 30.2173 1.5 20L-1.5 20C-1.5 31.8741 8.12588 41.5 20 41.5L20 38.5ZM1.5 20C1.5 9.78273 9.78273 1.5 20 1.5L20 -1.5C8.12588 -1.5 -1.5 8.12588 -1.5 20L1.5 20ZM20 1.5C30.2173 1.5 38.5 9.78273 38.5 20L41.5 20C41.5 8.12588 31.8741 -1.5 20 -1.5L20 1.5Z",
                  stroke: "rgba(93,129,55,1)",
                  fillRule: "nonzero",
                  strokeWidth: 1,
                },
                {
                  d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "OuterRing")}
            ></Icon>
          </View>
        </View>
        <View
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="44px"
          left="24px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Icon/Check")}
        >
          <Icon
            width="17.5px"
            height="13.41px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 17.5,
              height: 13.410003662109375,
            }}
            paths={[
              {
                d: "M17.5 1.41L5.5 13.41L0 7.91L1.41 6.5L5.5 10.58L16.09 0L17.5 1.41Z",
                fill: "rgba(93,129,55,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="23.29%"
            bottom="20.83%"
            left="14.58%"
            right="12.5%"
            {...getOverrideProps(overrides, "Vector")}
          ></Icon>
        </View>
      </View>
    </>
  );
}

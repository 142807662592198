import React from "react";
import { Bar as BarChart } from "react-chartjs-2";

function BarReskin({ scores, traitData, totalStudents }: any) {
  const levelScorePercentage = (dimension: any, value: number) => {
    //let data = scores;
    let data = scores.filter(
      (subtrait: any, i: any) => subtrait.number === dimension,
    );

    const overtotal = data.reduce(function (sum: any, current: any) {
      return sum + current.total;
    }, 0);

    return ((value / overtotal) * 100).toFixed(2);
  };

  const levelScoreNumber = (dimension: any, score: number) => {
    const val = scores.filter(
      (subtrait: any) =>
        subtrait.number === dimension && subtrait.score === score,
    )[0];

    return val.total;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    minBarLength: 5,
    categoryPercentage: 0.5,
    barPercentage: 1,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
      tooltip: {
        callbacks: {
          label: (yDataPoint: any) => {
            return (
              yDataPoint.dataset.label +
              ": " +
              ((yDataPoint.raw * totalStudents) / 100).toFixed(0) +
              " students"
            );
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: function (value: any) {
            return `${value}%`;
          },
          font: {
            size: 12,
            family: "Open Sans",
            weight: "bold",
          },
        },
        grid: {
          borderDash: [3, 2],
          color: "#C0C3C3",
          borderWidth: 0,
          lineWidth: 0.5,
        },

        beginAtZero: true,
      },
      y1: {
        min: 0,
        max: totalStudents,
        ticks: {
          stepSize: totalStudents / 10,
          callback: function (value: any) {
            return [totalStudents, 0].includes(value) ? value : "";
          },
          font: {
            size: 12,
            family: "Open Sans",
            weight: "bold",
          },
        },
        type: "linear" as const,
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: "Open Sans",
            weight: "bold",
          },
        },
        beginAtZero: true,
        width: 0,
      },
    },
  };

  const labels = traitData.dimension;
  const colors: any = {
    leadership: {
      primary: "#30396C",
      secondary: "#959AB7",
      tertiary: "#DEE1EE",
    },
    effectivecommunication: {
      primary: "#5D8137",
      secondary: "#9DB088",
      tertiary: "#DEECCF",
    },
    perseverance: {
      primary: "#DD614A",
      secondary: "#E5B5AC",
      tertiary: "#EBDDDA",
    },
    innovativethinking: {
      primary: "#8865A4",
      secondary: "#B2A3BF",
      tertiary: "#D3CCD9",
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: "Developing",
        data: labels.map((ele: any, i: number) =>
          levelScorePercentage(
            traitData.number[i],
            levelScoreNumber(traitData.number[i], 0),
          ),
        ),
        backgroundColor: colors[traitData.type].tertiary,
      },
      {
        label: "Approaching",
        data: labels.map((ele: any, i: number) =>
          levelScorePercentage(
            traitData.number[i],
            levelScoreNumber(traitData.number[i], 1),
          ),
        ),
        backgroundColor: colors[traitData.type].secondary,
      },
      {
        label: "Demonstrating",
        data: labels.map((ele: any, i: number) =>
          levelScorePercentage(
            traitData.number[i],
            levelScoreNumber(traitData.number[i], 2),
          ),
        ),
        backgroundColor: colors[traitData.type].primary,
      },
    ],
  };

  return (
    <>
      <BarChart data={data} height={"400px"} options={options} />
    </>
  );
}

export default BarReskin;

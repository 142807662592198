// Components
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
// Styles
import "./ReskinButton.scss";
import React from "react";

type TypeOfButton = "button" | "submit" | "reset" | undefined;

interface ReskinButtonProps {
  text: string;
  onClick?: Function;
  customClasses?: string;
  disabled?: boolean;
  size?: "xsmall" | "small" | "large" | "grow";
  variant?: "primary" | "secondary";
  tabIndex?: number;
  type?: TypeOfButton;
  loadingIndicator?: boolean;
}
const ReskinButton = ({
  text,
  onClick,
  customClasses,
  disabled,
  size,
  variant,
  tabIndex,
  type,
  loadingIndicator,
}: ReskinButtonProps) => {
  const [loading, setLoading] = React.useState(false);
  const variantClass = variant ? variant : "primary";
  const buttonType = type ? type : "button";
  const disabledClass = disabled ? "disabled" : null;
  const jsxElement: JSX.Element = (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      {text}
      <Spinner style={{ marginLeft: "5px" }} animation="border" />{" "}
    </span>
  );
  return (
    <Button
      bsPrefix="psq-btn"
      tabIndex={tabIndex}
      type={buttonType}
      className={`reskin-button  ${size || ""} ${customClasses || ""} ${disabledClass || variantClass}`}
      disabled={disabled}
      onClick={(e) => {
        setLoading(true);
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {loadingIndicator && loading ? jsxElement : text}
    </Button>
  );
};

export default ReskinButton;

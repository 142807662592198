// Components
import {
  TableHeader,
  TableHeaderWithHamburger,
} from "../../../../../components/Table/headerCells";

// Services
import EventService from "../../../../../services/EventService/event.service";

// Utils
import getHamburgerMenuItems from "./getHamburgerMenuItems";

interface HeaderRowProps {
  sortingHandler: Function;
}

function HeaderRow({ sortingHandler }: HeaderRowProps) {
  const handleHamburgerClick = (column: string) => {
    EventService.track({
      event_type: "user",
      event_name: "hei_list_sort",
      user_type: "hei",
      event_data: {
        column,
      },
    });
  };

  return (
    <tr className=" flex-nowrap">
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Bookmark");
        }}
        text="Flagged Students"
        size="col-1"
        isActive={true}
        dropdownReadableLabel="Filter bookmarks by"
        dropdownId="sort-bookmarks-dropdown"
        menuItems={[
          {
            text: "Sort Bookmarked",
            onClick: () =>
              sortingHandler({
                propertyKey: "bookmarked",
                sortDirection: "",
              }),
          },
        ]}
      />
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("name");
        }}
        size="col-1"
        text="Name"
        isActive={true}
        dropdownReadableLabel="Filter names by"
        dropdownId="name-order-dropdown"
        menuItems={getHamburgerMenuItems("name", sortingHandler)}
      />
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Age Range");
        }}
        text="Age Range"
        size="col-1"
        isActive={true}
        dropdownReadableLabel="Filter age range by"
        dropdownId="age-range-order-dropdown"
        menuItems={getHamburgerMenuItems("ageGroup", sortingHandler)}
      />
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Year Enrolled");
        }}
        text="Year Enrolled"
        size="col-1"
        isActive={true}
        dropdownReadableLabel="Filter enrollment year range by"
        dropdownId="enrollment-year-order-dropdown"
        menuItems={getHamburgerMenuItems("enrollmentYear", sortingHandler)}
      />
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Leadership");
        }}
        text="Leadership"
        size="col-1 ps-0"
        isActive={true}
        dropdownReadableLabel="Filter leadership scores by"
        dropdownId="leadership-score-order-dropdown"
        menuItems={getHamburgerMenuItems("leadershipScoreBand", sortingHandler)}
      />

      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Effective Communication");
        }}
        text="Effective Communication"
        size="col-1 wideIconCell effectiveCommunicationHeader"
        isActive={true}
        dropdownReadableLabel="Filter effective communication scores by"
        dropdownId="effective-communication-score-order-dropdown"
        menuItems={getHamburgerMenuItems(
          "communicationScoreBand",
          sortingHandler,
        )}
      />
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Perseverance");
        }}
        text="Perseverance"
        size="col-1 wideIconCell"
        isActive={true}
        dropdownReadableLabel="Filter perseverance scores by"
        dropdownId="perseverance-score-order-dropdown"
        menuItems={getHamburgerMenuItems(
          "perseveranceScoreBand",
          sortingHandler,
        )}
      />
      <TableHeaderWithHamburger
        onHamburgerClick={() => {
          handleHamburgerClick("Innovative Thinking");
        }}
        text="Innovative Thinking"
        size="col-1 ps-1"
        isActive={true}
        dropdownReadableLabel="Filter innovative thinking scores by"
        dropdownId="innovative-thinking-score-order-dropdown"
        menuItems={getHamburgerMenuItems(
          "innovativeThinkingScoreBand",
          sortingHandler,
        )}
      />
      {/* <TableHeader size="col-3 flex-shrink-1" text="My Comments" />
      <TableHeader size="col-1 flex-shrink-1" text="Send Support" /> */}
    </tr>
  );
}

export default HeaderRow;

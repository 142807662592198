// packages
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// Components
import Panel from "./panel";
import Send from "./send";

function Support({ setSelectedTab }: any) {
  React.useEffect(() => {
    // set the tab so student always selected in /support
    setSelectedTab("support");
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate replace to="/fullsummary/support/panel" />}
      />
      <Route path="/panel" element={<Panel />} />
      <Route path="/send/:userSessionId" element={<Send />} />
    </Routes>
  );
}

export default Support;

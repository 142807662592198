import BulletedStatement from "../../../../../../components/Card/CardContent/BulletedStatement";

type BulletedStatementType = {
  statement: string;
  bullets: string[];
};

interface SubTraitCardContentProps {
  cardLanguage: string[] | BulletedStatementType[];
}

const SubTraitCardContent = ({ cardLanguage }: SubTraitCardContentProps) => {
  const RenderedLanguage = cardLanguage.map((languageNode, index) => {
    if (typeof languageNode === "string") {
      return (
        <section className="language-node" key={index}>
          <p>{languageNode}</p>
        </section>
      );
    } else {
      const { statement, bullets } = languageNode;
      return (
        <section className="language-node" key={index}>
          <BulletedStatement statement={statement} bullets={bullets} />
        </section>
      );
    }
  });

  return <div className="language-container">{RenderedLanguage}</div>;
};

export default SubTraitCardContent;

// Packages
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Utilities
import { ucWords, removeWhiteSpace } from "../../../../../utilities/strings";

// Assets
import { ReactComponent as LeadershipImage } from "../../../../../assets/fullsummary/Leadership.svg";
import { ReactComponent as PerseveranceImage } from "../../../../../assets/fullsummary/Perseverance.svg";
import { ReactComponent as CommunicationImage } from "../../../../../assets/fullsummary/Communication.svg";
import { ReactComponent as InnovativeImage } from "../../../../../assets/fullsummary/Innovative.svg";
import { ReactComponent as CaretRight } from "../../../../../assets/reskin/icons/chevron-right.svg";

// Components
import Graph from "./GraphReskin";

// Services
import EventService from "../../../../../services/EventService/event.service";

// Styles
import "./Category.scss";

function Category({ performers, trait, counts, setSelectedTab }: any) {
  const navigate = useNavigate();

  const [isLoadingCounts, setLoadingCounts] = React.useState<boolean>(true);
  const [categoryCounts, setCategoryCounts] = React.useState<any>([]);

  React.useEffect(() => {
    if (isLoadingCounts) {
      counts.then((data: any) => {
        setCategoryCounts(data);

        EventService.track({
          event_type: "user",
          event_name: "hei_overview_trait_counts",
          user_type: "hei",
          event_data: {
            trait,
            counts: data,
          },
        });

        setLoadingCounts(false);
      });
    }
  }, [isLoadingCounts]);

  const determineScore = () => {
    const scores = [
      categoryCounts.highCount,
      categoryCounts.medCount,
      categoryCounts.lowCount,
    ];
    const max = Math.max.apply(null, scores);
    const index = scores.indexOf(max);

    switch (index) {
      case 0:
        return "Demonstrating";
      case 1:
        return "Approaching";
      case 2:
        return "Developing";
    }
  };

  const determineIcon = () => {
    switch (trait) {
      case "leadership":
        return <LeadershipImage />;
      case "effective communication":
        return <CommunicationImage />;
      case "perseverance":
        return <PerseveranceImage />;
      case "innovative thinking":
        return <InnovativeImage />;
    }
  };

  const handleGetMoreInsights = () => {
    EventService.track({
      event_type: "user",
      event_name: "hei_overview_getmore_click",
      user_type: "hei",
      event_data: {
        trait,
      },
    });

    setSelectedTab(`traits/${removeWhiteSpace(trait.toLowerCase())}`);
    navigate(`/fullsummary/traits/${removeWhiteSpace(trait.toLowerCase())}`);
  };

  // determine scoring
  const score = determineScore();

  if (isLoadingCounts) return <></>;

  return (
    <div className="category">
      <div className={`category-header`}>
        {/* <div className="icon">{determineIcon()}</div> */}
        <div>
          {/* {score} */}
          <h2 className={`${trait.toLowerCase().split(" ").join("")}-color`}>
            {ucWords(trait)}
          </h2>
        </div>
        <div className="options">
          <Button
            bsPrefix="caret-right"
            onClick={() => handleGetMoreInsights()}
          >
            <CaretRight />
          </Button>
        </div>
      </div>

      {/* <p>
        {
          TRAIT_DEFINITIONS[
            trait.toLowerCase() as keyof typeof TRAIT_DEFINITIONS
          ]
        }
      </p> */}

      <div className="info">
        <div className="graph">
          <Graph counts={categoryCounts} trait={trait} />
        </div>

        {/* <div className="performers">
          <div className="">
            <Students
              size="small"
              type="Demonstrating"
              trait={trait}
              performers={performers.high}
            />
          </div>

          <div className="">
            <Students
              size="small"
              type="Developing"
              trait={trait}
              performers={performers.low}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Category;

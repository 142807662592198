// Packages

// configs
import EventService from "../EventService/event.service";

// Actions
import {
  updateUserAssessmentState,
  userIsUploading,
} from "../../redux/actions";

// Types
import { MMAIAuthenticateResponse } from "./types";
import UserService from "../User/user.service";
import { AssessmentStatusType, UserResponses } from "../../models";

//Graphql and dynamic query
import { updateUserResponses } from "../../graphql/mutations";
import { getUserResponses } from "../../graphql/queries";
import createOrUpdate, { getOrList } from "../../components/dynamicqueries";

class MMAIService {
  static Authenticate = async (): Promise<MMAIAuthenticateResponse> => {
    try {
      const request = await fetch(
        "https://api.mmai-ghe-dev.ets-plalab-ghe-nonprod.c.ets.org/authenticate-user?teamID=ghe",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      const response = await request.json();

      if (response.Token) {
        return { status: "success", token: response.Token };
      } else {
        return {
          status: "fail",
          message: "Unable to authenticate with MMAI.",
        };
      }
    } catch (error) {
      console.log(error);
      return {
        status: "fail",
        message: "Unable to authenticate with MMAI.",
      };
    }
  };

  static PresignedUrl = async (token: string, filename: string) => {
    try {
      let request = await fetch(
        `https://api.mmai-ghe-dev.ets-plalab-ghe-nonprod.c.ets.org/preSigned?file=${filename}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
          },
        },
      );
      let response = await request.json();
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  static Upload = async (
    file: File,
    presigned: any,
    attempts: any,
    duration: any,
    userResponses: any,
    userAssessmentStateId: any,
    dispatch: any,
    sessionId: any,
    userId: any,
    s3VideoResponseLocation: string,
  ) => {
    const { url, fields } = presigned;

    try {
      // create form data
      let formData = new FormData();
      Object.entries(fields).forEach(([key, value]: [string, any]) => {
        switch (key) {
          default:
            formData.append(key, value);
            break;

          case "x-amz-meta-team_id":
            formData.append(key, "ghe");
            break;

          case "x-amz-meta-services":
            formData.append(key, "['all']");
            break;

          case "x-amz-meta-event_source":
            formData.append(key, "org.ets.psq." + process.env.REACT_APP_ENV);
            break;
        }
      });

      formData.append("file", file);

      let request = await fetch(url, {
        method: "POST",
        body: formData,
      });

      // update for uploading progress tracking
      dispatch(userIsUploading(false));

      ProcessActionsForUploadSuccess(
        userId,
        attempts,
        duration,
        userAssessmentStateId,
        userResponses,
        dispatch,
        file,
        sessionId,
        s3VideoResponseLocation,
      );
      return { status: "success" };
    } catch (error: unknown) {
      console.log(error);
      // typescript doesn't like those unknowns
      const customError = error as string;
      dispatch(userIsUploading(false));
      ProcessActionsForUploadFailure(attempts, duration, file, customError);
      return { status: "fail", error: customError };
    }
  };
}

const ProcessActionsForUploadSuccess = async (
  userId: any,
  attempts: any,
  duration: any,
  userAssessmentStateId: any,
  userResponses: any,
  dispatch: any,
  file: File,
  sessionId: any,
  s3VideoResponseLocation: string,
) => {
  // dispatch(
  //   updateUserAssessmentState({
  //     userId,
  //     userAssessmentStateId,
  //     statusType: AssessmentStatusType.VIDEO_COMPLETE,
  //   })
  // );

  EventService.track({
    event_type: "user",
    event_name: "user_submits_recording",
    user_type: "student",
    event_data: {
      section: "Communication",
      record_attempts: attempts,
      record_length: duration,

      file_name: file.name,
      upload_status: true,
    },
  });
  userResponses = await getOrList(getUserResponses, "getUserResponses", {
    id: userResponses.id,
  });

  if (userResponses) {

    const input = {
      id: userResponses.id,
      videoResponseLocation: file.name.split(".")[0], // remove the extension while saving
      userSessionId: sessionId,
      _version: userResponses._version,
      s3VideoResponseLocation: s3VideoResponseLocation,
    };

    // await createOrUpdate(updateUserResponses, input);
    const attempt = await createOrUpdate(updateUserResponses, input);
    // alert("attempt was just logged");
  }
};

const ProcessActionsForUploadFailure = async (
  attempts: any,
  duration: any,
  file: File,
  uploadError: any,
) => {
  EventService.track({
    event_type: "user",
    event_name: "user_submits_recording",
    user_type: "student",
    event_data: {
      section: "Communication",
      record_attempts: attempts,
      record_length: duration,
      file_name: file.name,
      upload_status: false,
      error: uploadError,
    },
  });
};

export default MMAIService;

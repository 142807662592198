/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function BIQPill(props) {
  const { iconContainer, overrides, ...rest } = props;
  return (
    <View
      width="374px"
      height="94px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      borderRadius="24px 24px 24px 0px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(228,238,241,1)"
      {...getOverrideProps(overrides, "BIQPill")}
      {...rest}
    >
      <View
        width="40px"
        height="40px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 20px - 0px)"
        left="16px"
        padding="0px 0px 0px 0px"
        children={iconContainer}
        {...getOverrideProps(overrides, "IconContainer")}
      ></View>
      <Text
        fontFamily="Open Sans"
        fontSize="12px"
        fontWeight="400"
        color="#353C3E"
        lineHeight="18px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="278px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="calc(50% - 36px - 0px)"
        right="24px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Please do NOT exit out of the program before completing all parts of the activity, as your responses may not be saved if you exit early."
        {...getOverrideProps(overrides, "Text")}
      ></Text>
    </View>
  );
}

// Packages
import { ConsoleLogger } from "@aws-amplify/core";
import { parse } from "path";
import React from "react";

// Assets
import { ReactComponent as SmallClock } from "../../../../assets/reskin/icons/small-clock.svg";

function Timer({ max, duration }: any) {
  const SmallClockIcon = <SmallClock />;

  const humanTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);

    return minutes !== 0
      ? `${minutes}:${pad(seconds - minutes * 60)}`
      : `00 : ${pad(seconds - minutes * 60)}`;
  };

  const pad = (unit: number) => {
    let str = "" + unit;
    var pad = "00";

    return pad.substring(0, pad.length - str.length) + str;
  };
  return (
    <div className="reskin-timer-controller controller">
      <div className="pt-1 pb-1">
        {SmallClockIcon} {humanTime(duration)}
      </div>
      <div>Max: {max} min</div>
    </div>
  );
}

export default Timer;

// Packages
import { Suspense } from "react";
import { useSelector } from "react-redux";

// Components
import ScrollToTop from "./components/scrollToTop";

// Models
import { Usertype } from "./models";

// Routes
import RoutesInstitution from "./routes/institution";
import RoutesStudent from "./routes/student";

// Store
import { RootState } from "./redux/reducers";
import { Spinner } from "react-bootstrap";

function AppContent() {
  const { userType } = useSelector((state: RootState) => state.user);
  
  return (
    <>
      <div className="psq d-flex flex-column h-100 align-items-center">
          <ScrollToTop />
          <Suspense fallback={() => <Spinner className="spinner" animation="border" role="status" />}>
            {userType === Usertype.STUDENT ? (
              <div className="app-content-container">
                <RoutesStudent />
              </div>
            ) : (
              <>
                <div className="app-content-container-institution">
                  <RoutesInstitution />
                </div>
              </>
            )}
          </Suspense>
      </div>
    </>
  );
}

export default AppContent;

// Packages
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";
import ReskinQuestionCard from "../../../components/Cards/ReskinQuestionCard/ReskinQuestionCard";
import { Spinner } from "react-bootstrap";

// Styles
import "./RecordingQuestions.scss";

// Store
import { RootState } from "../../../../redux/reducers";

// GraphQl
import {
  searchUserResponses,
  listSectionItems,
  listUserResponses,
} from "../../../../graphql/queries.js";
import { updateUserResponses } from "../../../../graphql/mutations.js";
import createOrUpdate, {
  getOrList,
} from "../../../../components/dynamicqueries";
import { API } from "aws-amplify";

interface DynamicObject {
  [key: string | number]: string;
}

const RecordingQuestions = () => {
  let navigate = useNavigate();
  const { id, sections } = useSelector((state: RootState) => state.assessment);
  const { userId } = useSelector((state: RootState) => state.user);

  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [questions, setQuestions] = React.useState<any>([]);

  const backgroundColorCodex: DynamicObject = {
    0: "#EFF1FA",
    1: "#EAF3F5",
    2: "#F2F6EE",
  };

  React.useEffect(() => {
    // load questions from data store here
    if (id !== "" && questions.length === 0) getSectionItems();
    async function getSectionItems() {
      const items = (
        await getOrList(listSectionItems, "listSectionItems", { limit: 10000 })
      )
        .filter((c: any) => c.section.id === sections[0].id)
        .sort((a: any, b: any) => a.itemSequence - b.itemSequence);

      setQuestions(items);
      setLoading(false);
    }
    setLoading(false);
  }, [id, sections]);

  async function getUserResponseData(nextToken = null): Promise<any> {
    let filterVariables = {
      limit: 10000,
      filter: { userId: { eq: userId } },
      nextToken: nextToken,
    };

    const UserResponseData = (await API.graphql({
      query: listUserResponses,
      variables: filterVariables,
    })) as any;

    console.log("UserResponseData", UserResponseData);

    if (UserResponseData.data.listUserResponses.items.length == 0) {
      if (UserResponseData.data.listUserResponses.nextToken != null) {
        return await getUserResponseData(
          UserResponseData.data.listUserResponses.nextToken,
        );
      } else if (UserResponseData.data.listUserResponses.nextToken == null) {
        return null;
      }
    } else if (UserResponseData.data.listUserResponses.items.length > 0) {
      return UserResponseData.data.listUserResponses.items;
    }
  }
  const selectQuestion = async (question: any) => {
    const UserResponseData = (await getUserResponseData(null)) as any;
    const input = {
      id: UserResponseData[0].id,
      selectedVideoQuestionId: question.id,
      _version: UserResponseData[0]._version,
    };
    await createOrUpdate(updateUserResponses, input);

    navigate("/assessment/communication/record", { state: question });
  };

  return (
    <div className="recording-questions">
      <AssessmentHeader isTransparent={true} withBackButton={true} />
      <div className="page-header">
        <h1>Select a Question</h1>
        <p className="instructions">
          Read the three questions below and select one that you would like to
          respond to.
        </p>
      </div>

      <div className="page-body">
        {isLoading && (
          <div className="loading">
            <Spinner animation="border" variant="light" />
          </div>
        )}

        {!isLoading &&
          questions.map((question: any, index: number) => {
            const questionItem = question.item;

            return (
              <div
                className="card-container"
                key={`recording-question-${index}`}
              >
                <ReskinQuestionCard
                  text={questionItem.content}
                  backgroundColor={backgroundColorCodex[index]}
                  onButtonClick={() => selectQuestion(questionItem)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RecordingQuestions;

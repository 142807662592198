// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const Itemresponsetype = {
  MOST_LIKELY: "MOST_LIKELY",
  LEAST_LIKELY: "LEAST_LIKELY",
  SELECTED: "SELECTED",
  UNSELECTED: "UNSELECTED",
};

const Highleveltraittype = {
  LEADERSHIP: "LEADERSHIP",
  INNOVATIVE_THINKING: "INNOVATIVE_THINKING",
  COMMUNICATION: "COMMUNICATION",
  PERSEVERANCE: "PERSEVERANCE",
};

const Usertype = {
  STUDENT: "STUDENT",
  INSTITUTION: "INSTITUTION",
};

const Displaytype = {
  RADIO: "RADIO",
  CHECKBOX: "CHECKBOX",
  DROPDOWN: "DROPDOWN",
};

const Itemtype = {
  VIDEO: "VIDEO",
  TRIPLET: "TRIPLET",
};

const AssessmentStatusType = {
  ASSESSMENT_STARTED: "ASSESSMENT_STARTED",
  BIQ_COMPLETE: "BIQ_COMPLETE",
  SAMPLE_INSTRUCTION_COMPLETE: "SAMPLE_INSTRUCTION_COMPLETE",
  TRIPLET_INPROGRESS: "TRIPLET_INPROGRESS",
  TRIPLET_COMPLETE: "TRIPLET_COMPLETE",
  VIDEO_FAILED: "VIDEO_FAILED",
  TRIPLET_FAILED: "TRIPLET_FAILED",
  VIDEO_COMPLETE: "VIDEO_COMPLETE",
  VIDEO_INPROGRESS: "VIDEO_INPROGRESS",
  VIDEO_SKIPPED: "VIDEO_SKIPPED",
  ASSESSMENT_COMPLETE: "ASSESSMENT_COMPLETE",
  ASSESSMENT_FAILED: "ASSESSMENT_FAILED",
};

const Profileattributetype = {
  AGE_GROUP: "ageGroup",
  GENDER: "gender",
  EMPL_STATUS: "emplStatus",
  ETHNICITY: "ethnicity",
  GOAL: "goal",
  ENROLLMENT_STATUS: "enrollmentStatus",
  PROGRAM_YEAR: "programYear",
  FIELD_OF_STUDY: "fieldOfStudy",
  TRANSFER_TO_FOUR_YEAR: "transferToFourYear",
  WORK_EXPERIENCE: "workExperience",
  EDUCATION: "education",
};

const ScoreBand = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
};

const Preferencetype = {
  USEFUL: "USEFUL",
  NOTUSEFUL: "NOTUSEFUL",
  UNSELECTED: "UNSELECTED",
  FAVORITED: "FAVORITED",
  UNFAVORITED: "UNFAVORITED",
};

const Phasetype = {
  PRETEST: "PRETEST",
  POSTTEST: "POSTTEST",
};

const {
  UserResponses,
  BIQ,
  User,
  Item,
  Section,
  SectionItem,
  Assessment,
  AssessmentSection,
  Institution,
  UserAssessmentState,
  UserScores,
  Admin,
  CutOffScores,
  Events,
  Narratives,
  InstitutionNarratives,
  EventTracking,
  QuickTips,
  CandidateComments,
  HeiComments,
  HeiBookmarks,
  StudentRequest,
  StudentResource,
  InstitutionResponse,
  InstitutionUser,
  LearningResource,
  StudentRecommendedResources,
  ResourceReaction,
  UserConfidenceLevel,
  StudentVisitedLearningResource,
  StudentFavouriteLearningResource,
  LearningSelfReflection,
  LearningSelfReflectionCount,
  Insights,
  ScorableResponses,
  PairsScorableResponses,
  TripletResponses,
  ResourceInfo,
  Card,
} = initSchema(schema);

export {
  UserResponses,
  BIQ,
  User,
  Item,
  Section,
  SectionItem,
  Assessment,
  AssessmentSection,
  Institution,
  UserAssessmentState,
  UserScores,
  Admin,
  CutOffScores,
  Events,
  Narratives,
  InstitutionNarratives,
  EventTracking,
  QuickTips,
  CandidateComments,
  HeiComments,
  HeiBookmarks,
  StudentRequest,
  StudentResource,
  InstitutionResponse,
  InstitutionUser,
  LearningResource,
  StudentRecommendedResources,
  ResourceReaction,
  UserConfidenceLevel,
  StudentVisitedLearningResource,
  StudentFavouriteLearningResource,
  LearningSelfReflection,
  LearningSelfReflectionCount,
  Insights,
  Itemresponsetype,
  Highleveltraittype,
  Usertype,
  Displaytype,
  Itemtype,
  AssessmentStatusType,
  Profileattributetype,
  ScoreBand,
  Preferencetype,
  Phasetype,
  ScorableResponses,
  PairsScorableResponses,
  TripletResponses,
  ResourceInfo,
  Card,
};

// Packages
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import { Usertype } from "../../../../models";
import { logInUser } from "../../../../redux/actions";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as EyeSlash } from "../../assets/eyeSlash.svg";
import EventService from "../../../../services/EventService/event.service";
import UserService from "../../../../services/User/user.service";
import * as customQueries from "../../../../graphql/custom-queries";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import SimpleBackdrop from "../../../../ui-components/SimpleBackdrop";

declare global {
  interface Window {
    pendo: any;
  }
}

type formDataType = {
  email: string;
  password: string;
  code?: string;
  error?: string;
  success?: string;
  status?: string;
  validated?: boolean;
};

export const SignIn = ({ changeAuthState, userType }: any) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false)
  const [validated, setValidated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<formDataType>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<any>({
    email: "",
    password: "",
  });

  useEffect(() => {
    EventService.page({
      type: "enter",
      page: "User Sign In",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "User Sign In",
      });
    };
  }, []);

  const handleInputChange = (e: any) => {
    setValidated(false);
    setErrors({ email: "", password: "" });
    const { value, dataset } = e.target;
    const { prop } = dataset;
    setFormData({
      ...formData,
      [prop]: value,
    });
  };

  const checkValidity = () => {
    let errors = 0,
      email = "",
      password = "";

    if (formData.email === "") {
      email = "Please enter a valid email address.";
      errors++;
    }

    if (formData.password === "") {
      password = "Password cannot be empty";
      errors++;
    }

    if (errors > 0) {
      setErrors({ email, password });
      return false;
    }

    return true;
  };

  const handleSignIn = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();
    event.stopPropagation();

    setErrors({ email: "", password: "" });

    if (checkValidity() === true) {
      // form is valid
      try {
        const user = await Auth.signIn(formData.email, formData.password);
        const { sub, email } = user.attributes;

        const dsUser = await UserService.getDSUser(sub);

        if (!dsUser) throw new Error("DSUserNotFound: User not found.");

        if (!UserService.canUserProceed(dsUser, userType)) {
          setErrors({
            email: "Not a valid account type.",
            password: "",
          });
          setIsLoading(false);
          await Auth.signOut();
          return false;
        }

        // get user group
        let loggedInUserGroup = null;
        if (user.signInUserSession.idToken.payload["cognito:groups"]) {
          const groups =
            user.signInUserSession.idToken.payload["cognito:groups"];

          if (groups && groups.length > 0) {
            loggedInUserGroup = groups[0];
          }
        }

        if (!loggedInUserGroup) {
          loggedInUserGroup = userType;
        }

        // identify the user with our analytics
        EventService.identify(dsUser.userId, {
          assessmentId: dsUser.assessmentState?.assessmentId,
          sessionId: dsUser.assessmentState?.sessionId,
          userGroup: loggedInUserGroup,
        });

        window.pendo.initialize({
          visitor: {
            id: dsUser.userId,
          },
        });

        dispatch(logInUser(dsUser, email, userType));
      } catch (error: unknown) {
        console.error(error);
        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];

        EventService.track({
          event_type: "user",
          event_name: "user_login_attempt",
          user_type:
            userType === Usertype.INSTITUTION ? "institution" : "student",
          event_data: {
            email: formData.email,
            error: customError.toString(),
          },
        });

        switch (errorHandling) {
          case "NotAuthorizedException":
            setErrors({ email: "Incorrect email or password." });
            break;

          case "UserNotFoundException":
            setErrors({
              email: "An account does not exist for this email.",
              password: "",
            });
            break;

          case "DSUserInvalidType":
            setErrors({
              email: "Not a valid account type.",
              password: "",
            });
            break;

          case "UserNotConfirmedException":
            // store locally needed for  verification
            sessionStorage.setItem("email", formData.email);

            // need to get the userId
            let filterExpression = { email: { eq: formData.email } };

            try {
              const response = await API.graphql({
                query: customQueries.searchUsersByEmail,
                variables: { filter: filterExpression },
              });

              const responseObject = JSON.parse(JSON.stringify(response));

              sessionStorage.setItem(
                "userId",
                responseObject.data.searchUsers.items[0].userId,
              );

              await Auth.resendSignUp(formData.email);
              setIsLoading(false);
              changeAuthState({ screen: "Verify" });
            } catch (err) {
              console.log("error resending code: ", err);
              setErrors({
                email: "Unconfirmed account, try again later.",
                password: "",
              });
            }

            break;

          default:
            console.log(customError);
            setErrors({ email: "An error occured, try again.", password: "" });
            break;
        }
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handlePasswordKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSignIn(event);
    }
  };

  return (
    <div className="form-container">
      <h1 className="callout">
        Helping individuals identify strengths in their employability skills.
      </h1>
      <h1 className="form-title">Sign In</h1>
      <Form
        className="form"
        noValidate
        validated={validated}
        onSubmit={(e: any) => handleSignIn(e)}
      >
        <Form.Group className="mb-4" controlId="formEmail">
          <Form.Label className="label">
            <span>
              Email <span className="required">*</span>
            </span>
          </Form.Label>
          <Form.Control
            className="email"
            type="email"
            placeholder=""
            data-prop={"email"}
            onChange={handleInputChange}
            required
            isInvalid={errors.email !== ""}
            tabIndex={1}
          />
          <Form.Control.Feedback className="email-feedback" type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-5" controlId="formPassword">
          <Form.Label className="label password">
            <span>
              Password <span className="required">*</span>
            </span>
            <Button
              tabIndex={4}
              variant="link"
              onClick={() => changeAuthState({ screen: "ForgotPassword" })}
            >
              Forgot Password
            </Button>
          </Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder=""
            data-prop={"password"}
            onChange={handleInputChange}
            onKeyDown={handlePasswordKeyDown}
            required
            isInvalid={errors.password !== ""}
            tabIndex={2}
          />
          <Button
            className="password-eye"
            variant="white"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeSlash /> : <Eye />}
          </Button>
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-5" controlId="formSubmit">
          <ReskinButton
            text="Sign In"
            type="submit"
            tabIndex={3}
            variant="primary"
          />
        </Form.Group>
        <Form.Group className="mb-5" controlId="formCreate">
          <Form.Label className="label">
            No account?
            <Button
              variant="link"
              tabIndex={5}
              onClick={(e: any) => {
                e.preventDefault();
                changeAuthState({ screen: "SignUp" });
              }}
            >
              Sign Up
            </Button>
          </Form.Label>
        </Form.Group>
      </Form>
      {isLoading ? <SimpleBackdrop open={true} /> : null}
    </div>
  );
};

// Packages
import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as queries from "../../../graphql/queries";
import {
  updateUserAssessmentState,
  updateUserAssessmentStateStatus,
} from "../../../redux/actions";

// Components
import ScrollToTop from "../../../components/scrollToTop";
import SubTraitGraph from "./SubTraitGraph";
import SubTraitCarousel from "./SubTraitCarousel";
import HorizontalScaleRadio from "../../../components/RadioReskin/HorizontalScaleRadio";
import DisclaimerModal from "./DisclaimerModal/DisclaimerModal";
import { Button } from "react-bootstrap";
import { ReactComponent as ApproachingGraph } from "../../../assets/icons/bar/approaching-graph.svg";
import { ReactComponent as DevelopingGraph } from "../../../assets/icons/bar/developing-graph.svg";
import { ReactComponent as DemonstratingGraph } from "../../../assets/icons/bar/demonstrating-graph.svg";
import { ReactComponent as NoDataGraph } from "../../../assets/icons/bar/no-data-graph.svg";

import ResourceCarousel from "../../../reskin/pages/ResourceHub/ResourceCarousel/ResourceCarousel";

// Data
import { HIGHLEVELTRAITTYPE } from "../../../enumerations";

//Model
import { AssessmentStatusType } from "../../../models";

// Utilities
import { ucWords } from "../../../utilities";
import useResourceApi from "../../../hooks/useResourceAPI";

// Enums
import { TRAIT_SCORES, TRAIT_DEFINITIONS_RESKIN } from "../../../enumerations";

// Services
import EventService from "../../../services/EventService/event.service";

// Redux
import { RootState } from "../../../redux/reducers";

//Graphql
import { listCandidateComments } from "../../../graphql/queries";
import { getOrList } from "../../../components/dynamicqueries";

// Assets
import { ReactComponent as BlueDot } from "../../../assets/dot-ocean.svg";
import { ReactComponent as WhiteDot } from "../../../assets/dot-selected-reskin.svg";
import { ReactComponent as InfoCircle } from "../../../assets/info-circle.svg";
import ResourceCardsData from "../support/single-trait-support/ResourceCarousel/ResourceCardsData";

// Utils
import {
  leadershipOptions,
  perseveranceOptions,
  effectiveCommunicationOptions,
  innovativeThinkingOptions,
} from "./TraitSelectOptions/TraitSelectBaseOptions";
import TraitSelectDropdown from "../../../reskin/components/Dropdown/TraitSelectDropdown/TraitSelectDropdown";
import { API } from "aws-amplify";
import ReskinBIQ from "../../assessment/about-you/ReskinBIQ";

interface DropdowOptionsType {
  [key: string]: any;
}

interface CodexType {
  [key: string]: string;
}

function TraitReport({ userScores, narratives, HEISupportResponses }: any) {
  const resourceServices = useResourceApi();
  const dispatch = useDispatch();
  const { trait = "Unspecified" } = useParams();
  const [defaultValue, setDefaultValue] = React.useState<number>(0);
  const [selectedTrait, setSelectedTrait] = React.useState<string>(trait);
  const [hasUnreadSupport, setUnreadSupport] = React.useState<boolean>(false);
  const [leaveTrait, setLeaveTrait] = React.useState<any>(null);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] =
    React.useState<boolean>(false);
  const [areResourceCardsLoading, setAreResourceCardsLoading] =
    React.useState(true);
  const [resourceCardsData, setResourceCardsData] = React.useState<any>({
    leadership: null,
    perseverance: null,
  });
  const [videoStatus, setVideoStatus] = React.useState<string>("");

  // DropdownOptions
  const leadershipDropdownOptionProps = leadershipOptions;
  const perseveranceDropdownOptionProps = perseveranceOptions;
  const effectiveCommunicationDropdownOptionProps =
    effectiveCommunicationOptions;
  const innovativeThinkingDropdownOptionProps = innovativeThinkingOptions;

  const [dropdownOptions, setDropdownOptions] =
    React.useState<DropdowOptionsType>({
      leadership: leadershipDropdownOptionProps,
      ["effective communication"]: effectiveCommunicationDropdownOptionProps,
      perseverance: perseveranceDropdownOptionProps,
      ["innovative thinking"]: innovativeThinkingDropdownOptionProps,
    });

  const navigate = useNavigate();
  const {
    userId,
    assessmentState: { sessionId, assessmentId, UserAssessmentStateId },
  } = useSelector((state: RootState) => state.user);
  const phase = "POSTTEST";
  const category = HIGHLEVELTRAITTYPE[trait as keyof typeof HIGHLEVELTRAITTYPE];
  // Carousels
  let subTraitsCarouselRef = React.useRef<any>(null);
  let quotesCarouselRef = React.useRef<any>(null);
  let behaviorsCarouselRef = React.useRef<any>(null);

  const backgroundClassCodex: CodexType = {
    perseverance: "perseverance",
    ["effective communication"]: "effective",
    ["innovative thinking"]: "innovative",
    leadership: "leadership",
  };

  // setDropdownOptions
  const onTraitDropdownChange = setDropdownOptions;

  // Filter out scoring for specific trait
  const traitScore = userScores.filter((userScore: any) => {
    return userScore.trait.toLowerCase() === trait;
  })[0];

  const handleModal = (arg: boolean) => {
    EventService.track({
      event_type: "user",
      event_name: `${arg ? "user_open_modal" : "user_close_modal"}`,
      user_type: "student",
      event_data: {
        screen: "Effective Communication Insights",
        modal_name: "Score explanation",
      },
    });
    setIsDisclaimerModalOpen(arg);
  };
  const handleConfidenceScaleActions = async (segmentValue: any) => {
    await resourceServices.createConfidenceScaleInput(
      segmentValue,
      category,
      phase,
      userId,
    );
    EventService.track({
      event_type: "user",
      event_name: "user_rate_confidence",
      user_type: "student",
      event_data: {
        category: category,
        rating: segmentValue,
        screen: "Insights category - " + category,
      },
    });
  };

  React.useEffect(() => {
    EventService.track({
      event_type: "user",
      event_name: "insight_category_start",
      user_type: "student",
      event_data: {
        category: trait,
      },
    });

    getLearningResources();

    return () => {
      EventService.track({
        event_type: "user",
        event_name: "insight_category_end",
        user_type: "student",
        event_data: {
          category: trait,
        },
      });
    };
  }, [trait]);

  const getLearningResources = async () => {
    const resourcesAndItsConnections =
      await resourceServices.getLearningResourcesWithAllTheConnections(
        trait,
        userId,
        sessionId,
        assessmentId,
      );
    const consolidatedResourceCardInfo =
      await resourceServices.consolidatedEachResourceCardInformation(
        resourcesAndItsConnections,
      );
    setResourceCardsData(consolidatedResourceCardInfo);
    setAreResourceCardsLoading(false);
  };

  React.useEffect(() => {
    const unread = HEISupportResponses.filter((institutionResponse: any) => {
      return (
        institutionResponse.traitName.toLowerCase() === trait &&
        institutionResponse.responseRead !== true
      );
    });

    setUnreadSupport(unread.length > 0 ? true : false);
  }, [HEISupportResponses]);

  React.useEffect(() => {
    // make sure on every trait change, we reset carousels
    if (subTraitsCarouselRef.current) {
      subTraitsCarouselRef.current.goToSlide(0, true);
    }

    if (quotesCarouselRef.current) {
      quotesCarouselRef.current.goToSlide(0, true);
    }

    if (behaviorsCarouselRef.current) {
      behaviorsCarouselRef.current.goToSlide(0, true);
    }
  }, [traitScore]);

  React.useEffect(() => {
    const getUserConfidenceScale = async () => {
      const confidenceLevels = await resourceServices.listConfidenceScale(
        category,
        phase,
        userId,
      );
      if (
        confidenceLevels.data.confidenceLevelByUserIdTraitPhaseAndSelectionDate
          .items.length > 0
      ) {
        setDefaultValue(
          confidenceLevels.data
            .confidenceLevelByUserIdTraitPhaseAndSelectionDate.items[0]
            .confidenceLevel,
        );
      } else {
        setDefaultValue(0);
      }
    };
    getUserConfidenceScale();
  }, [trait]);

  const switchTrait = (newTrait: string) => {
    // previous trait track
    // EventService.track({
    //   event_type: "user",
    //   event_name: "insight_category_start",
    //   user_type: "student",
    //   event_data: {
    //     category: newTrait,
    //   },
    // });

    // setSelectedTrait(newTrait);
    // console.log(newTrait);
    // const url: any = `/report/trait/${trait.toLowerCase()}`;
    // checkData(url, selectedTrait);

    navigate(`/report/trait/${newTrait.toLowerCase()}`);
  };

  // const handleAccessPortalClick = () => {
  //   navigate(`/report/support/${newTrait.toLowerCase()}`);
  // };

  const capitalized = trait.charAt(0).toUpperCase() + trait.slice(1);

  const dispatchErrorAction = async () => {
    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.VIDEO_FAILED,
      }),
    );

    dispatch(
      updateUserAssessmentStateStatus({
        statusType: AssessmentStatusType.VIDEO_FAILED,
      }),
    );
    return "done";
  };
  async function checkVideoScoring() {
    let filterCondition = {
      and: [
        { userSessionId: { eq: sessionId } },
        { eventName: { eq: "video" } },
      ],
    };
    const response = await API.graphql({
      query: queries.listEventTrackings,
      variables: { limit: 10000, filter: filterCondition },
    });
    const responseObject = JSON.parse(JSON.stringify(response));
    if (responseObject.data.listEventTrackings.items) {
      if (
        "Error" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        await dispatchErrorAction();
        sessionStorage.setItem("rerecordingAttempt", "true");
        alert(
          "There was a problem scoring your video submission. You will now be redirected to resubmit.",
        );
        navigate("/assessment/communication/intro");
      }
      if (
        "Skipped" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        setVideoStatus("Skipped");
      }
      if (
        "InProcess" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        setVideoStatus("InProcess");
      }
    }
  }
  React.useEffect(() => {
    if (trait === "effective communication") {
      checkVideoScoring();
    }
  }, [trait]);

  return (
    <>
      <ScrollToTop />

      <section className="traitReport section">
        <div className="wrapper container">
          <div className="content trait-definition">
            <p>
              <h2 style={{ color: "#397F93", fontWeight: "bold" }}>
                Your Trait Scores
              </h2>
            </p>
            <p>Select a Category</p>
            <div className="trait-select-wrapper">
              <TraitSelectDropdown
                selectedOption={dropdownOptions[trait]}
                options={Object.values(dropdownOptions)}
                onChange={(value: any) => switchTrait(value)}
              />
            </div>
            <p>
              <strong>{capitalized}</strong>
              {
                TRAIT_DEFINITIONS_RESKIN[
                  trait.toLowerCase() as keyof typeof TRAIT_DEFINITIONS_RESKIN
                ]
              }
            </p>
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderTop: "1px solid gray",
              borderBottom: "1px solid gray",
            }}
          >
            {`${TRAIT_SCORES[traitScore.score as keyof typeof TRAIT_SCORES]}` ===
            "Approaching" ? (
              <ApproachingGraph />
            ) : `${TRAIT_SCORES[traitScore.score as keyof typeof TRAIT_SCORES]}` ===
              "Developing" ? (
              <DevelopingGraph />
            ) : `${TRAIT_SCORES[traitScore.score as keyof typeof TRAIT_SCORES]}` ===
              "Demonstrating" ? (
              <DemonstratingGraph />
            ) : (
              <NoDataGraph />
            )}
          </div>

          {/* <AccessResourceHub
            onClick={handleAccessPortalClick}
            trait={trait}
            unreadMessages={hasUnreadSupport}
          /> */}

          {videoStatus === "Skipped" && trait == "effective communication" && (
            <div
              className="text-center"
              style={{
                backgroundColor: "white",
                padding: "2rem",
                borderBottom: "1px solid gray",
                font: "14px Open Sans",
                color: "#737582",
              }}
            >
              <p>
                In order to receive these insghts, you must first complete the
                Effective Communication assessment.
              </p>
              <Button
                className="reskin-button primary psq-btn psq-btn-primary"
                onClick={async () => {
                  sessionStorage.setItem("rerecordingAttempt", "true");
                  await dispatch(
                    updateUserAssessmentState({
                      userId,
                      UserAssessmentStateId,
                      statusType: "VIDEO_INPROGRESS",
                    }),
                  );
                  navigate("/assessment/communication/intro");
                }}
              >
                Begin Assessment
              </Button>
            </div>
          )}

          <div style={{ backgroundColor: "white", padding: "1.5rem" }}>
            <h3 style={{ color: "black", backgroundColor: "white" }}>
              {ucWords(trait)} Sub Traits
            </h3>
            <SubTraitGraph traitScore={traitScore} />
          </div>

          <div>
            {trait === "effective communication" && (
              <>
                <p className="disclaimer p-0">
                  <span
                    className="disclaimer-icon active"
                    onClick={() => handleModal(true)}
                  >
                    <InfoCircle />
                  </span>
                  <a
                    className="disclaimer-text active unselectable"
                    onClick={() => handleModal(true)}
                  >
                    {"How your Effective Communication skills are scored "}
                  </a>
                </p>
                <DisclaimerModal
                  shown={isDisclaimerModalOpen}
                  handleClose={() => handleModal(false)}
                />
              </>
            )}
          </div>

          <div className={`content interactive ${backgroundClassCodex[trait]}`}>
            {/* <div className= "content interactive"> */}
            <div className="cards">
              <SubTraitCarousel
                carouselRef={subTraitsCarouselRef}
                narratives={narratives}
                dynamicNarratives={traitScore.dimensionNarratives}
                traitScore={traitScore}
              />
            </div>
          </div>
          <div
            style={{ backgroundColor: "white", padding: "1rem" }}
            className="horizontalRadio"
          >
            <HorizontalScaleRadio
              headerText={`How confident are you in your ${trait} skills today?`}
              defaultValue={defaultValue}
              maximumValue={5}
              SelectedIcon={
                <WhiteDot
                  height="20"
                  width="20"
                  className={`selectedIcon ${backgroundClassCodex[trait]}`}
                />
              }
              UnselectedIcon={
                <WhiteDot height="20" width="20" color="#D9DAE0" />
              }
              selectedDividerClasses={`${backgroundClassCodex[trait]}`}
              radioDescriptorId={`${trait}-confidence-level`}
              componentCustomClasses="confidence-scale-radio"
              onChange={(segmentValue: any) => {
                handleConfidenceScaleActions(segmentValue);
              }}
              labels={{
                0: "Help!",
                2: "So-So",
                4: "Great!",
              }}
            />
          </div>

          <div className="content recommended-resources trait-report-recommendation-wrapper">
            <h3 className="resource-title">Your Recommended Resources</h3>
            <br />

            <ResourceCarousel
              cards={resourceCardsData}
              trait={trait}
              isLoading={areResourceCardsLoading}
            />

            <section className="to-resources-button-wrapper">
              <Button
                bsPrefix="psq-btn"
                variant="reskin-primary"
                className="to-resources-button"
                onClick={() => navigate(`/report/support`)}
              >
                View More Resources
              </Button>
            </section>
          </div>
        </div>
      </section>
      {/* <Tabs
        userScores={userScores}
        selectedTrait={selectedTrait}
        switchTrait={switchTrait}
      /> */}
    </>
  );
}

export default TraitReport;

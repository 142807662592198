import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import pkg from "../../../../../package.json";
import env from "../../../../configs/env";
import { RootState } from "../../../../redux/reducers";
import Subheader from "../../../../components/Subheader";
import { useNavigate } from "react-router-dom";

function Copyright() {
  let navigate = useNavigate();
  const year = new Date().getFullYear();

  const { userType } = useSelector((state: RootState) => state.user);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Subheader back={goBack} text="Copyright" />
      <Container>
        <small>
          Copyright © {year} by Educational Testing Service. All rights
          reserved. All trademarks are the property of their respective owners.
          ({pkg.name} {pkg.version} {env.stage} {userType?.toLowerCase()})
        </small>
      </Container>
    </>
  );
}

export default Copyright;

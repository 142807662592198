// Styles
import "./TraitSelectDropdown.scss";

interface TraitSelectOptionProps {
  leftIcon: JSX.Element;
  label: string;
  iconBackground: string;
  labelBackground: string;
  value: any;

  // Handled by dropdown component
  onClick: Function;
  rightIcon?: JSX.Element;
  isFirstOption?: boolean;
}

const TraitSelectDropdownOption = ({
  leftIcon,
  rightIcon,
  label,
  isFirstOption,
  iconBackground,
  labelBackground,
  onClick,
  value,
}: TraitSelectOptionProps) => {
  const topBorderClass = isFirstOption ? "" : "bordered-top";

  return (
    <div
      className={`trait-select-dropdown-option active ${topBorderClass}`}
      style={{ backgroundColor: labelBackground }}
      onClick={() => onClick(value)}
    >
      <div
        className="left-icon-container"
        style={{ backgroundColor: iconBackground }}
      >
        {leftIcon}
      </div>
      <div className="label-container">{label}</div>
      <div className="right-icon-container">{rightIcon}</div>
    </div>
  );
};

export default TraitSelectDropdownOption;

import React from "react";

interface ReflectionPromptProps {
  header: string;
  prompt: string;
  handleChange: Function;
}

const ReflectionPromptDesktop = ({
  header,
  prompt,
  handleChange,
}: ReflectionPromptProps) => {
  return (
    <div className="resource-reaction-prompt d-flex flex-column">
      <section className="prompt-header">
        <h3>{header}</h3>
      </section>
      <section className="prompt-body flex-grow-1">
        <div className="prompt-text">
          <p>{prompt}</p>
        </div>
        <div className="response-container">
          <textarea
            className="response"
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </section>
    </div>
  );
};

export default ReflectionPromptDesktop;

// Packages
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";

// Routing
import { Route, Routes, Navigate } from "react-router-dom";

// Actions
import { storeAssessment } from "../../redux/actions";

// Congigs
import { DEFAULTS } from "../../enumerations";

// Hooks
import WithIdleTimer from "../../components/WithIdleTimer";

// Models
import { AssessmentSection, UserResponses } from "../../models";

//graphql
import { listUserResponses } from "../../graphql/queries.js";
import { createUserResponses } from "../../graphql/mutations.js";

// Pages
import Completion from "./completion";
import Communication from "./communication";
import Submitted from "./communication/submitted";
import Onboarding from "./onboarding";
import AboutYou from "./about-you";
import Triplets from "./Triplets";
import LetsBegin from "./lets-begin";
import SkillsCompletePage from "../../ui-components/SkillsCompletePage";
// import HelpfulTips from "./helpful-tips";

// Reskin Components
import ReskinBIQ from "./about-you/ReskinBIQ";
import HowItWorksPage from "../../reskin/pages/BIQ/HowItWorksPage/HowItWorksPage";
import RecordingCongratulations from "../../reskin/pages/EffectiveCommunication/RecordingCongratulations/RecordingCongratulations";
import PairsIntroPage from "../../reskin/pages/Pairs/PairsIntroPage/PairsIntroPage";
import PairsCongratulationsPage from "../../reskin/components/PairsCongratulationsPage";
import AssessmentHeader from "../../reskin/components/Header/AssessmentHeader/AssessmentHeader";
import HelpfulTips from "../../reskin/components/HelpfulTips";
import ChooseStatement from "../../reskin/components/ChooseStatement";

// Store
import { RootState } from "../../redux/reducers";

//Grpahql
import { getOrList } from "../../components/dynamicqueries";
import { listAssessmentSections } from "../../graphql/queries";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ConsoleLogger } from "@aws-amplify/core";
import env from "../../configs/env";

function Assessment() {
  const dispatch = useDispatch();
  const { user, assessment } = useSelector((state: RootState) => state);
  const [storedResponses, setStoredResponses] = React.useState<any>(null);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    // fetch the assessment data for later retrieval
    fetchAssessment();
    async function fetchAssessment() {
      // get details about the asessment
      if (assessment.id === "") {
        const sections = (
          await getOrList(listAssessmentSections, "listAssessmentSections", {
            limit: 10000,
          })
        )
          .filter((c: any) => c.assessment.name === `${env.assessmentName}`)
          .sort((a: any, b: any) => a.sectionSequence - b.sectionSequence);
        dispatch(storeAssessment(sections));
      } else {
        // create a new UserResponse row once we have the assessment
        createUserResponse();
      }
    }

    async function createUserResponse() {
      let variable = { filter: { userId: { eq: user.userId } }, limit: 10000 };

      const original = await getOrList(
        listUserResponses,
        "listUserResponses",
        variable,
      );

      if (original.length === 0) {
        const input = {
          userId: user.userId,
          assessmentId: assessment.id,
          userSessionId: user.assessmentState.sessionId,
          userResponsesUserAssessmentStateId:
            user.assessmentState.UserAssessmentStateId,
        };
        const response = await API.graphql(
          graphqlOperation(createUserResponses, { input: input }),
        );
        const responseObject = JSON.parse(JSON.stringify(response));
        const newUserResponses = responseObject.data.createUserResponses;
        setStoredResponses(newUserResponses);
      } else {
        setStoredResponses(original[0]);
      }

      setLoading(false);
    }
  }, [assessment]);
  const { assessmentStatus } = user.assessmentState;

  return (
    <>
      {isLoading && <span>Loading...</span>}
      {!isLoading && (
        <>
          <Routes>
            {assessmentStatus === "ASSESSMENT_STARTED" && (
              <>
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/onboarding" />}
                />
                <Route path="about-you" element={<ReskinBIQ />} />
                <Route path="onboarding" element={<HowItWorksPage />} />
              </>
            )}

            {(assessmentStatus === "BIQ_COMPLETE" ||
              assessmentStatus === "VIDEO_FAILED" ||
              assessmentStatus === "VIDEO_INPROGRESS" ||
              assessmentStatus === "ASSESSMENT_FAILED") && (
              <>
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/communication" />}
                />
                <Route path="about-you" element={<ReskinBIQ />} />
                <Route
                  path="communication/*"
                  element={<Communication userResponses={storedResponses} />}
                />
              </>
            )}

            {(assessmentStatus === "VIDEO_COMPLETE" ||
              assessmentStatus === "TRIPLET_FAILED") && (
              <>
                <Route
                  path="communication/submitted/*"
                  element={<RecordingCongratulations />}
                />
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/lets-begin" />}
                />
                {/* <Route path="lets-begin" element={<LetsBegin />} /> */}
                <Route path="lets-begin" element={<PairsIntroPage />} />
                <Route
                  path="helpful-tips"
                  element={
                    <>
                      <AssessmentHeader isTransparent={false} />
                      <HelpfulTips />
                    </>
                  }
                />
              </>
            )}

            {assessmentStatus === "VIDEO_SKIPPED" && (
              <>
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/lets-begin" />}
                />
                {/* <Route path="lets-begin" element={<LetsBegin />} /> */}
                <Route path="lets-begin" element={<PairsIntroPage />} />
                <Route
                  path="helpful-tips"
                  element={
                    <>
                      <AssessmentHeader isTransparent={false} />
                      <HelpfulTips />
                    </>
                  }
                />
              </>
            )}

            {assessmentStatus === "SAMPLE_INSTRUCTION_COMPLETE" && (
              <>
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/triplets" />}
                />

                <Route
                  path="triplets"
                  element={
                    <ChooseStatement storedResponses={storedResponses} />
                  }
                />
                {/* <Route
                  path="triplets"
                  element={<Triplets storedResponses={storedResponses} />}
                /> */}
              </>
            )}

            {assessmentStatus === "TRIPLET_INPROGRESS" && (
              <>
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/triplets" />}
                />

                {/* <Route
                  path="triplets"
                  element={<Triplets storedResponses={storedResponses} />}
                /> */}
                <Route
                  path="triplets"
                  element={
                    <ChooseStatement storedResponses={storedResponses} />
                  }
                />
              </>
            )}

            {assessmentStatus === "TRIPLET_COMPLETE" && (
              <>
                <Route
                  path="*"
                  element={<Navigate replace to="/assessment/completion" />}
                />

                {/* <Route path="completion" element={<Completion />} /> */}
                {/* <Route path="completion" element={<SkillsCompletePage />} /> */}
                <Route
                  path="completion"
                  element={<PairsCongratulationsPage />}
                />
              </>
            )}

            {assessmentStatus === "ASSESSMENT_COMPLETE" && (
              <>
                <Route
                  path="/*"
                  element={<Navigate replace to="/report/summary" />}
                />
              </>
            )}
          </Routes>
        </>
      )}
    </>
  );
}

export default Assessment;

function SupportPill({ status }: any) {
  return (
    <span
      className={`support-pill ${status === "sent" ? "sent" : "requested"}`}
    >
      {status === "sent" ? "Support sent" : "Support requested"}
    </span>
  );
}

export default SupportPill;

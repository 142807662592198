// Utils
import {
  resourceActions,
  GetFavoritesParams,
  CreateOrRemoveParams,
  IsFavoriteParams,
  GetReactionsParams,
} from "./actions/resource";
import createOrUpdate, { getOrList } from "../components/dynamicqueries";
import { listResourceReactions } from "../graphql/queries";
import {
  createResourceReaction,
  updateResourceReaction,
} from "../graphql/mutations";
import getS3PresignedUrl from "../utilities/s3/getS3PresignedUrl";
/**
 * @author Dan Bourdier
 * @summary Exposes a lightweight/abstracted means of handling resource-related actions such as but not limited to:
 * 1. creating, removing, and comparing saved resources.
 * 2. restricting function param types
 * 3. exposing reference to already fetched resources(once initialized)
 *
 * @func createOrRemove
 * @func getFavorites
 * @func isFavorite
 *
 * @description: **IMPORTANT**, this API requires the following to execute select parts of logic:
 * 1. **cardId**, id of the resource
 * 2. **userId**, id of the user
 * 3. **resourceTrait**, trait the resource is classified by
 * 4. **assessId**, assessment Id of the shown resource
 * 5. ...
 * @returns [*services*]
 */

function useResourceAPI() {
  /**
   *
   * @param existingFavoritesList, pull from **#getFavorites** if not already
   *  exposed elsewhere
   */
  const isFavorite = ({
    cardId,
    existingFavoritesList,
  }: IsFavoriteParams): boolean => {
    return resourceActions.isFavorite({ cardId, existingFavoritesList });
  };

  const createOrRemoveReactions = async (
    reactionStatus: any,
    resourceCardId: any,
    resourceCardtrait: any,
    userId: any,
    sessionId: any,
    assessmentId: any,
  ) => {
    const reactionsList = await resourceActions
      .listReactions({
        resourceCardId,
        resourceCardtrait,
        userId,
        sessionId,
        assessmentId,
      })
      .then((res: any) => {
        return res;
      })
      .catch((errorRes: any) => {
        throw new Error(`Error in resource Api: ${errorRes}`);
      });

    if (reactionsList.data.listResourceReactions.items.length === 0) {
      await resourceActions
        .createReactions({
          reactionStatus,
          resourceCardId,
          resourceCardtrait,
          userId,
          sessionId,
          assessmentId,
        })
        .then((res: any) => {
          return res;
        })
        .catch((errorRes: any) => {
          throw new Error(`Error in resource Api: ${errorRes}`);
        });
    } else if (reactionsList.data.listResourceReactions.items.length === 1) {
      let id = reactionsList.data.listResourceReactions?.items[0]?.id;
      let _version =
        reactionsList.data.listResourceReactions?.items[0]?._version;

      await resourceActions
        .updateReactions({
          reactionStatus,
          resourceCardId,
          resourceCardtrait,
          userId,
          sessionId,
          assessmentId,
          id,
          _version,
        })
        .then((res: any) => {
          return res;
        })
        .catch((errorRes: any) => {
          throw new Error(`Error in resource Api: ${errorRes}`);
        });
    } else {
      console.error("couldnt update learning reactions");
    }
  };

  const updateFavs = async (
    isSaved: any,
    resourceCardId: any,
    resourceCardtrait: any,
    userId: any,
    sessionId: any,
    assessmentId: any,
  ) => {
    const favsList = await resourceActions
      .listfavs({
        resourceCardId,
        resourceCardtrait,
        userId,
        sessionId,
        assessmentId,
      })
      .then((res: any) => {
        return res;
      })
      .catch((errorRes: any) => {
        throw new Error(`Error in resource Api: ${errorRes}`);
      });

    if (
      favsList.data.listStudentFavouriteLearningResources.items.length === 0
    ) {
      await resourceActions
        .createFavorite({
          isSaved,
          resourceCardId,
          resourceCardtrait,
          userId,
          sessionId,
          assessmentId,
        })
        .then((res: any) => {
          return res;
        })
        .catch((errorRes: any) => {
          throw new Error(`Error in resource Api: ${errorRes}`);
        });
    } else if (
      favsList.data.listStudentFavouriteLearningResources.items.length === 1
    ) {
      let id =
        favsList.data.listStudentFavouriteLearningResources?.items[0]?.id;
      let _version =
        favsList.data.listStudentFavouriteLearningResources?.items[0]?._version;

      await resourceActions
        .updateFavs({
          isSaved,
          resourceCardId,
          resourceCardtrait,
          userId,
          sessionId,
          assessmentId,
          id,
          _version,
        })
        .then((res: any) => {
          return res;
        })
        .catch((errorRes: any) => {
          throw new Error(`Error in resource Api: ${errorRes}`);
        });
    } else {
      console.error("couldnt update favs");
    }
  };

  const getLearningResourcesWithAllTheConnections = async (
    resourceCardtrait: any,
    userId: any,
    sessionId: any,
    assessmentId: any,
  ) => {
    const resourcesList = await resourceActions
      .listResourcesAndItsConnections({
        resourceCardtrait,
        userId,
        sessionId,
        assessmentId,
      })
      .then((res: any) => {
        return res;
      })
      .catch((errorRes: any) => {
        throw new Error(`Error in resource Api: ${errorRes}`);
      });
    return resourcesList;
  };

  const consolidatedEachResourceCardInformation = async (
    ResourcesAndItsConnections: any,
  ) => {
    let consolidatedResources: any = [];
    ResourcesAndItsConnections.data.resources.items.map((resourceCard: any) => {
      let favorite = ResourcesAndItsConnections.data.favorites.items.filter(
        (favoritedCard: any) =>
          favoritedCard.learningResourceId === resourceCard.learningResourceId,
      );
      let reacted = ResourcesAndItsConnections.data.reactions.items.filter(
        (reactedCard: any) =>
          reactedCard.learningResourceId === resourceCard.learningResourceId,
      );
      let recommended =
        ResourcesAndItsConnections.data.recommendations.items.filter(
          (recommendedCard: any) =>
            recommendedCard.learningResourceId ===
            resourceCard.learningResourceId,
        );
      consolidatedResources.push({
        ...resourceCard,
        ...favorite[0],
        ...reacted[0],
        ...recommended[0],
      });
    });
    const consolidatedResourcesWithPresignedUrls = await getPreSigneds3Url(
      consolidatedResources,
    );
    return consolidatedResourcesWithPresignedUrls;
  };

  const getPreSigneds3Url = async (consolidatedResources: any) => {
    const updatedResourcesWithPresignedUrls: any = [];
    const listOfPresignUrlFunctions = consolidatedResources.map(
      (card: any) => async () => {
        const newCard = { ...card };
        const presignedImageLocation = await getS3PresignedUrl(
          card.imageLocation,
        );
        newCard.imageLocation = presignedImageLocation;
        updatedResourcesWithPresignedUrls.push(newCard);
      },
    );
    await Promise.all(
      listOfPresignUrlFunctions.map((presignFunction: Function) =>
        presignFunction(),
      ),
    );
    return updatedResourcesWithPresignedUrls;
  };

  const createConfidenceScaleInput = async (
    segmentValue: any,
    trait: any,
    phase: any,
    userId: any,
  ) => {
    await resourceActions.createUserConfidenceScale({
      segmentValue,
      trait,
      phase,
      userId,
    });
  };

  const listConfidenceScale = async (
    category: any,
    phase: any,
    userId: any,
  ) => {
    const confidenceList = await resourceActions
      .listUserConfidenceLevels({ category, phase, userId })
      .then((res: any) => {
        return res;
      })
      .catch((errorRes: any) => {
        throw new Error(`Error in resource Api: ${JSON.stringify(errorRes)}`);
      });
    return confidenceList;
  };

  const services = {
    isFavorite,
    createOrRemoveReactions,
    updateFavs,
    getLearningResourcesWithAllTheConnections,
    consolidatedEachResourceCardInformation,
    createConfidenceScaleInput,
    listConfidenceScale,
  };

  return services;
}

export default useResourceAPI;

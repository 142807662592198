// Styles
import "./Radio.scss";

interface RadioProps {
  label: string;
  onClick: Function;
  id?: string;
  name?: string;
  checked?: boolean;
}

const Radio = ({ label, id, name, onClick, checked }: RadioProps) => {
  const defaultString = label.replace(/\s+/g, "-").toLowerCase();
  const preparedId = id ? id : defaultString;
  const preparedName = name ? name : defaultString;

  return (
    <div className="reskin-radio-wrapper">
      <input
        type="radio"
        onClick={() => onClick()}
        name={preparedName}
        id={preparedId}
        className="reskin-radio-hidden-input"
        checked={checked}
      />

      <label htmlFor={preparedId} className="reskin-radio-label">
        {label}
      </label>
    </div>
  );
};

export default Radio;

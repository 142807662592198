// Assets
import { ReactComponent as LowBars } from "../../../../assets/reskin/icons/bars-low.svg";
import { ReactComponent as MediumBars } from "../../../../assets/reskin/icons/bars-medium.svg";
import { ReactComponent as HighBars } from "../../../../assets/reskin/icons/bars-high.svg";

// Styles
import "./TraitSectionHeader.scss";

interface TraitSectionHeaderProps {
  variant: "light" | "dark";
  score: number;
  header: string;
  isTwoLineHeader?: boolean;
}

interface IconCodexType {
  [key: number]: JSX.Element;
}

interface LabelCodexType {
  [key: number]: string;
}

const TraitSectionHeader = ({
  variant,
  score,
  header,
  isTwoLineHeader,
}: TraitSectionHeaderProps) => {
  const LowBarIcon = <LowBars />;
  const MediumBarIcon = <MediumBars />;
  const HighBarIcon = <HighBars />;

  const ScoreIconCodex: IconCodexType = {
    0: LowBarIcon,
    1: MediumBarIcon,
    2: HighBarIcon,
  };

  const ScoreLabelCodex: LabelCodexType = {
    0: "Developing",
    1: "Approaching",
    2: "Demonstrating",
  };

  const twoLineStyleHandler = isTwoLineHeader ? "two-line" : "";

  return (
    <div className="resource-trait-section-header">
      <div className={`trait-and-score ${twoLineStyleHandler}`}>
        <div className="trait-label-container">
          <h2 className={`trait-label ${variant}`}>{header}</h2>
        </div>
        <div className="score-display">
          <div className="icon-container">{ScoreIconCodex[score]}</div>
          <div className="label-container">
            <p className="score-label">{ScoreLabelCodex[score]}</p>
          </div>
        </div>
      </div>
      <div className="section-blurb-container">
        <p className={`section-blurb ${variant}`}>
          Your resources for continued learning.
        </p>
      </div>
    </div>
  );
};

export default TraitSectionHeader;

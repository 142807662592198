import "./verification-code-email.scss";

export const VerificationCodeEmail = ({ email }: any) => {
  const obscureEmail = (email: string) => {
    if (email === "") return "********@*****.***";
    const [name, domain] = email.split("@");
    return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
  };

  return (
    <div className="verification-email-label">
      A verification code has been sent to: 
      <span className="obscure-email">{obscureEmail(email)}</span>
    </div>
  );
}

// Packages
import React from "react";

import PairsButton from "./PairsButton";
import Button from "react-bootstrap/Button";

const PairsButtonConfigured = ({ items, handleSubmission, isCompleted }) => {
  const [selectedOption, setSelectedOption] = React.useState(null);

  const statements = items.map((pairsItem) => {
    const { item } = pairsItem;
    const { content } = item;
    return content;
  });

  const handleContinue = () => {
    if (selectedOption !== null) {
      const pairsResponse = items.map((pairsItem, index) => {
        let response = "UNSELECTED";

        if (index === selectedOption) {
          response = "SELECTED";
        }
        return {
          itemGuid: pairsItem.item.itemGuid,
          itemSeq: pairsItem.itemSequence,
          response,
        };
      });
      handleSubmission(pairsResponse);
      setSelectedOption(null);
    }
  };
  return (
    <>
      <PairsButton
        isSelected={selectedOption === 0 ? "true" : "false"}
        onClick={() => setSelectedOption(0)}
        overrides={{
          Text: { children: statements[0] },
          PairsButton: { height: "120px" },
        }}
      />
      <br />
      <PairsButton
        isSelected={selectedOption === 1 ? "true" : "false"}
        overrides={{
          Text: { children: statements[1] },
          PairsButton: { height: "120px" },
        }}
        onClick={() => setSelectedOption(1)}
      />

      <br />
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "260px",
          height: "46px",
          backgroundColor: selectedOption === null ? "#D9DAE0" : "#397F93",
          borderRadius: "24px",
          border: " none",
          marginBottom: "20px",
        }}
        onClick={() => handleContinue()}
        disabled={selectedOption === null}
      >
        Continue
      </Button>
      {/* <HireupProgressBarFooter itemsCompleted={index.item/2} itemsTotal={triplets.length/2} itemName="Questions"/> */}
    </>
  );
};

export default PairsButtonConfigured;

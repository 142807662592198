// Packages
import { useState } from "react";

// Images
import { ReactComponent as CaretLeft } from "../../assets/icons/caret-left.svg";
import { ReactComponent as CaretRight } from "../../assets/icons/caret-right.svg";

// Styles
import "./TablePaginator.scss";

interface TablePaginatorProps {
  onRangeChange?: Function;
  onNextPage?: Function;
  onPrevPage?: Function;
  itemTotal: number;
}

export default function TablePaginator({
  onRangeChange,
  onNextPage,
  onPrevPage,
  itemTotal,
}: TablePaginatorProps) {
  const [range, setRange] = useState(10);
  const [startingItemPosition, setStartingItemPosition] = useState(1);

  const getItemRangeDisplay = (): string => {
    let endingPosition = startingItemPosition + range - 1;

    if (endingPosition > itemTotal) {
      endingPosition = itemTotal;
    }
    return `${startingItemPosition} - ${endingPosition} of ${itemTotal}`;
  };

  const handleNextPage = () => {
    let updatedStartingPosition = startingItemPosition + range;

    if (updatedStartingPosition <= itemTotal) {
      setStartingItemPosition(updatedStartingPosition);
      if (onNextPage) {
        onNextPage(updatedStartingPosition, range);
      }
    }
  };

  const handlePrevPage = () => {
    if (startingItemPosition !== 1) {
      let updatedStartingPosition = startingItemPosition - range;

      if (updatedStartingPosition >= 1) {
        setStartingItemPosition(updatedStartingPosition);
      } else {
        updatedStartingPosition = 1;
        setStartingItemPosition(updatedStartingPosition);
      }

      if (onPrevPage) {
        onPrevPage(updatedStartingPosition, range);
      }
    }
  };

  return (
    <div className="d-flex justify-content-end tablePaginator">
      <div className="pt-1">
        <label htmlFor="range-selector">Rows per page</label>
      </div>
      <div className="ps-3">
        <select
          id="range-selector"
          className="form-select"
          onChange={(e) => {
            const parsedRange = Number.parseInt(e.target.value);
            if (onRangeChange) {
              onRangeChange(startingItemPosition, parsedRange);
            }

            setRange(parsedRange);
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
        </select>
      </div>
      <div className="ms-4 pt-1">
        <p>{getItemRangeDisplay()}</p>
      </div>
      <div className="d-flex">
        <div className="ps-4 pt-1">
          <span
            onClick={() => {
              handlePrevPage();
            }}
          >
            <CaretLeft />
          </span>
        </div>
        <div className="ps-4 pt-1">
          <span
            onClick={() => {
              handleNextPage();
            }}
          >
            <CaretRight />
          </span>
        </div>
      </div>
    </div>
  );
}

//packagess
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { API } from "aws-amplify";
import * as customQueries from "../../graphql/custom-queries";
import { useSelector } from "react-redux";

// Components
import Welcome from "./welcome";
import Overview from "./overview";
import Traits from "./traits";
import Tabs from "./tabs";
import IndividualInsight from "./individual-insight";
import Footer from "../../components/Footer";
import StudentPage from "./student-list";
import Support from "./support";

// Services
import EventService from "../../services/EventService/event.service";

// Redux
import { RootState } from "../../redux/reducers";
import { getOrList } from "../../components/dynamicqueries";
import { listUserScores, listUsers } from "../../graphql/queries";
import PrivacyPolicy from "../../reskin/pages/Profile/PrivacyPolicy/PrivacyPolicy";

function FullSummary() {
  const location = useLocation();
  const { pathname } = location;

  const parsePath = () => {
    return pathname.substring(13);
  };

  const { institutionId }: any = useSelector((state: RootState) => state.user);
  const [selectedTab, setSelectedTab] = React.useState<string>(parsePath());
  const [studentCount, setStudentCount] = React.useState<number>(0);
  const [totalRegisteredStudentCount, setTotalRegisteredStudentCount] =
    React.useState<number>(0);

  // get student completed count
  React.useEffect(() => {
    getStudentCount();
    getTotalRegisteredStudentCount();
  }, []);

  // handle tabs on navigate
  React.useEffect(() => {
    setSelectedTab(parsePath());
  }, [pathname]);

  async function getStudentCount() {
    let filter = {
      and: [
        { overallScore: { gte: 0 } },
        { institution: { eq: institutionId } },
      ],
    };

    const response = await API.graphql({
      query: customQueries.searchUserScoresCount,
      variables: { limit: 10000, filter: filter },
    });

    const responseObject = JSON.parse(JSON.stringify(response));
    const total = responseObject.data.searchUserScores.total;
    setStudentCount(total);

    EventService.track({
      event_type: "user",
      event_name: "hei_fullsummary",
      user_type: "hei",
      event_data: {
        psqCompleters: total,
      },
    });
  }

  async function getTotalRegisteredStudentCount() {
    let filter = {
      and: [
        { userType: { eq: "STUDENT" } },
        { institutionId: { eq: institutionId } },
      ],
    };

    const response = await getOrList(listUsers, "listUsers", {
      limit: 10000,
      filter: filter,
    });

    const responseObject = JSON.parse(JSON.stringify(response));
    const total = responseObject.length;
    setTotalRegisteredStudentCount(total);
  }

  return (
    <>
      {/* {selectedTab !== "welcome" && (
        <Tabs
          studentCount={studentCount}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        ></Tabs>
      )} */}

      <Routes>
        <Route
          path="/"
          element={<Navigate replace to="/fullsummary/overview" />}
        />
        <Route
          path="/welcome"
          element={
            <Welcome
              studentCount={studentCount}
              institutionId={institutionId}
              setSelectedTab={setSelectedTab}
            />
          }
        />
        <Route
          path="/overview"
          element={
            <Overview
              setSelectedTab={setSelectedTab}
              institutionId={institutionId}
              studentCount={studentCount}
              totalRegisteredStudentCount={totalRegisteredStudentCount}
            />
          }
        />

        <Route
          path="/traits/:trait"
          element={
            <Traits
              setSelectedTab={setSelectedTab}
              institutionId={institutionId}
            />
          }
        />

        <Route
          path="/students"
          element={
            <StudentPage
              setSelectedTab={setSelectedTab}
              institutionId={institutionId}
              isDashboard={false}
            />
          }
        />

        <Route
          path="/students/:userSessionId"
          element={<IndividualInsight setSelectedTab={setSelectedTab} />}
        />

        <Route
          path="/support/*"
          element={<Support setSelectedTab={setSelectedTab} />}
        />
      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default FullSummary;

// Packages
import React from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";
import { Form, Button } from "react-bootstrap";

// Components
import SubHeader from "../../../../components/Subheader";

// Services
import EventService from "../../../../services/EventService/event.service";

// Stores
import { RootState } from "../../../../redux/reducers";

// Utils
import { ucWords } from "../../../../utilities";

const RequestSupport = ({ userSupportRequests }: any) => {
  const { user } = useSelector((state: RootState) => state);
  const { assessmentState } = user;

  const [value, setValue] = React.useState<string>("");
  const [hasErrors, setErrors] = React.useState<string>("");
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [hasPreviouslySubmitted, setPreviouslySubmitted] =
    React.useState<boolean>(false);

  const { trait = "Leadership" } = useParams();
  const lowercaseTrait = trait.toLowerCase();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(`/report/support/${trait.toLowerCase()}`);
  };

  React.useEffect(() => {
    EventService.track({
      event_type: "user",
      event_name: "support_request_start",
      user_type: "student",
      event_data: {
        category: trait,
      },
    });

    return () => {
      EventService.track({
        event_type: "user",
        event_name: "support_request_end",
        user_type: "student",
        event_data: {
          category: trait,
        },
      });
    };
  }, []);

  React.useEffect(() => {
    const filteredTraitSupportRequests = userSupportRequests.filter(
      (userSupportRequest: any) => {
        return userSupportRequest.traitName.toLowerCase() === trait;
      },
    );

    if (filteredTraitSupportRequests.length > 0) setPreviouslySubmitted(true);
  }, [userSupportRequests]);

  const handleRequest = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (value.trim() === "") {
      setErrors("You must specify a message.");
    } else {
      setErrors("");

      try {
        setSubmitting(true);
        const requestDetails = {
          studentUserSessionId: assessmentState.sessionId,
          userAssessmentId: assessmentState.UserAssessmentStateId,
          userId: user.userId,
          responseSentToStudent: false,
          traitName: trait,
          studentQuestion: value,
          institutionId: user.institutionId,
          requestDate: new Date().toISOString(),
        };

        const newRequest = await API.graphql(
          graphqlOperation(mutations.createStudentRequest, {
            input: requestDetails,
          }),
        );

        EventService.track({
          event_type: "user",
          event_name: "support_request_submit",
          user_type: "student",
          event_data: {
            // @ts-ignore
            message_id: newRequest.data.createStudentRequest.id,
            category: trait,
            message_text: value,
          },
        });

        setTimeout(() => {
          goBack();
        }, 3000);
      } catch (err) {
        setSubmitting(false);
        setErrors("An error occured, try again.");
      }
    }
  };

  const renderSupportRequest = () => {
    if (hasPreviouslySubmitted) {
      return (
        <div className="prompt">
          You’ll be notified when your student success team sends additional
          support.
        </div>
      );
    } else {
      return (
        <>
          {" "}
          <div className="prompt">
            <p>{`Let your student success team know that you would like additional support developing your ${lowercaseTrait} traits.`}</p>
          </div>
          <Form
            noValidate
            className="form"
            onSubmit={(e: any) => handleRequest(e)}
          >
            <div className="items">
              <Form.Group className="mb-3" controlId="requestSupport.message">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Add a message"
                  isInvalid={hasErrors !== ""}
                  onChange={(e) => {
                    if (hasErrors !== "") setErrors("");
                    setValue(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {hasErrors}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                bsPrefix="psq-btn"
                variant="primary"
                onClick={handleRequest}
                className="send-button flex-grow-1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Support Request"}
              </Button>
            </div>
          </Form>
        </>
      );
    }
  };

  return (
    <div className="support-request-page bg-gradient-vertical">
      <SubHeader text={`Request Support - ${ucWords(trait)}`} back={goBack} />
      <div className="request-form-container d-flex flex-column ">
        {renderSupportRequest()}
      </div>
    </div>
  );
};

export default RequestSupport;

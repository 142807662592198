// Packages
import { Button } from "react-bootstrap";

// Assets
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right.svg";

type QuestionProps = {
  question: any;
  className?: string;
  selectQuestion?: (question: any) => void;
  hideSelf: any;
};

function QuestionBlock({
  question,
  selectQuestion,
  className = "",
  hideSelf,
}: QuestionProps) {
  return (
    <div className={`recording-prompt content ${className}`}>
      <h2 className="prompt-header">Record Response</h2>
      {!hideSelf && <p>{question?.content}</p>}
      {/* <p>{question.content}</p> */}
      {selectQuestion && (
        <Button
          bsPrefix="psq-btn"
          variant="secondary"
          className="btn-start"
          onClick={() => selectQuestion(question)}
        >
          Start&nbsp;
          <ArrowRight className="icon" />
        </Button>
      )}
    </div>
  );
}

export default QuestionBlock;

import "./_index.scss";

interface IconCellProps {
  icon: JSX.Element;
  size?: string;
  ariaLabel?: string;
}

export function IconCell({ icon, size, ariaLabel }: IconCellProps) {
  const sizeClass = size ? size : "col";

  return (
    <td className={`${sizeClass} justify-content-center cell`}>
      <span
        tabIndex={ariaLabel ? 0 : -1}
        aria-label={ariaLabel ? ariaLabel : ""}
      >
        {icon}
      </span>
    </td>
  );
}

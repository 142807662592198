import { Carousel } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "../../../assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../assets/chevron-right.svg";
import "./reskin-carousel.scss";

interface CarouselProps {
  content: JSX.Element[];
  activeIndex?: number;
  onSelect?: (index: number) => void;
}

export const ReskinCarousel = ({
  content,
  activeIndex,
  onSelect,
}: CarouselProps) => {
  return (
    <Carousel
      interval={null}
      touch={true}
      activeIndex={activeIndex}
      onSelect={onSelect}
      prevIcon={<ChevronLeft />}
      nextIcon={<ChevronRight />}
    >
      {content}
    </Carousel>
  );
};

// Packages
import React from "react";
import { Modal } from "react-bootstrap";
import ReskinButton from "../../../reskin/components/Buttons/ReskinButton/ReskinButton";

const ModalSubmitResponse = ({ onRequestClose }: any) => {
  return (
    <Modal className="modal-exit" show={true} onHide={onRequestClose}>
      <Modal.Header className="no-border" closeButton>
        <Modal.Title>Submit Response</Modal.Title>
      </Modal.Header>
      <hr className="border-line"></hr>

      <Modal.Body className="no-border">
        Your video response has not been submitted yet. If you leave this page,
        it will be lost.
      </Modal.Body>

      <Modal.Footer className="no-border">
        <ReskinButton
          text="Don't Submit"
          size="small"
          variant="secondary"
          customClasses="allow-button"
          onClick={() => onRequestClose()}
        />
        <ReskinButton
          text="Submit"
          size="small"
          variant="primary"
          customClasses="allow-button"
          onClick={() => {
            window.location.href = "/assessment/communication/questions";
            onRequestClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSubmitResponse;

type BulletedStatementType = {
  statement: string;
  bullets: string[];
};

type CardLanguageType = string[] | BulletedStatementType[];

interface InsightCardsLanguageSetProps {
  [key: string]: {
    Developing: CardLanguageType;
    Approaching: CardLanguageType;
    Demonstrating: CardLanguageType;
  };
}

const EffectiveCommunicationCardsLanguageSet: InsightCardsLanguageSetProps = {
  // ["Eye Contact"]: {
  //     Developing: [],
  //     Approaching: [],
  //     Demonstrating: [],
  // },
  ["Eye Contact"]: {
    Developing: [
      "You showed a tendency for avoiding eye contact. In your recording, you spent more time with a diverted gaze than you did maintaining eye contact.",
      "Average length of eye contact: 2 seconds",
      "Average amount of time between instances of eye contact: 14 seconds",
      "Try to spend more time holding eye contact and less time diverting your gaze. A safe rule is to maintain eye contact for 70% of the time while you are speaking and for 50% of the time while listening to someone speak.",
    ],
    Approaching: [
      "You showed a slight tendency for avoiding eye contact. In your recording, you spent more time with a diverted gaze than you did maintaining eye contact.",
      "Average length of eye contact: 4 seconds",
      "Average amount of time between instances of eye contact: 9 seconds",
      "Try to spend more time holding eye contact and less time diverting your gaze. A safe rule is to maintain eye contact for 70% of the time while you are speaking and for 50% of the time while listening to someone speak.",
    ],
    Demonstrating: [
      "You showed a strong tendency for maintaining eye contact. In your recording, you spent slightly more time holding eye contact than diverting your gaze.",
      "Average length of eye contact: 7 seconds",
      "Average amount of time between instances of eye contact: 4 seconds",
      "This is an effective combination for successful communication! A safe rule is to maintain eye contact for 70% of the time while you are speaking and for 50% of the time while listening to someone speak.",
    ],
  },
  Posture: {
    Developing: [
      "You showed a tendency for swaying from side-to-side and bobbing your head. You maintained a steady posture for 12% of your recording.",
      "Try to hold your head and shoulders still for 60% of the time you're speaking.",
    ],
    Approaching: [
      "You showed a slight tendency for holding your head still, but you also swayed from side-to-side and bobbed your head. You maintained a steady posture for 27% of your recording.",
      "Try to hold your head and shoulders still for 60% of the time you're speaking.",
    ],
    Demonstrating: [
      "You showed a strong tendency for holding steady posture. During your recording, you maintained even head and body posture for 65% of your video response.",
      "This is a great combination for successful communication!",
    ],
  },
  [" Speech Fluency"]: {
    Developing: [
      "You showed a tendency for using filler words and speaking quickly in your recording.",
      'Most common filler word: "like"',
      "Average number of words per minute: 210 words per minute",
      'Try to replace your use of "like" with effective pausing. This strategy will also help you slow your rate of speaking to a pace more conducive to strong communication.',
    ],
    Approaching: [
      "You showed a slight tendency for using filler words and speaking quickly in your recording.",
      'Most common filler word: "uh"',
      "Average number of words per minute: 188 words per minute",
      'Try to replace your use of "uh" with effective pausing. This strategy will also help you slow your rate of speaking to a pace more conducive to strong communication.',
    ],
    Demonstrating: [
      "You showed a tendency for speaking clearly at an appropriate pace in your recording.",
      'Most common filler word: "um"',
      "Average number of words per minute: 165 words per minute",
      'Your use of the filler word "um" is ok as it can help establish familiarity with others.',
    ],
  },
  ["Effective Pauses"]: {
    Developing: [
      "You showed a tendency for speaking quickly with notable pauses. In your recording, your fast-paced speaking was interrupted by sudden pauses.",
      "Number of pauses: 9",
      "Average length of pause: 8 seconds",
      "Try to limit your pauses to 2 or 3 seconds while speaking. Aim for two pauses for every minute of speech. Also, consider reducing the number of pauses you take, but maximize your pauses and form your next thoughts.",
    ],
    Approaching: [
      "You showed a slight tendency for speaking quickly with notable pauses. In your recording, your fast-paced speaking was interrupted by sudden pauses.",
      "Number of pauses: 5",
      "Average length of pause: 7 seconds",
      "Try to limit your pauses to 2 or 3 seconds while speaking. Aim for two pauses for every minute of speech. Also, consider reducing the number of pauses you take, but maximize your pauses and form your next thoughts.",
    ],
    Demonstrating: [
      "You showed a tendency for speaking confidently with effectively spaced and timed pauses. In your recording, you emphasized ideas with well-placed pauses.",
      "Number of pauses: 3",
      "Average length of pause: 3 seconds",
      "This is an effective combination for strong communication! A good rule is to limit your pauses to 2 or 3 seconds while speaking. Aim for two pauses for every minute of speech. Also, consider maximizing your pauses and form your next thoughts.",
    ],
  },
};

const LeadershipCardsLanguageSet = {
  // ["Eye Contact"]: {
  //     Developing: [],
  //     Approaching: [],
  //     Demonstrating: [],
  // },
  // {
  //     statement: "",
  //     bullets: [ ""]
  // },
  Influence: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "asserting yourself,",
          "exerting dominance,",
          "and controlling group situations.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["asserting yourself"],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["exerting dominance", "and controlling group situations."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for strongly relating to statements about:",
        bullets: [
          "asserting yourself,",
          "exerting dominance,",
          "and controlling group situations.",
        ],
      },
    ],
  },
  Responsibility: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "being dependable,",
          "keeping promises,",
          "and addressing mistakes.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["being dependable."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["keeping promises", "and addressing mistakes."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for strongly relating to statements about:",
        bullets: [
          "being dependable,",
          "keeping promises,",
          "and addressing mistakes.",
        ],
      },
    ],
  },
  Sociability: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "valuing interactions with people you don't know,",
          "maintaining friendships,",
          "and enjoying and preferring socializing over alone time.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["maintaining friendships."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: [
          "valuing interactions with people you don't know",
          "and enjoying and preferring socializing over alone time.",
        ],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for strongly relating to statements about:",
        bullets: [
          "valuing interactions with people you don't know,",
          "maintaining friendships,",
          "and enjoying and preferring socializing over alone time.",
        ],
      },
    ],
  },
  Trust: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "taking people at their word",
          "and seeing and believing the best in others.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["seeing and believing the best in others."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["taking people at their word."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for strongly relating to statements about:",
        bullets: [
          "taking people at their word",
          "and seeing and believing the best in others.",
        ],
      },
    ],
  },
  Collaboration: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "resolving disagreements,",
          "fostering teamwork,",
          "and valuing politeness.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["valuing politeness."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["resolving disagreements", "and fostering teamwork."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for strongly relating to statements about:",
        bullets: [
          "resolving disagreements,",
          "fostering teamwork,",
          "and valuing politeness.",
        ],
      },
    ],
  },
  ["Even-Temperedness"]: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "maintaining calmness,",
          "not being quick to anger,",
          "and not being easily frustrated.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["not being easily frustrated."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["maintaining calmness", "and not being quick to anger."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for strongly relating to statements about:",
        bullets: [
          "maintaining calmness,",
          "not being quick to anger,",
          "and not being easily frustrated.",
        ],
      },
    ],
  },
  Organization: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "keeping your workspace in order,",
          "being punctual,",
          "and planning and preparing.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["keeping your workspace in order"],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["being punctual", "and planning and preparing."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "keeping your workspace in order,",
          "being punctual,",
          "and planning and preparing.",
        ],
      },
    ],
  },
};

const PerseveranceCardsLanguageSet = {
  // ["Eye Contact"]: {
  //     Developing: [],
  //     Approaching: [],
  //     Demonstrating: [],
  // },
  Persistence: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "taking on challenges,",
          "maintaining motivation,",
          "and striving for excellence.",
        ],
      },
      "You showed a pattern for being least likely to relate to statements about: taking on challenges, maintaining motivation, and striving for excellence.",
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["taking on challenges."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["maintaining motivation", "and striving for excellence."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "taking on challenges,",
          "maintaining motivation,",
          "and striving for excellence.",
        ],
      },
    ],
  },
  Resiliency: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "regulating negative emotions,",
          "effectively managing stress,",
          "and adapting to unexpected issues.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["effectively managing stress."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: [
          "regulating negative emotions",
          "and adapting to unexpected issues.",
        ],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "regulating negative emotions,",
          "effectively managing stress,",
          "and adapting to unexpected issues.",
        ],
      },
    ],
  },
  ["Self-Discipline"]: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "maintaining focus,",
          "making considered decisions,",
          "and controlling impulses.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["maintaining focus."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["making considered decisions", "and controlling impulses."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "maintaining focus,",
          "making considered decisions,",
          "and controlling impulses.",
        ],
      },
    ],
  },
  ["Rule-Following "]: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "appreciating authority,",
          "adhering to codes of conduct,",
          "and respecting norms and traditions.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["adhering to codes of conduct."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: [
          "appreciating authority",
          "and respecting norms and traditions.",
        ],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "appreciating authority,",
          "adhering to codes of conduct,",
          "and respecting norms and traditions.",
        ],
      },
    ],
  },
};

const InnovativeThinkingCardsLanguageSet = {
  // ["Eye Contact"]: {
  //     Developing: [],
  //     Approaching: [],
  //     Demonstrating: [],
  // },
  Curiosity: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "engaging with the unfamiliar,",
          "learning new things,",
          "being inquisitive,",
          "and seeking new experiences.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["engaging with the unfamiliar", "and learning new things."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["being inquisitive", "and seeking new experiences."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "engaging with the unfamiliar,",
          "learning new things,",
          "being inquisitive,",
          "and seeking new experiences.",
        ],
      },
    ],
  },
  Creativity: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "coming up with new ideas,",
          "finding creative solutions,",
          "trying to improve things,",
          "and being original.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: [
          "coming up with new ideas",
          "and finding creative solutions.",
        ],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["trying to improve", "and being original."],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "coming up with new ideas,",
          "finding creative solutions,",
          "trying to improve things,",
          "and being original.",
        ],
      },
    ],
  },
  ["Artistic Appreciation"]: {
    Developing: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "seeking art for expression,",
          "feeling affected by art,",
          "noticing beauty,",
          "and enjoying different forms of art.",
        ],
      },
    ],
    Approaching: [
      {
        statement: "You showed a pattern for relating to statements about:",
        bullets: ["seeking art for expression", "and feeling affected by art."],
      },
      {
        statement:
          "However, you were less likely to relate to statements about:",
        bullets: ["noticing beauty,", "enjoying different forms of art"],
      },
    ],
    Demonstrating: [
      {
        statement:
          "You showed a pattern for being least likely to relate to statements about:",
        bullets: [
          "seeking art for expression,",
          "feeling affected by art,",
          "noticing beauty,",
          "and enjoying different forms of art.",
        ],
      },
    ],
  },
};

const subtraitCarouselLanguage = {
  leadership: LeadershipCardsLanguageSet,
  perseverance: PerseveranceCardsLanguageSet,
  ["effective communication"]: EffectiveCommunicationCardsLanguageSet,
  ["innovative thinking"]: InnovativeThinkingCardsLanguageSet,
};

export default subtraitCarouselLanguage;

// Packages
import React from "react";

// Assets
import { ReactComponent as CameraImage } from "../../../../assets/camera.svg";

function HideSelf(props: any) {
  const { question } = props;
  return (
    <>
      <div className="image d-flex justify-content-center">
        <CameraImage />
      </div>
      <p>
        Your self view is off, but your camera is still capturing video to
        enable review and feedback. Tap the camera icon below to turn self view
        on.
        <br /> <br />
        <strong>{question.content}</strong>
      </p>
    </>
  );
}

export default HideSelf;

//Packages
import { API, graphqlOperation, Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
//Graphql
import * as getUser from "../../../../graphql/queries";
import * as listCandidateComments from "../../../../graphql/queries";
import { listLearningSelfReflections } from "../../../../graphql/queries";
import { listHeiBookmarks } from "../../../../graphql/queries";
import {
  createHeiBookmarks,
  updateHeiBookmarks,
} from "../../../../graphql/mutations";

import createOrUpdate, {
  getOrList,
} from "../../../../components/dynamicqueries";
//Images
import { ReactComponent as BookmarkImage } from "../../../../assets/fullsummary/bookmark.svg";
import { ReactComponent as SelectedBookmarkImage } from "../../../../assets/fullsummary/SelectedBookmark.svg";

//Model
import { HeiBookmarks } from "../../../../models";
// Redux
import { RootState } from "../../../../redux/reducers";

// Services
import EventService from "../../../../services/EventService/event.service";

//Component
import Loading from "../../loading";
import SupportPill from "../../../../components/SupportPill";

function Tips({ assessmentID, candidateId, userSessionId, userscores }: any) {
  // const candidateId = "85a7730c-4228-4113-96ee-0e928cc31438";
  // const userSessionId = "2e8269fa-8992-476a-9b88-c0e63ad587d2";
  let navigate = useNavigate();
  const { userId } = useSelector((state: RootState) => state.user);
  const [userInfo, setUserInfo] = React.useState<any>([]);
  const [userComment, setUserComment] = React.useState<string>();
  const [selfReflection, setSelfReflection] = React.useState<boolean>(false);
  const [heiBookmark, setHeiBookmark] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tips, setTips] = React.useState<any>([]);
  const [imageUrl, setimage] = React.useState<any>([]);
  React.useEffect(() => {
    getUserInfo();
    // getCandidateComments();
    getSelfReflections();
    getTips();
    getHeiBookmark();
    getS3PresignedUrl();
  }, []);

  const getCamelCaseTrait = (traitName: any) => {
    if (traitName === "effective communication") {
      return "Effective Communication";
    } else if (traitName === "innovative thinking") {
      return "Innovative Thinking";
    } else if (traitName === "leadership") {
      return "Leadership";
    } else if (traitName === "perseverance") {
      return "Perseverance";
    }
  };

  const { studentRequests } = userscores[0].userAssessmentState;

  let supportText = "none";

  let traitList = new Array();

  if (studentRequests.items.length > 0) {
    supportText = "requested";
    let listofRequests = studentRequests.items;
    listofRequests.map((value: any) => {
      if (value.institutionResponses.items.length > 0) {
        supportText = "sent";
      } else {
        supportText = "requested";
        traitList = [...traitList, getCamelCaseTrait(value.traitName)];
        return false;
      }
    });
  }

  const getHeiBookmark = async () => {
    const filterVariable = {
      limit: 100000,
      filter: { userId: { eq: userId } },
    };
    const heiBookmark = await getOrList(
      listHeiBookmarks,
      "listHeiBookmarks",
      filterVariable,
    );

    if (heiBookmark.length > 0) {
      let candidateUserIds: any = heiBookmark[0].candidateUserId;

      if (
        candidateUserIds.filter((item: any) => item === candidateId).length > 0
      ) {
        setHeiBookmark(true);
      }
    }
  };
  const getTips = async () => {
    const result = userscores[0].userAssessmentState.quickTips;
    if (result) {
      const tips = Object.values(JSON.parse(result.tips));
      setTips(tips);
    }
  };

  const getCandidateComments = async () => {
    const result = userscores[0].userAssessmentState.comments;
    let comment = "";
    if (result) {
      const candidateComments = result;
      const randomData: any = [];
      if (candidateComments.declineCommentOnLeadership === false) {
        randomData.push(candidateComments.leadershipComment);
      }
      if (candidateComments.declineCommentOnCommunication === false) {
        randomData.push(candidateComments.communicationComment);
      }
      if (candidateComments.declineCommentOnInnovativeThinking === false) {
        randomData.push(candidateComments.innovativeThinkingComment);
      }
      if (candidateComments.declineCommentOnPerserverance === false) {
        randomData.push(candidateComments.perseveranceComment);
      }
      if (randomData.length > 0) {
        const random = Math.floor(Math.random() * randomData.length);
        setUserComment(randomData[random]);
        setSelfReflection(true);
      } else {
        setUserComment(
          `decided not to submit a self-reflection on SkillTrack. It might be a good idea to reach out to her and see how she feels about her performance`,
        );
      }
      setLoading(false);
    } else {
      setUserComment(
        `hasn't completed this self-reflection yet. Please check back later.`,
      );
      setLoading(false);
    }
  };

  const getSelfReflections = async () => {
    const result: any = await API.graphql(
      graphqlOperation(listLearningSelfReflections, {
        filter: {
          userId: { eq: candidateId },
        },
      }),
    );
    // console.log(result?.data.listLearningSelfReflections.items, "result");
    if (result?.data.listLearningSelfReflections.items.length > 0) {
      setSelfReflection(true);
      setUserComment(
        result?.data.listLearningSelfReflections.items[0].takeawayComment +
          ". " +
          result?.data.listLearningSelfReflections.items[0].applicationComment,
      );
    } else {
      setUserComment(
        `hasn't completed this self-reflection yet. Please check back later.`,
      );
    }
    setLoading(false);
  };

  const getUserInfo = async () => {
    setUserInfo(userscores[0].userAssessmentState.user);
  };
  const bookmark = async (boolvalue: any) => {
    const filter = { filter: { userId: { eq: userId } } };
    const original = await getOrList(
      listHeiBookmarks,
      "listHeiBookmarks",
      filter,
    );

    if (original.length > 0) {
      const candidateUserId: any = original[0].candidateUserId;
      let candidateData: any = candidateUserId;
      if (boolvalue) {
        if (!candidateUserId.includes(candidateId)) {
          candidateData = candidateUserId.concat([candidateId]);
        }

        EventService.track({
          event_type: "user",
          event_name: "hei_student_bookmark",
          user_type: "hei",
          event_data: {
            studentId: candidateId,
          },
        });

        setHeiBookmark(true);
      } else {
        candidateData = candidateUserId.filter(
          (item: any) => item !== candidateId,
        );

        EventService.track({
          event_type: "user",
          event_name: "hei_student_unbookmark",
          user_type: "hei",
          event_data: {
            studentId: candidateId,
          },
        });

        setHeiBookmark(false);
      }
      const input = {
        id: original[0].id,
        userId: userId,
        userSessionId: userSessionId,
        assessmentId: assessmentID,
        candidateUserId: candidateData,
        _version: original[0]._version,
      };
      await createOrUpdate(updateHeiBookmarks, input);
    } else {
      const input = {
        userId: userId,
        userSessionId: userSessionId,
        assessmentId: assessmentID,
        candidateUserId: [candidateId],
      };
      await createOrUpdate(createHeiBookmarks, input);

      EventService.track({
        event_type: "user",
        event_name: "hei_student_bookmark",
        user_type: "hei",
        event_data: {
          studentId: candidateId,
        },
      });

      setHeiBookmark(true);
    }
  };

  const getS3PresignedUrl = async () => {
    let preSignedUrl: any;
    let filePath = "photos/" + candidateId + ".PNG";
    preSignedUrl = await Storage.get(filePath, {
      level: "public",
      download: false,
      expires: 300,
    });

    const checkImage = (imageSrc: string) => {
      var img = new Image();
      img.onload = () => {
        setimage(imageSrc);
      };
      img.onerror = () => {
        setimage(
          require("../../../../assets/reskin/icons/profile_pic.svg").default,
        );
      };
      img.src = imageSrc;
    };
    checkImage(preSignedUrl);
  };

  const renderUserComment = () => {
    if (typeof userComment === "undefined") return <></>;

    if (selfReflection) {
      return `${userComment}`;
    } else {
      return `${userInfo.name} ${userComment}`;
    }
  };

  const renderTraitsList = () => {
    return (
      <span className="traits-list">
        {traitList.length > 0 &&
          traitList.reduce((previousValue, currentValue) => {
            return previousValue + ", " + currentValue;
          })}
      </span>
    );
  };

  const handleSendSupport = () => {
    navigate(`/fullsummary/support/send/${userSessionId}`);
  };

  const goToStudentList = () => {
    navigate("/fullsummary/students");
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="container wrapper student">
            <div className="title">
              <h1>
                <a className="student-list-link" onClick={goToStudentList}>
                  Student List
                </a>
                <p>&nbsp;</p>
                {">"} Individual SkillTrack Insights
              </h1>
              <button
                type="button"
                className="psq-btn-secondary"
                onClick={() => bookmark(!heiBookmark)}
              >
                {!heiBookmark ? (
                  <span>Flag Student Profile</span>
                ) : (
                  <span>Student Profile Flagged</span>
                )}
              </button>
            </div>
          </div>
          <div className="student-detail">
            <p className="profile-info">Profile Info</p>
            <div className="content info">
              <div className="userImage">
                <img
                  src={imageUrl}
                  alt={`Student ${userInfo.name}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    // currentTarget.src =
                    //   require("../../../../assets/fullsummary/user-avatar.png").default;
                  }}
                />
              </div>
              <div className="name-and-pill">
                <div>
                  <strong>{userInfo.name}</strong>
                </div>
                {supportText !== "none" ? (
                  <div className="pill-wrapper">
                    <SupportPill status={supportText} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <p className="demographics">
                {userInfo.gender}, Age {userInfo.ageGroup}, Enrolled since{" "}
                {userInfo.enrollmentYear}{" "}
              </p>
              <div className="content-header goals">
                <p className="one">
                  <span className="goal-title">My Personal Goals</span>
                  <br />
                  <span className="goal-text">{userInfo.goal}</span>
                </p>
                <p className="two">
                  <span className="goal-title">Employment Status</span>
                  <br />
                  <span className="goal-text">{userInfo.emplStatus}</span>
                </p>
                <p className="three">
                  <span className="goal-title">Years of Work Experience</span>
                  <br />
                  <span className="goal-text">{userInfo.workExperience}</span>
                </p>
              </div>
              <div className="quote">
                <em>What {userInfo.name.split(" ")[0]} has to say:</em>
                <p>{renderUserComment()}</p>
              </div>

              {/* <div className="support-request">
                <div className="support-request-content">
                  <span className="support-request-title">
                    My Support Request
                  </span>
                  <br />
                  <div>{renderTraitsList()}</div>
                </div>

                <Button
                  bsPrefix="psq-btn"
                  variant="secondary"
                  className="btn-sendSupport"
                  onClick={handleSendSupport}
                >
                  Send Support
                </Button>
              </div> */}

              <div className="content tips">
                <strong>Quick Tips for {userInfo.name.split(" ")[0]}</strong>
                <ul>
                  {tips.map((tip: string, index: number) => {
                    return <li key={index}>{tip} </li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Tips;

import { Button } from "react-bootstrap";
import "./BottomBorderInput.scss";
import { useState } from "react";
import { ReactComponent as Eye } from "../../../assets/eye.svg";
import { ReactComponent as EyeSlash } from "../../../assets/eyeSlash.svg";

interface BottomBorderInputType {
  value: any;
  onChange: Function;
  label: string | JSX.Element;
  id: string;
  name?: string;
  customClasses?: string;
  disabled?: boolean;
  type?: string;
}

const BottomBorderInput = ({
  value,
  onChange,
  label,
  id,
  name,
  disabled,
  customClasses,
  type,
}: BottomBorderInputType) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={`bottom-border-input-wrapper mb-5 ${customClasses}`}>
      {label && (
        <div>
          <label className="bottom-border-input-label" htmlFor={id}>
            {label}
          </label>
        </div>
      )}
      <input
        className={`bottom-border-input`}
        disabled={disabled}
        name={name || id}
        value={value}
        type={type ? showPassword ? "text" : "password" : "text"}
        onChange={(e) => onChange(e.target.value)}
      />
      {type === "password" && (
        <Button
          className="password-eye"
          variant="white"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeSlash /> : <Eye />}
        </Button>
      )}
    </div>
  );
};

export default BottomBorderInput;

// Packages
import { useContext, useEffect, useState } from "react";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ModalContext } from "../../../../contexts/context";
import { RootState } from "../../../../redux/reducers";
import { LazyInstitution } from "../../../../models";
import { HelpModal, SignOutModal } from "../../modals";
import { ReactComponent as BuildingLogo } from "../../../institution/assets/institution-building.svg";
import { ReactComponent as ProfileLogo } from "../../../institution/assets/profile-logo.svg";
import { ReactComponent as SignOut } from "../../../institution/assets/sign-out.svg";
import { ReactComponent as QuestionCircle } from "../../../institution/assets/question-circle.svg";
import "./institution-header.scss";
import { useLocation } from "react-router-dom";

export const InstitutionHeader = () => {
  const { showModal } = useContext(ModalContext);
  const { user } = useSelector((state: RootState) => state);
  const [currentInstitution, setCurrentInstitution] = useState<LazyInstitution | null>(null);
  const location = useLocation();

  useEffect(() => {
    const setInitialInstitution = async () => {
      async function getInstitution(user: any) {
        return await user.institution;
      }
      const newInstitution = await getInstitution(user);
      setCurrentInstitution(newInstitution);
    };
    setInitialInstitution();
  }, [user]);

  const handleSignOut = () => {
    showModal(SignOutModal);
  };

  const handleHelp = () => {
    showModal(HelpModal);
  };

  const dropdownItems = [
    { path: "/fullsummary/traits/leadership", title: "Leadership" },
    { path: "/fullsummary/traits/effectivecommunication", title: "Effective Communication" },
    { path: "/fullsummary/traits/perseverance", title: "Perseverance" },
    { path: "/fullsummary/traits/innovativethinking", title: "Innovative Thinking" },
  ];
  

  const isActive = (pathname: string) => {
    const currentPath = location.pathname;
    const segments = currentPath.split('/');
    const lastSegment = segments.pop() || segments.pop();
  
    const pathnameSegments = pathname.split('/');
    const lastPathnameSegment = pathnameSegments.pop() || pathnameSegments.pop();
  
    return lastSegment === lastPathnameSegment;
  };

  function isBaseActive(basePath: string): boolean {
    if (basePath.startsWith('/')) {
      basePath = basePath.substring(1);
    }
    const normalizedPathname = location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`;
    const targetPath = `/${basePath}/`;
    return normalizedPathname === targetPath || normalizedPathname.startsWith(targetPath);
  }

  const isAnyActive = (paths: string[]) => {
    return paths.some(path => isActive(path));
  };
  
  return (
    <>
      <Navbar className="institution-header">
        <Nav.Link className="header-left" title="Home" href="/">
          <span style={{color: "#255E6E"}}>Skill</span><span style={{color: "#000000"}}>Track</span>
        </Nav.Link>
        <div className="header-right">
          <Nav.Item className="name">
            <BuildingLogo />
            {currentInstitution?.name}
          </Nav.Item>
          <ProfileLogo />
          <NavDropdown className="profile-dropdown" title={user.name}>
            <NavDropdown.Item onClick={handleHelp}>
              <QuestionCircle />
              Help
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleSignOut}>
              <SignOut />
              Sign Out
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </Navbar>
      <Nav className="institution-nav" defaultActiveKey="/">
        <Nav.Link className={`link-item ${isActive('/overview') ? 'active' : ''}`} href="/fullsummary/overview">
          Dashboard
        </Nav.Link>
        <NavDropdown
          className={`institution-dropdown-item ${isAnyActive(dropdownItems.map(item => item.path)) ? 'dropdown-active' : ''}`}
          title={"Insights by Skill"}
        >
          {dropdownItems.map((item, index) => (
            <NavDropdown.Item
              key={index}
              className={`dropdown-item ${isActive(item.path) ? 'active' : ''}`}
              href={item.path}
            >
              {item.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
        <Nav.Link className={`link-item ${isBaseActive("/fullsummary/students") ? 'active' : ''}`} href="/fullsummary/students">
          Students
        </Nav.Link>
      </Nav>
    </>
  );
};

// Packages
import React from "react";
import { useSelector } from "react-redux";

// Components
import ReskinNavBar from "../../NavBars/ReskinNavBar";
import MainAppMenu from "../../Menus/MainAppMenu/MainAppMenu";
import MainAppAccountMenu from "../../Menus/MainAppAccountMenu/MainAppAccountMenu";

// Store
import { RootState } from "../../../../redux/reducers";

// Assets
import { ReactComponent as NavProfileCircle } from "../../../../assets/reskin/icons/nav-person-circle.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/re-skin/closeIcon.svg";
import { ReactComponent as CloseIconDark } from "../../../../assets/reskin/icons/close-icon-charcoal.svg";
import { ReactComponent as HamburgerIconWhite } from "../../../../assets/reskin/icons/hamburger-icon-white.svg";
import { ReactComponent as HamburgerIconBlack } from "../../../../assets/reskin/icons/hamburger-icon-black.svg";

// Style
import "./MainAppHeader.scss";

const MainAppHeader = () => {
  const { user } = useSelector((state: RootState) => state);
  const { name } = user;
  const [showMenu, setShowMenu] = React.useState(false);
  const [headerConfiguration, setHeaderConfiguration] = React.useState("");
  const [MenuContent, setMenuContent] = React.useState(<></>);
  const isShownClass = showMenu ? "" : "hidden";

  React.useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMenu]);

  const headerConfigurationStrings = {
    main: "main-app-menu-configuration",
    assessment: "assessment-menu-configuration",
  };

  // Left Side

  const CloseLeftMenuButton = (
    <button
      className="reskin-clear-button"
      onClick={() => {
        setShowMenu(false);
        setHeaderConfiguration(headerConfigurationStrings.assessment);
        setMenuContent(<></>);
      }}
    >
      {<HamburgerIconBlack />}
    </button>
  );

  const CloseLeftMenuDisplay = (
    <div className="main-app-header-left-display">
      <div className="vertically-centered me-2">{CloseLeftMenuButton}</div>
      <div className="vertically-centered">
        <p className="m-0 mt-1">Menu</p>
      </div>
    </div>
  );

  const ProfileDisplay = (
    <div className="profile-display">
      <div className="vertically-centered me-2">
        <NavProfileCircle />
      </div>
      <div className="vertically-centered">
        <p className="m-0">{name}</p>
      </div>
    </div>
  );
  const hideMenu = () => {
    setShowMenu(false);
    setHeaderConfiguration(headerConfigurationStrings.assessment);
    setMenuContent(<></>);
  };

  const OpenLeftMenuButton = (
    <button
      className="reskin-clear-button"
      onClick={() => {
        setMenuContent(<MainAppMenu hideMenu={hideMenu} />);
        setShowMenu(true);
        setHeaderConfiguration(headerConfigurationStrings.main);
      }}
    >
      <HamburgerIconWhite />
    </button>
  );

  const getLeftIcon = () => {
    let CurrentIcon = OpenLeftMenuButton;

    if (showMenu && headerConfiguration === headerConfigurationStrings.main) {
      CurrentIcon = CloseLeftMenuDisplay;
    }

    if (
      showMenu &&
      headerConfiguration === headerConfigurationStrings.assessment
    ) {
      CurrentIcon = ProfileDisplay;
    }

    return CurrentIcon;
  };

  // Right Side
  const CloseButton = (
    <button
      className="reskin-clear-button"
      onClick={() => {
        setShowMenu(false);
        setHeaderConfiguration(headerConfigurationStrings.assessment);
        setMenuContent(<></>);
      }}
    >
      {headerConfiguration === headerConfigurationStrings.main ? (
        <CloseIconDark />
      ) : (
        <CloseIcon />
      )}
    </button>
  );

  const PersonButton = (
    <button
      className="reskin-clear-button"
      onClick={() => {
        setHeaderConfiguration(headerConfigurationStrings.assessment);
        setMenuContent(<MainAppAccountMenu />);
        setShowMenu(!showMenu);
      }}
    >
      <NavProfileCircle />
    </button>
  );

  const getRightIcon = () => {
    return showMenu ? CloseButton : PersonButton;
  };

  return (
    <>
      <div className="main-app-header-wrapper">
        <div className={`reskin-background-overlay ${isShownClass}`} />
        <div className="main-app-header">
          <ReskinNavBar
            leftIcon={getLeftIcon()}
            rightIcon={getRightIcon()}
            customClasses={`${headerConfiguration}`}
            isSticky={true}
          />
          <div
            className={`reskin-header-menu ${isShownClass} ${headerConfiguration}`}
          >
            {MenuContent}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainAppHeader;

import Subheader from "../../../../components/Subheader";
import { useNavigate } from "react-router-dom";
import { TermsConView } from "../../../components/TermsConView/TermsConView";

function TermsAndConditions() {
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Subheader back={goBack} text="Terms &amp; Conditions" />
      <TermsConView />
    </>
  );
}

export default TermsAndConditions;

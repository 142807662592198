// Components
import { Button } from "react-bootstrap";

// Styles
import "./NoBorderButton.scss";

// Assets
import { ReactComponent as GreenCaretRight } from "../../../../assets/reskin/icons/green-caret-right.svg";

interface NoBorderButtonProps {
  text: string;
  icon?: JSX.Element;
  onClick: Function;
  customClasses?: string;
  isIconLeft?: boolean;
}

const NoBorderButton = ({
  text,
  icon,
  onClick,
  customClasses,
  isIconLeft,
}: NoBorderButtonProps) => {
  const DefaultIcon = <GreenCaretRight />;

  // Which side is the Icon on?
  const ButtonContent = isIconLeft ? (
    <>
      {icon || DefaultIcon} <span className="button-text">{text}</span>{" "}
    </>
  ) : (
    <>
      <span className="button-text">{text}</span> {icon || DefaultIcon}
    </>
  );

  return (
    <Button
      className={`no-border-button ${customClasses}`}
      onClick={() => onClick()}
    >
      {ButtonContent}
    </Button>
  );
};

export default NoBorderButton;

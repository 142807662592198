// Packages
import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import Subheader from "../../../../components/Subheader";
import { LazyInstitution } from "../../../../models";

// Redux
import { RootState } from "../../../../redux/reducers";

// Styles
import "./MyAccount.styles.css";
import ResetPasswordModal from "../../../components/Modals/ResetPasswordModal/ResetPasswordModal";

function MyAccount() {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const { user } = useSelector((state: RootState) => state);
  const { email, name, enrollmentYear, enrollmentSemester } = user;
  const [currentInstitution, setCurrentInstitution] = useState<LazyInstitution | null>(null);

  let navigate = useNavigate();
  let firstName = name?.split(" ")[0];
  let lastName = name?.split(" ")[1];

  useEffect(() => {
    const setInitialInstitution = async () => {
      async function getInstitution(user: any) {
        return await user.institution;
      }
      const newInstitution = await getInstitution(user);
      setCurrentInstitution(newInstitution);
    };
    setInitialInstitution();
  });

  let instituteName =
    currentInstitution && currentInstitution?.name
      ? currentInstitution.name
      : "";

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Subheader text="My Account" back={goBack} />
      <section className="myAccount section">
        <div className="wrapper container pb-4">
          <div className="reskin-content">
            <Form>
              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  disabled
                  placeholder="name@example.com"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <Form.Label className="formlabel">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="*******"
                      readOnly
                      plaintext
                      disabled
                    ></Form.Control>
                  </div>
                  <Button
                    className="h-50"
                    variant="link"
                    onClick={() => setShowResetPasswordModal(true)}
                  >
                    Reset Password
                  </Button>
                  {showResetPasswordModal && (
                    <ResetPasswordModal
                      show={showResetPasswordModal}
                      handleClose={() => setShowResetPasswordModal(false)}
                    />
                  )}
                </div>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">First Name</Form.Label>
                <Form.Control
                  type="First Name"
                  value={firstName}
                  disabled
                  placeholder="First Name"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">Last Name</Form.Label>
                <Form.Control
                  type="Last Name"
                  value={lastName}
                  disabled
                  placeholder="Last Name"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">
                  Your institution that Requests SkillTrack
                </Form.Label>
                <Form.Control
                  disabled
                  value={instituteName}
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">
                  Year of Enrollment
                </Form.Label>
                <Form.Control
                  type="Year of Enrollment"
                  value={enrollmentYear ? enrollmentYear : ""}
                  disabled
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">
                  Semester of Enrollment
                </Form.Label>
                <Form.Control
                  type="Semster of Enrollment"
                  value={enrollmentSemester ? enrollmentSemester : ""}
                  disabled
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}

export default MyAccount;

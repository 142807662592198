import { TermsConView } from "../../../components/TermsConView/TermsConView";
import "./terms-conditions.scss";

export const TermsConditions = () => {
  return (
    <div className="terms-conditions-container">
      <h1>Terms &amp; Conditions</h1>
      <TermsConView />
    </div>
  );
}
// Components
import {
  Cell,
  ProfileImageLeftCell,
} from "../../../../../components/Table/dataCells";
import SupportCell from "./SupportCell";
import { Button } from "react-bootstrap";

// Types
import { StudentSupportRowType } from "../utils/types";

// Packages
import React from "react";
import { Storage } from "aws-amplify";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface StudentSupportRowProps {
  row: StudentSupportRowType;
}

const StudentSupportRow = ({ row }: StudentSupportRowProps) => {
  const {
    name,
    leadership,
    perseverance,
    userSessionId,
    effectiveCommunication,
    innovativeThinking,
    userId,
  } = row;

  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = React.useState();
  const getS3PresignedUrl = async () => {
    let preSignedUrl: any;
    let filePath = "photos/" + userId + ".PNG";
    preSignedUrl = await Storage.get(filePath, {
      level: "public",
      download: false,
      expires: 300,
    });
    setImageUrl(preSignedUrl);
  };

  React.useEffect(() => {
    getS3PresignedUrl();
  }, []);

  const ProfilePicture = (
    <img
      src={imageUrl}
      className="profilePicture"
      alt="student"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          require("../../../../../assets/fullsummary/user-avatar.png").default;
      }}
    />
  );

  const StudentLink = (
    //TODO: link destination to google for now.
    <Link className="studentLink" to={`/fullsummary/students/${userSessionId}`}>
      <LinesEllipsis text={name} maxLine="2" />
    </Link>
  );

  const renderSupportBtn = () => {
    return (
      <Button
        bsPrefix="psq-btn"
        variant="primary"
        type="submit"
        className="btn-sendSupport"
        onClick={() => {
          navigate(`/fullsummary/support/send/${userSessionId}`);
        }}
      >
        Send Support
      </Button>
    );
  };

  return (
    <tr className="row tableRow flex-nowrap">
      <ProfileImageLeftCell
        renderedImage={ProfilePicture}
        size="col-2 pt-3"
        renderedText={StudentLink}
      />
      <SupportCell supportStatus={leadership} />
      <SupportCell supportStatus={perseverance} />
      <SupportCell supportStatus={effectiveCommunication} />
      <SupportCell supportStatus={innovativeThinking} />
      <Cell content={renderSupportBtn()} size="col-2" />
    </tr>
  );
};

export default StudentSupportRow;

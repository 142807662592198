// Packages
import "./institution-auth.scss";
import { Col } from "react-bootstrap";
import {
  SignIn,
  SignUp,
  ResetPassword,
  ForgotPassword,
  VerifyAccount,
  Success,
} from "../../forms";
declare global {
  interface Window {
    pendo: any;
  }
}

export const InstitutionAuth = ({ changeAuthState, userType, form }: any) => {
  console.log(form);
  const renderForm = () => {
    switch (form) {
      case "SignUp":
        return <SignUp changeAuthState={changeAuthState} userType={userType} />;
      case "ResetPassword":
        return <ResetPassword changeAuthState={changeAuthState} />;
      case "ForgotPassword":
        return <ForgotPassword changeAuthState={changeAuthState} />;
      case "Verify":
        return <VerifyAccount changeAuthState={changeAuthState} />;
      case "Success":
        return <Success changeAuthState={changeAuthState} />;
      default:
        return <SignIn changeAuthState={changeAuthState} userType={userType} />;
    }
  };

  return (
    <div className="authentication">
      <Col className="column-image" lg={7}>
        <div className="logos">
          <h1 className="app-title">SkillTrack</h1>
        </div>
      </Col>
      <Col className="column-form" lg={5}>
        {renderForm()}
      </Col>
    </div>
  );
};

// Assets
import { ReactComponent as AlertImage } from "../../../../assets/alert.svg";

type ErrorViewType = {
  type?: string;
};

function ErrorView({ type = "unspecified" }: ErrorViewType) {
  let message = "";

  switch (type) {
    default:
      message =
        "You can not record your response because the camera and microphone access has been blocked. Try refreshing this page or go to your browser settings to change the permission.";
      break;
  }

  return (
    <div className="errorView">
      <div className="image d-flex justify-content-center">
        <AlertImage />
      </div>
      <p>{message}</p>
    </div>
  );
}

export default ErrorView;

import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";

function ReflectionModal({ show, close }: any) {
  let navigate = useNavigate();

  return (
    <Modal dialogClassName="mt-5" show={show} onHide={() => close(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Response Submitted</Modal.Title>
      </Modal.Header>
      <Modal.Body>Thank you for submitting your reflections!</Modal.Body>
      <div style={{margin: "auto", padding: "20px"}}>
        <ReskinButton
          size="small"
          variant="primary"
          onClick={() => {
            navigate(-1);
            close(false);
          }}
          text="Close"
        />
      </div>
    </Modal>
  );
}

export default ReflectionModal;

// Packages
import React from "react";
import { Container } from "react-bootstrap";

// Assets
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";

type SubheaderType = {
  back?: any;
  text: string | any;
  subtext?: string;
  variant?: string;
  noSpacers?: boolean;
};

function Subheader({
  back,
  text,
  subtext,
  variant = "primary",
  noSpacers = false,
}: SubheaderType) {
  return (
    <section className={`subheader variant-${variant.toLowerCase()}`}>
      <Container className="wrapper">
        <div className="top">
          {back ? (
            <button onClick={back} className="btn-back">
              <BackIcon />
            </button>
          ) : (
            <>{!noSpacers && <div className="spacer"></div>}</>
          )}

          <div className="text">{text}</div>

          <>{!noSpacers && <div className="spacer"></div>}</>
        </div>

        {subtext && <div className="bottom">{subtext}</div>}
      </Container>
    </section>
  );
}

export default Subheader;

// Packages
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as EyeSlash } from "../../assets/eyeSlash.svg";
import "./password-input.scss";

export const PasswordInput = ({ handleInputChange, error, disableAutoComplete, tabIndex }: any) => {
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordFocus = (value: boolean) => setPasswordFocused(value);
  const autoCompleteStatus = disableAutoComplete ? "on" : "off";

  return (
    <Form.Group className="mb-4 password-input" controlId="formPassword">
      <Form.Label className="label">
        <span>
          Password <span className="required">*</span>
        </span>
      </Form.Label>
      <Form.Control
        tabIndex={tabIndex}
        type={showPassword ? "text" : "password"}
        placeholder=""
        data-prop={"password"}
        onChange={handleInputChange}
        autoComplete={autoCompleteStatus}
        required
        isInvalid={error !== ""}
        onFocus={() => togglePasswordFocus(true)}
        onBlur={() => togglePasswordFocus(false)}
      />
      <Button
        className="password-eye"
        variant="white"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <EyeSlash /> : <Eye />}
      </Button>
      {passwordFocused && (
        <Form.Text className="text-help">
          Password must be at least 8 characters and include a number, a
          lowercase, an uppercase and special symbol.
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

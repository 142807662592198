// Packages
import React from "react";
import { Table } from "react-bootstrap";
import { v4 as uuidV4 } from "uuid";

// Components
import Skeleton from "react-loading-skeleton";
import HeaderRow from "./HeaderRow";
import { StudentRow } from "./Body";

// Services
import EventService from "../../../../services/EventService/event.service";

// Styles
import "./index.scss";

// Types
import { StudentRowType } from "../types";

interface StudentTableProps {
  isTableLoading: boolean;
  sortingHandler: Function;
  allStudentsList: StudentRowType[];
  currentStudentsList: StudentRowType[];
  setRefreshingCSVData: Function;
  setStudentsList: Function;
  isDashboard: boolean;
}

function StudentTable({
  allStudentsList,
  currentStudentsList,
  setStudentsList,
  isTableLoading,
  setRefreshingCSVData,
  sortingHandler,
  isDashboard,
}: StudentTableProps) {
  const RenderedRows = currentStudentsList.map((student, index) => {
    if (isTableLoading) return <Skeleton />;

    return (
      <StudentRow
        setStudentsList={setStudentsList}
        allStudentsList={allStudentsList}
        setRefreshingCSVData={setRefreshingCSVData}
        propKey={`studentTable-row-${index}-${uuidV4()}`}
        index={index}
        student={student}
      />
    );
  });

  React.useEffect(() => {
    EventService.track({
      event_type: "user",
      event_name: "hei_list_start",
      user_type: "hei",
      event_data: {
        listedStudents: currentStudentsList,
      },
    });
  }, []);

  return (
    <>
      <Table className={`studentTable ${isDashboard ? "dashboard" : ""} `}>
        <HeaderRow sortingHandler={sortingHandler} />
        <tbody className="border-0">{RenderedRows}</tbody>
      </Table>
    </>
  );
}

export default StudentTable;

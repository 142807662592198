// Packages
import { useNavigate } from "react-router-dom";

// Assets
import { ReactComponent as PersonCompass } from "../../../../assets/reskin/icons/person-compass.svg";

// Components
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import ReskinIntroPage from "../../CommonPages/ReskinIntroPage";

const PairsIntroPage = () => {
  const navigate = useNavigate();
  const goToHelpfulTips = () => navigate("/assessment/helpful-tips");

  const PersonWithRingsIcon = <PersonCompass />;

  const StartButton = (
    <ReskinButton text="Start" size="small" onClick={() => goToHelpfulTips()} />
  );

  const HeaderTextContent = (
    <>
      <h2>Employability</h2>
      <h2>Skills</h2>
    </>
  );

  const BodyTextContent = (
    <>
      <p>
        In this activity you will see question screens with{" "}
        <strong>two statements</strong> on them. Read each statement and select
        the statement that is <strong>most like you</strong>.{" "}
      </p>
    </>
  );

  const RenderedControls = <div className="controls-box">{StartButton}</div>;

  return (
    <>
      <AssessmentHeader isTransparent={true} />
      <ReskinIntroPage
        HeaderIcon={PersonWithRingsIcon}
        headerText={HeaderTextContent}
        bodyText={BodyTextContent}
        Controls={RenderedControls}
      />
    </>
  );
};

export default PairsIntroPage;

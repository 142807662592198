import { Modal } from "react-bootstrap";

// Assets
import { ReactComponent as ChatBubble } from "../../../../assets/icons/chat-bubble.svg";

interface DisclaimerModalProps {
  shown: boolean;
  handleClose: Function;
}

const DisclaimerModal = ({ shown, handleClose }: DisclaimerModalProps) => {
  return (
    <Modal
      show={shown}
      onHide={() => handleClose()}
      size="lg"
      className="disclaimer-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="unselectable">
          <span>
            <ChatBubble /> How Your Effective Communication Skill is Scored
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="disclaimer-body-content">
          <p>
            Your overarching Effective Communication score is an aggregate of
            several features that were noted in your recording. These include
            eye contact, speech fluency, and effective pausing as well as
            additional components, such as length of speaking time, pace of
            speech, and use of filler words. These additional features combine
            with the four subskills to form a more holistic Effective
            Communication score.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisclaimerModal;

import "./FilterBadge.scss";
import { ReactComponent as CloseIconBlack } from "../../../../assets/re-skin/closeIconBlack.svg";

interface FilterBadgeProps {
  label: string;
  onClick: Function;
}

const FilterBadge = ({ label, onClick }: FilterBadgeProps) => {
  return (
    <div className="reskin-filter-badge">
      {label}
      <div className="closeIcon" onClick={() => onClick()}>
        <CloseIconBlack />
      </div>
    </div>
  );
};

export default FilterBadge;

// Packages
import { DataStore } from "@aws-amplify/datastore";
import React from "react";

// Components
import Subheader from "../../../components/Subheader";
import { useNavigate } from "react-router-dom";
// Models
import { UserScores } from "../../../models";

function PSQReport() {
  let navigate = useNavigate();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [userscores, setUserscores] = React.useState<any>([]);

  React.useEffect(() => {
    getUserScores();
    async function getUserScores() {
      const items = (await DataStore.query(UserScores)).filter((c) =>
        console.log(c),
      );
      setUserscores(items);
      setLoading(false);
    }
    setLoading(false);
  });

  const handleview = (data: string) => {
    navigate("/report/" + data);
  };

  return (
    <>
      <Subheader text="SkillTrack Report" />
      {isLoading && <span>Loading...</span>}
      {!isLoading && (
        <section className="report bg-gradient-vertical-section">
          <div className="wrapper container pb-1">
            <b>Way to go Jhon</b>
            <p>You're on your way to becoming a great communicator! </p>
          </div>
          <div className="">
            <div className="row">
              <div className="col-6 myBox">
                <div
                  className="inner"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleview("leadership")}
                >
                  <h3>Approaching</h3>
                  <p>Leadership</p>
                  <p>
                    7 traits&nbsp;<b>{">"}</b>
                    <i className="fa fa-arrow-circle-right"></i>
                  </p>
                </div>
              </div>
              <div className="col-6 myBox">
                <div
                  className="inner"
                  onClick={() => handleview("leadership")}
                  style={{ textAlign: "right", cursor: "pointer" }}
                >
                  <h3 style={{ color: "#ee5353" }}>Developing</h3>
                  <p>Perseverance</p>
                  <p>
                    4 traits&nbsp;<b>{">"}</b>
                    <i className="fa fa-arrow-circle-right"></i>
                  </p>
                </div>
              </div>
              <div className="col-6 myBox">
                <div
                  className="inner"
                  onClick={() => handleview("leadership")}
                  style={{ cursor: "pointer" }}
                >
                  <h3 style={{ color: "#3dcb3d" }}>Demonstrating</h3>
                  <p>Communication</p>
                  <p>
                    3 traits&nbsp;<b>{">"}</b>
                    <i className="fa fa-arrow-circle-right"></i>
                  </p>
                </div>
              </div>
              <div
                className="col-6 myBox"
                onClick={() => handleview("leadership")}
                style={{ cursor: "pointer" }}
              >
                <div className="inner " style={{ textAlign: "right" }}>
                  <h3>Approaching</h3>
                  <p>Innovative Thinking</p>
                  <p>
                    4 traits&nbsp;<b>{">"}</b>
                    <i className="fa fa-arrow-circle-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <p className="pb-1">
              Tap each Category to view trait scores and more
            </p>
          </div>
        </section>
      )}
    </>
  );
}

export default PSQReport;

// Packages
import React from "react";
import { Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import EventService from "../../../../services/EventService/event.service";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import { validateEmail } from "../../../../utilities/emailVerification";

export const ForgotPassword = ({ changeAuthState }: any) => {
  const [errors, setErrors] = React.useState<any>({ email: "" });
  const [formData, setFormData] = React.useState<any>({
    email: "",
  });
  const emailInput = React.useRef<any>(null);

  const handleInputChange = (e: any) => {
    let { value, dataset } = e.target;
    const { prop } = dataset;

    setFormData({
      ...formData,
      [prop]: value,
    });

    // reset specific prop user is in
    setErrors({ ...errors, [prop]: "" });
  };

  const checkValidity = () => {
    let errors = 0;
    let errorValues = { email: "" };

    if (!validateEmail(formData.email.trim())) {
      errorValues.email = "Please enter a valid email address.";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }

    return true;
  };

  const handleContinue = async () => {
    if (checkValidity()) {
      // store email for late ruse

      EventService.track({
        event_type: "user",
        event_name: "user_request_password_reset",
        user_type: "student",
        event_data: {
          email: formData.email,
        },
      });
      sessionStorage.setItem("email", formData.email);

      try {
        await Auth.forgotPassword(formData.email).then((data) => {
          console.log(data);
          changeAuthState({ screen: "ResetPassword" });
        });
      } catch (error: unknown) {
        console.log(error);

        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];

        switch (errorHandling) {
          case "LimitExceededException":
            setErrors({ email: "Attempt limit exceeded, try again later." });
            break;

          case "UserNotFoundException":
            setErrors({ email: "User not found, try again." });
            break;

          default:
            setErrors({ email: "An error occured, try again." });
            break;
        }
      }
    }
  };

  const goBack = () => changeAuthState({ screen: "SignIn" });

  React.useEffect(() => {
    if (emailInput && emailInput.current) emailInput.current.focus();
  });

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "User Forgot Password",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "User Forgot Password",
      });
    };
  }, []);

  return (
    <div className="form-container">
      <h1 className="form-title">Reset Password</h1>
      <p className="directions">
        Enter your email associated with your account. A verification code will
        be sent to your email for you to reset your password.
      </p>
      <Form className="form" noValidate>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label className="label">
            <span>
              Email <span className="required">*</span>
            </span>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            data-prop={"email"}
            onChange={handleInputChange}
            required
            isInvalid={errors.email !== ""}
            autoComplete="off"
            ref={emailInput}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="buttons">
          <ReskinButton
            variant="primary"
            customClasses="left-btn"
            onClick={handleContinue}
            type="button"
            text="Send"
          />
          <ReskinButton
            variant="secondary"
            onClick={goBack}
            type="button"
            text="Cancel"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

// Packages
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";
import { useNavigate } from "react-router-dom";

// Components
import TwoButtonModal from "../../../components/Modals/TwoButtonModal/TwoButtonModal";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";

// Actions
import { updateUserAssessmentState } from "../../../../redux/actions";

// Store
import { RootState } from "../../../../redux/reducers";

interface SkipModalProps {
  show: boolean;
  handleClose: Function;
}

const SkipModal = ({ show, handleClose }: SkipModalProps) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const {
    userId,
    assessmentState,
    assessmentState: { UserAssessmentStateId, sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);

  const navigateToAssessment = async () => {
    const requestDetails = {
      userId: userId,
      userSessionId: sessionId,
      assessmentId: assessmentId,
      eventName: "video",
      eventStatus: "Skipped",
    };

    await API.graphql(
      graphqlOperation(mutations.createEventTracking, {
        input: requestDetails,
      }),
    );

    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: "VIDEO_SKIPPED",
      }),
    );
  };

  const goToMainApp = async () => {
    sessionStorage.setItem("rerecordingAttempt", "false");
    await dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: "ASSESSMENT_COMPLETE",
      }),
    );
    navigate("/report/summary");
  };

  const handleSkip = () => {
    if (assessmentState.assessmentStatus === "VIDEO_INPROGRESS") {
      goToMainApp();
    } else {
      navigateToAssessment();
    }
  };

  const LeftButton = (
    <ReskinButton
      text="No, I'll Record"
      size="small"
      variant="secondary"
      customClasses="allow-button"
      onClick={() => handleClose()}
    />
  );

  const RightButton = (
    <ReskinButton
      text="Yes, I'll Skip"
      size="small"
      customClasses="allow-button"
      onClick={() => handleSkip()}
    />
  );

  const BodyContent = (
    <>
      <p className="skip-modal-prompt mb-4">
        You may choose to skip the video recording. Skipping the recording will
        prevent you from receiving insights about your Communication Skills.
      </p>

      <p>Would you still like to skip the recording?</p>
    </>
  );

  return (
    <TwoButtonModal
      bodyContent={BodyContent}
      LeftButton={LeftButton}
      RightButton={RightButton}
      show={show}
      onClose={handleClose}
    />
  );
};

export default SkipModal;

// Packages
import React from "react";

// Routing
import { Route, Routes, Navigate } from "react-router-dom";

// Components
// import Intro from "./intro";
import Questions from "./questions";
import Record from "./record";
import Submitted from "./submitted";

// Reskin Components
import RecordingIntroPage from "../../../reskin/pages/EffectiveCommunication/RecordingIntroPage/RecordingIntroPage";
import RecordingExplanationPage from "../../../reskin/pages/EffectiveCommunication/RecordingExplanationPage/RecordingExplanationPage";
import RecordingQuestions from "../../../reskin/pages/EffectiveCommunication/RecordingQuestions/RecordingQuestions";
import VideoUpload from "../../../reskin/pages/EffectiveCommunication/VideoUpload/VideoUpload";
import RecordingCongratulations from "../../../reskin/pages/EffectiveCommunication/RecordingCongratulations/RecordingCongratulations";

// Models
import { UserResponses } from "../../../models";

type CommunicationType = {
  userResponses?: UserResponses;
};

function Communication({ userResponses }: CommunicationType) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to="/assessment/communication/intro" />}
        />
        <Route path="intro" element={<RecordingIntroPage />} />
        <Route path="explanation" element={<RecordingExplanationPage />} />
        <Route path="questions" element={<RecordingQuestions />} />
        <Route
          path="record"
          element={<VideoUpload userResponses={userResponses} />}
        />
        <Route path="submitted" element={<RecordingCongratulations />} />
      </Routes>
    </>
  );
}

export default Communication;

// Packages
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import createOrUpdate, {
  getOrList,
} from "../../../../components/dynamicqueries";
import { createUser } from "../../../../graphql/mutations";
import { listInstitutions } from "../../../../graphql/queries";
import { Usertype } from "../../../../models";
import EventService from "../../../../services/EventService/event.service";
import UserService from "../../../../services/User/user.service";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import { TermsAndConditions } from "../TermsAndConditions/TermsAndConditions";
import { PasswordInput } from "../../components";
import { validateEmail } from "../../../../utilities/emailVerification";

export const SignUp = ({ changeAuthState, userType }: any) => {
  const [validated] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    institution: "",
    enrollmentYear: "",
    enrollmentSemester: "",
    roleTitle: "",
    terms: false,
  });
  const [errors, setErrors] = useState<any>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    institution: "",
    enrollmentYear: "",
    enrollmentSemester: "",
    roleTitle: "",
    terms: "",
  });
  const [displayTerms, toggleTerms] = useState<boolean>(false);
  const [Institutions, setInsitutions] = useState<any[]>([]);
  const [selectedInsitution, setselectedInsitution] = useState<string>("");

  useEffect(() => {
    if (selectedInsitution && selectedInsitution.length > 1) {
      setErrors({ ...errors, institution: "" });
    }
  }, [selectedInsitution]);

  useEffect(() => {
    EventService.page({
      type: "enter",
      page: "User Create Account",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "User Create Account",
      });
    };
  }, []);

  const handleInputChange = (e: any) => {
    let { value, dataset, checked } = e.target;
    const { prop } = dataset;

    if (prop === "terms") {
      value = checked;
    }

    setFormData({
      ...formData,
      [prop]: value,
    });

    // // reset specific prop user is in
    setErrors({ ...errors, [prop]: "" });
  };

  const onSelectedInstitution = (result: string) => {
    setErrors({ ...errors, institution: "" });
    setselectedInsitution(result === "Select an institution" ? "" : result);
  };

  const checkValidity = async () => {
    let errors = 0;

    let errorValues = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      institution: "",
      enrollmentYear: "",
      enrollmentSemester: "",
      roleTitle: "",
      terms: "",
    };

    if (!validateEmail(formData.email.trim())) {
      errorValues.email = "Please enter a valid email address.";
      errors++;
    }

    const isValidUser = await UserService.isValidInstitutionUser(
      formData.email.trim(),
      selectedInsitution,
    );

    // check against InsitutionUser table
    if (
      formData.email.trim() !== "" &&
      userType === Usertype.INSTITUTION &&
      !isValidUser
    ) {
      errorValues.email = `Uh oh! We don’t have a record of your institution for the current study. Please reach out to <a href="mailto:skilltracksupport@ets.org">SkillTrack Help</a> for assistance.`;
      errors++;
    }

    if (formData.password.trim() === "") {
      errorValues.password = "Password cannot be empty.";
      errors++;
    }

    if (formData.firstName.trim() === "") {
      errorValues.firstName = "Enter your first name.";
      errors++;
    }

    if (formData.lastName.trim() === "") {
      errorValues.lastName = "Enter your last name.";
      errors++;
    }

    if (selectedInsitution === "" || selectedInsitution.length <= 1) {
      errorValues.institution = "Please enter your institution.";
      errors++;
    }

    if (formData.terms !== true) {
      errorValues.terms = "You must agree to the terms and conditions";
      errors++;
    }

    if (
      userType === Usertype.STUDENT &&
      formData.enrollmentYear.trim() === ""
    ) {
      errorValues.enrollmentYear = "Select year of enrollment.";
      errors++;
    }

    if (
      userType === Usertype.STUDENT &&
      formData.enrollmentSemester.trim() === ""
    ) {
      errorValues.enrollmentSemester = "Select semester of enrollment.";
      errors++;
    }

    if (userType === Usertype.INSTITUTION && formData.roleTitle.trim() === "") {
      errorValues.roleTitle = "Specify your role or title.";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }

    return true;
  };

  const handleSignUp = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setErrors({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      institution: "",
      enrollmentYear: "",
      enrollmentSemester: "",
      roleTitle: "",
      terms: "",
    });

    const isValid = await checkValidity();

    if (isValid === true) {
      const customAttribute = "custom:userType";
      try {
        const userData = await Auth.signUp({
          username: formData.email,
          password: formData.password,
          attributes: {
            email: formData.email,
            [customAttribute]: userType,
          },
        });

        // need to get cognito id
        const { userSub: userId } = userData;

        // insert into dynamo
        const input = {
          userId,
          name: `${formData.firstName} ${formData.lastName}`,
          enrollmentYear: Number(formData.enrollmentYear),
          enrollmentSemester: formData.enrollmentSemester,
          email: formData.email,
          institutionId: selectedInsitution,
          userType: userType,
        };

        const dstest = await createOrUpdate(createUser, input);

        // store locally for confirmation
        sessionStorage.setItem("email", formData.email);
        sessionStorage.setItem("userId", userId);
        // track event
        EventService.track({
          event_type: "user",
          event_name: "user_register",
          user_type:
            userType === Usertype.INSTITUTION ? "institution" : "student",
          event_data: {
            userId: userId,
            success: true,
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            institution: selectedInsitution,
            enrollment_year: Number(formData.enrollmentYear),
            enrollment_semester: formData.enrollmentSemester,
            role_title: formData.roleTitle,
          },
        });

        // change to verify account state
        changeAuthState({ screen: "Verify" });
      } catch (error: unknown) {
        console.log(error);

        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];

        // track event
        EventService.track({
          event_type: "user",
          event_name: "user_register",
          user_type:
            userType === Usertype.INSTITUTION ? "institution" : "student",
          event_data: {
            success: false,
            failure_reason: customError.toString(),
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            institution: selectedInsitution,
            enrollment_year: Number(formData.enrollmentYear),
            enrollment_semester: formData.enrollmentSemester,
          },
        });

        switch (errorHandling) {
          case "InvalidParameterException":
            setErrors({ ...errors, email: "Enter a valid email address." });
            break;

          case "InvalidPasswordException":
            setErrors({ ...errors, password: "Invalid password format." });
            break;

          case "UsernameExistsException":
            setErrors({
              ...errors,
              email: "An account already exists for this email.",
            });
            break;

          default:
            console.log(customError);
            setErrors({ email: "An error occured, try again.", password: "" });
            break;
        }
      }
    }
  };

  const generateEnrollmentYears = () => {
    const currentYear = new Date().getFullYear();
    let years: number[] = [];
    for (let i = currentYear - 2; i !== currentYear + 2; i++) {
      years.push(i);
    }
    return years;
  };

  // query for Institutions
  if (Institutions.length === 0) {
    getOrList(listInstitutions, "listInstitutions", {
      SortDirection: "ASC",
    }).then((result) => {
      let suggestions = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        if (element.id !== "lumina") suggestions.push(element.name);
      }

      setInsitutions(suggestions.sort());
    });
  }

  const semesters = ["Spring", "Summer", "Fall", "Winter"];

  return (
    <div className="form-container">
      {!displayTerms && (
        <>
          <h1 className="form-title">Sign Up</h1>
          <Form
            className="form"
            noValidate
            validated={validated}
            onSubmit={(e: any) => handleSignUp(e)}
          >
            <Form.Group className="mb-4" controlId="formEmail">
              <Form.Label className="label">
                <span>
                  Email <span className="required">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                data-prop={"email"}
                onChange={handleInputChange}
                required
                value={formData.email}
                isInvalid={errors.email !== ""}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                <span dangerouslySetInnerHTML={{ __html: errors.email }} />
              </Form.Control.Feedback>
            </Form.Group>

            <PasswordInput
              handleInputChange={handleInputChange}
              error={errors.password}
            />

            <Form.Group className="mb-4" controlId="formFirstName">
              <Form.Label className="label">
                <span>
                  First Name <span className="required">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                data-prop={"firstName"}
                onChange={handleInputChange}
                required
                isInvalid={errors.firstName !== ""}
                value={formData.firstName}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formLastName">
              <Form.Label className="label">
                <span>
                  Last Name <span className="required">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                data-prop={"lastName"}
                onChange={handleInputChange}
                required
                value={formData.lastName}
                isInvalid={errors.lastName !== ""}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formInstitution">
              <Form.Label className="label">
                <span>
                  {" "}
                  {userType === Usertype.STUDENT
                    ? "Institution that Requests SkillTrack"
                    : "Your Institution"}{" "}
                  <span className="required">*</span>
                </span>
              </Form.Label>
              <Form.Select
                className="form-control"
                aria-label="Select an institution"
                isInvalid={errors.institution !== ""}
                onChange={(e) => onSelectedInstitution(e.target.value)}
              >
                <option>Select an institution</option>
                {Institutions.map((institution: string, index: number) => {
                  return (
                    <option value={institution} key={index}>
                      {institution}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.institution}
              </Form.Control.Feedback>
            </Form.Group>

            {userType === Usertype.STUDENT && (
              <>
                <Form.Group className="mb-4" controlId="formEnrollmentYear">
                  <Form.Label className="label">
                    <span>
                      Year of Enrollment <span className="required">*</span>
                    </span>
                  </Form.Label>
                  {errors.enrollmentYear !== "" && (
                    <div className="invalid-feedback d-block">
                      {errors.enrollmentYear}
                    </div>
                  )}
                  <div className="radios">
                    {generateEnrollmentYears().map((year, index: number) => {
                      return (
                        <Form.Check type="radio" key={index}>
                          <Form.Check.Input
                            id={`year-${year}`}
                            type="radio"
                            name="enrollmentYear"
                            data-prop={"enrollmentYear"}
                            value={year}
                            checked={formData.enrollmentYear == year}
                            isValid
                            onChange={handleInputChange}
                          />
                          <Form.Check.Label htmlFor={`year-${year}`}>
                            {year}
                          </Form.Check.Label>
                        </Form.Check>
                      );
                    })}
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formEnrollmentSemester">
                  <Form.Label className="label">
                    <span>
                      Semester of Enrollment <span className="required">*</span>
                    </span>
                  </Form.Label>
                  {errors.enrollmentSemester !== "" && (
                    <div className="invalid-feedback d-block">
                      {errors.enrollmentSemester}
                    </div>
                  )}
                  <div className="radios">
                    {semesters.map((semester, index: number) => {
                      return (
                        <Form.Check type="radio" key={index}>
                          <Form.Check.Input
                            id={`semester-${semester}`}
                            type="radio"
                            name="enrollmentSemester"
                            data-prop={"enrollmentSemester"}
                            value={semester}
                            checked={formData.enrollmentSemester == semester}
                            isValid
                            onChange={handleInputChange}
                          />
                          <Form.Check.Label htmlFor={`semester-${semester}`}>
                            {semester}
                          </Form.Check.Label>
                        </Form.Check>
                      );
                    })}
                  </div>
                </Form.Group>
              </>
            )}

            {userType === Usertype.INSTITUTION && (
              <>
                <Form.Group className="mb-4" controlId="formRoleTitle">
                  <Form.Label className="label">
                    <span>
                      Your Title/Role <span className="required">*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    data-prop={"roleTitle"}
                    onChange={handleInputChange}
                    value={formData.roleTitle}
                    required
                    isInvalid={errors.roleTitle !== ""}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.roleTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-4" controlId="formTerms">
              <Form.Check type="checkbox">
                <Form.Check.Input
                  id="terms"
                  type="checkbox"
                  name="terms"
                  tabIndex={0}
                  data-prop={"terms"}
                  role="checkbox"
                  onChange={handleInputChange}
                  checked={formData.terms}
                  isInvalid={formData.terms !== true && errors.terms !== ""}
                />
                <Form.Check.Label className="label" htmlFor="terms">
                  I agree to the
                  <Button
                    variant="link"
                    onClick={(e: any) => {
                      e.preventDefault();
                      toggleTerms(!displayTerms);
                    }}
                  >
                    Terms and Conditions
                  </Button>
                </Form.Check.Label>
              </Form.Check>

              {formData.terms !== true && errors.terms !== "" && (
                <div className="invalid-feedback d-block">{errors.terms}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-4" controlId="formSubmit">
              <ReskinButton
                text="Next"
                onClick={handleSignUp}
                variant="primary"
              />
            </Form.Group>

            <Form.Group className="mt-3" controlId="formCreate">
              <Form.Label className="label">
                Already have an account?
                <Button
                  variant="link"
                  onClick={(e: any) => {
                    e.preventDefault();
                    changeAuthState({ screen: "SignIn" });
                  }}
                >
                  Sign In
                </Button>
              </Form.Label>
            </Form.Group>
          </Form>
        </>
      )}
      {displayTerms && <TermsAndConditions back={() => toggleTerms(false)} />}
    </div>
  );
};

// Packages
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ManWithFlag } from "../../../../assets/reskin/icons/leadership-reskin.svg";
import { ReactComponent as ChatBubble } from "../../../../assets/reskin/icons/effective-communication-reskin.svg";
import { ReactComponent as SmallMountain } from "../../../../assets/reskin/icons/perseverance-reskin.svg";
import { ReactComponent as LightBulb } from "../../../../assets/reskin/icons/innovative-thinking-reskin.svg";
// Components
import ReskinMenu from "../ReskinMenu/ReskinMenu";

// Styles
import "./MainAppMenu.scss";

const MainAppMenu = ({ hideMenu }: any) => {
  const navigate = useNavigate();

  const mainAppMenuItems = [
    {
      Icon: <></>,
      onClick: () => navigate("/report/summary"),
      label: "Insights Report",
      textColor: "#3B8397",
      isFirstItem: true,
    },
    {
      Icon: <></>,
      onClick: () => {
        hideMenu();
        navigate("/report/support");
      },
      label: "Resource Hub",
      textColor: "#3B8397",
    },
    {
      Icon: <></>,
      onClick: () => {},
      label: "Trait Scores",
      textColor: "#3B8397",
      subMenuItems: [
        {
          onClick: () => {
            hideMenu();
            navigate("/report/trait/leadership");
          },
          label: "Leadership",
          textColor: "#30396C",
          Icon: <ManWithFlag />,
        },
        {
          onClick: () => {
            hideMenu();
            navigate("/report/trait/effective communication");
          },
          label: "Effective Communication",
          textColor: "#5D8137",
          Icon: <ChatBubble />,
        },
        {
          onClick: () => {
            hideMenu();
            navigate("/report/trait/perseverance");
          },
          label: "Perseverance",
          textColor: "#DD614A",
          Icon: <SmallMountain />,
        },
        {
          onClick: () => {
            hideMenu();
            navigate("/report/trait/innovative thinking");
          },
          label: "Innovative Thinking",
          textColor: "#8865A4",
          Icon: <LightBulb />,
        },
      ],
    },
    {
      Icon: <></>,
      onClick: () => {
        hideMenu();
        navigate("/profile/my-favorites");
      },
      label: "Favorites",
      textColor: "#3B8397",
    },
    {
      Icon: <></>,
      onClick: () => {
        hideMenu();
        navigate("/report/reflections");
      },
      label: "Reflections",
      textColor: "#3B8397",
    },
  ];

  return (
    <>
      <ReskinMenu menuItems={mainAppMenuItems} />
    </>
  );
};

export default MainAppMenu;

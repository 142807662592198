// Components
import { Button } from "react-bootstrap";

// Styles
import "./NoBorderIconButton.scss";

// Assets
import { ReactComponent as GreenCaretRight } from "../../../../assets/reskin/icons/green-caret-right.svg";

interface NoBorderIconButtonProps {
  icon?: JSX.Element;
  onClick: Function;
  customClasses?: string;
}

const NoBorderIconButton = ({
  icon,
  onClick,
  customClasses,
}: NoBorderIconButtonProps) => {
  const DefaultIcon = <GreenCaretRight />;

  return (
    <Button
      className={`no-border-icon-button ${customClasses}`}
      onClick={() => onClick()}
    >
      {icon || DefaultIcon}
    </Button>
  );
};

export default NoBorderIconButton;

// Packages
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import Subheader from "../../../../components/Subheader";
import MessageTab from "./MessageTab";

// Services
import EventService from "../../../../services/EventService/event.service";

// Utils
import { ucWords } from "../../../../utilities";

interface SupportInboxProps {
  messages?: any;
  sessionId: any;
}

const SupportInbox = ({ messages }: SupportInboxProps) => {
  const navigate = useNavigate();
  const { trait = "Leadership" } = useParams();
  const [responses, setResponses] = React.useState<any>([]);

  React.useEffect(() => {
    return () => {
      EventService.track({
        event_type: "user",
        event_name: "support_message_list_end",
        user_type: "student",
        event_data: {
          category: trait,
        },
      });
    };
  }, []);

  React.useEffect(() => {
    const filteredResponses = messages.filter((supportResponse: any) => {
      return supportResponse.traitName.toLowerCase() === trait;
    });


    EventService.track({
      event_type: "user",
      event_name: "support_message_list_start",
      user_type: "student",
      event_data: {
        category: trait,
        messages: filteredResponses.length,
        new_message: filteredResponses.filter((response: any) => {
          return response.responseRead !== true;
        }).length,
      },
    });

    setResponses(filteredResponses);
  }, [messages]);
  const updatefilteredResponses = (index: any) => {
    const responseData = responses;
    responseData[index].responseRead = true;
    setResponses(responseData);
  };

  const RenderedMessages = responses.map((message: any, index: any) => {
    return (
      <MessageTab
        index={index}
        message={message}
        updatefilteredResponses={updatefilteredResponses}
      />
    );
  });

  const handleBack = () => {
    navigate(`/report/support/${trait.toLowerCase()}`);
  };

  return (
    <div className="support-inbox bg-gradient-vertical">
      <Subheader
        text={`Additional Support - ${ucWords(trait)}`}
        back={() => handleBack()}
      />
      <div className="inbox-prompt d-flex align-items-center justify-content-center">
        <div className="prompt-text-container">
          Here's what your student success team has to say!
        </div>
      </div>
      <div className="messages-container">{RenderedMessages}</div>
    </div>
  );
};

export default SupportInbox;

// Packages
import React from "react";
import { useIdleTimer } from "react-idle-timer";

// Components
import ReskinButton from "../../Buttons/ReskinButton/ReskinButton";
import TwoButtonModal from "../TwoButtonModal/TwoButtonModal";

// Redux
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../redux/actions";

interface SessionTimeoutModalProptype {
  handleClose: Function;
  show: boolean;
}

const SessionTimeoutModal = ({
  handleClose,
  show,
}: SessionTimeoutModalProptype) => {
  const [remainingTime, setRemainingTime] = React.useState<number>(0);

  const dispatch = useDispatch();
  const signOut = () => dispatch(logoutUser());

  const { getRemainingTime } = useIdleTimer({
    onIdle: signOut,
    timeout: 60000,
    startOnMount: true,
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const ModalBody = (
    <>
      <p>{`You have been inactive for a while. For your security, we will sign you out in ${remainingTime} seconds`}</p>
    </>
  );
  const SignOutButton = (
    <ReskinButton
      variant="secondary"
      size="small"
      onClick={signOut}
      text="Sign Out"
    />
  );
  const StayButton = (
    <ReskinButton
      variant="primary"
      size="small"
      onClick={handleClose}
      text="Stay Signed In"
    />
  );

  return (
    <TwoButtonModal
      onClose={handleClose}
      show={show}
      headerText="Session Time Out"
      bodyContent={ModalBody}
      LeftButton={SignOutButton}
      RightButton={StayButton}
    />
  );
};

export default SessionTimeoutModal;

export const schema = {
  models: {
    UserResponses: {
      name: "UserResponses",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        tripletResponse: {
          name: "tripletResponse",
          isArray: true,
          type: {
            nonModel: "TripletResponses",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        tripletScorableResponse: {
          name: "tripletScorableResponse",
          isArray: false,
          type: {
            nonModel: "ScorableResponses",
          },
          isRequired: false,
          attributes: [],
        },
        pairsScorableResponse: {
          name: "pairsScorableResponse",
          isArray: false,
          type: {
            nonModel: "PairsScorableResponses",
          },
          isRequired: false,
          attributes: [],
        },
        selectedVideoQuestionId: {
          name: "selectedVideoQuestionId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        videoResponseLocation: {
          name: "videoResponseLocation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        s3VideoResponseLocation: {
          name: "s3VideoResponseLocation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userAssessmentState: {
          name: "userAssessmentState",
          isArray: false,
          type: {
            model: "UserAssessmentState",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["userResponsesUserAssessmentStateId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        userResponsesUserAssessmentStateId: {
          name: "userResponsesUserAssessmentStateId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UserResponses",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "UserResponsesByUserSession",
            fields: ["userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "UserResponsesByByUserAndSession",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "UserResponsesByvideoResponseLocation",
            fields: ["videoResponseLocation"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["PSQ_ADMIN", "INSTITUTION_ADMIN"],
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    BIQ: {
      name: "BIQ",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        sequence: {
          name: "sequence",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        content: {
          name: "content",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        profileAttribute: {
          name: "profileAttribute",
          isArray: false,
          type: {
            enum: "Profileattributetype",
          },
          isRequired: true,
          attributes: [],
        },
        displayType: {
          name: "displayType",
          isArray: false,
          type: {
            enum: "Displaytype",
          },
          isRequired: false,
          attributes: [],
        },
        options: {
          name: "options",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "BIQS",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    User: {
      name: "User",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        enrollmentStatus: {
          name: "enrollmentStatus",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        programYear: {
          name: "programYear",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        fieldOfStudy: {
          name: "fieldOfStudy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        transferToFourYear: {
          name: "transferToFourYear",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ageGroup: {
          name: "ageGroup",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        gender: {
          name: "gender",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        goal: {
          name: "goal",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        emplStatus: {
          name: "emplStatus",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ethnicity: {
          name: "ethnicity",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        institutionId: {
          name: "institutionId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        institution: {
          name: "institution",
          isArray: false,
          type: {
            model: "Institution",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["institutionId"],
          },
        },
        enrollmentYear: {
          name: "enrollmentYear",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        enrollmentSemester: {
          name: "enrollmentSemester",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userRole: {
          name: "userRole",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userType: {
          name: "userType",
          isArray: false,
          type: {
            enum: "Usertype",
          },
          isRequired: false,
          attributes: [],
        },
        lastLogin: {
          name: "lastLogin",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assessmentStates: {
          name: "assessmentStates",
          isArray: true,
          type: {
            model: "UserAssessmentState",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["user"],
          },
        },
        institutionResponses: {
          name: "institutionResponses",
          isArray: true,
          type: {
            model: "InstitutionResponse",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["institutionUser"],
          },
        },
        candidateComments: {
          name: "candidateComments",
          isArray: true,
          type: {
            model: "CandidateComments",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["user"],
          },
        },
        userConfidenceLevel: {
          name: "userConfidenceLevel",
          isArray: true,
          type: {
            model: "UserConfidenceLevel",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["userId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Users",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["userId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "usersByInstitutionAndType",
            fields: ["institutionId", "userType", "userId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["PSQ_ADMIN", "INSTITUTION_ADMIN"],
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    Item: {
      name: "Item",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        itemGuid: {
          name: "itemGuid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        accnum: {
          name: "accnum",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        version: {
          name: "version",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "Itemtype",
          },
          isRequired: true,
          attributes: [],
        },
        content: {
          name: "content",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        dimension: {
          name: "dimension",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        dimensionDesc: {
          name: "dimensionDesc",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        alphaStatValue: {
          name: "alphaStatValue",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        dltaStatValue: {
          name: "dltaStatValue",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        tauStatValue: {
          name: "tauStatValue",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        sclStatValue: {
          name: "sclStatValue",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        sections: {
          name: "sections",
          isArray: true,
          type: {
            model: "SectionItem",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["itemSectionsId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Items",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Section: {
      name: "Section",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        items: {
          name: "items",
          isArray: true,
          type: {
            model: "SectionItem",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["sectionItemsId"],
          },
        },
        assessments: {
          name: "assessments",
          isArray: true,
          type: {
            model: "AssessmentSection",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["sectionAssessmentsId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Sections",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    SectionItem: {
      name: "SectionItem",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        sectionItemItemId: {
          name: "sectionItemItemId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        sectionItemSectionId: {
          name: "sectionItemSectionId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        section: {
          name: "section",
          isArray: false,
          type: {
            model: "Section",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["sectionItemSectionId"],
          },
        },
        item: {
          name: "item",
          isArray: false,
          type: {
            model: "Item",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["sectionItemItemId"],
          },
        },
        itemSequence: {
          name: "itemSequence",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        itemSectionsId: {
          name: "itemSectionsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        sectionItemsId: {
          name: "sectionItemsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "SectionItems",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "itemsBySection",
            fields: ["sectionItemSectionId", "itemSequence"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Assessment: {
      name: "Assessment",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        sections: {
          name: "sections",
          isArray: true,
          type: {
            model: "AssessmentSection",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["assessment"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Assessments",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AssessmentSection: {
      name: "AssessmentSection",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        assessmentSectionAssessmentId: {
          name: "assessmentSectionAssessmentId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        assessmentSectionSectionId: {
          name: "assessmentSectionSectionId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        assessment: {
          name: "assessment",
          isArray: false,
          type: {
            model: "Assessment",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["assessmentSectionAssessmentId"],
          },
        },
        section: {
          name: "section",
          isArray: false,
          type: {
            model: "Section",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["assessmentSectionSectionId"],
          },
        },
        sectionSequence: {
          name: "sectionSequence",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        sectionAssessmentsId: {
          name: "sectionAssessmentsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "AssessmentSections",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "sectionsByAssessment",
            fields: ["assessmentSectionAssessmentId", "sectionSequence"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Institution: {
      name: "Institution",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        desc: {
          name: "desc",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        users: {
          name: "users",
          isArray: true,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["institution"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Institutions",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserAssessmentState: {
      name: "UserAssessmentState",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentStatus: {
          name: "assessmentStatus",
          isArray: false,
          type: {
            enum: "AssessmentStatusType",
          },
          isRequired: false,
          attributes: [],
        },
        assessmentStartDate: {
          name: "assessmentStartDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        assessmentEndDate: {
          name: "assessmentEndDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        userAssessmentStateUserId: {
          name: "userAssessmentStateUserId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["userAssessmentStateUserId"],
          },
        },
        userScores: {
          name: "userScores",
          isArray: false,
          type: {
            model: "UserScores",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: ["id"],
            targetNames: ["userAssessmentStateUserScoresId"],
          },
        },
        userResponses: {
          name: "userResponses",
          isArray: false,
          type: {
            model: "UserResponses",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: ["id"],
            targetNames: ["userAssessmentStateUserResponsesId"],
          },
        },
        heiComments: {
          name: "heiComments",
          isArray: true,
          type: {
            model: "HeiComments",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["userAssessmentStateHeiCommentsId"],
          },
        },
        quickTips: {
          name: "quickTips",
          isArray: false,
          type: {
            model: "QuickTips",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: ["id"],
            targetNames: ["userAssessmentStateQuickTipsId"],
          },
        },
        comments: {
          name: "comments",
          isArray: false,
          type: {
            model: "CandidateComments",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: ["id"],
            targetNames: ["userAssessmentStateCommentsId"],
          },
        },
        studentRequests: {
          name: "studentRequests",
          isArray: true,
          type: {
            model: "StudentRequest",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["studentUserSession"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        userAssessmentStateUserScoresId: {
          name: "userAssessmentStateUserScoresId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        userAssessmentStateUserResponsesId: {
          name: "userAssessmentStateUserResponsesId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        userAssessmentStateQuickTipsId: {
          name: "userAssessmentStateQuickTipsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        userAssessmentStateCommentsId: {
          name: "userAssessmentStateCommentsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UserAssessmentStates",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "ByUserSession",
            fields: ["userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "AssessmentStatesByUser",
            fields: ["userId", "assessmentStartDate"],
          },
        },
        {
          type: "key",
          properties: {
            name: "userSessionsByUserId",
            fields: ["userAssessmentStateUserId", "userSessionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["PSQ_ADMIN", "INSTITUTION_ADMIN"],
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    UserScores: {
      name: "UserScores",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        rawTripletScores: {
          name: "rawTripletScores",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        rawVideoScores: {
          name: "rawVideoScores",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        f01: {
          name: "f01",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f02: {
          name: "f02",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f03: {
          name: "f03",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f04: {
          name: "f04",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f05: {
          name: "f05",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f06: {
          name: "f06",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f07: {
          name: "f07",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f08: {
          name: "f08",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f09: {
          name: "f09",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f10: {
          name: "f10",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f11: {
          name: "f11",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f12: {
          name: "f12",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f13: {
          name: "f13",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f14: {
          name: "f14",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        f15: {
          name: "f15",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        eyeContact: {
          name: "eyeContact",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        headBodyPosture: {
          name: "headBodyPosture",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        speechFluency: {
          name: "speechFluency",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        effectivePauses: {
          name: "effectivePauses",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        pacePitchVolume: {
          name: "pacePitchVolume",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        leadershipScoreBand: {
          name: "leadershipScoreBand",
          isArray: false,
          type: {
            enum: "ScoreBand",
          },
          isRequired: false,
          attributes: [],
        },
        perseveranceScoreBand: {
          name: "perseveranceScoreBand",
          isArray: false,
          type: {
            enum: "ScoreBand",
          },
          isRequired: false,
          attributes: [],
        },
        communicationScoreBand: {
          name: "communicationScoreBand",
          isArray: false,
          type: {
            enum: "ScoreBand",
          },
          isRequired: false,
          attributes: [],
        },
        innovativeThinkingScoreBand: {
          name: "innovativeThinkingScoreBand",
          isArray: false,
          type: {
            enum: "ScoreBand",
          },
          isRequired: false,
          attributes: [],
        },
        leadershipScore: {
          name: "leadershipScore",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        perseveranceScore: {
          name: "perseveranceScore",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        communicationScore: {
          name: "communicationScore",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        innovativeThinkingScore: {
          name: "innovativeThinkingScore",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        overallScore: {
          name: "overallScore",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        dimensionNarratives: {
          name: "dimensionNarratives",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        institution: {
          name: "institution",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userAssessmentState: {
          name: "userAssessmentState",
          isArray: false,
          type: {
            model: "UserAssessmentState",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["userScoresUserAssessmentStateId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        userScoresUserAssessmentStateId: {
          name: "userScoresUserAssessmentStateId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UserScores",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "UserScoresByUserSession",
            fields: ["userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "UserSessionsByUser",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["PSQ_ADMIN", "INSTITUTION_ADMIN"],
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    Admin: {
      name: "Admin",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        adminId: {
          name: "adminId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        startDate: {
          name: "startDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        endDate: {
          name: "endDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Admins",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["adminId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CutOffScores: {
      name: "CutOffScores",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        adminId: {
          name: "adminId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        dimensionId: {
          name: "dimensionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        cutScore1: {
          name: "cutScore1",
          isArray: false,
          type: "Float",
          isRequired: true,
          attributes: [],
        },
        cutScore2: {
          name: "cutScore2",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        cutScore3: {
          name: "cutScore3",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        cutScore4: {
          name: "cutScore4",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        cutScore5: {
          name: "cutScore5",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        cutScore6: {
          name: "cutScore6",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        cutScore7: {
          name: "cutScore7",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        cutScore8: {
          name: "cutScore8",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CutOffScores",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "CutScoresByAdmin",
            fields: ["adminId", "dimensionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Events: {
      name: "Events",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        eventId: {
          name: "eventId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        eventType: {
          name: "eventType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        eventName: {
          name: "eventName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        eventDate: {
          name: "eventDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        eventData: {
          name: "eventData",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userType: {
          name: "userType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Events",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["eventId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "EventsByUserSession",
            fields: ["userSessionId", "eventDate"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["PSQ_ADMIN"],
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    Narratives: {
      name: "Narratives",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        dimensionId: {
          name: "dimensionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        definition: {
          name: "definition",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        highScoreNarrative: {
          name: "highScoreNarrative",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        mediumScoreNarrative: {
          name: "mediumScoreNarrative",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lowScoreNarrative: {
          name: "lowScoreNarrative",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        improvementNarrative: {
          name: "improvementNarrative",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
        improvementDynamicNarrative: {
          name: "improvementDynamicNarrative",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        developingTips: {
          name: "developingTips",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        demonstratingTips: {
          name: "demonstratingTips",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        approachingTips: {
          name: "approachingTips",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Narratives",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["dimensionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    InstitutionNarratives: {
      name: "InstitutionNarratives",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        highLevelTrait: {
          name: "highLevelTrait",
          isArray: false,
          type: {
            enum: "Highleveltraittype",
          },
          isRequired: true,
          attributes: [],
        },
        demonstratingNarrative: {
          name: "demonstratingNarrative",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        developingNarrative: {
          name: "developingNarrative",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        approachingNarrative: {
          name: "approachingNarrative",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "InstitutionNarratives",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["highLevelTrait"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    EventTracking: {
      name: "EventTracking",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        eventName: {
          name: "eventName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        eventStatus: {
          name: "eventStatus",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        eventData: {
          name: "eventData",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "EventTrackings",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "EventTrackingByUserDetails",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "EventTrackingByUserSesion",
            fields: ["userSessionId", "eventName", "eventStatus"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    QuickTips: {
      name: "QuickTips",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        tips: {
          name: "tips",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "QuickTips",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "QuickTipsByUserDetails",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CandidateComments: {
      name: "CandidateComments",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["userId"],
          },
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        comment: {
          name: "comment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        leadershipComment: {
          name: "leadershipComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        leadershipCommentFromModel: {
          name: "leadershipCommentFromModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        leadershipCommentProfane: {
          name: "leadershipCommentProfane",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        declineCommentOnLeadership: {
          name: "declineCommentOnLeadership",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        communicationComment: {
          name: "communicationComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        communicationCommentFromModel: {
          name: "communicationCommentFromModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        communicationCommentProfane: {
          name: "communicationCommentProfane",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        declineCommentOnCommunication: {
          name: "declineCommentOnCommunication",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        innovativeThinkingComment: {
          name: "innovativeThinkingComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        innovativeThinkingCommentFromModel: {
          name: "innovativeThinkingCommentFromModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        innovativeThinkingCommentProfane: {
          name: "innovativeThinkingCommentProfane",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        declineCommentOnInnovativeThinking: {
          name: "declineCommentOnInnovativeThinking",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        perseveranceComment: {
          name: "perseveranceComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        perseveranceCommentFromModel: {
          name: "perseveranceCommentFromModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        perseveranceCommentProfane: {
          name: "perseveranceCommentProfane",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        declineCommentOnPerserverance: {
          name: "declineCommentOnPerserverance",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CandidateComments",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "CandidateComments",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    HeiComments: {
      name: "HeiComments",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        username: {
          name: "username",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        candidateUserId: {
          name: "candidateUserId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        candidateUserSessionId: {
          name: "candidateUserSessionId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userAssessmentId: {
          name: "userAssessmentId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        userAssessmentState: {
          name: "userAssessmentState",
          isArray: false,
          type: {
            model: "UserAssessmentState",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["userAssessmentId"],
          },
        },
        comment: {
          name: "comment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        userAssessmentStateHeiCommentsId: {
          name: "userAssessmentStateHeiCommentsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "HeiComments",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "HeiComments",
            fields: ["userId", "candidateUserId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "commentsByUserAssessmentAndHEIUser",
            fields: ["userAssessmentId", "userId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    HeiBookmarks: {
      name: "HeiBookmarks",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        candidateUserId: {
          name: "candidateUserId",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "HeiBookmarks",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "BookmarksByHEI",
            fields: ["userId", "assessmentId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    StudentRequest: {
      name: "StudentRequest",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        studentUserSessionId: {
          name: "studentUserSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userAssessmentId: {
          name: "userAssessmentId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        studentUserSession: {
          name: "studentUserSession",
          isArray: false,
          type: {
            model: "UserAssessmentState",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["userAssessmentId"],
          },
        },
        traitName: {
          name: "traitName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        studentQuestion: {
          name: "studentQuestion",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        requestDate: {
          name: "requestDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        institutionId: {
          name: "institutionId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        responseSentToStudent: {
          name: "responseSentToStudent",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        institutionResponses: {
          name: "institutionResponses",
          isArray: true,
          type: {
            model: "InstitutionResponse",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["studentRequest"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "StudentRequests",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "requestsByAssessment",
            fields: ["userAssessmentId", "requestDate"],
          },
        },
      ],
    },
    StudentResource: {
      name: "StudentResource",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        resourceLink: {
          name: "resourceLink",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "StudentResources",
      attributes: [
        {
          type: "model",
          properties: {},
        },
      ],
    },
    InstitutionResponse: {
      name: "InstitutionResponse",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        studentUserId: {
          name: "studentUserId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        studentUserSessionId: {
          name: "studentUserSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        studentRequestId: {
          name: "studentRequestId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        studentRequest: {
          name: "studentRequest",
          isArray: false,
          type: {
            model: "StudentRequest",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["studentRequestId"],
          },
        },
        traitName: {
          name: "traitName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        response: {
          name: "response",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        responseDate: {
          name: "responseDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        institutionUserId: {
          name: "institutionUserId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        institutionUser: {
          name: "institutionUser",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["institutionUserId"],
          },
        },
        responseRead: {
          name: "responseRead",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        resourceInfo: {
          name: "resourceInfo",
          isArray: true,
          type: {
            nonModel: "ResourceInfo",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "InstitutionResponses",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "responsesByStudentRequestId",
            fields: ["studentRequestId", "responseDate"],
          },
        },
        {
          type: "key",
          properties: {
            name: "responsesByInstitutionUserId",
            fields: ["institutionUserId", "responseDate"],
          },
        },
      ],
    },
    InstitutionUser: {
      name: "InstitutionUser",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        emailId: {
          name: "emailId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        institution: {
          name: "institution",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "InstitutionUsers",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["emailId"],
          },
        },
      ],
    },
    LearningResource: {
      name: "LearningResource",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        resourceLink: {
          name: "resourceLink",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imageLocation: {
          name: "imageLocation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        duration: {
          name: "duration",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        credit: {
          name: "credit",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        resourceCards: {
          name: "resourceCards",
          isArray: true,
          type: {
            nonModel: "Card",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        studentFavourites: {
          name: "studentFavourites",
          isArray: true,
          type: {
            model: "StudentFavouriteLearningResource",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["learningResource"],
          },
        },
        studentVisited: {
          name: "studentVisited",
          isArray: true,
          type: {
            model: "StudentVisitedLearningResource",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["learningResource"],
          },
        },
        ResourceReaction: {
          name: "ResourceReaction",
          isArray: true,
          type: {
            model: "ResourceReaction",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["learningResource"],
          },
        },
        studentRecommended: {
          name: "studentRecommended",
          isArray: true,
          type: {
            model: "StudentRecommendedResources",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: ["learningResource"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "LearningResources",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byTraitAndTitle",
            queryField: "learingResourcesByTraitAndTitle",
            fields: ["trait", "title"],
          },
        },
      ],
    },
    StudentRecommendedResources: {
      name: "StudentRecommendedResources",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        sequence: {
          name: "sequence",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        learningResourceId: {
          name: "learningResourceId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        learningResource: {
          name: "learningResource",
          isArray: false,
          type: {
            model: "LearningResource",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["learningResourceId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "StudentRecommendedResources",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "RecommendedByUserIdAndResourceTitle",
            queryField: "recommendedResourcesByUserAndResourceTitle",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "RecommendedResourcesByResourceId",
            queryField: "RecommendedByResourceIdandUserId",
            fields: ["learningResourceId", "userId"],
          },
        },
      ],
    },
    ResourceReaction: {
      name: "ResourceReaction",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        preference: {
          name: "preference",
          isArray: false,
          type: {
            enum: "Preferencetype",
          },
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        learningResourceId: {
          name: "learningResourceId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        learningResource: {
          name: "learningResource",
          isArray: false,
          type: {
            model: "LearningResource",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["learningResourceId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ResourceReactions",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byUserIdAndResource",
            queryField: "resourceReactionsByUser",
            fields: ["userId", "learningResourceId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "ResourceReactionsByResourceId",
            queryField: "ResourceReactionsByIdandUserId",
            fields: ["learningResourceId", "userId"],
          },
        },
      ],
    },
    UserConfidenceLevel: {
      name: "UserConfidenceLevel",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: {
            enum: "Highleveltraittype",
          },
          isRequired: true,
          attributes: [],
        },
        phase: {
          name: "phase",
          isArray: false,
          type: {
            enum: "Phasetype",
          },
          isRequired: true,
          attributes: [],
        },
        confidenceLevel: {
          name: "confidenceLevel",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        selectedDate: {
          name: "selectedDate",
          isArray: false,
          type: "AWSDate",
          isRequired: true,
          attributes: [],
        },
        selectedTime: {
          name: "selectedTime",
          isArray: false,
          type: "AWSTime",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "UserConfidenceLevels",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byUserIdTraitPhaseAndSelectedDate",
            queryField: "confidenceLevelByUserIdTraitPhaseAndSelectionDate",
            fields: [
              "userId",
              "trait",
              "phase",
              "selectedDate",
              "selectedTime",
            ],
          },
        },
      ],
    },
    StudentVisitedLearningResource: {
      name: "StudentVisitedLearningResource",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        learningResourceId: {
          name: "learningResourceId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        learningResource: {
          name: "learningResource",
          isArray: false,
          type: {
            model: "LearningResource",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["learningResourceId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "StudentVisitedLearningResources",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "vistiedByUserIdAndResourceTitle",
            queryField: "visitedResourcesByUserAndResourceTitle",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "VisitedResourcesByResourceId",
            queryField: "VisitedByResourceIdandUserId",
            fields: ["learningResourceId", "userId"],
          },
        },
      ],
    },
    StudentFavouriteLearningResource: {
      name: "StudentFavouriteLearningResource",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        trait: {
          name: "trait",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        preference: {
          name: "preference",
          isArray: false,
          type: {
            enum: "Preferencetype",
          },
          isRequired: true,
          attributes: [],
        },
        learningResourceId: {
          name: "learningResourceId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        learningResource: {
          name: "learningResource",
          isArray: false,
          type: {
            model: "LearningResource",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetNames: ["learningResourceId"],
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "StudentFavouriteLearningResources",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "favouritesByUserIdAndResourceTitle",
            queryField: "favouriteResourcesByUserAndResourceId",
            fields: ["userId", "userSessionId"],
          },
        },
        {
          type: "key",
          properties: {
            name: "FavoritesByResourceId",
            queryField: "FavoritedByResourceIdandUserId",
            fields: ["learningResourceId", "userId"],
          },
        },
      ],
    },
    LearningSelfReflection: {
      name: "LearningSelfReflection",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        takeawayComment: {
          name: "takeawayComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        applicationComment: {
          name: "applicationComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        commentDate: {
          name: "commentDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        institution: {
          name: "institution",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "LearningSelfReflections",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byUserIdAndCommentDate",
            queryField: "selfReflectionsByUserAndCommentDate",
            fields: ["userId", "commentDate"],
          },
        },
      ],
    },
    LearningSelfReflectionCount: {
      name: "LearningSelfReflectionCount",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        userSessionId: {
          name: "userSessionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        assessmentId: {
          name: "assessmentId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        selfReflectionCount: {
          name: "selfReflectionCount",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "LearningSelfReflectionCounts",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "reflectionCountByUserId",
            queryField: "selfReflectionCountByUserId",
            fields: ["userId"],
          },
        },
      ],
    },
    Insights: {
      name: "Insights",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        dimensionId: {
          name: "dimensionId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        developingScoreInsights: {
          name: "developingScoreInsights",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
        approachingScoreInsights: {
          name: "approachingScoreInsights",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
        demonstratingScoreInsights: {
          name: "demonstratingScoreInsights",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Insights",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["dimensionId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    Itemresponsetype: {
      name: "Itemresponsetype",
      values: ["MOST_LIKELY", "LEAST_LIKELY", "SELECTED", "UNSELECTED"],
    },
    Highleveltraittype: {
      name: "Highleveltraittype",
      values: [
        "LEADERSHIP",
        "INNOVATIVE_THINKING",
        "COMMUNICATION",
        "PERSEVERANCE",
      ],
    },
    Usertype: {
      name: "Usertype",
      values: ["STUDENT", "INSTITUTION"],
    },
    Displaytype: {
      name: "Displaytype",
      values: ["RADIO", "CHECKBOX", "DROPDOWN"],
    },
    Itemtype: {
      name: "Itemtype",
      values: ["VIDEO", "TRIPLET"],
    },
    AssessmentStatusType: {
      name: "AssessmentStatusType",
      values: [
        "ASSESSMENT_STARTED",
        "BIQ_COMPLETE",
        "SAMPLE_INSTRUCTION_COMPLETE",
        "TRIPLET_INPROGRESS",
        "TRIPLET_COMPLETE",
        "VIDEO_FAILED",
        "TRIPLET_FAILED",
        "VIDEO_COMPLETE",
        "VIDEO_INPROGRESS",
        "VIDEO_SKIPPED",
        "ASSESSMENT_COMPLETE",
        "ASSESSMENT_FAILED",
      ],
    },
    Profileattributetype: {
      name: "Profileattributetype",
      values: [
        "ageGroup",
        "gender",
        "emplStatus",
        "ethnicity",
        "goal",
        "enrollmentStatus",
        "programYear",
        "fieldOfStudy",
        "transferToFourYear",
        "workExperience",
        "education",
      ],
    },
    ScoreBand: {
      name: "ScoreBand",
      values: ["HIGH", "MEDIUM", "LOW"],
    },
    Preferencetype: {
      name: "Preferencetype",
      values: ["USEFUL", "NOTUSEFUL", "UNSELECTED", "FAVORITED", "UNFAVORITED"],
    },
    Phasetype: {
      name: "Phasetype",
      values: ["PRETEST", "POSTTEST"],
    },
  },
  nonModels: {
    ScorableResponses: {
      name: "ScorableResponses",
      fields: {
        TestCode: {
          name: "TestCode",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        Response: {
          name: "Response",
          isArray: true,
          type: "Int",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    PairsScorableResponses: {
      name: "PairsScorableResponses",
      fields: {
        calibration_name: {
          name: "calibration_name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        responses: {
          name: "responses",
          isArray: true,
          type: "Int",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    TripletResponses: {
      name: "TripletResponses",
      fields: {
        itemGuid: {
          name: "itemGuid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        itemSeq: {
          name: "itemSeq",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        response: {
          name: "response",
          isArray: false,
          type: {
            enum: "Itemresponsetype",
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
    ResourceInfo: {
      name: "ResourceInfo",
      fields: {
        traitName: {
          name: "traitName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        resourceLinks: {
          name: "resourceLinks",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    Card: {
      name: "Card",
      fields: {
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        content: {
          name: "content",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
  },
  codegenVersion: "3.4.4",
  version: "74c14cd7421ab0af8f8910d3554026c7",
};

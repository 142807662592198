// Packages
import React from "react";

// Components
import MenuItem from "../MenuItem/MenuItem";

// Types
import { SubMenuPropType } from "../ReskinMenu";

// Assets
import { ReactComponent as ChevronUp } from "../../../../../assets/reskin/icons/chevron-up-charcoal.svg";
import { ReactComponent as ChevronDown } from "../../../../../assets/reskin/icons/chevron-down-charcoal.svg";

// Styles
import "./SubMenu.scss";

const SubMenu = ({
  Icon,
  onClick,
  label,
  textColor,
  isLastItem,
  subMenuItems,
}: SubMenuPropType) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const isLastItemClass = isLastItem ? "is-last-item" : "";
  const SubMenuItems = subMenuItems.map((menuItem, index) => {
    return (
      <MenuItem
        {...menuItem}
        tabIndex={isDropdownOpen ? 0 : -1}
        key={`reskin-menu-item-${index}-${label}`}
      />
    );
  });

  const UpIcon = <ChevronUp />;
  const DownIcon = <ChevronDown />;

  const dropdownStatus = isDropdownOpen ? "open" : "closed";
  const CurrentIcon = isDropdownOpen ? UpIcon : DownIcon;

  return (
    <>
      <div
        className={`reskin-submenu-toggler active ${isLastItemClass}`}
        tabIndex={0}
        onClick={() => {
          onClick();
          setIsDropdownOpen(!isDropdownOpen);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onClick();
            setIsDropdownOpen(!isDropdownOpen);
          }
        }}
        style={{ color: textColor || "#737582" }}
      >
        <div className="header-icon-container">{Icon ? Icon : null}</div>
        <div className="header-label-container">
          <p className="m-0">{label}</p>
        </div>
        <div className="dropdown-icon-container">{CurrentIcon}</div>
      </div>
      <div className="reskin-menu-pseudo-border" />

      <div className={`reskin-submenu-items-container ${dropdownStatus}`}>
        {SubMenuItems}
      </div>
    </>
  );
};

export default SubMenu;

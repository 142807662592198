// Packages
import React from "react";
import { useParams } from "react-router-dom";
import { Storage } from "aws-amplify";

// Components
import StudentCard from "./studentCard";
import TraitCard from "./traitCard";
import TraitGraph from "./traitGraph";
import Loading from "../loading";

// Grpahql
import { listNarratives } from "../../../graphql/queries";
import { listCandidateComments } from "../../../graphql/custom-queries";
import { searchUsers } from "../../../graphql/queries";
import { getOrList } from "../../../components/dynamicqueries";

// Hooks
import usePrevious from "../../../hooks/usePrevious";

// Models
import { Narratives } from "../../../models";
import { DIMENSIONS } from "../../../enumerations";

// Servives
import EventService from "../../../services/EventService/event.service";

// Utils
import { ucWords } from "../../../utilities";
import { any } from "prop-types";

function Traits({ setSelectedTab, institutionId }: any) {
  const { trait = "leadership" } = useParams();
  const [isLoading, setLoading] = React.useState<boolean>(true);

  const [userInfo, setUserInfo] = React.useState<any>([]);
  const [narrative, setNarratives] = React.useState<any>([]);
  const [candidateComment, setCandidateComment] = React.useState<any>([]);
  // const [graphTraits, setGraphTraits] = React.useState<any>([]);
  const [developingvalue, setDevelopingvalue] = React.useState<any>(0);
  const [demonstraingvalue, setDemonstraingvalue] = React.useState<any>(0);

  React.useEffect(() => {
    getUser();
    Narrative();
    setLoading(false);
    // traitsData();

    return () => {
      EventService.page({
        type: "exit",
        page: `HEI Trait ${trait}`,
      });
    };
  }, []);

  const prevTrait = usePrevious(trait);

  React.useEffect(() => {
    if (prevTrait !== "" && typeof prevTrait !== "undefined") {
      EventService.page({
        type: "exit",
        page: `HEI Trait ${prevTrait}`,
      });
    }

    EventService.page({
      type: "enter",
      page: `HEI Trait ${trait}`,
    });

    setSelectedTab(`traits/${trait}`);
    candidateComments();
  }, [trait]);

  const Narrative = async () => {
    const narratives = await getOrList(listNarratives, "listNarratives", {});

    setNarratives(narratives);
  };

  const candidateComments = async () => {
    const commentfilter = [
      {
        trait: "leadership",
        declineCol: "declineCommentOnLeadership",
        profaneCol: "leadershipCommentProfane",
        comment: "leadershipComment",
      },
      {
        trait: "perseverance",
        declineCol: "declineCommentOnPerserverance",
        profaneCol: "perseveranceCommentProfane",
        comment: "perseveranceComment",
      },
      {
        trait: "effectivecommunication",
        declineCol: "declineCommentOnCommunication",
        profaneCol: "communicationCommentProfane",
        comment: "communicationComment",
      },
      {
        trait: "innovativethinking",
        declineCol: "declineCommentOnInnovativeThinking",
        profaneCol: "innovativeThinkingCommentProfane",
        comment: "innovativeThinkingComment",
      },
    ];
    const { declineCol, profaneCol, comment } = commentfilter.filter(
      (traits: any) => traits.trait === trait,
    )[0];
    const variable = {
      limit: 10000,
      filter: {
        or: [{ [declineCol]: { eq: false }, [profaneCol]: { ne: true } }],
      },
    };

    let traitComments = await getOrList(
      listCandidateComments,
      "listCandidateComments",
      variable,
    );
    traitComments = traitComments
      .filter(
        (val: any, key: any) =>
          val.user.institutionId == institutionId &&
          val.user.assessmentStates.items[0].userScores.overallScore >= 0,
      )
      .filter((val: any, index: any) => {
        return index < 3;
      });
    if (traitComments.length > 0) {
      traitComments.forEach(async (element: any, index: any) => {
        const imgUrl = await getS3PreSignedUrl(element.userId);
        element["imgUrl"] = imgUrl;

        if (index + 1 === traitComments.length) {
          setCandidateComment(traitComments);
        }
      });
    } else {
      setCandidateComment([]);
    }
  };

  const getUser = async () => {
    let studentInfo = [
      {
        type: "leadership",
        filterComment: "declineCommentOnLeadership",

        userData: [
          {
            name: "Brian Richard",
            details: "Student, Age 31-40, Enrolled since 2020",
            description:
              '"Leadership is a developing trait for me. I know I tend to get comfortable very easily with something- when I feel secure I would rather just coast (i.e. take commands) rather than make waves (delegate tasks). The SkillTrack was right in diagnosing that area of weakness but gave me several pieces of specific insight on how to improve upon traits in the area."',
            image: await getS3PreSignedUrl(
              "fbc6a573-361e-4464-8e8b-a5cb536d2c0a",
            ),
          },
          {
            name: "Becky Miles",
            details: "Student, Age 23-30, Enrolled since 2020",
            description:
              '"I learnt that I should take more responsibility in group project and I should also facilitate conflict resolution when needed in teamwork."',
            image: await getS3PreSignedUrl(
              "01607ffa-434d-42fc-a6bb-b28b47624e9d",
            ),
          },
          {
            name: "Jerry Tommy",
            details: "Student, Age 23-30, Enrolled since 2020",
            description:
              '"I learnt that being a leader one has to be able to listen to his team members and also be an active member of the team and leading by example."',
            image: await getS3PreSignedUrl(
              "9c7f0ebd-1684-4e44-88c2-2d83a512dc21",
            ),
          },
        ],
      },
      {
        type: "perseverance",
        filterComment: "declineCommentOnPerserverance",

        userData: [
          {
            name: "Anthony Taylor",
            details: "Student, Age 23-30, Enrolled since 2021",
            description:
              '"Learning to the patient, calm and thinking before reacting is key to attaining the level of perseverance needed to thrive."',
            image: await getS3PreSignedUrl(
              "920bf1e1-1d0f-4837-84d7-93fd99186b01",
            ),
          },
          {
            name: "Danielle Ford",
            details: "Student, Age 31-40, Enrolled since 2021",
            description:
              '"I\'m more so in the approaching phase. I need to continue my goalsetting breaking them down into smaller goals and continue to believe in myself that I can achieve the goals that I set for myself."',
            image: await getS3PreSignedUrl(
              "c0034d12-f133-4b5b-b4ee-08265a317a6f",
            ),
          },
          {
            name: "Zack Schwartz",
            details: "Student, Age 31-40, Enrolled since 2021",
            description:
              '"I learned that perseverance is also a strong trait for me, but something I want to continue working on."',
            image: await getS3PreSignedUrl(
              "cbc378a4-eb51-47e6-8d0b-83dcb176e621",
            ),
          },
        ],
      },
      {
        type: "effectivecommunication",
        filterComment: "declineCommentOnCommunication",

        userData: [
          {
            name: "David Warnar",
            details: "Student, Age 23-30, Enrolled since 2022",
            description:
              '"I\'ve learned to be more outspoken and be able to be interactive with people be it strangers or friends or people you just met for the first time."',
            image: await getS3PreSignedUrl(
              "5209ee7d-6976-4103-8699-2b2e8c6423b9",
            ),
          },
          {
            name: "Jon Yunker",
            details: "Student, Age 41-50, Enrolled since 2022",
            description:
              '"Interestingly I\'m have low score in eye contact and posture but high in speech fluency. I think this reinforces some challenges I have with lack of confidence. I like the coaching around nod in agreement to validate what people are saying."',
            image: await getS3PreSignedUrl(
              "bea3ba6c-2c39-441f-9597-ccef440f8679",
            ),
          },
          {
            name: "Marielle Berman",
            details: "Student, Age 31-40, Enrolled since 2022",
            description:
              '"I liked the recommendation of tilting your head slightly to the side to demonstrate friendliness and openness."',
            image: await getS3PreSignedUrl(
              "1f14a33f-d8f0-46c6-9318-fbc660cc72b8",
            ),
          },
        ],
      },
      {
        type: "innovativethinking",
        filterComment: "declineCommentOnInnovativeThinking",

        userData: [
          {
            name: "Richards Smith",
            details: "Student, Age 23-30, Enrolled since 2020",
            description:
              '"Thinking innovately brings great ideas, SkillTrack also helps one to learn how to think innovatively and unlock the door to more brilliant thoughts."',
            image: await getS3PreSignedUrl(
              "a77a2f13-ad34-4045-b5d1-88b66a846bbe",
            ),
          },
          {
            name: "Sandra Schenning",
            details: "Student, Age 23-30, Enrolled since 2020",
            description:
              '"I thought it was interesting to think innovatively since I work for the government we keep the processes the same we have been doing for years. I should talk to my boss to see what different processes I can make easier for contracting."',
            image: await getS3PreSignedUrl(
              "cb5b0020-4b16-4de0-bc00-7eea4b91bf69",
            ),
          },
          {
            name: "George Williams",
            details: "Student, Age 23-30, Enrolled since 2020",
            description:
              '"I am very innovative and my SkillTrack results did confirm that. I learned that I would do much better if I am willing to accept when I am wrong."',
            image: await getS3PreSignedUrl(
              "a1699b99-6ef9-4cee-a991-26eeff270c4b",
            ),
          },
        ],
      },
    ];

    setUserInfo(studentInfo);
  };

  const subtraits = [
    {
      type: "leadership",
      number: ["f08", "f03", "f09", "f12", "f11", "f07", "f02"],
      dimension: [
        DIMENSIONS.F08,
        DIMENSIONS.F03,
        DIMENSIONS.F09,
        DIMENSIONS.F12,
        DIMENSIONS.F11,
        DIMENSIONS.F07,
        DIMENSIONS.F02,
      ],
      studentPerformance:
        "Seven subtraits make up the Leadership category. Among the students who have taken SkillTrack their responses to items that evaluated [x] demonstrated strength in those areas, but they may need some development among [y].",
      title: "Leadership",
    },
    {
      type: "perseverance",

      number: ["f01", "f06", "f05", "f04"],
      dimension: [
        DIMENSIONS.F01,
        DIMENSIONS.F06,
        DIMENSIONS.F05,
        DIMENSIONS.F04,
      ],
      studentPerformance:
        "Four subtraits make up the Perseverance category. Among the students who have taken SkillTrack their responses to items that evaluated [x] demonstrated strength, but they may need some development among [y].",
      title: "Perseverance",
    },
    {
      type: "effectivecommunication",

      number: [
        "headBodyPosture",
        "speechFluency",
        "effectivePauses",
        "eyeContact",
      ],
      dimension: [
        DIMENSIONS.headBodyPosture,
        DIMENSIONS.speechFluency,
        DIMENSIONS.effectivePauses,
        DIMENSIONS.eyecontact,
      ],
      studentPerformance: `Four subtraits make up the Effective Communication category. Among the students who have taken SkillTrack their performance that evaluated [x] demonstrated strength, but they may need some development among [y].`,
      title: "Effective  Communication",
    },
    {
      type: "innovativethinking",

      number: ["f14", "f15", "f13"],
      dimension: [DIMENSIONS.F14, DIMENSIONS.F15, DIMENSIONS.F13],
      studentPerformance: `Three subtraits make up the Innovative Thinking category. Among the students who have taken SkillTrack their responses to items that evaluated [x] demonstrated strength, but they may need some development among [y].`,
      title: "Innovative Thinking",
    },
  ];

  const getS3PreSignedUrl = async (userId: any) => {
    let filePath = "photos/" + userId + ".PNG";
    let preSignedUrl = await Storage.get(filePath, {
      level: "public",
      download: false,
      expires: 300,
    });
    return preSignedUrl;
  };

  const determineTraitName = (trait: string) => {
    if (trait === "effectivecommunication") return "Effective Communication";
    if (trait === "innovativethinking") return "Innovative Thinking";

    return ucWords(trait);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <section className="container fullsummary-trait">
          {/* <div className="container title">
            <p>Student {determineTraitName(trait)} Overview</p>
          </div>

          {userInfo.length > 0 && (
            <StudentCard
              studentData={userInfo}
              traitName={trait}
              candidateComment={candidateComment}
            />
          )} */}

          <TraitGraph
            traitName={trait}
            traitData={
              subtraits.filter(
                (subtrait: any, i: any) => subtrait.type === trait,
              )[0]
            }
            institutionId={institutionId}
          />

          {narrative.length > 0 && (
            <TraitCard narratives={narrative} traitName={trait} />
          )}
        </section>
      )}
    </>
  );
}

export default Traits;

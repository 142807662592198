// Packages
import React from "react";
import { Form } from "react-bootstrap";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import { ReactComponent as SuccessIcon } from "../../../institution/assets/verify-success.svg";


export const Success = ({ changeAuthState }: any) => {
  const email = sessionStorage.getItem("email") || "";
  const screen = sessionStorage.getItem("AuthState");

  const obscureEmail = (value: string) => {
    if (value === "") return "********@*****.***";
    const [name, domain] = value.split("@");
    return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
  };

  const returnToSignIn = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("AuthState");
    changeAuthState({ screen: "SignIn" });
  }

  const Verification = () => {
    return (
    <>
      <p className="mb-4" style={{color: "#5D8137", fontSize: "32px", fontWeight: "600"}}>
      Success!
      </p>
      <p>Your account has been created using <br></br>{obscureEmail(email)}</p>
    </>
  )};

  const Reset = () => {
    return (
    <>
      <p className="mb-4" style={{color: "#5D8137", fontSize: "32px", fontWeight: "600"}}>
      Success!
      </p>
      <p>Your password has been reset</p>
    </>
  )};

  return (
    <div className="form-container">
      <Form noValidate>
        <Form.Group className="mb-5">
          <SuccessIcon />
          {screen === "Verify" ? <Verification /> : <Reset />}
        </Form.Group>

        <Form.Group>
          <ReskinButton
            variant="primary"
            onClick={returnToSignIn}
            text="Sign In"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export const PrivacyPolView = () => {
  return (
    <div className="terms">
      <p>
        ETS recognizes the importance of protecting your privacy. This Privacy Notice describes our practices related to personal information privacy and security.
      </p>
      <p>
        ETS has a global privacy program to protect all "Personal Information." Personal Information is any information that we can use to identify you (such as your name, username or identification number) or contact you (such as your email address or telephone number). Personal Information includes other types of information (such as test scores or demographic data) when that information is associated with your Personal Information.
      </p>
      <p>
        <a href="https://www.ets.org/legal/privacy.html">Read more</a>
      </p>
    </div>
  );
};

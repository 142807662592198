// Packages
import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { RootState } from "../../../redux/reducers";
import { updateUserAssessmentStateStatus } from "../../../redux/actions";

// GraphQL
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";

// Styles
import "./InsightsReport.scss";

// Components
import ReskinNavBar from "../../components/NavBars/ReskinNavBar";
import MainAppHeader from "../../components/Header/MainAppHeader/MainAppHeader";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";
import InsightsReportCard from "../../components/Cards/InsightsReportCard/InsightsReportCard";

// Assets
import { ReactComponent as BackIcon } from "../../../assets/icons/back.svg";
import userIcon from "../../../assets/re-skin/userIcon.svg";
import star from "../../../assets/re-skin/Star.svg";
import leadership from "../../../assets/re-skin/InsightsReportIcons/Leadership.svg";
import EffectiveCommunication from "../../../assets/re-skin/InsightsReportIcons/EffectiveCommunication.svg";
import Perseverance from "../../../assets/re-skin/InsightsReportIcons/Perseverance.svg";
import InnovativeThinking from "../../../assets/re-skin/InsightsReportIcons/InnovativeThinking.svg";
import NoDataAvailable from "../../../assets/re-skin/InsightsReportIcons/NoDataAvailable.svg";
import barsLow from "../../../assets/re-skin/InsightsReportIcons/barsLow.svg";
import barsMedium from "../../../assets/re-skin/InsightsReportIcons/barsMedium.svg";
import barsHigh from "../../../assets/re-skin/InsightsReportIcons/barsHigh.svg";
import RightArrow from "../../../assets/re-skin/InsightsReportIcons/RightArrow.svg";
import { ReactComponent as NavPersonCircle } from "../../../assets/reskin/icons/nav-person-circle.svg";
import { updateUserAssessmentState } from "../../../redux/actions";
import { AssessmentStatusType } from "../../../models";
import EventService from "../../../services/EventService/event.service";

function InsightsReport({ userScores }: any) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    assessmentState: { sessionId },
  } = useSelector((state: RootState) => state.user);

  const [videoStatus, setVideoStatus] = React.useState<string>("");

  const dispatchErrorAction = async () => {
    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.VIDEO_FAILED,
      }),
    );

    dispatch(
      updateUserAssessmentStateStatus({
        statusType: AssessmentStatusType.VIDEO_FAILED,
      }),
    );
    return "done";
  };

  async function checkVideoScoring() {
    let filterCondition = {
      and: [
        { userSessionId: { eq: sessionId } },
        { eventName: { eq: "video" } },
      ],
    };
    const response = await API.graphql({
      query: queries.listEventTrackings,
      variables: { limit: 10000, filter: filterCondition },
    });
    const responseObject = JSON.parse(JSON.stringify(response));

    if (responseObject.data.listEventTrackings.items) {
      if (
        "Error" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        await dispatchErrorAction();
        sessionStorage.setItem("rerecordingAttempt", "true");
        alert(
          "There was a problem scoring your video submission. You will now be redirected to resubmit.",
        );
        navigate("/assessment/communication/intro");
      }
      if (
        "Skipped" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        setVideoStatus("Skipped");
      }
      if (
        "InProcess" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        setVideoStatus("InProcess");
      }
    }
  }

  React.useEffect(() => {
    checkVideoScoring();
  }, []);

  React.useEffect(() => {
    const onScroll = () => {
      const bottom =
        -Math.ceil(window.innerHeight + window.scrollY) +
        document.documentElement.scrollHeight;
      EventService.track({
        event_type: "user",
        event_name: "insights_report_scroll",
        user_type: "student",
        event_data: {
          page: "Insights Report",
          pixels_from_bottom: bottom,
        },
      });
    };
    // clean up code
    window.removeEventListener("scrollend", onScroll);
    window.addEventListener("scrollend", onScroll, { passive: true });
    return () => window.removeEventListener("scrollend", onScroll);
  }, []);

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "Insights Report",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "Insights Report",
      });
    };
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const { name } = useSelector((state: RootState) => state.user);
  const firstName = name?.split(" ")[0];

  const data = [
    {
      text: "Begin Assessment",
    },
    {
      text: "Submit Your Response",
    },
    {
      text: "Go to my trait scores",
    },
  ];

  const getBarIcon = (val: string) => {
    switch (val) {
      case "LOW":
        return <img src={barsLow} className="bar-icon" />;
      case "MEDIUM":
        return <img src={barsMedium} className="bar-icon" />;
      case "HIGH":
        return <img src={barsHigh} className="bar-icon" />;
    }
    if (val == null) {
      return <img src={NoDataAvailable} className="noData-icon" />;
    }
  };

  const BackButton = (
    <button onClick={goBack} className="btn-back">
      <BackIcon />
    </button>
  );

  const ProfileMenu = <NavPersonCircle />;
  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = useSelector((state: RootState) => state.user);

  return (
    <div className="reskin-insights-report-page">
      {/* <section className='subheader'>
        <ReskinNavBar leftIcon={BackButton} rightIcon={ProfileMenu} />
      </section> */}

      <MainAppHeader />
      <div className="ImageContainer"></div>
      <div className="page-body">
        <div className="titleContainer">
          <img src={star} alt="start" className="pb-5" />
          <span>
            <p className="title">{`Way to go, ${firstName}!`}</p>
            <p className="subTitle">
              You’re on your way to becoming a great communicator.
            </p>
          </span>
        </div>

        <InsightsReportCard
          TraitIcon={
            <img src={leadership} alt="leadership icon" className="cardsIcon" />
          }
          traitName="Leadership"
          traitClass="leadership"
          subtraitsCount={7}
          traitScore={userScores.leadershipScoreBand}
          status=""
          navLink="/report/trait/leadership"
        />

        <InsightsReportCard
          TraitIcon={
            <img
              src={EffectiveCommunication}
              alt="effective communication icon"
              className="cardsIcon"
            />
          }
          traitName="Effective Communication"
          traitClass="effective-communication"
          subtraitsCount={4}
          traitScore={userScores.communicationScoreBand}
          status={videoStatus}
          navLink="/report/trait/effective%20communication"
        />

        <InsightsReportCard
          TraitIcon={
            <img
              src={Perseverance}
              alt="perseverance icon"
              className="cardsIcon"
            />
          }
          traitName="Perseverance"
          traitClass="perseverance"
          subtraitsCount={4}
          traitScore={userScores.perseveranceScoreBand}
          status=""
          navLink="/report/trait/perseverance"
        />

        <InsightsReportCard
          TraitIcon={
            <img
              src={InnovativeThinking}
              alt="innovative thinking icon"
              className="cardsIcon"
            />
          }
          traitName="Innovative Thinking"
          traitClass="innovative-thinking"
          subtraitsCount={3}
          traitScore={userScores.innovativeThinkingScoreBand}
          status=""
          navLink="/report/trait/innovative%20thinking"
        />
      </div>
      <div className="bottomContainer">
        <p className="headerText">Keep Building Your Skills</p>
        <p className="subHeader">
          Check out the excellent resources available to you to help you keep
          growing.
        </p>
        <ReskinButton
          variant="primary"
          text="Resource Hub"
          customClasses="mt-3 resource-hub-button"
          // className="btn-signin primary mt-3"
          tabIndex={2}
          onClick={() => navigate("/report/support")}
        />
      </div>
    </div>
  );
}

export default InsightsReport;

// Packages
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import ReskinNavBar from "../../NavBars/ReskinNavBar";
import AssessmentMenu from "../../Menus/AssessmentMenu/AssessmentMenu";

// Store
import { RootState } from "../../../../redux/reducers";

// Assets
import { ReactComponent as NavProfileCircle } from "../../../../assets/reskin/icons/nav-person-circle.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/re-skin/closeIcon.svg";
import { ReactComponent as BackIcon } from "../../../../assets/reskin/icons/caret-left-white.svg";

// Style
import "./AssessmentHeader.scss";

interface AssessmentHeaderProps {
  isTransparent: boolean;
  withBackButton?: boolean;
}

const AssessmentHeader = ({
  isTransparent,
  withBackButton,
}: AssessmentHeaderProps) => {
  const { user } = useSelector((state: RootState) => state);
  const { name } = user;
  const [showMenu, setShowMenu] = React.useState(false);
  const isShownClass = showMenu ? "" : "hidden";
  const navigate = useNavigate();

  React.useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMenu]);

  const isFixedClass = isTransparent ? "fixed-header" : "";
  const isTransparentClass = isTransparent ? "transparent-header" : "";

  // Left Side
  const ProfileDisplay = (
    <div className="profile-display">
      <div className="vertically-centered me-2">
        <NavProfileCircle />
      </div>
      <div className="vertically-centered">
        <p className="m-0">{name}</p>
      </div>
    </div>
  );

  const BackButton = (
    <button className="reskin-clear-button" onClick={() => navigate(-1)}>
      <BackIcon />
    </button>
  );

  const getLeftIcon = () => {
    let CurrentIcon = <></>;

    if (withBackButton) {
      CurrentIcon = BackButton;
    }

    if (showMenu) {
      CurrentIcon = ProfileDisplay;
    }

    return CurrentIcon;
  };

  // Right Side
  const CloseButton = (
    <button className="reskin-clear-button" onClick={() => setShowMenu(false)}>
      <CloseIcon />
    </button>
  );

  const PersonButton = (
    <button
      className="reskin-clear-button"
      onClick={() => setShowMenu(!showMenu)}
    >
      <NavProfileCircle />
    </button>
  );

  const MenuContent = <AssessmentMenu />;

  const getRightIcon = () => {
    return showMenu ? CloseButton : PersonButton;
  };

  return (
    <div className={`reskin-header ${isFixedClass}`}>
      <div className={`reskin-background-overlay ${isShownClass}`} />
      <div className="menu-content-container">
        <ReskinNavBar
          leftIcon={getLeftIcon()}
          rightIcon={getRightIcon()}
          customClasses={isTransparentClass}
          isSticky={isTransparent}
        />
        <div className={`reskin-header-menu ${isShownClass}`}>
          {MenuContent}
        </div>
      </div>
    </div>
  );
};

export default AssessmentHeader;

// Packages
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import ReskinNavBar from "../../../components/NavBars/ReskinNavBar";
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";
import NumberedStepSection from "../../../components/TipSections/NumberedStepSection";
import BIQPill from "../../../../ui-components/BIQPill";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";

// Assets
import { ReactComponent as NavProfileCircle } from "../../../../assets/reskin/icons/nav-person-circle.svg";
import { ReactComponent as OpenPalm } from "../../../../assets/reskin/icons/open-palm.svg";
import { ReactComponent as LargeClock } from "../../../../assets/reskin/icons/large-clock.svg";
import { ReactComponent as Parliament } from "../../../../assets/reskin/icons/parliament.svg";

// Services
import EventService from "../../../../services/EventService/event.service";

// Styles
import "./HowItWorksPage.scss";

const HowItWorksPage = () => {
  const OpenPalmIcon = <OpenPalm />;
  const LargeClockIcon = <LargeClock />;
  const ParliamentIcon = <Parliament />;
  let navigate = useNavigate();

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "How SkillTrack Works",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "How SkillTrack Works",
      });
    };
  }, []);

  const handleContinue = () => {
    navigate("/assessment/about-you");
  };

  return (
    <div className="how-it-works-page">
      <AssessmentHeader isTransparent={false} />
      <div className="how-it-works-header">
        <p className="header-text"> How SkillTrack Works</p>
      </div>
      <div className="how-it-works-content">
        <NumberedStepSection
          stepNumber={1}
          headerText="Tell Us More About You"
          bodyText="Answer a few background questions to personalize your experience."
          footerText="Takes about 2 minutes"
        />
        <NumberedStepSection
          stepNumber={2}
          headerText="Effective Communication"
          bodyText="Record a 1 - 2 minute spoken response to one of three questions. Your communication skills will be evaluated."
          footerText="Takes about 5 minutes"
        />
        <NumberedStepSection
          stepNumber={3}
          headerText="Personal Skills and Qualities"
          bodyText="Answer a few background questions to personalize your experience."
          footerText="Takes about 25 minutes"
          isLast={true}
        />

        <BIQPill
          iconContainer={OpenPalmIcon}
          overrides={{
            BIQPill: {
              marginTop: "rem",
              marginLeft: "auto",
              marginRight: "auto",
              width: "320px",
            },
            Text: { width: "230px" },
          }}
        />
        <BIQPill
          iconContainer={LargeClockIcon}
          backgroundColor="#EFF1FA"
          overrides={{
            BIQPill: {
              marginTop: "1rem",
              marginLeft: "auto",
              marginRight: "auto",
              width: "320px",
            },
            Text: {
              children:
                "The total time for this activity will be around 30 minutes.",
              paddingTop: "1.2rem",
              width: "230px",
            },
          }}
        />
        <BIQPill
          iconContainer={ParliamentIcon}
          backgroundColor="#F2F6EE"
          overrides={{
            BIQPill: {
              marginTop: "1rem",
              marginLeft: "auto",
              width: "320px",
              marginRight: "auto",
              marginBottom: "1rem",
            },
            Text: {
              children: "Your data will be shared with your institution.",
              paddingTop: "1.2rem",
              width: "230px",
            },
          }}
        />

        <ReskinButton
          text="Next: Tell Us More About You"
          customClasses="how-it-works-next-button unhighlightable"
          onClick={() => handleContinue()}
        />
      </div>
    </div>
  );
};

export default HowItWorksPage;

// Types
import { AssessmentType, AssessmentActionTypes } from "../types";

const initialAssessmentState: AssessmentType = {
  id: "",
};

export function assessmentReducer(
  state: AssessmentType = initialAssessmentState,
  action: AssessmentActionTypes,
): AssessmentType {
  switch (action.type) {
    case "STORE_ASSESSMENT":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

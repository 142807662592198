// Packages
import React from "react";
import { useNavigate } from "react-router-dom";

// Assets
import { ReactComponent as EffectiveCommunication } from "../../../../assets/reskin/icons/effective-communication.svg";

// Components
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import SkipModal from "./SkipModal";
import ReskinIntroPage from "../../CommonPages/ReskinIntroPage";

// Styles
import "./RecordingIntroPage.scss";

const RecordingIntroPage = () => {
  const [isSkipModalOpen, setIsSkipModalOpen] = React.useState(false);

  let navigate = useNavigate();

  const handleNext = () => {
    navigate("/assessment/communication/explanation");
  };

  const EffectiveCommunicationIcon = <EffectiveCommunication />;
  const LeftButton = (
    <ReskinButton
      text="Skip"
      variant="secondary"
      size="small"
      customClasses="left-button"
      onClick={() => setIsSkipModalOpen(true)}
    />
  );

  const RightButton = (
    <ReskinButton
      text="Start"
      size="small"
      customClasses="right-button"
      onClick={() => handleNext()}
    />
  );

  const RenderedControls = (
    <div className="controls-wrapper">
      {LeftButton}
      {RightButton}
    </div>
  );

  const HeaderContent = (
    <>
      <h2>Effective</h2>
      <h2>Communication</h2>
    </>
  );

  return (
    <>
      <AssessmentHeader isTransparent={true} />
      <ReskinIntroPage
        HeaderIcon={EffectiveCommunicationIcon}
        headerText={HeaderContent}
        bodyText="In this activity your communication skills will be evaluated. You will receive insight into some of the components of communication that employers find important.​​"
        Controls={RenderedControls}
      />
      <SkipModal
        show={isSkipModalOpen}
        handleClose={() => setIsSkipModalOpen(false)}
      />
    </>
  );
};

export default RecordingIntroPage;

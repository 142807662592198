// Packages
import React from "react";
import { Modal } from "react-bootstrap";
import ReskinButton from "../../../reskin/components/Buttons/ReskinButton/ReskinButton";

const ModalDurationTooShort = ({ onRequestClose }: any) => {
  const handleRetry = () => {
    onRequestClose();
  };

  return (
    <Modal className="modal-duration" show={true} onHide={onRequestClose}>
      <Modal.Header className="no-border" closeButton>
        <Modal.Title>Video is Too Short</Modal.Title>
      </Modal.Header>
      <hr className="border-line"></hr>
      <Modal.Body className="no-border">
        Looks like your video is too short, please re-record and make sure it is
        at least 20 seconds long.
      </Modal.Body>
      <Modal.Footer className="no-border">
        <ReskinButton
          onClick={handleRetry}
          variant="primary"
          text="Retry"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDurationTooShort;

// Packages
import { useNavigate } from "react-router-dom";

// Components
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import EffectiveCommunicationTip from "../../../components/Cards/EffectiveCommunicationTip/EffectiveCommunicationTip";
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";

// Styles
import "./RecordingExplanationPage.scss";

const RecordingExplanationPage = () => {
  let navigate = useNavigate();

  const handleNext = () => {
    navigate("/assessment/communication/questions");
  };

  return (
    <div className="recording-explanation-page">
      <AssessmentHeader isTransparent={true} withBackButton={true} />
      <div className="page-header">
        <h1>
          Effective <br />
          Communication
        </h1>
      </div>

      <div className="page-body">
        <EffectiveCommunicationTip
          tipNumber={1}
          header="Select a Question"
          headerVariant="blue"
          text="You will see three questions, select one of the questions to answer."
          customClasses="tip-card"
        />
        <EffectiveCommunicationTip
          tipNumber={2}
          header="Record Your Response"
          headerVariant="green"
          text="Spend between one and two minutes answering the question. "
          customClasses="tip-card"
        />
        <EffectiveCommunicationTip
          tipNumber={3}
          header="Review and Submit"
          headerVariant="grey"
          text="Review your response, you will have the option to rerecord one more time before submitting if you choose."
          customClasses="tip-card"
        />

        <div className="next-button-container">
          <ReskinButton
            text="Next: Select a Question"
            size="large"
            onClick={() => handleNext()}
            customClasses="next-button"
          />
        </div>
      </div>
    </div>
  );
};

export default RecordingExplanationPage;

function VerificationCodeEmail({ email }: any) {
  const obscureEmail = (email: string) => {
    if (email === "") return "********@*****.***";
    const [name, domain] = email.split("@");
    return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
  };

  return (
    <p className="verification-email-label">
      A verification code has been sent to:
      <br />
      <span className="obscure-email">{obscureEmail(email)}</span>
    </p>
  );
}

export default VerificationCodeEmail;

// Components
import Trait from "./Trait";
import Ring from "./Ring";

function GraphInsights({ handleSelection, bands, counts }: any) {
  return (
    <div className="welcome-graph-insights">
      <div className="graph-container">
        {counts.leadershipCounts !== null &&
          counts.perserveranceCounts !== null &&
          counts.communicationCounts !== null &&
          counts.innovativeThinkineCounts !== null && <Ring counts={counts} />}
        <div className="quadrants">
          {/* TOP LEFT */}
          <Trait
            band={bands.leadershipBand}
            className="text-left q1"
            trait="Leadership"
            handleSelection={handleSelection}
          />

          {/* TOP RIGHT */}
          <Trait
            band={bands.perserveranceBand}
            className="text-right q2"
            trait="Perseverance"
            handleSelection={handleSelection}
          />

          {/* BOTTOM LEFT */}
          <Trait
            band={bands.communicationBand}
            counts={counts.communicationCounts}
            className="text-left bottom q3"
            trait="Effective Communication"
            handleSelection={handleSelection}
          />

          {/* BOTTOM RIGHT */}
          <Trait
            band={bands.innovativeThinkingBand}
            className="bottom text-right q4"
            trait="Innovative Thinking"
            handleSelection={handleSelection}
          />
        </div>
      </div>
    </div>
  );
}

export default GraphInsights;

const SubtraitGraphHeader = () => {
  return (
    <div className="subtrait-graph-header">
      <div className="name-space"></div>
      <div className="header-container developing-header-container">
        <span className="header-text">Developing</span>
      </div>
      <div className="header-container approaching-header-container">
        <span className="header-text">Approaching</span>
        <div className="header-dashes" />
      </div>
      <div className="header-container demonstrating-header-container">
        <span className="header-text">Demonstrating</span>
        <div className="header-dashes" />
      </div>
      <div className="header-container"></div>
    </div>
  );
};

export default SubtraitGraphHeader;

// Packages
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Components
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";
import PrimaryButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import ReskinNavBar from "../../../components/NavBars/ReskinNavBar";

// Assets
import { ReactComponent as NavProfileCircle } from "../../../../assets/reskin/icons/nav-person-circle.svg";
import { ReactComponent as ParamountIcon } from "../../../../assets/reskin/icons/paramount.svg";
import { ReactComponent as CheckCircleIcon } from "../../../../assets/reskin/icons/check-circle.svg";

// Styles
import "./RecordingCongratulations.scss";

// Actions
import { updateUserAssessmentState } from "../../../../redux/actions";

// Store
import { RootState } from "../../../../redux/reducers";

// Types
import { AssessmentStatusType } from "../../../../models";

const RecordingCongratulations = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state);
  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = useSelector((state: RootState) => state.user);

  const rerecordedAttempt =
    sessionStorage.getItem("rerecordingAttempt") || "false";

  const handleNext = () => {
    sessionStorage.removeItem("rerecordingAttempt");
    if (rerecordedAttempt === "false") {
      navigate("/assessment/lets-begin");
    } else {
      dispatch(
        updateUserAssessmentState({
          userId,
          UserAssessmentStateId,
          statusType: AssessmentStatusType.ASSESSMENT_COMPLETE,
        }),
      );
    }
  };

  return (
    <div className="congratulations-page">
      <AssessmentHeader isTransparent={false} />
      <div className="icon-container">
        <ParamountIcon className="paramount-icon" />
      </div>
      <div className="messages-container">
        <h3 className="congratulations-message">
          <p>{`Way to go, ${user.name}!`}</p>
        </h3>
        <p className="you-completed">You've completed</p>
        <p className="section-name">Effective Communication</p>

        <div className="icon-container check-circle">
          <CheckCircleIcon />
        </div>

        <p className="mt-1">
          <strong>Your recording has been submitted!</strong>
        </p>

        {rerecordedAttempt === "false" && (
          <p style={{ color: "#737582" }}>
            Your effective communication skills will be evaluated in the
            background while you complete the SkillTrack Employability Skills
            activity <br />
            (this should take about 20 minutes).
          </p>
        )}
      </div>
      <div className="button-container">
        <PrimaryButton
          text={
            rerecordedAttempt === "false"
              ? "Next: Employability Skills"
              : "Next: Insights Report"
          }
          onClick={() => handleNext()}
          customClasses="next-nav-button"
        />
      </div>
    </div>
  );
};

export default RecordingCongratulations;

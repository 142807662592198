// Components
import MenuItem from "./MenuItem/MenuItem";
import SubMenu from "./SubMenu/SubMenu";
import { useRef } from "react";

// Styles
import "./ReskinMenu.scss";

export interface MenuItemPropType {
  Icon?: JSX.Element;
  onClick: Function;
  label: string;
  textColor?: string;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  tabIndex: number;
  tabOrderRef?: any;
}

export interface SubMenuPropType {
  Icon?: JSX.Element;
  onClick: Function;
  label: string;
  textColor?: string;
  isLastItem?: boolean;
  subMenuItems: MenuItemPropType[];
}

interface ReskinMenuProps {
  menuItems: any[];
}

const ReskinMenu = ({ menuItems }: ReskinMenuProps) => {
  const firstItemRef = useRef<HTMLInputElement>(null);
  const lastItemRef = useRef<HTMLInputElement>(null);

  const MenuContent = menuItems.map((menuItem, index) => {
    const { subMenuItems, isFirstItem, isLastItem } = menuItem;
    let tabOrderRef;

    if (isFirstItem) {
      tabOrderRef = firstItemRef;
    } else if (isLastItem) {
      tabOrderRef = lastItemRef;
    }

    if (subMenuItems) {
      return (
        <SubMenu
          {...menuItem}
          tabOrderRef={tabOrderRef}
          key={`reskin-submenu-${index}`}
        />
      );
    }

    return (
      <MenuItem
        tabIndex={0}
        {...menuItem}
        tabOrderRef={tabOrderRef}
        key={`reskin-menu-item-${index}`}
      />
    );
  });

  return (
    <div className="reskin-menu">
      <span
        className="first-focus-handler"
        tabIndex={0}
        onFocus={() => {
          {
            /* 
                // @ts-ignore */
          }
          lastItemRef?.current?.focus();
        }}
      />
      {MenuContent}
      <span
        className="last-focus-handler"
        tabIndex={0}
        onFocus={() => {
          {
            /* 
                // @ts-ignore */
          }
          firstItemRef?.current?.focus();
        }}
      />
    </div>
  );
};

export default ReskinMenu;

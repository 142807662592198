/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function BulletedMessage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="12px"
      direction="row"
      width="338px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "BulletedMessage")}
      {...rest}
    >
      <View
        width="8px"
        height="unset"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 26931")}
      >
        <Icon
          width="8px"
          height="8px"
          viewBox={{ minX: 0, minY: 0, width: 8, height: 8 }}
          paths={[
            {
              d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
              fill: "rgba(93,129,55,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="11px"
          left="0px"
          {...getOverrideProps(overrides, "BulletPoint")}
        ></Icon>
      </View>
      <Text
        fontFamily="Open Sans"
        fontSize="16px"
        fontWeight="400"
        color="rgba(53,60,62,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="318px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="You can change your responses on the screen but once you click continue they are locked (you cannot go back) .​&#xD;"
        {...getOverrideProps(overrides, "Text")}
      ></Text>
    </Flex>
  );
}

// Packages
import { useNavigate } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";

// Assets
import { ReactComponent as CircleCaretRight } from "../../../../assets/icons/circle-caret-right.svg";
import { ReactComponent as YellowDot } from "../../../../assets/report/inbox/yellow-dot.svg";

// Graphql
import { updateInstitutionResponse } from "../../../../graphql/mutations";
import createOrUpdate from "../../../../components/dynamicqueries";

interface MessageTabProps {
  index: any;
  message: any;
  updatefilteredResponses: any;
}

const MessageTab = ({
  index,
  message,
  updatefilteredResponses,
}: MessageTabProps) => {
  const navigate = useNavigate();
  const { institutionUser, response, createdAt, responseRead } = message;
  const profilePictureUrl =
    require("../../../../assets/fullsummary/user-avatar.png").default;

  const ProfilePicture = (
    <img
      src={profilePictureUrl}
      className="profile-picture"
      alt="Institution"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          require("../../../../assets/fullsummary/user-avatar.png").default;
      }}
    />
  );

  const renderCreatedDate = new Intl.DateTimeFormat("en-US").format(
    new Date(createdAt),
  );

  const handleClick = async () => {
    if (!message.responseRead) {
      // mark message as read
      const input: any = {
        id: message.id,
        responseRead: true,
        _version: message._version,
      };

      const updateData = await createOrUpdate(updateInstitutionResponse, input);
      updatefilteredResponses(index);
    }

    navigate(`/report/support/inbox/message`, { state: message });
  };

  return (
    <button className="message-tab d-flex" onClick={() => handleClick()}>
      <div className="profile-picture-container">{ProfilePicture}</div>
      <div className="unread-indicator-container">
        {!responseRead && <YellowDot />}
      </div>
      <div className="message-text-container">
        <p className="date">{renderCreatedDate}</p>
        <strong className="subject">{`Message from ${institutionUser.name}`}</strong>
        <LinesEllipsis text={response} maxLine="1" />
      </div>
      <div className="icon-container d-flex align-items-center justify-content-center">
        <CircleCaretRight />
      </div>
    </button>
  );
};

export default MessageTab;

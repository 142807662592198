/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import BulletedMessage from "../../ui-components/BulletedMessage";
import ReskinButton from "./Buttons/ReskinButton/ReskinButton";
import { updateUserAssessmentState } from "../../redux/actions";
import { AssessmentStatusType } from "../../models";
import { RootState } from "../../redux/reducers";

export default function HelpfulTips(props) {
  const { overrides, ...rest } = props;
  const dispatch = useDispatch();
  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = useSelector((state) => state.user);

  const handleContinue = () => {
    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.SAMPLE_INSTRUCTION_COMPLETE,
      }),
    );
  };

  return (
    <View
      width="414px"
      height="736px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "HelpfulTips")}
      {...rest}
    >
      <View
        width="892px"
        height="1114px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="-528px"
        left="1px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "image 12")}
      ></View>
      <Icon
        width="284px"
        height="28.49px"
        viewBox={{ minX: 0, minY: 0, width: 284, height: 28.4903564453125 }}
        paths={[]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="5.16%"
        bottom="90.97%"
        left="72.46%"
        right="-41.06%"
        transformOrigin="top left"
        transform="rotate(-180deg)"
        {...getOverrideProps(overrides, "Vector41191")}
      ></Icon>
      <View {...getOverrideProps(overrides, "Header")}></View>
      <Flex
        gap="10px"
        direction="row"
        width="318px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="607px"
        left="48px"
        borderRadius="48px"
        padding="16px 10px 16px 5px"
      >
        <ReskinButton
          onClick={handleContinue}
          text={"Next: Start with Question 1 "}
        />
      </Flex>

      <Text
        fontFamily="Open Sans"
        fontSize="24px"
        fontWeight="600"
        color="rgba(57,127,147,1)"
        lineHeight="32px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="288px"
        height="30.39px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="2%"
        bottom="69.22%"
        left="7%"
        right="5.22%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Helpful Tips"
        {...getOverrideProps(overrides, "Helpful Tips")}
      ></Text>
      <View
        padding="0px 0px 0px 0px"
        width="180px"
        height="17.09px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="43.82px"
        left="23px"
        {...getOverrideProps(overrides, "Group 513823")}
      >
        <br />
        <BulletedMessage
          overrides={{
            Text: {
              children: "There are no right or wrong answers",
            },
          }}
        ></BulletedMessage>
        <br />
        <BulletedMessage
          overrides={{
            Text: {
              children:
                "The more honest you are the more personalized your development insights will be",
            },
          }}
        ></BulletedMessage>
        <br />
        <BulletedMessage
          overrides={{
            Text: {
              children:
                "You can change your responses on the screen but once you click continue they are locked (you cannot go back) .​",
            },
          }}
        ></BulletedMessage>
        <br />
        <BulletedMessage
          overrides={{
            Text: {
              children:
                "There is no time limit but the activity will time out after a few minutes​",
            },
          }}
        ></BulletedMessage>
        <br />
        <BulletedMessage
          overrides={{
            Text: {
              children:
                "If you time out or you want to come back later any screens you have completed will be saved and you’ll pick up where you left off​.",
            },
          }}
        ></BulletedMessage>
        <br />
        <BulletedMessage
          overrides={{
            Text: {
              children:
                "If it is difficult to determine which statement is most like you, just make your best choice ",
            },
          }}
        ></BulletedMessage>
      </View>
    </View>
  );
}

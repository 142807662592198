/**
 * ucWords.
 *
 * Uppercase the first letter of every word in a string.
 *
 * @str original string to be converted
 * @returns formatted string
 */
export function ucWords(str: string) {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
}

export function removeWhiteSpace(str: string) {
  return str.replace(/\s+/g, "");
}

const defaults = {
  ucWords,
  removeWhiteSpace,
};

export default defaults;

// Styles
import "./ReskinNavBar.scss";

interface NavBarProps {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  headerText?: string;
  isSticky?: boolean;
  customClasses?: string;
}

const NavBar = ({
  leftIcon,
  rightIcon,
  headerText,
  isSticky,
  customClasses,
}: NavBarProps) => {
  const stickyClass = isSticky ? "is-sticky" : "";

  const RenderedHeader = headerText ? <h1>{headerText}</h1> : null;

  return (
    <div className={`reskin-nav-bar-container ${stickyClass} ${customClasses}`}>
      <div className="left-icon-container">{leftIcon ? leftIcon : null}</div>
      <div className="header-text-container">{RenderedHeader}</div>
      <div className="right-icon-container">{rightIcon ? rightIcon : null}</div>
    </div>
  );
};

export default NavBar;

import { useLocation } from "react-router-dom";
import SubtraitGraphHeader from "./SubtraitGraphHeader";
import Tooltip from "@mui/material/Tooltip";
import { Fragment } from "react";

function SubTraitGraph({ traitScore }: any) {
  const location = useLocation();

  const determineScoreName = (value: number) => {
    switch (value) {
      case 0:
        return "developing";
      case 1:
        return "approaching";
      case 2:
        return "demonstrating";
    }
  };

  const trait = () => {
    const split = location.pathname.split("/");
    const trait = split.pop() || split.pop();
    const cleanTrait = trait?.replace(/%20/g, "-");

    return cleanTrait;
  };

  const renderBars = () => {
    return traitScore.data.map((subtrait: any, index: number) => {
      return (
        <Fragment key={index}>
          <span className="hidden">{`${subtrait.dimension} ${determineScoreName(subtrait.value)}`}</span>
          <div className="subtrait secondary" aria-hidden={true}>
            <Tooltip
              title={subtrait.dimension}
              placement="bottom-end"
              style={{ zIndex: "1000" }}
            >
              <div className="subtrait-name">{subtrait.dimension}</div>
            </Tooltip>
            <div
              className={`subtrait-value  ${subtrait.value >= 0 ? "filled" : ""} ${trait()} ${determineScoreName(subtrait.value)}`}
            >
              <div className="bar" />
            </div>
            <div
              className={`subtrait-value ${subtrait.value >= 1 ? "filled" : ""} ${trait()} ${determineScoreName(subtrait.value)}`}
            >
              <div className="bar" />
            </div>
            <div
              className={`subtrait-value ${subtrait.value >= 2 ? "filled" : ""} ${trait()} ${determineScoreName(subtrait.value)}`}
            >
              <div className="bar" />
            </div>
            <div className="subtrait-value "></div>
          </div>
        </Fragment>
      );
    });
  };

  return (
    <div className="graphContainer">
      <div className="subtraitGraph">
        <SubtraitGraphHeader />
        <div className="subtrait master">
          <div className="subtrait-name"></div>

          <div className="subtrait-value dashed-right"></div>
          <div className="subtrait-value dashed-right"></div>
          <div className="subtrait-value dashed-right"></div>
          <div className="subtrait-value"></div>
        </div>
        {renderBars()}
      </div>
    </div>

    // <div className="graphContainer">
    //   <div className="subtraitGraph">
    //     <SubtraitGraphHeader />
    //     <div className="subtrait master">
    //       <div className="subtrait-name"></div>
    //       <div className="subtrait-value dashed-right"></div>
    //       <div className="subtrait-value dashed-right"></div>
    //       <div className="subtrait-value dashed-right"></div>
    //       <div className="subtrait-value"></div>
    //     </div>

    //     {renderBars()}
    //   </div>
    // </div>
  );
}

export default SubTraitGraph;

// Packages
import React from "react";
import { useDispatch } from "react-redux";
import { API, Auth } from "aws-amplify";

// Components
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";
import SimpleBackdrop from "../../../ui-components/SimpleBackdrop";
import { ReactComponent as AlertIconRed } from "../../../assets/re-skin/AlertIconRed.svg";
import { ReactComponent as Eye } from "../../../assets/eye.svg";
import { ReactComponent as EyeSlash } from "../../../assets/eyeSlash.svg";

// Actions
import { logInUser } from "../../../redux/actions";

// Styles
import "./SignIn.scss";
import "@aws-amplify/ui-react/styles.css";
import { Form, Button } from "react-bootstrap";

// Data
import * as customQueries from "../../../graphql/custom-queries";
import { updateUser } from "../../../graphql/mutations";
// Types
import { formDataType } from "./types";
import { Usertype } from "../../../models";

// Services
import UserService from "../../../services/User/user.service";
import EventService from "../../../services/EventService/event.service";

function SignIn({ changeAuthState, userType }: any) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<formDataType>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<any>({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleInputChange = (e: any) => {
    setValidated(false);
    setErrors({ email: "", password: "" });
    const { value, dataset } = e.target;
    const { prop } = dataset;
    setFormData({
      ...formData,
      [prop]: value,
    });
  };

  const checkValidity = () => {
    let errors = 0,
      email = "",
      password = "";

    if (formData.email === "") {
      email = "Please enter a valid email address.";
      errors++;
    }

    if (formData.password === "") {
      password = "Password cannot be empty";
      errors++;
    }

    if (errors > 0) {
      setErrors({ email, password });
      return false;
    }

    return true;
  };

  const handlePasswordKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSignIn(event);
    }
  };

  const handleSignIn = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);

    // setValidated(true);
    setErrors({ email: "", password: "" });

    if (checkValidity() === true) {
      // form is valid
      try {
        const user = await Auth.signIn(formData.email, formData.password);
        const { sub: userId, email } = user.attributes;
        const dsUser = await UserService.getDSUser(userId);

        const result = await API.graphql({
          query: updateUser,
          variables: {
            input: {
              userId: userId,
              lastLogin: new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              }),
              userType: userType,
              _version: dsUser._version,
            },
          },
        });

        if (!dsUser) throw new Error("DSUserNotFound: User not found.");

        if (!UserService.canUserProceed(dsUser, userType)) {
          setErrors({
            email: "Not a valid account type.",
            password: "",
          });
          setIsLoading(false);
          await Auth.signOut();
          return false;
        }

        // get user group
        let loggedInUserGroup = null;
        if (user.signInUserSession.idToken.payload["cognito:groups"]) {
          const groups =
            user.signInUserSession.idToken.payload["cognito:groups"];

          if (groups && groups.length > 0) {
            loggedInUserGroup = groups[0];
          }
        }

        if (!loggedInUserGroup) {
          loggedInUserGroup = userType;
        }

        // identify the user with our analytics
        EventService.identify(dsUser.userId, {
          assessmentId: dsUser.assessmentState?.assessmentId,
          sessionId: dsUser.assessmentState?.sessionId,
          userGroup: loggedInUserGroup,
        });

        window.pendo.initialize({
          visitor: {
            id: dsUser.userId,
          },
        });

        dispatch(logInUser(dsUser, email, userType));
      } catch (error: unknown) {
        console.error(error);
        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];

        EventService.track({
          event_type: "user",
          event_name: "user_login_attempt",
          user_type:
            userType === Usertype.INSTITUTION ? "institution" : "student",
          event_data: {
            email: formData.email,
            error: customError.toString(),
          },
        });

        switch (errorHandling) {
          case "NotAuthorizedException":
            setErrors({ email: "Incorrect email or password." });
            break;

          case "UserNotFoundException":
            setErrors({
              email: "An account does not exist for this email.",
              password: "",
            });
            break;

          case "DSUserInvalidType":
            setErrors({
              email: "Not a valid account type.",
              password: "",
            });
            break;

          case "UserNotConfirmedException":
            // store locally needed for  verification
            sessionStorage.setItem("email", formData.email);

            // need to get the userId
            let filterExpression = { email: { eq: formData.email } };

            try {
              const response = await API.graphql({
                query: customQueries.searchUsersByEmail,
                variables: { filter: filterExpression },
              });

              const responseObject = JSON.parse(JSON.stringify(response));

              sessionStorage.setItem(
                "userId",
                responseObject.data.searchUsers.items[0].userId,
              );

              await Auth.resendSignUp(formData.email);
              changeAuthState({ screen: "Verify" });
              setIsLoading(false);
            } catch (err) {
              console.log("error resending code: ", err);
              setErrors({
                email: "Unconfirmed account, try again later.",
                password: "",
              });
            }

            break;

          default:
            console.log(customError);
            setErrors({ email: "An error occured, try again.", password: "" });
            break;
        }

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="reskin-sign-in-container">
      <div className="ImageContainer">
        <div className="TopContainer">
          <div className="text-container header-container">
            <p className="Header m-0">SkillTrack</p>
          </div>
          {/*<div className='SubTitle'>*/}
          <div className="text-container subtext-container">
            <p className="SubTitleText m-0">
              SkillTrack will help you identify your strengths in employability
              skills.
            </p>
          </div>
          {/*</div>*/}
        </div>
        <div className="BottomContainer">
          <p className="boldText">Sign In</p>
          <div className="InputContainer">
            <section className="middle flex-grow-1">
              <Form
                className="form"
                noValidate
                validated={validated}
                onSubmit={(e: any) => handleSignIn(e)}
              >
                <Form.Group className="mb-4 item" controlId="formBasicEmail">
                  <div className="form-section-container">
                    <Form.Label>
                      <span className="TextLabel">Email</span> &nbsp;
                      <span style={{ color: "#E0021B" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="TextInputContainer"
                      type="email"
                      placeholder=""
                      data-prop={"email"}
                      onChange={handleInputChange}
                      required
                      isInvalid={errors.email !== ""}
                      tabIndex={1}
                    />
                    <Form.Control.Feedback
                      className="login-input"
                      type="invalid"
                    >
                      <AlertIconRed />
                      <span className="error-text">{errors.email}</span>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group className="mb-4 item" controlId="formBasicPassword">
                  <div className="form-section-container">
                    <Form.Label className="d-flex align-items-end">
                      <span className="TextLabel">Password</span> &nbsp;
                      <span style={{ color: "#E0021B" }}>*</span>
                      <span className="forgetPassword">
                        <button
                          className="forgot link-as-btn"
                          tabIndex={4}
                          onClick={() =>
                            changeAuthState({ screen: "ForgotPassword" })
                          }
                        >
                          Forgot Password?
                        </button>
                      </span>
                    </Form.Label>

                    <Form.Control
                      className="TextInputContainer"
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      data-prop={"password"}
                      onChange={handleInputChange}
                      onKeyDown={handlePasswordKeyDown}
                      required
                      isInvalid={errors.password !== ""}
                      tabIndex={2}
                    />
                    <Button
                      className="password-eye"
                      variant="white"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeSlash /> : <Eye />}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      <AlertIconRed />
                      <span className="error-text">{errors.password}</span>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3 submission item"
                  controlId="formSubmit"
                >
                  <div className="controls-container d-flex align-items-center justify-content-center mt-5 ml-2">
                    <ReskinButton text="Sign In" tabIndex={3} type="submit" />
                  </div>
                </Form.Group>
                <span className="create">
                  No account?&nbsp;
                  <button
                    className="link-as-btn btn-color"
                    onClick={(e: any) => {
                      e.preventDefault();
                      changeAuthState({ screen: "SignUp" });
                    }}
                  >
                    Sign Up
                  </button>
                  {isLoading ? <SimpleBackdrop open={true} /> : null}
                </span>
              </Form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;

// Components
import NoBorderIconButton from "../../Buttons/NoBorderIconButton/NoBorderIconButton";
import { Modal } from "react-bootstrap";

// Assets
import { ReactComponent as Close } from "../../../../assets/reskin/icons/charcoal-close-icon.svg";

// Styles
import "./TwoButtonModal.scss";

interface TwoButtonModalProps {
  LeftButton: JSX.Element;
  RightButton: JSX.Element;
  onClose: Function;
  headerText?: string;
  bodyContent: JSX.Element;
  customClasses?: string;
  show: boolean;
}

const TwoButtonModal = ({
  LeftButton,
  RightButton,
  onClose,
  headerText,
  bodyContent,
  customClasses,
  show,
}: TwoButtonModalProps) => {
  const CloseIcon = <Close />;

  return (
    <Modal show={show} dialogClassName="two-button-modal" centered>
      <section className={`modal-header-container ${customClasses}`}>
        <div className="title-container">
          <div>
            <h3 className="header-text pt-1">{headerText || ""}</h3>
          </div>
          <NoBorderIconButton icon={CloseIcon} onClick={() => onClose()} />
        </div>
      </section>
      <section className="modal-body">
        <div className="body-container">{bodyContent}</div>

        <div className="button-container">
          {LeftButton}
          {RightButton}
        </div>
      </section>
    </Modal>
  );
};

export default TwoButtonModal;

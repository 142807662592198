const CardBodyContent = ({ narrative }: any) => {
  return (
    <div style={{ marginTop: "10px" }}>
      <div className="d-flex">
        <div className="col-2">
          <div className="feedback-list-bar"></div>
        </div>
        <div className="col-10">
          <div style={{ marginLeft: "-10px" }}>
            <ul className="feedback-list">
              {narrative.improvementNarrative.map(
                (improvement: string, index: number) => {
                  return (
                    <div>
                      <li>
                        <span>{improvement}</span>
                      </li>
                    </div>
                  );
                },
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBodyContent;

function OverallInsights({ narratives, bands, requestedSupportCount }: any) {
  const hasNarrativeValues = () => {
    const hasValue = Object.values(narratives).filter((val) => {
      return val !== "";
    });

    if (hasValue.length === Object.values(narratives).length) return true;
    return false;
  };

  const determinePrefix = (trait: string, band: string) => {
    const score = bands[band];

    return `${score} ${trait};`;
  };

  const determineSupportRequests = () => {
    return `${requestedSupportCount} ${
      requestedSupportCount === 1 ? "student has" : "students have"
    }`;
  };

  return (
    <div className="welcome-overall-insights">
      <h2>Overall Insights</h2>
      <p>
        SkillTrack has evaluated students on four categories that employers
        often look for when making hiring decisions. This report will break down
        these insights and guide you in supporting the career success of your
        students. As an overview, based on the students who have completed
        SkillTrack so far, the entering class is made up of students who are:
      </p>

      {hasNarrativeValues() && (
        <ul>
          <li>
            {determinePrefix("leadership", "leadershipBand")}{" "}
            {narratives.leadershipNarrative.toLowerCase()}
          </li>
          <li>
            {determinePrefix("perseverance", "perseveranceBand")}{" "}
            {narratives.perserveranceNarrative.toLowerCase()}
          </li>
          <li>
            {determinePrefix("innovative thinking", "innovativeThinkingBand")}{" "}
            {narratives.innovativeThinkingNarrative.toLowerCase()}
          </li>
          <li>
            {determinePrefix("effective communication", "communicationBand")}{" "}
            {narratives.communicationNarrative.toLowerCase()}
          </li>
          <li>{determineSupportRequests()} requested support.</li>
        </ul>
      )}

      <p>
        This report will dynamically update as more students complete
        SkillTrack!
      </p>
    </div>
  );
}

export default OverallInsights;

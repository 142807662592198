export const isUserSpecifiedOption = (option: string) => {
  let result = false;

  if (
    option.toLowerCase().includes("prefer to self-describe") ||
    option.slice(0, 5).toLowerCase().includes("other")
  ) {
    result = true;
  }

  return result;
};

export const doesArrHaveUserSpecifiedOption = (optionsArr: string[]) => {
  return optionsArr;
};

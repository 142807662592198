// Packages
import React from "react";

// Components
import Tips from "./Tips";
import NoBorderButton from "../../../../components/Buttons/NoBorderButton/NoBorderButton";

// Assets
import { ReactComponent as InfoCircle } from "../../../../../assets/reskin/icons/green-info-circle.svg";

// Styles
import "./TipsControls.scss";

const TipsControls = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const InfoCircleIcon = <InfoCircle />;

  const ModalToggler2 = (
    <span className="tips-toggler" onClick={() => setIsModalOpen(true)}>
      <div className="toggler-icon-container">{InfoCircleIcon} </div>
      <p className="toggler-text">Recording Tips</p>
    </span>
  );

  return (
    <div className="tips-control-block">
      <div className="toggler-container">{ModalToggler2}</div>
      <Tips show={isModalOpen} setShow={setIsModalOpen} />
    </div>
  );
};

export default TipsControls;

// Packages
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

// Redux
import { RootState } from "../../../redux/reducers";

// Components
import Checkbox from "../../components/Checkboxes/Checkbox";
import { ReactComponent as FilterIcon } from "../../../assets/re-skin/FilterIcon.svg";
import { ReactComponent as ArrowDown } from "../../../assets/re-skin/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../assets/re-skin/arrowUp.svg";
import { ReactComponent as FavoriteIcon } from "../../../assets/re-skin/FavoriteIcon.svg";
import ResourceCard from "../../components/Cards/ResourceCard/ResourceCard";

// Utils
import { ResourceType } from "../../../screens/report/support/single-trait-support/ResourceCarousel/ResourceCardsData";
import useResourceApi from "../../../hooks/useResourceAPI";

// Styles
import "./MyFavorites.scss";
import FilterBadge from "../../components/Badges/FilterBadge/FilterBadge";

interface FavoriteCardType {
  id: string;
  userId: string;
  resourceLink: string;
  trait: string;
  assessmentId: string;
  userSessionId: string;
  learningResource: ResourceType;
  learningResourceId: string;
  createdAt: string;
  updatedAt: string;
  _deleted: boolean;
}

function MyFavorites() {
  let allTraits = [
    "Leadership",
    "Effective Communication",
    "Perseverance",
    "Innovative Thinking",
  ];
  let traits = [
    "All",
    "Leadership",
    "Effective Communication",
    "Perseverance",
    "Innovative Thinking",
  ];
  const resourceServices = useResourceApi();
  const [SelectedTab, setSelectedTab] = React.useState<any>("all");
  const [Loading, setLoading] = React.useState<any>(true);
  const [filter, openFilter] = React.useState<any>(false);
  const [leadershipList, setLeadershipList] = React.useState<
    FavoriteCardType[]
  >([]);
  const [effectiveCommunicationList, setEffectiveCommunicationList] =
    React.useState<FavoriteCardType[]>([]);
  const [perseveranceList, setPerseveranceList] = React.useState<
    FavoriteCardType[]
  >([]);
  const [innovativeThinkingList, setInnovativeThinkingList] = React.useState<
    FavoriteCardType[]
  >([]);
  const [FavoriteResources, setFavoriteResources] = React.useState<
    FavoriteCardType[]
  >([]);
  const [selectedTraits, setSelectedTraits] = React.useState<any[]>(traits);
  let navigate = useNavigate();

  const {
    userId,
    assessmentState: { sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);

  const goBack = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    getFavorites(SelectedTab);
    openFilter(false);
  }, [SelectedTab, selectedTraits]);

  const getFavorites = async (selectedTraitName: any) => {
    const resourcesAndItsConnections =
      await resourceServices.getLearningResourcesWithAllTheConnections(
        selectedTraitName,
        userId,
        sessionId,
        assessmentId,
      );
    const consolidatedResourceCardInfo =
      await resourceServices.consolidatedEachResourceCardInformation(
        resourcesAndItsConnections,
      );
    const FavoritesList = consolidatedResourceCardInfo.filter(
      (resourceCard: any) => resourceCard.isFavorited === "FAVORITED",
    );
    setFavoriteResources(FavoritesList);
    const leadershipList = selectedTraits.includes("Leadership")
      ? FavoritesList.filter((x: any) => x.trait === "leadership")
      : null;
    const effectiveCommunicationList = selectedTraits.includes(
      "Effective Communication",
    )
      ? FavoritesList.filter((x: any) => x.trait === "effective communication")
      : null;
    const perseveranceList = selectedTraits.includes("Perseverance")
      ? FavoritesList.filter((x: any) => x.trait === "perseverance")
      : null;
    const innovativeThinkingList = selectedTraits.includes(
      "Innovative Thinking",
    )
      ? FavoritesList.filter((x: any) => x.trait === "innovative thinking")
      : null;
    setLeadershipList(leadershipList);
    setEffectiveCommunicationList(effectiveCommunicationList);
    setPerseveranceList(perseveranceList);
    setInnovativeThinkingList(innovativeThinkingList);
    setLoading(false);
  };

  const handleThumbnailClick = (e: any, resource: any) => {
    // Refer to navigation logic in resourceCarousel to see required fields for resourceCards screen. -D
    if (resource.type === "Cards")
      navigate("/report/resourceCards", {
        state: {
          parentTitle: resource.title,
          cards: resource.resourceCards,
          resourceTrait: resource.trait,
          userId: userId,
          assessId: assessmentId,
          sessionId: sessionId,
          cardId: resource.learningResourceId,
          isFavorite: false,
        },
      });
  };

  const goToResourceHub = () => navigate("/report/support");

  const handleCheckboxClicked = (traitName: string) => {
    const selectedTraitIndex = selectedTraits.indexOf(traitName);
    if (selectedTraitIndex > -1) {
      const updatedTraits = selectedTraits.filter(
        (traitStr: string) => traitStr !== traitName,
      );
      setSelectedTraits(updatedTraits);
    } else {
      let updatedTraits;
      if (traitName === "All") {
        updatedTraits = allTraits;
      } else {
        updatedTraits = [...selectedTraits, traitName];
      }
      setSelectedTraits(updatedTraits);
    }
  };

  const handleFavoritesClick = async (resource: any) => {
    const { learningResourceId: resourceCardId, trait: resourceCardtrait } =
      resource;
    const isSaved = false;
    await resourceServices.updateFavs(
      isSaved,
      resourceCardId,
      resourceCardtrait,
      userId,
      sessionId,
      assessmentId,
    );
    await unSave(resource);
  };

  const unSave = async (resource: any) => {
    await getFavorites(SelectedTab);
  };

  const handleReactionClick = async (
    reactionStatus: string,
    resourceCardId: string,
    resourceCardtrait: string,
  ) => {
    await resourceServices.createOrRemoveReactions(
      reactionStatus,
      resourceCardId,
      resourceCardtrait,
      userId,
      sessionId,
      assessmentId,
    );
  };

  const bottomContainer = () => {
    return (
      <div className="bottomContainer">
        <p className="headerText">Explore More</p>
        <p className="subHeader">
          Keep going! Take advantage of more learning opportunities in the
          Resource Hub.
        </p>
        <Button
          bsPrefix="psq-btn"
          variant="reskin-primary"
          className="btn-signin primary mt-3"
          onClick={goToResourceHub}
          tabIndex={2}
        >
          Resource Hub
        </Button>
      </div>
    );
  };

  const handleFilter = () => {
    openFilter(!filter);
  };

  const dropdownFilter = () => {
    return (
      <div className="top-container">
        <div className="title">My Favorites</div>
        <div className="filterContainer">
          <div className="filter-title">
            <FilterIcon />
            Filter By Category
            <Button onClick={handleFilter} className="arrow-icon">
              {filter ? <ArrowUp /> : <ArrowDown />}
            </Button>
          </div>
          {filter && (
            <div className="category-container">
              {traits.map((item, index) => {
                return (
                  <Checkbox
                    label={item}
                    onClick={() => handleCheckboxClicked(item)}
                    key={index}
                    checked={selectedTraits.includes(item)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  const EmptyFavorites = () => {
    return (
      <div className="bodyContainer">
        <FavoriteIcon />
        <p className="Icon-text">
          You haven’t saved any learning resources yet! Visit the{" "}
          <a
            href="/report/support"
            style={{ color: "#397F93", fontWeight: "600" }}
          >
            Resource Hub
          </a>{" "}
          to explore what’s available.
        </p>
      </div>
    );
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderedLeadershipCards =
    leadershipList === null ? null : (
      <>
        <div className="title">{capitalizeFirstLetter("Leadership")}</div>
        {leadershipList.length === 0 && !Loading ? (
          <EmptyFavorites />
        ) : (
          <div>
            <ul className="favorite-cards-list">
              {leadershipList.map((resourceCard: any, idx: any) => (
                <ResourceCard
                  key={`thumbnail-card-${idx}`}
                  resourceLink={resourceCard["resourceLink"]}
                  imageLocation={resourceCard["imageLocation"]}
                  duration={resourceCard["duration"]}
                  type={resourceCard["type"]}
                  trait={resourceCard["trait"]}
                  title={resourceCard["title"]}
                  credit={resourceCard["credit"]}
                  resourceCardId={resourceCard["learningResourceId"]}
                  resourceCardtrait={resourceCard["trait"]}
                  sourcePage="MyFavorites"
                  isLandingPage={false}
                  handleThumbnailClick={(e: any) => {
                    handleThumbnailClick(e, resourceCard);
                  }}
                  isFavorited={resourceCard["isFavorited"]}
                  onSaveClick={() => {
                    handleFavoritesClick(resourceCard);
                  }}
                  reactionStatus={resourceCard["isReacted"]}
                  onReactionsClick={(
                    reactionStatus: any,
                    resourceCardId: any,
                    resourceCardtrait: any,
                  ) => {
                    handleReactionClick(
                      reactionStatus,
                      resourceCardId,
                      resourceCardtrait,
                    );
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </>
    );

  const renderedEffectiveCommunicationCards =
    effectiveCommunicationList === null ? null : (
      <>
        <div className="title">
          {capitalizeFirstLetter("Effective Communication")}
        </div>
        {effectiveCommunicationList !== null &&
        effectiveCommunicationList.length === 0 &&
        !Loading ? (
          <EmptyFavorites />
        ) : (
          <div>
            <ul className="favorite-cards-list">
              {effectiveCommunicationList.map((resourceCard: any, idx: any) => (
                <ResourceCard
                  key={`thumbnail-card-${idx}`}
                  resourceLink={resourceCard["resourceLink"]}
                  imageLocation={resourceCard["imageLocation"]}
                  duration={resourceCard["duration"]}
                  type={resourceCard["type"]}
                  trait={resourceCard["trait"]}
                  title={resourceCard["title"]}
                  credit={resourceCard["credit"]}
                  resourceCardId={resourceCard["learningResourceId"]}
                  resourceCardtrait={resourceCard["trait"]}
                  sourcePage="MyFavorites"
                  isLandingPage={false}
                  handleThumbnailClick={(e: any) => {
                    handleThumbnailClick(e, resourceCard);
                  }}
                  onSaveClick={() => {
                    handleFavoritesClick(resourceCard);
                  }}
                  isFavorited={resourceCard["isFavorited"]}
                  reactionStatus={resourceCard["isReacted"]}
                  onReactionsClick={(
                    reactionStatus: any,
                    resourceCardId: any,
                    resourceCardtrait: any,
                  ) => {
                    handleReactionClick(
                      reactionStatus,
                      resourceCardId,
                      resourceCardtrait,
                    );
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </>
    );

  const renderedPerseveranceCards =
    perseveranceList === null ? null : (
      <>
        <div className="title">{capitalizeFirstLetter("Perseverance")}</div>
        {perseveranceList !== null &&
        perseveranceList.length === 0 &&
        !Loading ? (
          <EmptyFavorites />
        ) : (
          <div>
            <ul className="favorite-cards-list">
              {perseveranceList.map((resourceCard: any, idx: any) => (
                <ResourceCard
                  key={`thumbnail-card-${idx}`}
                  resourceLink={resourceCard["resourceLink"]}
                  imageLocation={resourceCard["imageLocation"]}
                  duration={resourceCard["duration"]}
                  type={resourceCard["type"]}
                  trait={resourceCard["trait"]}
                  title={resourceCard["title"]}
                  credit={resourceCard["credit"]}
                  resourceCardId={resourceCard["learningResourceId"]}
                  resourceCardtrait={resourceCard["trait"]}
                  sourcePage="MyFavorites"
                  isLandingPage={false}
                  handleThumbnailClick={(e: any) => {
                    handleThumbnailClick(e, resourceCard);
                  }}
                  onSaveClick={() => {
                    handleFavoritesClick(resourceCard);
                  }}
                  isFavorited={resourceCard["isFavorited"]}
                  reactionStatus={resourceCard["isReacted"]}
                  onReactionsClick={(
                    reactionStatus: any,
                    resourceCardId: any,
                    resourceCardtrait: any,
                  ) => {
                    handleReactionClick(
                      reactionStatus,
                      resourceCardId,
                      resourceCardtrait,
                    );
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </>
    );

  const renderedInnovativeThinkingCards =
    innovativeThinkingList === null ? null : (
      <>
        <div className="title">
          {capitalizeFirstLetter("Innovative Thinking")}
        </div>
        {innovativeThinkingList !== null &&
        innovativeThinkingList.length === 0 &&
        !Loading ? (
          <EmptyFavorites />
        ) : (
          <div>
            <ul className="favorite-cards-list">
              {innovativeThinkingList.map((resourceCard: any, idx: any) => (
                <ResourceCard
                  key={`thumbnail-card-${idx}`}
                  resourceLink={resourceCard["resourceLink"]}
                  imageLocation={resourceCard["imageLocation"]}
                  duration={resourceCard["duration"]}
                  type={resourceCard["type"]}
                  trait={resourceCard["trait"]}
                  title={resourceCard["title"]}
                  credit={resourceCard["credit"]}
                  resourceCardId={resourceCard["learningResourceId"]}
                  resourceCardtrait={resourceCard["trait"]}
                  sourcePage="MyFavorites"
                  isLandingPage={false}
                  handleThumbnailClick={(e: any) => {
                    handleThumbnailClick(e, resourceCard);
                  }}
                  onSaveClick={() => {
                    handleFavoritesClick(resourceCard);
                  }}
                  isFavorited={resourceCard["isFavorited"]}
                  reactionStatus={resourceCard["isReacted"]}
                  onReactionsClick={(
                    reactionStatus: any,
                    resourceCardId: any,
                    resourceCardtrait: any,
                  ) => {
                    handleReactionClick(
                      reactionStatus,
                      resourceCardId,
                      resourceCardtrait,
                    );
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </>
    );

  const renderFilterBadges = () => {
    const renderedBadges = selectedTraits.map((traitName) => {
      return (
        <div className="badgeContainer" key={traitName}>
          <FilterBadge
            label={traitName}
            onClick={() => handleCheckboxClicked(traitName)}
          />
        </div>
      );
    });
    return renderedBadges;
  };

  return (
    <div className="reskin-my-favorites-page">
      {dropdownFilter()}
      {renderFilterBadges()}
      {selectedTraits.length === 0 && (
        <div className="bodyContainer">
          <FavoriteIcon />
          <p className="Icon-text">
            Please Select a Category to view your saved resources.
          </p>
        </div>
      )}

      {Loading && (
        <div className="d-flex justify-content-around">
          <>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </>
        </div>
      )}
      <div>
        {renderedLeadershipCards}
        {renderedEffectiveCommunicationCards}
        {renderedPerseveranceCards}
        {renderedInnovativeThinkingCards}
      </div>
      {bottomContainer()}
    </div>
  );
}

export default MyFavorites;

// Packages
import { useRef } from "react";

// Hooks
import useOnClickOutside from "../../../hooks/useOnClickOutside";

// Images
import { useState } from "react";
import { ReactComponent as SortArrows } from "../../../assets/icons/up-down-arrows.svg";
import { ReactComponent as SortArrow } from "../../../assets/reskin/icons/up-down-arrow.svg";
// Components
import { Dropdown } from "react-bootstrap";

// Styles
import "./_index.scss";

interface MenuItem {
  text: string;
  onClick: Function;
  disabled?: boolean;
}

interface TableHeaderWithHamburgerProps {
  onHamburgerClick: Function;
  size?: string;
  text: string;
  menuItems?: MenuItem[];
  dropdownReadableLabel?: string;
  dropdownId?: string;
  isActive: boolean;
}

export function TableHeaderWithHamburger({
  onHamburgerClick,
  size,
  text,
  menuItems,
  dropdownReadableLabel,
  dropdownId,
  isActive,
}: TableHeaderWithHamburgerProps) {
  const sizeClass = size ? size : "col";

  // Dropdown Menu Management
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsMenuOpen(false));
  const dropdownDisplay = isMenuOpen ? "shown" : "hidden";
  const renderedMenuItems = menuItems
    ? menuItems.map((item, index) => {
        const { text, onClick } = item;
        return (
          <li
            key={`${text}-menu-item-${index}`}
            tabIndex={0}
            onClick={() => onClick()}
          >
            <div className="hamburger-menu-item text-start pt-2 pb-2 ps-2 pe-2 m-0 active">
              {text}
            </div>
          </li>
        );
      })
    : [
        <li>
          <div>No options</div>
        </li>,
      ];

  return (
    <th className={`${sizeClass}  header-cell`} scope="col">
      <div className="hamburger-container p-0  m-0">
        <div className="text-start">{text}</div>
        <div className="flex align-items-center ">
          <button
            className="active shifted unstyled-button"
            onClick={(e) => {
              setIsMenuOpen(!isMenuOpen);
              onHamburgerClick(e);
            }}
            tabIndex={0}
            aria-disabled={!isActive}
            aria-expanded={isMenuOpen}
            aria-label={dropdownReadableLabel}
            aria-owns={dropdownId}
            role="button"
          >
            <SortArrow
              fill="white"
              className="icon active hamburger-dropdown-toggle"
            />
          </button>
        </div>
      </div>
      <div className="hamburger-dropdown-wrapper">
        <ul
          ref={dropdownRef}
          className={`hamburger-dropdown-content p-0 ${dropdownDisplay}`}
          id={dropdownId}
          aria-disabled={!isActive}
          role={"listbox"}
          onMouseLeave={() => setIsMenuOpen(false)}
        >
          {renderedMenuItems}
        </ul>
      </div>
    </th>
  );
}

// Packages
import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../loading";
import SupportRequests from "./SupportRequests"; // *****
import Messages from "./Messages"; // *****
import { getIndividualStudent } from "../../../../graphql/custom-queries";
import { listStudentResources } from "../../../../graphql/queries";
import { getOrList } from "../../../../components/dynamicqueries";

import { DIMENSIONS } from "../../../../enumerations";

//Image
import { ReactComponent as LeadershipImage } from "../../../../assets/fullsummary/Leadership.svg";
import { ReactComponent as PerseveranceImage } from "../../../../assets/fullsummary/Perseverance.svg";
import { ReactComponent as CommunicationImage } from "../../../../assets/fullsummary/Communication.svg";
import { ReactComponent as InnovativeImage } from "../../../../assets/fullsummary/Innovative.svg";

// Services
import EventService from "../../../../services/EventService/event.service";

function Send() {
  const { userSessionId = "" } = useParams();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [candidateId, setCandidateId] = React.useState<string>("");
  const [subTrait, setSubTrait] = React.useState<any>([]);
  const [userScore, setUserScore] = React.useState<any>([]);
  const [studentResources, setStudentResources] = React.useState<any>([]);

  React.useEffect(() => {
    if (userSessionId) {
      getUserScores();
      getStudentResources();
    }

    return () => {
      EventService.track({
        event_type: "user",
        event_name: "hei_support_student_end",
        user_type: "hei",
        event_data: {
          student_id: userScore.userId,
          student_sessionId: userScore.userSessionId,
        },
      });
    };
  }, []);

  const getStudentResources = async () => {
    const studentResourcesList = await getOrList(
      listStudentResources,
      "listStudentResources",
      {},
    );
    await studentResourcesTrait(studentResourcesList);
  };

  const studentResourcesTrait = async (studentResourcesList: any) => {
    const leadership = [
      "Leadership",
      DIMENSIONS.F08,
      DIMENSIONS.F03,
      DIMENSIONS.F09,
      DIMENSIONS.F12,
      DIMENSIONS.F11,
      DIMENSIONS.F07,
      DIMENSIONS.F02,
    ];
    const perseverance = [
      "Perseverance",
      DIMENSIONS.F01,
      DIMENSIONS.F06,
      DIMENSIONS.F05,
      DIMENSIONS.F14,
    ];
    const communication = [
      "Communication",
      DIMENSIONS.speechFluency,
      DIMENSIONS.eyecontact,
      DIMENSIONS.headBodyPosture,
      DIMENSIONS.effectivePauses,
    ];
    const innovativeThinking = [
      "InnovativeThinking",
      DIMENSIONS.F14,
      DIMENSIONS.F15,
      DIMENSIONS.F13,
    ];

    const leadershipSR = leadership.map((trait: any) => {
      const srData = studentResourcesList.filter(
        (value: any) => value.trait === trait,
      );
      return { trait: "leadership", subtrait: trait, data: srData };
    });

    const perseveranceSR = perseverance.map((trait: any) => {
      const srData = studentResourcesList.filter(
        (value: any) => value.trait === trait,
      );
      return { trait: "perseverance", subtrait: trait, data: srData };
    });
    const communicationSR = communication.map((trait: any) => {
      const srData = studentResourcesList.filter(
        (value: any) => value.trait === trait,
      );
      return { trait: "effectiveCommunication", subtrait: trait, data: srData };
    });
    const innovativeThinkingSR = innovativeThinking.map((trait: any) => {
      const srData = studentResourcesList.filter(
        (value: any) => value.trait === trait,
      );
      return { trait: "innovativeThinking", subtrait: trait, data: srData };
    });
    setStudentResources([
      ...perseveranceSR,
      ...leadershipSR,
      ...communicationSR,
      ...innovativeThinkingSR,
    ]);
  };

  const getUserScores = async () => {
    const filterVariable = {
      limit: 100000,
      filter: {
        userSessionId: {
          eq: userSessionId, // filter priority = 1
        },
      },
    };
    const items = await getOrList(
      getIndividualStudent,
      "listUserScores",
      filterVariable,
    );

    const {
      f01,
      f02,
      f03,
      f04,
      f05,
      f06,
      f07,
      f08,
      f09,
      f11,
      f12,
      f13,
      f14,
      f15,
      eyeContact,
      headBodyPosture,
      speechFluency,
      effectivePauses,
      leadershipScore,
      perseveranceScore,
      communicationScore,
      innovativeThinkingScore,
      userId,
    } = items[0];

    const userScores = [
      {
        name: "Leadership",
        value: leadershipScore,
        image: <LeadershipImage />,
        data: [
          { number: "f08", value: f08, dimension: DIMENSIONS.F08 },
          { number: "f03", value: f03, dimension: DIMENSIONS.F03 },
          { number: "f09", value: f09, dimension: DIMENSIONS.F09 },
          { number: "f12", value: f12, dimension: DIMENSIONS.F12 },
          { number: "f11", value: f11, dimension: DIMENSIONS.F11 },
          { number: "f07", value: f07, dimension: DIMENSIONS.F07 },
          { number: "f02", value: f02, dimension: DIMENSIONS.F02 },
        ],
      },
      {
        name: "Perseverance",
        value: perseveranceScore,
        image: <PerseveranceImage />,
        data: [
          { number: "f01", value: f01, dimension: DIMENSIONS.F01 },
          { number: "f06", value: f06, dimension: DIMENSIONS.F06 },
          { number: "f05", value: f05, dimension: DIMENSIONS.F05 },
          { number: "f04", value: f04, dimension: DIMENSIONS.F04 },
          { number: "", value: -1, dimension: "" },
          { number: "", value: -1, dimension: "" },
          { number: "", value: -1, dimension: "" },
        ],
      },
      {
        name: "Effective Communication",
        value: communicationScore,
        image: <CommunicationImage />,
        data: [
          {
            number: "",
            value: eyeContact,
            dimension: DIMENSIONS.eyecontact,
          },
          {
            number: "",
            value: headBodyPosture,
            dimension: DIMENSIONS.headBodyPosture,
          },
          {
            number: "",
            value: speechFluency,
            dimension: DIMENSIONS.speechFluency,
          },
          {
            number: "",
            value: effectivePauses,
            dimension: DIMENSIONS.effectivePauses,
          },
        ],
      },
      {
        name: "Innovative Thinking",
        value: innovativeThinkingScore,
        image: <InnovativeImage />,
        data: [
          { number: "f14", value: f14, dimension: DIMENSIONS.F14 },
          { number: "f15", value: f15, dimension: DIMENSIONS.F15 },
          { number: "f13", value: f13, dimension: DIMENSIONS.F13 },
          { number: "", value: -1, dimension: "" },
          { number: "", value: -1, dimension: "" },
          { number: "", value: -1, dimension: "" },
          { number: "", value: -1, dimension: "" },
        ],
      },
    ];

    setSubTrait(userScores);
    setCandidateId(userId);
    setUserScore(items[0]);

    setLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <section className="individual-support">
          <div className="support-requests">
            <SupportRequests
              candidateId={candidateId}
              userScores={userScore}
              graphData={subTrait}
              userSessionId={userSessionId}
            />
            <div className="content graph container">
              <Messages
                studentResources={studentResources}
                userScore={userScore}
                getUserScores={getUserScores}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Send;

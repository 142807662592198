// Packages
import React from "react";

// Contexts
import { ModalConsumer } from "../../../contexts/consumer";
/*
 * ModalRoot is used to dynamically set the modal component
 */

const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) =>
      Component ? <Component {...props} onRequestClose={hideModal} /> : null
    }
  </ModalConsumer>
);

export default ModalRoot;

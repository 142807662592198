// Types
import { UserType, UserActionTypes } from "../types";

// Utilities
import { determineUsertype } from "../../utilities/user";

// Determine initial userType
const userType = determineUsertype();

// Definte the initial user state
const initialUserState: any = {
  loggedIn: false,
  userId: "",
  isUploading: false,
  userType: userType,
};

export function userReducer(
  state: UserType = initialUserState,
  action: UserActionTypes,
): UserType {
  switch (action.type) {
    case "CREATE_USER":
      return {
        ...state,
        ...action.payload,
      };

    case "GET_USER":
      return { ...state };

    case "LOGIN_USER":
      return {
        ...state,
        ...action.payload,
      };

    case "LOGOUT_USER":
      return { ...initialUserState };

    case "SET_ISUPLOADING":
      return {
        ...state,
        ...action.payload,
      };

    case "UPDATE_USER":
      return {
        ...state,
        ...action.payload,
      };

    case "UPDATE_USERBIQ":
      return {
        ...state,
        ...action.payload,
      };

    case "UPDATE_USERASSESSMENTSTATE":
      return {
        ...state,
        assessmentState: {
          ...state.assessmentState,
          assessmentStatus: action.payload,
        },
      };

    case "UPDATE_USERTYPE":
      return {
        ...state,
        userType: action.payload,
      };

    default:
      return state;
  }
}

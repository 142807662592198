import { ReactComponent as ManWithFlag } from "../../../../assets/reskin/icons/man-with-flag.svg";
import { ReactComponent as ChatBubble } from "../../../../assets/reskin/icons/chat-bubble.svg";
import { ReactComponent as SmallMountain } from "../../../../assets/reskin/icons/small-mountain.svg";
import { ReactComponent as LightBulb } from "../../../../assets/reskin/icons/light-bulb.svg";
export const leadershipOptions = {
  label: "Leadership",
  iconBackground: "#1E2442",
  labelBackground: "#30396c",
  value: "leadership",
  leftIcon: <ManWithFlag />,
};
export const perseveranceOptions = {
  label: "Perseverance",
  iconBackground: "#AB4B39",
  labelBackground: "#dd614a",
  value: "perseverance",
  leftIcon: <ChatBubble />,
};
export const effectiveCommunicationOptions = {
  label: "Effective Communication",
  iconBackground: "#446028",
  labelBackground: "#5d8137",
  value: "effective communication",
  leftIcon: <SmallMountain />,
};
export const innovativeThinkingOptions = {
  label: "Innovative Thinking",
  iconBackground: "#664B7A",
  labelBackground: "#8865a4",
  value: "innovative thinking",
  leftIcon: <LightBulb />,
};

import "./_index.scss";

interface CellProps {
  content: JSX.Element;
  size?: string;
}

export function Cell({ content, size }: CellProps) {
  const sizeClass = size ? size : "col";

  return (
    <td className={`${sizeClass} d-flex justify-content-center cell`}>
      <div className="cell-content">{content}</div>
    </td>
  );
}

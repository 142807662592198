// Packages
import { ActionCreator, Dispatch } from "redux";
import { Auth } from "aws-amplify";

// Actions
import { failure, request } from "./common.actions";

// Models
import { AssessmentStatusType, User, Usertype } from "../../models";

// Services
import EventService, { track } from "../../services/EventService/event.service";
import UserService from "../../services/User/user.service";

//Utils
import useResourceApi from "../../hooks/useResourceAPI";

// Types
import {
  UserType,
  UserBIQ,
  CREATE_USER,
  GET_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SET_ISUPLOADING,
  UPDATE_USER,
  UPDATE_USERBIQ,
  UPDATE_USERASSESSMENTSTATE,
  UPDATE_USERTYPE,
  UserActionTypes,
  confidenceResponseType,
} from "../types/user.types";
import createOrUpdate, { getOrList } from "../../components/dynamicqueries";
import * as createUsers from "../../graphql/mutations";
import { listUsers, searchUsers } from "../../graphql/queries";

const getUserSuccess: ActionCreator<UserActionTypes> = () => {
  return { type: GET_USER, payload: {} };
};

const createUserSuccess: ActionCreator<UserActionTypes> = (user: UserType) => {
  return { type: CREATE_USER, payload: user };
};

const loginUserSuccess: ActionCreator<UserActionTypes> = (user: UserType) => {
  return { type: LOGIN_USER, payload: user };
};

const logoutUserSuccess: ActionCreator<UserActionTypes> = () => {
  return { type: LOGOUT_USER, payload: {} };
};

const updateUserSuccess: ActionCreator<UserActionTypes> = (user: UserType) => {
  return { type: UPDATE_USER, payload: user };
};

const updateUserBIQSuccess: ActionCreator<UserActionTypes> = (
  profile: UserBIQ,
) => {
  return { type: UPDATE_USERBIQ, payload: profile };
};

const updateUserUserAssessmentStateSuccess: ActionCreator<UserActionTypes> = (
  statusType: AssessmentStatusType,
) => {
  return { type: UPDATE_USERASSESSMENTSTATE, payload: statusType };
};

const setUserIsUploading: ActionCreator<UserActionTypes> = (
  isUploading: boolean,
) => {
  return { type: SET_ISUPLOADING, payload: isUploading };
};

const setUsertype: ActionCreator<UserActionTypes> = (usertype: string) => {
  return { type: UPDATE_USERTYPE, payload: usertype };
};

export function createUser(user: UserType) {
  return async (dispatch: Dispatch) => {
    dispatch(request());
    try {
      const input = {
        userId: user.userId,
        name: user.name,
        enrollmentYear: user.enrollmentYear,
        enrollmentSemester: user.enrollmentSemester,
        institution: user.institution,
      };
      const userSave = await createOrUpdate(createUsers.createUser, input);

      dispatch(createUserSuccess(user));
    } catch (err) {
      dispatch(failure("Server error upon creating new user row."));
    }
  };
}

export function getUser() {
  return (dispatch: Dispatch) => {
    dispatch(getUserSuccess());
  };
}

export function updateUser(user: UserType) {
  return (dispatch: Dispatch) => {
    dispatch(updateUserSuccess(user));
  };
}

export function logInUser(dsUser: any, email: string, userType: Usertype) {
  return async (dispatch: Dispatch) => {
    // track event
    track({
      event_type: "user",
      event_name: "user_login",
      user_type: userType === Usertype.INSTITUTION ? "institution" : "student",
      event_data: {
        userId: dsUser.userId,
      },
    });

    dispatch(
      loginUserSuccess({
        ...dsUser,
        loggedIn: true,
        email: email,
        userType: userType,
      }),
    );
  };
}

export function logoutUser() {
  return async (dispatch: Dispatch) => {
    // logout of amplify
    dispatch(request());
    try {
      await Auth.signOut();
      await track({
        event_type: "user",
        event_name: "user_logout",
      });
      await EventService.deidentify();
      dispatch(logoutUserSuccess());
      sessionStorage.clear();
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
      dispatch(failure("Server error upon signing user out."));
    }
  };
}

export function initiateResetPassword() {
  return async (dispatch: Dispatch) => {
    // logout of amplify
    dispatch(request());
    try {
      await Auth.signOut();
      await track({
        event_type: "user",
        event_name: "user_logout",
      });
      await EventService.deidentify();
      dispatch(logoutUserSuccess());
      sessionStorage.clear();
      sessionStorage.setItem("AuthState", "ForgotPassword");
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
      dispatch(failure("Server error upon signing user out."));
    }
  };
}

export function updateUserBIQ(profile: UserType) {
  return async (dispatch: Dispatch) => {
    const { userId, ...biqData } = profile;

    try {
      const variable = { limit: 10000, filter: { userId: { eq: userId } } };
      const original = await getOrList(listUsers, "listUsers", variable);

      const input = {
        userId: userId,
        enrollmentStatus: biqData.enrollmentStatus,
        programYear: biqData.programYear,
        fieldOfStudy: biqData.fieldOfStudy,
        transferToFourYear: biqData.transferToFourYear,
        ageGroup: biqData.ageGroup,
        emplStatus: biqData.emplStatus,
        ethnicity: biqData.ethnicity,
        gender: biqData.gender,
        goal: biqData.goal,
        userType: original[0].userType,
        _version: original[0]._version,
      };

      await createOrUpdate(createUsers.updateUser, input);

      dispatch(updateUserBIQSuccess(biqData));
    } catch (error) {
      console.log("Update BIQ Error: ", error);
      dispatch(failure("Server error unable to save User BIQ Data."));
    }
  };
}

export function updateUserConfidenceLevels(
  confidenceResponse: confidenceResponseType,
) {
  return async (dispatch: Dispatch) => {
    const { userId, ...selectedUserConfidenceLevels } = confidenceResponse;

    try {
      useResourceApi().createConfidenceScaleInput(
        selectedUserConfidenceLevels.leadershipConfidenceScore,
        "LEADERSHIP",
        "PRETEST",
        userId,
      );
      useResourceApi().createConfidenceScaleInput(
        selectedUserConfidenceLevels.innovativeThinkingConfidenceScore,
        "INNOVATIVE_THINKING",
        "PRETEST",
        userId,
      );
      useResourceApi().createConfidenceScaleInput(
        selectedUserConfidenceLevels.effectiveCommunicationConfidenceScore,
        "COMMUNICATION",
        "PRETEST",
        userId,
      );
      useResourceApi().createConfidenceScaleInput(
        selectedUserConfidenceLevels.perseveranceConfidenceScore,
        "PERSEVERANCE",
        "PRETEST",
        userId,
      );
    } catch (error) {
      console.log("Update BIQ user Confidence Error: ", error);
      dispatch(failure("Server error unable to save User BIQ Data."));
    }
  };
}

export function updateUserAssessmentState({
  userId,
  UserAssessmentStateId,
  statusType,
}: any) {
  return async (dispatch: Dispatch) => {
    try {
      await UserService.updateUserAssessmentState(
        UserAssessmentStateId,
        statusType,
      );
      dispatch(updateUserUserAssessmentStateSuccess(statusType));
    } catch (error) {
      console.log("Update User Assessment State Error: ", error);
      dispatch(failure("Unable to update user assessment state."));
    }
  };
}

export function updateUserAssessmentStateStatus({ statusType }: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateUserUserAssessmentStateSuccess(statusType));
    } catch (error) {
      console.log("Update User Assessment State Error: ", error);
      dispatch(failure("Unable to update user assessment state."));
    }
  };
}

export function userIsUploading(isUploading: boolean) {
  return (dispatch: Dispatch) => {
    dispatch(setUserIsUploading(isUploading));
  };
}

export function updateUsertype(usertype: string) {
  return (dispatch: Dispatch) => {
    dispatch(setUsertype(usertype));
  };
}

//Packages
import { useSelector } from "react-redux";
import React from "react";

//Model
import { HeiComments } from "../../../../models";

// Redux
import { RootState } from "../../../../redux/reducers";

//Image
import { ReactComponent as EditImage } from "../../../../assets/fullsummary/edit.svg";
import { ReactComponent as NoComments } from "../../../../assets/reskin/icons/no-comments.svg";

// Services
import EventService from "../../../../services/EventService/event.service";

//Graphql

import { listHeiComments } from "../../../../graphql/queries";
import {
  createHeiComments,
  updateHeiComments,
} from "../../../../graphql/mutations";
import createOrUpdate, {
  getOrList,
} from "../../../../components/dynamicqueries";

function HeiComment({
  assessmentID,
  candidateId,
  userSessionId,
  assessmentStateID,
  userscores,
}: any) {
  // const candidateId = "85a7730c-4228-4113-96ee-0e928cc31438";
  // const userSessionId = "2e8269fa-8992-476a-9b88-c0e63ad587d2";
  const { userId, name } = useSelector((state: RootState) => state.user);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [heiComments, setHeiComments] = React.useState<any>([]);
  const [comment, setComment] = React.useState<string>("");
  const commentsRef = React.useRef<any>(null);

  React.useEffect(() => {
    getHeiComments();
  }, [userscores]);

  React.useEffect(() => {
    if (edit && commentsRef && commentsRef.current) commentsRef.current.focus();
  }, [edit]);

  const getHeiComments = async () => {
    let heiComment = userscores[0].userAssessmentState.heiComments.items;
    //Not filtering by userId as we are showing comments of all hei users for current institution
    //heiComment = heiComment.filter((comment: any) => comment.userId === userId);

    if (heiComment.length > 0) {
      setHeiComments(heiComment);
    }
  };
  const heicomments = () => {
    setEdit(!edit);
  };
  const handleChange = (event: any): void => {
    setComment(event.target.value);
  };

  const submit = async () => {
    if (comment === "") return;
    const filter = {
      filter: { candidateUserId: { eq: candidateId }, userId: { eq: userId } },
    };
    const original = await getOrList(
      listHeiComments,
      "listHeiComments",
      filter,
    );

    const heicomment = heiComments.length > 0 ? heiComments : "No Comments";

    EventService.track({
      event_type: "user",
      event_name: "hei_student_update_comment",
      user_type: "hei",
      event_data: {
        studentId: userId,
        comment: comment,
      },
    });

    if (original.length > 11111111111110) {
      const input = {
        id: original[0].id,

        comment: heicomment,
        _version: original[0]._version,
      };
      await createOrUpdate(createHeiComments, input);
    } else {
      const input = {
        userId: userId,
        userSessionId: userSessionId,
        assessmentId: assessmentID,
        candidateUserId: candidateId,
        comment: comment,
        candidateUserSessionId: userSessionId,
        userAssessmentId: assessmentID,
        userAssessmentStateHeiCommentsId: assessmentStateID,
        timestamp: new Date().toISOString(),
        username: name,
      };
      await createOrUpdate(createHeiComments, input);
    }
    setComment("");
    setEdit(false);
  };
  return (
    <>
      <div className="comments p-4">
        <div className="comment-title">
          <strong style={{ color: "#397F93", fontSize: 24, fontWeight: 600 }}>
            Add Comments
          </strong>
          {/* <button className="edit" onClick={() => heicomments()}>
            <EditImage /> Edit
          </button> */}
        </div>

        {!edit && (
          <div className="commentsContent" onClick={() => heicomments()}>
            <p style={{ color: "gray" }}>{"Enter comments here"}</p>
          </div>
        )}

        {edit && (
          <>
            <div className="commentsContent">
              <div className="content-input">
                <textarea
                  ref={commentsRef}
                  name="comments"
                  value={comment}
                  onChange={handleChange}
                  placeholder="Enter comments here"
                ></textarea>
              </div>
            </div>
            <div className="content-btn">
              <button
                type="button"
                className=" psq-btn-primary"
                onClick={() => submit()}
              >
                Save
              </button>
              <button
                className="edit psq-btn-secondary"
                onClick={() => heicomments()}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
      {<CommentsComponent comments={heiComments} />}
    </>
  );
}
export default HeiComment;

const CommentsComponent = ({ comments }: any) => {
  // Sort comments by timestamp in descending order (latest first)
  const sortedComments = comments.sort(
    (a: any, b: any) =>
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );
  const getInitials = (name: string): string => {
    const [firstName, lastName] = name.split(" ");
    return `${firstName.charAt(0).toUpperCase()}${lastName ? lastName.charAt(0).toUpperCase() : ""}`;
  };

  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    } as any;
    return date.toLocaleString("en-US", options);
  };

  return (
    <div
      className="mt-4 comments-container comments"
      style={{ maxHeight: 400, overflow: "auto" }}
    >
      <h2 style={{ color: "#397F93", fontSize: 24, fontWeight: 600 }}>
        Posted Comments
      </h2>
      <ul>
        {sortedComments.length > 0 ? (
          sortedComments.map((comment: any, index: number) => (
            <li
              style={{
                listStyleType: "none",
                padding: 15,
                borderBottom:
                  index === sortedComments.length - 1
                    ? "none"
                    : "0.5px solid #e3e3e3",
              }}
              key={comment.id}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#F2F6EE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#5D8137",
                    }}
                  >
                    {getInitials(comment?.username || "")}
                  </span>
                </div>
                <div style={{ paddingTop: "9px" }}>
                  <strong
                    style={{
                      color: "#353C3E",
                      fontSize: 18,
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {comment.username}
                  </strong>
                  :{" "}
                  <small
                    style={{ color: "#737582", fontSize: 12, fontWeight: 400 }}
                  >
                    {formatTimestamp(comment.timestamp)}
                  </small>
                  <div
                    style={{
                      color: "#737582",
                      fontSize: 14,
                      fontWeight: 400,
                      marginTop: 10,
                    }}
                  >
                    {comment.comment}
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li
            style={{ listStyleType: "none", padding: 20, textAlign: "center" }}
          >
            <NoComments />
          </li>
        )}
      </ul>
    </div>
  );
};

// Packages
import { ActionCreator, Dispatch } from "redux";

// Types
import {
  AssessmentType,
  AssessmentActionTypes,
  STORE_ASSESSMENT,
} from "../types";
import { AssessmentSection } from "../../models";

const storeAssessmentSuccess: ActionCreator<AssessmentActionTypes> = (
  assessment: AssessmentType,
) => {
  return { type: STORE_ASSESSMENT, payload: assessment };
};

export function storeAssessment(assessmentSections: AssessmentSection[]) {
  return (dispatch: Dispatch) => {
    let assessmentData = { id: "", name: "" };

    const sections = assessmentSections.map((assessmentSection: any) => {
      const { assessment, section, sectionSequence } = assessmentSection;

      // define parent assessment data
      assessmentData = { id: assessment.id, name: assessment.name };

      return {
        id: section.id,
        name: section.name,
        description: section.description,
        sequence: sectionSequence,
      };
    });

    dispatch(storeAssessmentSuccess({ ...assessmentData, sections }));
  };
}

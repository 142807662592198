// Packages
import React from "react";
import { useParams } from "react-router-dom";

// Components
import SubTraitCardContent from "./SubTraitCardContent/SubtraitCardContent";

// Assets
import { ReactComponent as Developing } from "../../../../../assets/icons/bar/developing-bar.svg";
import { ReactComponent as Approaching } from "../../../../../assets/icons/bar/approaching-bar.svg";
import { ReactComponent as Demonstrating } from "../../../../../assets/icons/bar/demonstrating-bar.svg";
import { ReactComponent as FlipRight } from "../../../../../assets/icons/flip-right.svg";
import { ReactComponent as FlipGreen } from "../../../../../assets/icons/flip-green.svg";
import { ReactComponent as BulletIcon } from "../../../../../assets/icons/blue-bullet.svg";

// Services
import EventService from "../../../../../services/EventService/event.service";

// Utils
import subtraitCarouselLanguage from "../../../../../language/screens/report/trait-report/SubTraitCarousel/subtrait-carousel-language";

function SubTraitCard({ subtrait, userScore, narrative }: any) {
  const { trait } = useParams();

  let cardBgClass;

  switch (trait) {
    case "leadership":
      cardBgClass = "leadership";
      break;
    case "perseverance":
      cardBgClass = "perseverance";
      break;
    case "innovative thinking":
      cardBgClass = "innovative-thinking";
      break;
    case "effective communication":
      cardBgClass = "effective-communication";
      break;
    default:
      cardBgClass = "leadership";
  }

  const [isFlipped, setFlipped] = React.useState<boolean>(false);

  // overlay
  const traitCardRef = React.useRef(null);

  const renderImprovements = () => {
    return narrative.improvementNarrative.map(
      (improvement: string, index: number) => {
        return (
          <div className="bullet-div" key={index}>
            <BulletIcon className="bullet-image" />{" "}
            <p className="p-text" key={index}>
              {" "}
              {improvement}
            </p>
          </div>
        );
      },
    );
  };

  const handleFlip = () => {
    EventService.track({
      event_type: "user",
      event_name: "insight_trait_description_flip",
      user_type: "student",
      event_data: {
        category: trait?.toLowerCase(),
        subtrait: subtrait?.toLowerCase(),
        flipped: !isFlipped,
      },
    });

    setFlipped(!isFlipped);
  };

  const getCardLanguage = () => {
    if (trait) {
      if (subtrait) {
        const traitLevelLanguage =
          subtraitCarouselLanguage[
            trait as keyof typeof subtraitCarouselLanguage
          ];
        const subtraitLevelLanguage =
          traitLevelLanguage[subtrait as keyof typeof traitLevelLanguage];

        if (!subtraitLevelLanguage) {
          console.log(`${subtrait} LEVEL LANGUAGE WAS UNDEFINED`);
        }
        const textualUserScore = convertNumericScore(userScore.value);
        const scoreLevelLanguage =
          subtraitLevelLanguage[
            textualUserScore as keyof typeof subtraitLevelLanguage
          ];
        return scoreLevelLanguage || [];
      }
    }

    return [];
  };

  return (
    <>
      {userScore.value >= 0 && (
        <div
          className="subtraitCard"
          onClick={() => handleFlip()}
          // onMouseOver={() => setIsOverlayShown(true)}
          // onMouseOut={() => setIsOverlayShown(false)}
        >
          <div className="card-container" ref={traitCardRef}>
            <div className={`card-wrapper ${isFlipped ? "flipped" : ""}`}>
              <div className={`card-inner front bg-${cardBgClass}`}>
                <div className="card-inner-header">
                  <div className="trait-description">
                    Your Trait Score
                    <br />
                    <strong className="trait-head-text">{subtrait}</strong>
                  </div>
                  <div
                    className={`trait-score score-${convertNumericScore(
                      userScore.value,
                    )}`}
                  >
                    {userScore.value === 0 ? (
                      <Developing />
                    ) : userScore.value === 1 ? (
                      <Approaching />
                    ) : userScore.value === 2 ? (
                      <Demonstrating />
                    ) : null}
                    <br />
                    {convertNumericScore(userScore.value)}
                  </div>
                </div>

                <div className="card-inner-body">
                  <hr style={{ border: "1px solid white" }} />
                  <SubTraitCardContent cardLanguage={getCardLanguage()} />
                </div>

                <div className="card-inner-footer">
                  How to Improve?
                  <FlipRight className="arrow" fill="#ffffff" />
                </div>
              </div>
              <div className="card-inner back">
                <div className="card-inner-header">
                  <div className="trait-description">
                    Initiative
                    <br />
                    <strong className="trait-head-text">{subtrait}</strong>
                  </div>
                  <div
                    className={`trait-score score-${convertNumericScore(
                      userScore.value,
                    )}`}
                  >
                    {userScore.value === 0 ? (
                      <Developing />
                    ) : userScore.value === 1 ? (
                      <Approaching />
                    ) : userScore.value === 2 ? (
                      <Demonstrating />
                    ) : null}
                    <br />
                    {convertNumericScore(userScore.value)}
                  </div>
                </div>

                <div className="card-inner-body">
                  <hr />
                  {/* <Tips style={{margin: '0 2.7rem'}}/> */}
                  <p>{narrative.definition}</p>
                  <p>
                    {userScore.value === 0 && narrative.lowScoreNarrative}
                    {userScore.value === 1 && narrative.mediumScoreNarrative}
                    {userScore.value === 2 && narrative.highScoreNarrative}
                  </p>
                </div>

                <div className="card-inner-footer green">
                  Your Trait Score
                  <FlipGreen className="flip-green" />
                  {/* <FlipRight className="arrow" fill="#ffffff" /> */}
                  {/* <ArrowIcon className="arrow" fill="#ffffff" /> */}
                </div>
              </div>
            </div>
          </div>
          {/* <FocusOverlay
            underlyingComponentRef={traitCardRef}
            shown={isOverlayShown}
            onClick={() => handleFlip()}
          /> */}
        </div>
      )}
    </>
  );
}

function convertNumericScore(value: number) {
  switch (value) {
    case 0:
      return "Developing";
    case 1:
      return "Approaching";
    case 2:
      return "Demonstrating";
  }
}

export default SubTraitCard;

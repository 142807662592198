/* eslint-disable jsx-a11y/alt-text */

// Packages
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import PSQCarousel from "../../../../../components/Carousel";
import CardWithThumbnail from "../../../../../components/Card/CardWithThumbnail";
import ResourceReflectionModal from "./ResourceReflections/ResourceReflectionModal";
import { Spinner } from "react-bootstrap";
import FavoriteCard from "../../../../../reskin/pages/FavoriteCard";

// Services
import * as eventServices from "../../../../../services/EventService/event.service";

// Utils
import { ResourceType } from "./ResourceCardsData";
import getS3PresignedUrl from "../../../../../utilities/s3/getS3PresignedUrl";
// Utils
import useResourceApi from "../../../../../hooks/useResourceAPI";

// Redux
import { RootState } from "../../../../../redux/reducers";

// GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { getOrList } from "../../../../../components/dynamicqueries";
import {
  createStudentFavouriteLearningResource,
  updateLearningSelfReflectionCount,
  createLearningSelfReflectionCount,
  createStudentVisitedLearningResource,
} from "../../../../../graphql/mutations.js";
import {
  listStudentFavouriteLearningResources,
  listLearningSelfReflectionCounts,
  visitedByResourceIdandUserId,
} from "../../../../../graphql/queries";
import { deleteStudentFavouriteLearningResource } from "../../../../../graphql/mutations.js";

export interface ResourceCarouselProps {
  cardsData: {
    [key: string]: ResourceType[] | any;
  };
  trait: string;
  isLoading: boolean;
  isLandingPage?: boolean;
  sourcePage?: string;
  // Number of buffer cards to add to end of carousel
  numberOfBufferCards?: Number;
}

interface FavoriteCardType {
  id: string;
  userId: string;
  trait: string;
  assessmentId: string;
  userSessionId: string;
  learningResourceId: string;
  createdAt: string;
  updatedAt: string;
  _deleted: boolean;
}

type ResourceCardsDataType = {
  title: string;
  trait: string;
  id: string;
  isCardFavorited: boolean;
  resourceCards: any;
};

interface ReflectionModalState {
  isReflectionModalOpen: boolean;
  resourceCardsData: ResourceCardsDataType | null;
}

const ResourceCarousel = ({
  cardsData,
  trait,
  isLoading,
  isLandingPage,
  numberOfBufferCards,
  sourcePage,
}: ResourceCarouselProps): JSX.Element => {
  const navigate = useNavigate();
  const resourceServices = useResourceApi();
  const {
    userId,
    assessmentState: { UserAssessmentStateId, sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);
  const [reflectionModalState, setReflectionModalState] =
    React.useState<ReflectionModalState>({
      isReflectionModalOpen: false,
      resourceCardsData: null,
    });

  if (isLoading) {
    return <Spinner animation="border" variant="light" />;
  }

  const saveOrUnSaveLearningResource = async (
    id: any,
    isSaved: any,
    trait: any,
    title: string,
    type: string,
  ) => {
    eventServices.track({
      event_type: "user",
      event_name: `resource_${isSaved ? "save" : "unsave"}`,
      user_type: "student",
      event_data: {
        category: `${trait}`,
        type: `${type}`,
        content: `${title}`,
        resource_id: `${id}`,
        context: `${sourcePage ? sourcePage : "Resource Hub"} - ${!isLandingPage ? trait : "Landing Page"}`,
      },
    });
  };

  const handleReactionClick = async (
    reactionStatus: string,
    resourceCardId: string,
    resourceCardtrait: string,
  ) => {
    resourceServices.createOrRemoveReactions(
      reactionStatus,
      resourceCardId,
      resourceCardtrait,
      userId,
      sessionId,
      assessmentId,
    );
  };

  const unSave = async (resource: any) => {
    // await getFavorites(SelectedTab);
  };

  // const getFavorites = async (selectedTraitName: any) => {
  //     const resourcesAndItsConnections = await resourceServices.getLearningResourcesWithAllTheConnections(selectedTraitName, userId, sessionId, assessmentId)
  //     const consolidatedResourceCardInfo = await resourceServices.consolidatedEachResourceCardInformation(resourcesAndItsConnections)
  //     const FavoritesList = consolidatedResourceCardInfo.filter((resourceCard: any) => resourceCard.isFavorited === "FAVORITED")
  //     setFavoriteResources(FavoritesList);
  //     setLoading(false)
  // }

  // const handleFavoritesClick = async (resource: any) => {
  //     const { learningResourceId: resourceCardId, trait: resourceCardtrait } = resource;
  //     const isSaved = false
  //     await resourceServices.updateFavs(isSaved, resourceCardId, resourceCardtrait, userId, sessionId, assessmentId)
  //     await unSave(resource)
  // }
  const handleFavoritesClick = async (
    resourceCardId: any,
    isSaved: any,
    resourceCardtrait: any,
    title: any,
    type: any,
  ) => {
    resourceServices.updateFavs(
      isSaved,
      resourceCardId,
      resourceCardtrait,
      userId,
      sessionId,
      assessmentId,
    );
    saveOrUnSaveLearningResource(
      resourceCardId,
      isSaved,
      resourceCardtrait,
      title,
      type,
    );
  };

  const goToCardListPage = (
    title: string,
    trait: string,
    cardId: string,
    isFav: boolean,
    resourceCards?: [],
  ) => {
    navigate("/report/resourceCards", {
      state: {
        parentTitle: title,
        cards: resourceCards,
        resourceTrait: trait,
        userId: userId,
        assessId: assessmentId,
        sessionId: sessionId,
        cardId: cardId,
        isFavorite: isFav,
      },
    });
  };

  const getVisitedResources = async (learningResourceId: any) => {
    const visitedResourcesfilter = {
      learningResourceId: learningResourceId,
      userId: { eq: userId },
    };
    const vistitedResources = await getOrList(
      visitedByResourceIdandUserId,
      "VisitedByResourceIdandUserId",
      visitedResourcesfilter,
    );
    return vistitedResources;
  };

  const updateVisitedResourcesTable = async (
    learningResourceId: any,
    trait: any,
  ) => {
    const input = {
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      learningResourceId: learningResourceId,
      trait: trait,
    };
    await API.graphql(
      graphqlOperation(createStudentVisitedLearningResource, { input: input }),
    );
  };

  const SubmitReflectionCount = async (
    learningResourceId: any,
    listSelfReflectionCounts: any,
  ) => {
    await updateVisitedResourcesTable(learningResourceId, trait);
    if (listSelfReflectionCounts.length === 0) {
      const input = {
        userId: userId,
        assessmentId: assessmentId,
        userSessionId: sessionId,
        selfReflectionCount: 1,
      };
      await API.graphql(
        graphqlOperation(createLearningSelfReflectionCount, { input: input }),
      );
    } else if (listSelfReflectionCounts.length === 1) {
      const visitedResourceCount =
        listSelfReflectionCounts[0]["selfReflectionCount"];
      const input = {
        id: listSelfReflectionCounts[0]["id"],
        selfReflectionCount: visitedResourceCount + 1,
        _version: listSelfReflectionCounts[0]["_version"],
      };
      await API.graphql(
        graphqlOperation(updateLearningSelfReflectionCount, { input: input }),
      );
    }
  };

  const handleSlideChange = (nextIdx: number, state: any) => {
    eventServices.track({
      event_type: "user",
      event_name: "resource_slide",
      user_type: "student",
      event_data: {
        category: `${trait}`,
        Index_total: `${Math.ceil(state.totalItems / state.slidesToShow)}`,
        Index_current: `${nextIdx + 1}`,
        context: `${sourcePage ? sourcePage : "Resource Hub"} - ${!isLandingPage ? trait : "Landing Page"}`,
      },
    });
  };

  const RenderedResourceCards = cardsData.map((cardDatum: any, idx: any) => {
    const {
      learningResourceId: id,
      resourceLink,
      title,
      author,
      type,
      imageLocation,
      duration,
      resourceCards,
      isFavorited,
      isReacted,
    } = cardDatum;
    const ClickableThumbnail = (
      <a href={resourceLink} target="_blank">
        <img src={imageLocation} width="100%" height="100%" />
      </a>
    );
    let isCardFavorited: boolean;

    isFavorited === "FAVORITED"
      ? (isCardFavorited = true)
      : (isCardFavorited = false);

    const handleReflectionModalOperations = (listSelfReflectionCounts: any) => {
      // Find out if user has visited any resources,
      if (listSelfReflectionCounts.length === 1) {
        const visitedResourceCount =
          listSelfReflectionCounts[0]["selfReflectionCount"];

        // if number of visited resources divisible by 3, open reflection modal,
        if (visitedResourceCount % 3 === 0) {
          if (type === "Cards") {
            setReflectionModalState({
              isReflectionModalOpen: true,
              resourceCardsData: {
                title,
                trait,
                id,
                isCardFavorited,
                resourceCards,
              },
            });

            return;
          }

          setReflectionModalState({
            isReflectionModalOpen: true,
            resourceCardsData: null,
          });

          return;
        }
      }

      // if number of visited resources not divisible by 3 run normal operations
      type === "Cards" &&
        goToCardListPage(title, trait, id, isCardFavorited, resourceCards);
    };
    const handleThumbnailClick = (e: any, resource: any) => {
      // Refer to navigation logic in resourceCarousel to see required fields for resourceCards screen. -D
      if (resource.type === "Cards")
        navigate("/report/resourceCards", {
          state: {
            parentTitle: resource.title,
            cards: resource.resourceCards,
            resourceTrait: resource.trait,
            userId: userId,
            assessId: assessmentId,
            sessionId: sessionId,
            cardId: resource.learningResourceId,
            isFavorite: false,
          },
        });
    };

    // const handleThumbnailClick = async (e : any) => {
    //     eventServices.track({
    //         event_type: "user",
    //         event_name: "resource_open",
    //         user_type: "student",
    //         event_data: {
    //             category: `${trait}`,
    //             type: `${type}`,
    //             content: `${title}`,
    //             resource_id: `${id}`,
    //             context: `${sourcePage ? sourcePage : 'Resource Hub'} - ${!isLandingPage ? trait : 'Landing Page'}`,
    //             click:  `${e.button == 0 ? 'left_click' : e.button == 1 ? 'middle_click' : 'right_click'}`
    //         },
    //     });

    //     const countsFilter = {
    //         limit: 10000,
    //         filter: {
    //             userId: {eq: userId},
    //             assessmentId: {eq: assessmentId},
    //             userSessionId: {eq: sessionId}
    //         },
    //     };

    //     // check if user has visited this resource
    //     const listOfVisitedResources = await getVisitedResources(id);

    //     // if user has visited resource before, run normal operations
    //     if (listOfVisitedResources.length === 1) {
    //         type === "Cards" && goToCardListPage(title, trait, id, isCardFavorited, resourceCards);

    //     // if user has not visited resource yet, handle modal and reflection count updates.
    //     } else if (listOfVisitedResources.length === 0) {
    //         let listSelfReflectionCounts = await getOrList(listLearningSelfReflectionCounts, "listLearningSelfReflectionCounts", countsFilter);
    //         handleReflectionModalOperations(listSelfReflectionCounts);
    //         SubmitReflectionCount(id, listSelfReflectionCounts); // passing in the id of the learning resource
    //     }
    // };

    return (
      // <CardWithThumbnail
      //     key={`thumbnail-card-${idx}`}
      //     ContentThumbnail={ClickableThumbnail}
      //     title={title}
      //     author={author}
      //     resourceCardId = {id}
      //     resourceCardtrait = {trait}
      //     onThumbnailClick={handleThumbnailClick}
      //     type={type}
      //     isSaved={isCardFavorited}
      //     onSave={(isSaved: any) => {handleFavoritesClick(id, isSaved, trait, title, type)}}
      //     duration={duration}
      //     reactionStatus = {isReacted}
      //     isLandingPage = {isLandingPage}
      //     sourcePage = {sourcePage}
      //     onReactionsClick={ (reactionStatus: any,resourceCardId: any, resourceCardtrait: any) => { handleReactionClick(reactionStatus,resourceCardId, resourceCardtrait)}}
      // />

      <div>
        <ul className="favorite-cards-list">
          <FavoriteCard
            key={`thumbnail-card-${idx}`}
            resourceLink={cardDatum["resourceLink"]}
            imageLocation={cardDatum["imageLocation"]}
            duration={cardDatum["duration"]}
            type={cardDatum["type"]}
            trait={cardDatum["trait"]}
            title={cardDatum["title"]}
            credit={cardDatum["credit"]}
            resourceCardId={cardDatum["learningResourceId"]}
            resourceCardtrait={cardDatum["trait"]}
            handleThumbnailClick={(e: any) => {
              handleThumbnailClick(e, cardDatum);
            }}
            //   unSave={() => {
            //     handleFavoritesClick(cardDatum)
            //   }}
            unSave={(isSaved: any) => {
              handleFavoritesClick(id, isSaved, trait, title, type);
            }}
            reactionStatus={cardDatum["isReacted"]}
            onReactionsClick={(
              reactionStatus: any,
              resourceCardId: any,
              resourceCardtrait: any,
            ) => {
              handleReactionClick(
                reactionStatus,
                resourceCardId,
                resourceCardtrait,
              );
            }}
          />
        </ul>
      </div>
    );
  });

  const BufferCard = <div className="buffer-card" />;
  const getBufferedCards = () => {
    let result = [];
    if (numberOfBufferCards) {
      const BufferArr = [];
      for (let i = 0; i < numberOfBufferCards; i++) {
        BufferArr.push(BufferCard);
      }

      result = [...RenderedResourceCards, ...BufferArr];
      return result;
    }
  };

  const CarouselContent = numberOfBufferCards
    ? getBufferedCards()
    : RenderedResourceCards;
  const bufferedClassHandler = numberOfBufferCards ? "buffered-carousel" : "";

  return (
    <div className={`resource-carousel ${bufferedClassHandler}`}>
      <ResourceReflectionModal
        handleClose={() => {
          if (reflectionModalState.resourceCardsData) {
            const { title, trait, id, isCardFavorited, resourceCards } =
              reflectionModalState.resourceCardsData;
            goToCardListPage(title, trait, id, isCardFavorited, resourceCards);
          }

          setReflectionModalState({
            isReflectionModalOpen: false,
            resourceCardsData: null,
          });
        }}
        show={reflectionModalState.isReflectionModalOpen}
        resourceCardtrait={trait}
      />
      <PSQCarousel
        content={CarouselContent}
        handleChange={(nextSlide: number, state: any) =>
          handleSlideChange(nextSlide, state)
        }
      />
    </div>
  );
};

export default ResourceCarousel;

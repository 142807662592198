import { Cell } from "../../../../../components/Table/dataCells";
import { SupportStatus } from "../utils/types";

interface SupportCellProps {
  supportStatus?: SupportStatus;
}

const SupportCell = ({ supportStatus }: SupportCellProps) => {
  if (!supportStatus) {
    return <Cell size="left-aligned-cell col-2 pt-2" content={<></>} />;
  }

  const { date, sender, status } = supportStatus;

  if (status === "requested") {
    const content = (
      <div className="pt-1 ps-1">
        <strong>Support requested</strong>
      </div>
    );

    return <Cell content={content} size="left-aligned-cell col-2 pt-3" />;
  } else if (status === "sent") {
    const content = (
      <div className="cell-interior ps-1 pt-1">
        <p className="sent-by-text">{`${sender} sent support on`}</p>
        <p className="sent-by-text">{date}</p>
      </div>
    );

    return <Cell content={content} size="left-aligned-cell col-2 pt-2" />;
  } else {
    return <Cell size="left-aligned-cell col-2 pt-2" content={<></>} />;
  }
};

export default SupportCell;

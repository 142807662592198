// Packages
import React from "react";
import { useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";

// Components
import { Route, Routes, Navigate } from "react-router-dom";
import TraitMenu from "./support/trait-menu";
import SingleTraitSupport from "./support/single-trait-support";
import Loading from "../../components/loading";

// Reskin Components
import ResourceHub from "../../reskin/pages/ResourceHub/ResourceHub";
import Reflections from "../../reskin/pages/Reflections";

// Pages
import Summary from "./summary";
import SelfReaction from "./self-reaction";
import TraitReport from "./trait-report";
import PSQReport from "./psq-report";
import Feedback from "./feeback";
import SupportInbox from "./support/inbox";
import ExpandedMessage from "./support/inbox/ExpandedMessage";
import RequestSupport from "./support/request";
import ResourceCardCarousel from "./resource-card-carousel";

// Reskin Pages
import MainAppHeader from "../../reskin/components/Header/MainAppHeader/MainAppHeader";
import InsightsReport from "../../reskin/pages/InsightsReport";

// Redux / Reducers
import { RootState } from "../../redux/reducers";
import { userSupportReducer } from "../../reducers/index";

// Services
import EventService from "../../services/EventService/event.service";

// Types
import { DIMENSIONS } from "../../enumerations";

// graphql
import {
  listUserScores,
  listNarratives,
  searchStudentRequests,
  searchInstitutionResponses,
} from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";
import * as customsubscriptions from "../../graphql/custom-subscriptions";
import { getOrList } from "../../components/dynamicqueries";

function Report() {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [feedbackReady, setFeedbackReady] = React.useState<boolean>(false);
  const [userScores, setUserScores] = React.useState<any>(null);
  const [userScoresValues, setUserScoresValues] = React.useState<any>(null);
  const [userSupportRequests, dispatchUserSupportRequests] = React.useReducer(
    userSupportReducer,
    [],
  );

  const [HEISupportResponses, setHEISupportResponses] = React.useState<any>([]);
  const [narratives, setNarratives] = React.useState<any>([]);
  const [userAssessmentstateId, setUserAssessmentstateId] =
    React.useState<any>("");
  const { user } = useSelector((state: RootState) => state);
  const { userId, assessmentState } = user;

  React.useEffect(() => {
    if (isLoading === true) getUserResults();
    const userScoreSubscriptionOnCreate = API.graphql(
      graphqlOperation(customsubscriptions.onUserScoresCreate),
    );
    // @ts-ignore
    userScoreSubscriptionOnCreate.subscribe({
      next: ({ provider, value }: any) => {
        if (
          value.data.onCreateUserScores.userSessionId ===
          assessmentState.sessionId
        ) {
          getUserScores();
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    const userScoreSubscriptionOnUpdate = API.graphql(
      graphqlOperation(customsubscriptions.onUserScoresUpdate),
    );
    // @ts-ignore
    userScoreSubscriptionOnUpdate.subscribe({
      next: ({ provider, value }: any) => {
        if (
          value.data.onUpdateUserScores.userSessionId ===
          assessmentState.sessionId
        ) {
          getUserScores();
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    const userSupportSubscription = API.graphql(
      graphqlOperation(subscriptions.onCreateStudentRequest),
    );
    // @ts-ignore
    userSupportSubscription.subscribe({
      next: ({ provider, value }: any) => {
        if (
          value.data.onCreateStudentRequest.studentUserSessionId ===
          assessmentState.sessionId
        ) {

          dispatchUserSupportRequests({
            type: "add",
            value: value.data.onCreateStudentRequest,
          });
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    const heiSupportSubscription = API.graphql(
      graphqlOperation(subscriptions.onCreateInstitutionResponse),
    );
    // @ts-ignore
    heiSupportSubscription.subscribe({
      next: ({ provider, value }: any) => {
        if (
          value.data.onCreateInstitutionResponse.studentUserSessionId ===
          assessmentState.sessionId
        ) {
          getHEISupportResponses();
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    return () => {
      // @ts-ignore
      if (typeof userScoreSubscriptionOnCreate.unsubscribe === "function") {
        // @ts-ignore
        userScoreSubscriptionOnCreate.unsubscribe();
        // @ts-ignore
        userScoreSubscriptionOnUpdate.unsubscribe();
        // @ts-ignore
        userSupportSubscription.unsubscribe();
        // @ts-ignore
        heiSupportSubscription.unsubscribe();
      }
    };
  }, []);

  async function getUserResults() {
    await getUserScores();
    await getUserSupportRequests();
    await getHEISupportResponses();
    setLoading(false);
  }

  async function getUserScores() {
    EventService.track({
      event_type: "user",
      event_name: "user_report_retrieval_attempt",
      user_type: "student",
      event_data: {},
    });

    try {
      const filterVariables = {
        limit: 10000,
        filter: { userId: { eq: userId } },
      };
      const items = await getOrList(
        listUserScores,
        "listUserScores",
        filterVariables,
      );
      if (items.length > 0) {
        // scoring translated
        const {
          f01,
          f02,
          f03,
          f04,
          f05,
          f06,
          f07,
          f08,
          f09,
          f11,
          f12,
          f13,
          f14,
          f15,
          eyeContact,
          headBodyPosture,
          speechFluency,
          effectivePauses,
          leadershipScoreBand,
          perseveranceScoreBand,
          communicationScoreBand,
          innovativeThinkingScoreBand,
          dimensionNarratives,
          userScoresUserAssessmentStateId,
        } = items[0];

        setUserScoresValues(items[0]);
        const userScores = [
          {
            trait: "Leadership",
            score: leadershipScoreBand,
            quotes: [
              {
                author: "Rosalynn Carter",
                text: "A leader takes people where they want to go. A great leader takes people where they don't necessarily want to go, but ought to be.",
              },
              {
                author: "Jack Welch",
                text: "Before you are a leader, success is all about growing yourself. When you become a leader, success is all about others.",
              },
              {
                author: "Steve Jobs",
                text: "My job is not to be easy on people. My job is to take these great people we have and to push them and make them even better.",
              },
              {
                author: "Sheryl Sandberg",
                text: "Leadership is about making others better as a result of your presence, and making sure that impact lasts in your absence.",
              },
            ],
            behaviors: [
              "Demonstrate strong compassion for the team ",
              "Ability to cultivate an inspiring vision ",
              "Confidence to make tough decisions quickly",
              "Effectively coach and mentor individuals",
            ],
            data: [
              { number: "f08", value: f08, dimension: DIMENSIONS.F08 },
              { number: "f03", value: f03, dimension: DIMENSIONS.F03 },
              { number: "f09", value: f09, dimension: DIMENSIONS.F09 },
              { number: "f12", value: f12, dimension: DIMENSIONS.F12 },
              { number: "f11", value: f11, dimension: DIMENSIONS.F11 },
              { number: "f07", value: f07, dimension: DIMENSIONS.F07 },
              { number: "f02", value: f02, dimension: DIMENSIONS.F02 },
            ],
            dimensionNarratives: JSON.parse(dimensionNarratives) || [],
          },
          {
            trait: "Perseverance",
            score: perseveranceScoreBand,
            quotes: [
              {
                author: "Newt Gingrich",
                text: "Perseverance is the hard work you do after you get tired of doing the hard work you already did.",
              },
              {
                author: "Harriet Beecher Stowe",
                text: "When you get into a tight place and everything goes against you until it seems that you cannot hold on for a minute longer, never give up then, for that is just the place and time when the tide will turn.",
              },
              {
                author: "Thomas Edison",
                text: "Many of life's failures are people who did not realize how close they were to success when they gave up.",
              },
              {
                author: "Marie Curie",
                text: "Life is not easy for any of us. But what of that? We must have perseverance and above all confidence in ourselves. We must believe that we are gifted for something and that this thing must be attained.",
              },
            ],
            behaviors: [
              "Keeps goals visible at all times ",
              "Has a strong growth-mindset",
              "Possesses the ability to override an unwanted thought, feeling, or impulse",
              "Demonstrates passion for reaching their goals",
            ],
            data: [
              { number: "f01", value: f01, dimension: DIMENSIONS.F01 },
              { number: "f06", value: f06, dimension: DIMENSIONS.F06 },
              { number: "f05", value: f05, dimension: DIMENSIONS.F05 },
              { number: "f04", value: f04, dimension: DIMENSIONS.F04 },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
            ],
            dimensionNarratives: JSON.parse(dimensionNarratives) || [],
          },
          {
            trait: "Effective Communication",
            score: communicationScoreBand,
            quotes: [
              {
                author: "Peter Drucker",
                text: "The most important thing in communication is to hear what isn't being said.",
              },
              {
                author: "Jesse James Garrett",
                text: "Effective communication is a key factor in the success of your product.",
              },
              {
                author: "Anthony Robbins",
                text: "To effectively communicate, we must realize that we are all different in the way we perceive the world and use this understanding as a guide to our communication with others.",
              },
              {
                author: "Laurie Buchanan",
                text: "Sometimes it isn't what a person says, but rather what they don't say, that tells you more than their words ever could.",
              },
            ],
            behaviors: [
              "Engaging, interesting, and approachable - command an audience ",
              "Willing to have difficult conversations that are productive ",
              "Communicates both verbally and non-verbally ",
              "Shows empathy for those they are communicating with",
            ],
            data: [
              {
                number: "eyeContact",
                value: eyeContact,
                dimension: "Eye Contact",
              },
              {
                number: "headBodyPosture",
                value: headBodyPosture,
                dimension: "Posture",
              },
              {
                number: "speechFluency",
                value: speechFluency,
                dimension: " Speech Fluency",
              },
              {
                number: "effectivePauses",
                value: effectivePauses,
                dimension: "Effective Pauses",
              },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
            ],
            dimensionNarratives: JSON.parse(dimensionNarratives) || [],
          },
          {
            trait: "Innovative Thinking",
            score: innovativeThinkingScoreBand,
            quotes: [
              {
                author: "Jeff Bezos",
                text: "I think frugality drives innovation, just like other constraints do. One of the only ways to get out of a tight box is to invent your way out.",
              },
              {
                author: "Li Keqiang",
                text: "Changes call for innovation, and innovation leads to progress.",
              },
              {
                author: "Miles Davis",
                text: "I’ll play it first and tell you what it is later.",
              },
              {
                author: "Helen Keller",
                text: "Security is mostly a superstitution. Life is either a daring adventure or nothing.",
              },
            ],
            behaviors: [
              "Introduces and apply new ideas, processes, procedures",
              "Has a strong bias toward action ",
              "Is risk-taking and reflective ",
              "Demonstrates courage and humility",
            ],
            data: [
              { number: "f14", value: f14, dimension: DIMENSIONS.F14 },
              { number: "f15", value: f15, dimension: DIMENSIONS.F15 },
              { number: "f13", value: f13, dimension: DIMENSIONS.F13 },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
            ],
            dimensionNarratives: JSON.parse(dimensionNarratives) || [],
          },
        ];
        setUserScores(userScores);
        setUserAssessmentstateId(userScoresUserAssessmentStateId);
        setFeedbackReady(true);
      }
      EventService.track({
        event_type: "user",
        event_name: "user_report_retrieval_parse",
        user_type: "student",
        event_data: {
          items,
        },
      });

      const narratives = await getOrList(listNarratives, "listNarratives", {});

      setNarratives(narratives);
    } catch (error: unknown) {
      const customError = error as string;
      EventService.track({
        event_type: "user",
        event_name: "user_report_retrieval_error",
        user_type: "student",
        event_data: {
          error: customError,
        },
      });
      console.log("Error retrieving items", error);
    }
  }

  async function getUserSupportRequests() {
    try {
      let filterVariables = {
        limit: 10000,
        filter: {
          and: [
            { studentUserSessionId: { eq: assessmentState.sessionId } },
            { institutionId: { eq: user.institutionId } },
          ],
        },
      };

      const supportRequests = await getOrList(
        searchStudentRequests,
        "searchStudentRequests",
        filterVariables,
      );

      dispatchUserSupportRequests({ type: "set", value: supportRequests });
    } catch (error: unknown) {
      console.log("Error retrieving support requests", error);
    }
  }

  async function getHEISupportResponses() {
    try {
      let filterVariables = {
        limit: 10000,
        filter: {
          and: [{ studentUserSessionId: { eq: assessmentState.sessionId } }],
        },
      };

      const heiSupportResponses = await getOrList(
        searchInstitutionResponses,
        "searchInstitutionResponses",
        filterVariables,
      );

      setHEISupportResponses(heiSupportResponses);
    } catch (error: unknown) {
      console.log("Error retrieving support requests", error);
    }
  }

  const fetchreport = () => {
    EventService.track({
      event_type: "user",
      event_name: "user_report_fetch",
      user_type: "student",
      event_data: {},
    });
    getUserScores();
  };

  return (
    <>
      {isLoading && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Loading />
        </div>
      )}
      {!isLoading && !feedbackReady && (
        <Feedback fetchreport={fetchreport}></Feedback>
      )}
      {!isLoading && feedbackReady && (
        <Routes>
          <Route path="/" element={<Navigate replace to="/report/summary" />} />

          <Route
            path="summary"
            element={
              // <Summary
              //   userScores={userScores}
              //   HEISupportResponses={HEISupportResponses}
              // />
              // for testing purpose
              <InsightsReport userScores={userScoresValues}></InsightsReport>
            }
          />
          <Route
            path="/selfreflect/:trait"
            element={
              <>
                <MainAppHeader />
                <Reflections />
              </>
            }
          />

          <Route
            path="/reflections"
            element={
              <>
                <MainAppHeader />
                <Reflections />
              </>
            }
          />

          <Route
            path="/trait/:trait"
            element={
              <>
                <MainAppHeader />
                <TraitReport
                  userScores={userScores}
                  narratives={narratives}
                  HEISupportResponses={HEISupportResponses}
                />
              </>
            }
          />

          <Route
            path="support"
            element={
              <>
                <MainAppHeader />
                <ResourceHub />
              </>
            }
          />

          <Route
            path="support/:trait"
            element={
              <>
                <MainAppHeader />
                <SingleTraitSupport
                  userScores={userScores}
                  narratives={narratives}
                  userSupportRequests={userSupportRequests}
                  HEISupportResponses={HEISupportResponses}
                />
              </>
            }
          />

          <Route path="resourceCards" element={<ResourceCardCarousel />} />

          <Route
            path="support/inbox/:trait"
            element={
              <SupportInbox
                messages={HEISupportResponses}
                sessionId={assessmentState.sessionId}
              />
            }
          />

          <Route path="support/inbox/message" element={<ExpandedMessage />} />

          <Route
            path="support/request/:trait"
            element={
              <RequestSupport userSupportRequests={userSupportRequests} />
            }
          />

          <Route path="tab" element={<PSQReport />} />
        </Routes>
      )}
    </>
  );
}

export default Report;

// Packages
import { Spinner } from "react-bootstrap";

// Components
import Ring from "../Ring";

// Services
import EventService from "../../../../../services/EventService/event.service";

function Trait({ band, className, trait, handleSelection }: any) {
  const handleClick = (loc: any) => {
    EventService.track({
      event_type: "user",
      event_name: "hei_welcome_trait_click",
      user_type: "hei",
      event_data: {
        trait,
        band,
        quadrant: loc,
      },
    });

    handleSelection("overview");
  };

  return (
    <button
      className={`quadrant font-18 line-28 ${className}`}
      onClick={() => handleClick(`traits/${trait.toLowerCase()}`)}
    >
      <div className="trait-info">
        <div className={`score score-${band}`}> {band !== "" ? band : ""}</div>
        <div className="trait">{trait}</div>
      </div>

      {/* {band !== "" && (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )} */}
    </button>
  );
}

export default Trait;

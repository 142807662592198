// Packages
import { useEffect } from "react";
import { CloseButton } from "react-bootstrap";
import { track } from "../../../../services/EventService/event.service";
import { TermsConView } from "../../../components/TermsConView/TermsConView";

type TermsAndConditionsType = {
  back?: () => void;
};

export const TermsAndConditions = ({ back }: TermsAndConditionsType) => {
  useEffect(() => {
    track({
      event_type: "user",
      event_name: "user_opens_terms",
      event_data: {},
    });
  });

  const handleBack = () => {
    if (back) back();

    track({
      event_type: "user",
      event_name: "user_closes_terms",
      event_data: {},
    });
  };

  return (
    <>
      <CloseButton onClick={handleBack} />
      <h1 className="form-title mt-5 mb-3">Terms and Conditions</h1>
      <TermsConView />
    </>
  );
};

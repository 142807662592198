const getHamburgerMenuItems = (
  propertyKey: string,
  sortingHandler: Function,
) => {
  return [
    {
      text: "Sort by ASC",
      onClick: () =>
        sortingHandler({
          propertyKey: propertyKey,
          sortDirection: "ascending",
        }),
    },
    {
      text: "Sort by DESC",
      onClick: () =>
        sortingHandler({
          propertyKey: propertyKey,
          sortDirection: "descending",
        }),
    },
  ];
};

export default getHamburgerMenuItems;

// Packages
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import Subheader from "../../../../../components/Subheader";
import ResourcesList from "./ResourcesList";

// Services
import EventService from "../../../../../services/EventService/event.service";

const ExpandedMessage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    id,
    institutionUser,
    response,
    responseDate,
    resourceInfo,
    traitName,
  } = state as any;

  React.useEffect(() => {
    EventService.track({
      event_type: "user",
      event_name: "support_message_start",
      user_type: "student",
      event_data: {
        category: traitName,
        message_id: id,
        message_sent_date: responseDate,
      },
    });

    return () => {
      EventService.track({
        event_type: "user",
        event_name: "support_message_end",
        user_type: "student",
        event_data: {
          category: traitName,
          message_id: id,
          message_sent_date: responseDate,
        },
      });
    };
  }, []);

  const profilePictureUrl =
    require("../../../../../assets/fullsummary/user-avatar.png").default;

  const ProfilePicture = (
    <img
      src={profilePictureUrl}
      className="profile-picture"
      alt="Institution"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          require("../../../../../assets/fullsummary/user-avatar.png").default;
      }}
    />
  );

  const handleBack = () => {
    navigate(`/report/support/inbox/${traitName.toLowerCase()}`);
  };

  return (
    <div className="expanded-message">
      <div className="sticky-top header-container">
        <Subheader back={() => handleBack()} text="View Message" />
        <div className="sender-container d-flex">
          <div className="profile-picture-container">{ProfilePicture}</div>
          <div className="d-flex justify-content-center align-items-center">
            <p className="sender-text">{`Message from ${institutionUser.name}`}</p>
          </div>
        </div>
      </div>
      <section className="message-container">
        <div>
          <p className="text">{response}</p>
        </div>
        {resourceInfo.length > 0 && (
          <div>
            <ResourcesList message_id={id} resources={resourceInfo} />
          </div>
        )}
      </section>
    </div>
  );
};

export default ExpandedMessage;

import { API } from "aws-amplify";

export default async function createOrUpdate(schema: any, inputs: any) {
  const data = await API.graphql({
    query: schema,
    variables: { input: inputs },
  });
  return data;
}

export async function getOrList(schema: any, parameter: any, variables: any) {
  const response = await API.graphql({
    query: schema,
    variables: variables,
  });
  const responseObject = JSON.parse(JSON.stringify(response));
  const original = responseObject["data"][parameter];
  if (original.items != undefined) {
    return original["items"];
  } else {
    return original;
  }
}

import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactComponent as InfoIcon } from "../../../../../assets/reskin/icons/transparent-info-circle.svg";
import { TRAIT_DEFINITIONS } from "../../../../../enumerations";

const LollipopChart = ({ trait, data }: any) => {
  const [dataLocal, setDataLocal] = useState<any>(null);
  const [dataPassed, setDataPassed] = useState<any>(null);
  const [centerNumberPlugin, setCenterNumberPlugin] = useState<any>(null);
  const [backgroundColorPlugin, setBackgroundColorPlugin] = useState<any>(null);

  useEffect(() => {
    if (data) {
      data.then((resolvedData: any) => {
        console.log("trait, dataLocal", trait, resolvedData.highPercentage);
        setDataLocal(resolvedData.highPercentage);
      });
    }
  }, [data]);

  useEffect(() => {
    if (dataLocal) {
      const dataPassedTemp = {
        labels: [trait],
        backgroundColor: "#255E6E",
        datasets: [
          {
            data: [dataLocal],
            borderColor: ["#255E6E"],
            barThickness: 4,
            color: "#255E6E",
            backgroundColor: "#255E6E",
          },
        ],
        padding: 10,
      };

      setDataPassed(dataPassedTemp);

      const centerNumberPluginTemp = {
        id: trait,
        afterDatasetDraw: function (chart: any) {
          const ctx = chart.ctx;
          for (let i = 0; i < chart.getDatasetMeta(0).data.length; i++) {
            const x = chart.getDatasetMeta(0).data[i].x;
            const y = chart.getDatasetMeta(0).data[i].y;
            drawCircle(x, y);
          }
          function drawCircle(x: any, y: any) {
            const angle = Math.PI / 180;
            ctx.restore();
            ctx.beginPath();
            ctx.fillStyle = "#255E6E";
            ctx.arc(x, y, 8, angle * 0, angle * 360, false);
            ctx.fill();
            ctx.save();
            ctx.restore();
            ctx.beginPath();
            ctx.fillStyle = colors[trait.toLowerCase().split(" ").join("")];
            ctx.arc(x, y, 5, angle * 0, angle * 360, false);
            ctx.fill();
            ctx.save();
          }
        },
      };
      setCenterNumberPlugin(centerNumberPluginTemp);
      const backgroundColorPluginTemp = {
        id: trait,
        beforeDraw: function (chart: any) {
          if (chart) {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "#f8f8f8";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      };

      setBackgroundColorPlugin(backgroundColorPluginTemp);
    }
  }, [dataLocal, trait]);
  const colors: any = {
    leadership: "#30396C",
    effectivecommunication: "#5D8137",
    perseverance: "#DD614A",
    innovativethinking: "#8865A4",
  };

  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      customCanvasBackgroundColor: {
        color: "lightGreen",
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return "Demonstrating" + ":" + parseInt(context.raw) + "%";
          },
        },
      },
    },

    scales: {
      y: {
        min: 0,
        max: 100,
        // position: { x: 0.2},
        ticks: {
          maxTicksLimit: 6,
          stepSize: 25,
          display: ["Leadership"].includes(trait) ? true : true,
          callback: function (value: any) {
            return ["Leadership"].includes(trait) ? `${value}%` : ``;
          },
          padding: !["Leadership"].includes(trait) ? 5 : 5,
          font: {
            size: 12,
            family: "Open Sans",
            weight: "bold",
          },
        },
        grid: {
          borderDash: [2, 2],
          color: "#30396C",
          borderWidth: 0,
          lineWidth: 1.5,
        },

        beginAtZero: true,
      },
      x: {
        display: false,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        beginAtZero: false,
        width: 0,
      },
    },
  };
  const renderTooltip = (props: any) => (
    <Tooltip
      className={`${trait.toLowerCase().split(" ").join("")}-color ${trait.toLowerCase().split(" ").join("")}-background `}
      id="button-tooltip"
      {...props}
    >
      {TRAIT_DEFINITIONS[trait.toLowerCase() as keyof typeof TRAIT_DEFINITIONS]}
    </Tooltip>
  );

  return (
    <div>
      <div className="header-lollipop">
        <h5
          className={`${trait.toLowerCase().split(" ").join("")}-color`}
          style={{ padding: "10px 5px" }}
        >
          {trait}
        </h5>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <InfoIcon
            className={`${trait.toLowerCase().split(" ").join("")}-color`}
            width={18}
          />
        </OverlayTrigger>
      </div>
      {dataPassed && centerNumberPlugin && (
        <Bar
          data={dataPassed}
          plugins={[backgroundColorPlugin, centerNumberPlugin]}
          options={options}
          width={"210px"}
        />
      )}
    </div>
  );
};

export default LollipopChart;

// Assets
import { ReactComponent as LeadershipImage } from "../../../../../assets/fullsummary/tab/Leadership.svg";
import { ReactComponent as PerseveranceImage } from "../../../../../assets/fullsummary/tab/Perseverance.svg";
import { ReactComponent as CommunicationImage } from "../../../../../assets/fullsummary/tab/Communication.svg";
import { ReactComponent as InnovativeImage } from "../../../../../assets/fullsummary/tab/Innovative.svg";

function SubTraitGraph({ userScores }: any) {
  const determineScoreName = (value: number) => {
    switch (value) {
      case 0:
        return "developing";
      case 1:
        return "approaching";
      case 2:
        return "demonstrating";
    }
  };

  const dummyData = [
    {
      number: "leadership",
      value: 0,
      dimension: "Leadership",
      image: <LeadershipImage className="graph-icons" />,
    },
    {
      number: "perseverance",
      value: 2,
      dimension: "Perseverance",
      image: <PerseveranceImage className="graph-icons" />,
    },
    {
      number: "effectiveCommunication",
      value: 1,
      dimension: (
        <div className="effective-communication">
          <span className="top">Effective</span>
          <span className="bottom">Communication</span>
        </div>
      ),
      image: <CommunicationImage className="graph-icons" />,
    },
    {
      number: "innovativeThinking",
      value: 0,
      dimension: "Innovative Thinking",
      image: <InnovativeImage className="graph-icons" />,
    },
  ];

  const renderBars = () => {
    return userScores.map((subtrait: any, index: number) => {
      const textualValue = determineScoreName(subtrait.value);

      return (
        <>
          <span className="hidden">{`${subtrait.name} ${textualValue}`}</span>
          <div aria-hidden={true} className="subtrait secondary" key={index}>
            <div className="subtrait-name">
              <div className="dimension-and-icon">
                <div className="icon">{subtrait.image}</div>
                <div className="dimension">{subtrait.name}</div>
              </div>
            </div>
            <div
              className={`subtrait-value  ${
                subtrait.value >= 0 ? "filled" : ""
              } ${textualValue}`}
            >
              <div className="bar" />
            </div>
            <div
              className={`subtrait-value ${
                subtrait.value >= 1 ? "filled" : ""
              } ${textualValue}`}
            >
              <div className="bar" />
            </div>
            <div
              className={`subtrait-value ${
                subtrait.value >= 2 ? "filled" : ""
              } ${textualValue}`}
            >
              <div className="bar" />
            </div>
            <div className="subtrait-value "></div>
          </div>
        </>
      );
    });
  };

  return (
    <div className="subtraitGraph">
      <div className="label developing">Developing</div>
      <div className="label approaching">Approaching</div>
      <div className="label demonstrating">Demonstrating</div>
      <div className="subtrait master">
        <div className="subtrait-name"></div>
        <div className="subtrait-value dashed-right"></div>
        <div className="subtrait-value dashed-right"></div>
        <div className="subtrait-value dashed-right"></div>
        <div className="subtrait-value"></div>
      </div>

      {renderBars()}
    </div>
  );
}

export default SubTraitGraph;

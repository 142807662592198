// Packages
import React from "react";

// Assets
import { ReactComponent as NavProfileCircle } from "../../../../assets/reskin/icons/nav-person-circle.svg";

// Components
import ReskinNavBar from "../../../components/NavBars/ReskinNavBar";
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";
import HireupProgressBarFooter from "../../../components/Footers/HireupProgressBarFooter";
import RadioSet from "../QuestionFormats/RadioSet";
import CheckboxSet from "../QuestionFormats/CheckboxSet";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import BIQErrorMessage from "../../../components/Messages/BIQErrorMessage";
import TraitConfidenceQuestions from "../../../../screens/assessment/about-you/question/TraitConfidenceQuestions";
// Styles
import "./BIQQuestionWrapper.scss";
import ReskinTraitConfidenceQuestions from "../../../../screens/assessment/about-you/question/ReskinTraitConfidenceQuestions";

interface QuestionType {
  content: string;
  createdAt: string;
  id: string;
  options: string[];
  profileAttribute: string;
  updatedAt: string;
  sequence: number;
  displayType: string;
}

interface BIQQuestionWrapper {
  currentResponse: any;
  questionTotal: number;
  questions: QuestionType[];
  handleNext: Function;
  handlePrevious: Function;
  handleResponseChange: Function;
  confidenceResponse: any;
  setConfidenceResponse: Function;
  checkConfidenceResponseValidity: Function;
  initialConfidenceResponse: any;
  currentPage: number;
}

interface ErrorMessagesType {
  [key: string]: string;
}

const BIQQuestionWrapper = ({
  questions,
  questionTotal,
  handlePrevious,
  handleNext,
  handleResponseChange,
  currentResponse,
  confidenceResponse,
  setConfidenceResponse,
  checkConfidenceResponseValidity,
  initialConfidenceResponse,
  currentPage,
}: BIQQuestionWrapper) => {
  const [errorMessages, setErrorMessages] = React.useState<ErrorMessagesType>(
    {},
  );
  const [pageStatus, setPageStatus] = React.useState("progress");

  const [showConfidenceResponseError, setShowConfidenceResponseError] =
    React.useState(false);

  React.useEffect(() => {
    const handleNextPageCall = () => {
      if (
        Object.keys(errorMessages).length === 0 &&
        pageStatus === "complete"
      ) {
        setErrorMessages({});
        setPageStatus("progress");
        handleNext();
      }
    };

    handleNextPageCall();
  }, [errorMessages, pageStatus]);

  if (!questions || !questions[0]) {
    return <div>There was an error</div>;
  }
  // Validation
  const checkForErrors = async () => {
    const updatedErrors: ErrorMessagesType = {};

    if (Object.keys(questions[0]).length !== 0) {
      questions.forEach((question) => {
        const { profileAttribute } = question;

        if (
          currentResponse[profileAttribute] == "" ||
          currentResponse[profileAttribute].length == 0
        ) {
          updatedErrors[profileAttribute] = "Please answer this question.";
          updatedErrors.main =
            "Please finish unanswered questions before moving forward.";
        }
      });
    } else {
      checkConfidenceResponseValidity();
      if (!checkConfidenceResponseValidity()) {
        updatedErrors.main =
          "Please finish unanswered questions before moving forward.";
      }
    }
    await setErrorMessages(updatedErrors);
  };

  // Handle styling for if this is the first page
  const firstCurrentQuestion = questions[0];
  const isBeginningOfAssessment =
    firstCurrentQuestion && firstCurrentQuestion.sequence === 1;
  const layoutHandler = isBeginningOfAssessment ? "beginning-layout" : "";

  // Changehandler
  const handleChange = (key: string, value: any) => {
    const updatedResponse = {
      ...currentResponse,
      [key]: value,
    };

    handleResponseChange(updatedResponse);
  };

  const RenderedQuestions = questions.map((question) => {
    const { sequence, content, options, profileAttribute, displayType } =
      question;
    if (question.displayType === "CHECKBOX") {
      return (
        <CheckboxSet
          questionNumber={sequence}
          questionText={content}
          options={options}
          responseKey={profileAttribute}
          selectedOptions={currentResponse[profileAttribute]}
          errorMessage={errorMessages[profileAttribute]}
          onChange={handleChange}
          isRequired={true}
        />
      );
    } else if (displayType === "RADIO") {
      return (
        <RadioSet
          questionNumber={sequence}
          questionText={content}
          options={options}
          responseKey={profileAttribute}
          selectedOption={currentResponse[profileAttribute]}
          errorMessage={errorMessages[profileAttribute]}
          onChange={handleChange}
          isRequired={true}
        />
      );
    } else if (currentPage === 6) {
      // lastpage showing confidence traits as this item does not exist in backend
      return (
        <ReskinTraitConfidenceQuestions
          currentConfidenceResponse={confidenceResponse}
          onChange={(response: any) => setConfidenceResponse(response)}
          setShowError={setShowConfidenceResponseError}
          checkIsValid={checkConfidenceResponseValidity}
          showError={showConfidenceResponseError}
          defaultScores={initialConfidenceResponse}
          questionNumber={10}
        />
      );
    }
  });

  return (
    <>
      <AssessmentHeader isTransparent={false} />
      <div className="biq-question-wrapper">
        {/* <ReskinNavBar rightIcon={<NavProfileCircle />} /> */}
        <div
          className={`error-message-container ${errorMessages.main ? "" : "hidden"}`}
        >
          <BIQErrorMessage text={errorMessages.main} />
        </div>
        <div className="question-wrapper-header-block">
          <h2 className="question-wrapper-header">Tell Us More About You</h2>
        </div>
        <div className="biq-question-content-wrapper">
          <div className="questions">{RenderedQuestions}</div>

          <div className={`buttons-wrapper ${layoutHandler}`}>
            <ReskinButton
              text="Previous"
              variant="secondary"
              customClasses={`${!isBeginningOfAssessment ? "" : "hidden"} reskin-biq-button`}
              size="small"
              onClick={() => {
                handlePrevious();
                setErrorMessages({});
                setPageStatus("progress");
              }}
            />
            <ReskinButton
              text="Next"
              size="small"
              variant="primary"
              customClasses="reskin-biq-button"
              onClick={() => {
                // nextPageHandler in useEffect
                checkForErrors();
                setPageStatus("complete");
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <HireupProgressBarFooter
          itemsCompleted={
            questions && questions[0]
              ? questions[0].sequence == undefined
                ? 10
                : questions[0].sequence
              : 1
          }
          itemsTotal={questionTotal}
          itemName="Question"
        />
      </div>
    </>
  );
};

export default BIQQuestionWrapper;

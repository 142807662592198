// Packages
import React from "react";
import { useSelector } from "react-redux";

// Components
import { Modal, Button } from "react-bootstrap";
import Subheader from "../../../../../../components/Subheader";
import OceanButton from "../../../../../../components/Button/OceanButton";
import DesktopContentView from "./DesktopContentView";

//Redux
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../../../../../redux/reducers";
import { createLearningSelfReflection } from "../../../../../../graphql/mutations";
import MobileContentView from "./MobileContentView";
import EventService from "../../../../../../services/EventService/event.service";

interface ResourceReflectionModalPropTypes {
  show: boolean;
  handleClose: Function;
  resourceCardtrait: string;
}

const ResourceReflectionModal = ({
  show,
  handleClose,
  resourceCardtrait,
}: ResourceReflectionModalPropTypes) => {
  const [reflection1Value, setReflection1Value] = React.useState("");
  const [reflection2Value, setReflection2Value] = React.useState("");

  const {
    userId,
    assessmentState: { UserAssessmentStateId, sessionId, assessmentId },
    institutionId,
  } = useSelector((state: RootState) => state.user);

  const canSubmit =
    reflection1Value.length > 0 || reflection2Value.length > 0 ? true : false;

  const saveResourceReflections = async () => {
    const input = {
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      takeawayComment: reflection1Value ? reflection1Value : null,
      applicationComment: reflection2Value ? reflection2Value : null,
      commentDate: new Date().toISOString(),
      institution: institutionId,
    };
    await API.graphql(
      graphqlOperation(createLearningSelfReflection, { input: input }),
    );
    EventService.track({
      event_type: "user",
      event_name: "resource_reflection_submit",
      user_type: "student",
      event_data: {
        category: `${resourceCardtrait}`,
        text_1: reflection1Value,
        text_2: reflection2Value,
      },
    });
    handleClose();
  };

  return (
    <Modal
      className="resource-reflection-modal"
      onHide={function () {
        EventService.track({
          event_type: "user",
          event_name: "resource_reflection_skip",
          user_type: "student",
          event_data: {
            category: `${resourceCardtrait}`,
          },
        });
        handleClose();
      }}
      show={show}
      onEntered={function () {
        EventService.track({
          event_type: "user",
          event_name: "resource_reflection_open",
          user_type: "student",
          event_data: {
            category: `${resourceCardtrait}`,
            cause: `${resourceCardtrait == "All" ? "User initiated" : "Automatic"}`,
          },
        });
      }}
      size="lg"
      centered
      aria-modal={show}
    >
      <Modal.Header className="resource-reflection-header" closeButton>
        <Modal.Title>
          <div className="header-content">
            <h3>Before Moving On, Take Time To Reflect!</h3>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DesktopContentView
          prompt1Handler={setReflection1Value}
          prompt2Handler={setReflection2Value}
        />
        <MobileContentView
          prompt1Handler={setReflection1Value}
          prompt2Handler={setReflection2Value}
        />
      </Modal.Body>

      <Modal.Footer>
        <section className="controls-container">
          <Button
            bsPrefix="psq-btn"
            variant="secondary"
            className="control-button"
            onClick={function () {
              EventService.track({
                event_type: "user",
                event_name: "resource_reflection_skip",
                user_type: "student",
                event_data: {
                  category: `${resourceCardtrait}`,
                },
              });
              handleClose();
            }}
          >
            Skip
          </Button>
          <OceanButton
            onClick={() => {
              saveResourceReflections();
              handleClose();
            }}
            text="Submit All"
            customClasses="submit-all-button control-button"
            disabled={!canSubmit}
          />
        </section>
      </Modal.Footer>
    </Modal>
  );
};

export default ResourceReflectionModal;

// Packages
import React from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * When defining the labels, the key should be the index of the segment that you want
 * the label to appear beneath.
 *
 * i.e. if want to label the first segment "first option", you must define
 * {
 *      0: "first option"
 * }
 */
interface LabelType {
  [key: number]: string;
}

interface HorizontalRadioProps {
  onChange: Function;
  defaultValue: number;
  maximumValue: number;
  labels?: LabelType;
  SelectedIcon: JSX.Element;
  UnselectedIcon: JSX.Element;
  headerText: string;

  // Important for accessibility purposes
  radioDescriptorId: string;

  // Optional className controllers
  componentCustomClasses?: string;
  headerCustomClasses?: string;
  bodyCustomClasses?: string;

  // component will append the class "selected-divider" to selected divider
  selectedDividerClasses?: string;
  unselectedDividerClasses?: string;
}

const HorizontalScaleRadio = ({
  onChange,
  defaultValue,
  maximumValue,
  labels,
  SelectedIcon,
  UnselectedIcon,
  headerText,
  radioDescriptorId,
  componentCustomClasses,
  // optional className controllers
  headerCustomClasses,
  bodyCustomClasses,
  selectedDividerClasses,
  unselectedDividerClasses,
}: HorizontalRadioProps) => {
  const RenderedRadioSegments: JSX.Element[] = [];
  const [currentValue, setCurrentValue] = React.useState(defaultValue);
  const radioKey = uuidv4();

  React.useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const getCurrentIcon = (isFilled: boolean) => {
    if (isFilled) {
      return <>{SelectedIcon}</>;
    } else {
      return <>{UnselectedIcon}</>;
    }
  };
  const dividerClassCodex = {
    selected: selectedDividerClasses
      ? `${selectedDividerClasses} selected-divider`
      : "default-divider selected-divider",
    unselected: unselectedDividerClasses
      ? unselectedDividerClasses
      : "default-divider",
  };
  const getDividerClass = (isSelected: boolean) =>
    isSelected ? dividerClassCodex.selected : dividerClassCodex.unselected;
  const handleDefaultClassOverride = (customClasses: string | undefined) =>
    customClasses ? customClasses : "default";

  const GenerateRadioSegments = () => {
    for (let i = 0; i < maximumValue; i++) {
      const segmentValue = i + 1;
      const isSegmentFilled = segmentValue <= currentValue;
      const isSegmentLabeled = labels && labels[i];
      const CurrentIcon = getCurrentIcon(isSegmentFilled);

      if (i === 0) {
        RenderedRadioSegments.push(
          <div
            className="radio-segment-icon-wrapper"
            key={`radio-${radioKey}-segment-${i}`}
          >
            <input
              className="hidden-input"
              id={`${radioDescriptorId}-${segmentValue}`}
              value={segmentValue}
              type="radio"
            />
            <label
              htmlFor={`${radioDescriptorId}-${segmentValue}`}
              className="segment-label radio-segment-icon active"
              onClick={() => {
                setCurrentValue(segmentValue);
                onChange(segmentValue);
              }}
            >
              {CurrentIcon}
            </label>
            {isSegmentLabeled && <p className="visual-label">{labels[i]}</p>}
          </div>,
        );
      } else {
        // add a divider if it's not the first segment
        RenderedRadioSegments.push(
          <React.Fragment key={`radio-${radioKey}-segment-${i}`}>
            <div
              className={`segment-divider ${getDividerClass(isSegmentFilled)}`}
            />
            <div className="radio-segment-icon-wrapper">
              <input
                className="hidden-input"
                id={`${radioDescriptorId}-${segmentValue}`}
                value={segmentValue}
                type="radio"
              />
              <label
                htmlFor={`${radioDescriptorId}-${segmentValue}`}
                className="segment-label radio-segment-icon active"
                onClick={() => {
                  setCurrentValue(segmentValue);
                  onChange(segmentValue);
                }}
              >
                {CurrentIcon}
              </label>
              {isSegmentLabeled && (
                <p className="visual-label unselectable">{labels[i]}</p>
              )}
            </div>
          </React.Fragment>,
        );
      }
    }
  };

  GenerateRadioSegments();

  return (
    <fieldset className={`horizontal-scale-radio ${componentCustomClasses}`}>
      <div className="d-flex flex-column internal-wrapper">
        <div
          className={`horizontal-radio-header m-0 ${handleDefaultClassOverride(headerCustomClasses)}`}
        >
          <legend className="radio-legend unselectable">{headerText}</legend>
        </div>
        <div className="horizontal-radio-body">
          <div
            className={`segments-wrapper m-0 ${handleDefaultClassOverride(bodyCustomClasses)}`}
          >
            {RenderedRadioSegments}
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default HorizontalScaleRadio;

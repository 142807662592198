/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudentRequest = /* GraphQL */ `
  mutation CreateStudentRequest(
    $input: CreateStudentRequestInput!
    $condition: ModelStudentRequestConditionInput
  ) {
    createStudentRequest(input: $input, condition: $condition) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudentRequest = /* GraphQL */ `
  mutation UpdateStudentRequest(
    $input: UpdateStudentRequestInput!
    $condition: ModelStudentRequestConditionInput
  ) {
    updateStudentRequest(input: $input, condition: $condition) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudentRequest = /* GraphQL */ `
  mutation DeleteStudentRequest(
    $input: DeleteStudentRequestInput!
    $condition: ModelStudentRequestConditionInput
  ) {
    deleteStudentRequest(input: $input, condition: $condition) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStudentResource = /* GraphQL */ `
  mutation CreateStudentResource(
    $input: CreateStudentResourceInput!
    $condition: ModelStudentResourceConditionInput
  ) {
    createStudentResource(input: $input, condition: $condition) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudentResource = /* GraphQL */ `
  mutation UpdateStudentResource(
    $input: UpdateStudentResourceInput!
    $condition: ModelStudentResourceConditionInput
  ) {
    updateStudentResource(input: $input, condition: $condition) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudentResource = /* GraphQL */ `
  mutation DeleteStudentResource(
    $input: DeleteStudentResourceInput!
    $condition: ModelStudentResourceConditionInput
  ) {
    deleteStudentResource(input: $input, condition: $condition) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInstitutionResponse = /* GraphQL */ `
  mutation CreateInstitutionResponse(
    $input: CreateInstitutionResponseInput!
    $condition: ModelInstitutionResponseConditionInput
  ) {
    createInstitutionResponse(input: $input, condition: $condition) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInstitutionResponse = /* GraphQL */ `
  mutation UpdateInstitutionResponse(
    $input: UpdateInstitutionResponseInput!
    $condition: ModelInstitutionResponseConditionInput
  ) {
    updateInstitutionResponse(input: $input, condition: $condition) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInstitutionResponse = /* GraphQL */ `
  mutation DeleteInstitutionResponse(
    $input: DeleteInstitutionResponseInput!
    $condition: ModelInstitutionResponseConditionInput
  ) {
    deleteInstitutionResponse(input: $input, condition: $condition) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInstitutionUser = /* GraphQL */ `
  mutation CreateInstitutionUser(
    $input: CreateInstitutionUserInput!
    $condition: ModelInstitutionUserConditionInput
  ) {
    createInstitutionUser(input: $input, condition: $condition) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInstitutionUser = /* GraphQL */ `
  mutation UpdateInstitutionUser(
    $input: UpdateInstitutionUserInput!
    $condition: ModelInstitutionUserConditionInput
  ) {
    updateInstitutionUser(input: $input, condition: $condition) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInstitutionUser = /* GraphQL */ `
  mutation DeleteInstitutionUser(
    $input: DeleteInstitutionUserInput!
    $condition: ModelInstitutionUserConditionInput
  ) {
    deleteInstitutionUser(input: $input, condition: $condition) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLearningResource = /* GraphQL */ `
  mutation CreateLearningResource(
    $input: CreateLearningResourceInput!
    $condition: ModelLearningResourceConditionInput
  ) {
    createLearningResource(input: $input, condition: $condition) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLearningResource = /* GraphQL */ `
  mutation UpdateLearningResource(
    $input: UpdateLearningResourceInput!
    $condition: ModelLearningResourceConditionInput
  ) {
    updateLearningResource(input: $input, condition: $condition) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLearningResource = /* GraphQL */ `
  mutation DeleteLearningResource(
    $input: DeleteLearningResourceInput!
    $condition: ModelLearningResourceConditionInput
  ) {
    deleteLearningResource(input: $input, condition: $condition) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStudentRecommendedResources = /* GraphQL */ `
  mutation CreateStudentRecommendedResources(
    $input: CreateStudentRecommendedResourcesInput!
    $condition: ModelStudentRecommendedResourcesConditionInput
  ) {
    createStudentRecommendedResources(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudentRecommendedResources = /* GraphQL */ `
  mutation UpdateStudentRecommendedResources(
    $input: UpdateStudentRecommendedResourcesInput!
    $condition: ModelStudentRecommendedResourcesConditionInput
  ) {
    updateStudentRecommendedResources(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudentRecommendedResources = /* GraphQL */ `
  mutation DeleteStudentRecommendedResources(
    $input: DeleteStudentRecommendedResourcesInput!
    $condition: ModelStudentRecommendedResourcesConditionInput
  ) {
    deleteStudentRecommendedResources(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createResourceReaction = /* GraphQL */ `
  mutation CreateResourceReaction(
    $input: CreateResourceReactionInput!
    $condition: ModelResourceReactionConditionInput
  ) {
    createResourceReaction(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateResourceReaction = /* GraphQL */ `
  mutation UpdateResourceReaction(
    $input: UpdateResourceReactionInput!
    $condition: ModelResourceReactionConditionInput
  ) {
    updateResourceReaction(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteResourceReaction = /* GraphQL */ `
  mutation DeleteResourceReaction(
    $input: DeleteResourceReactionInput!
    $condition: ModelResourceReactionConditionInput
  ) {
    deleteResourceReaction(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserConfidenceLevel = /* GraphQL */ `
  mutation CreateUserConfidenceLevel(
    $input: CreateUserConfidenceLevelInput!
    $condition: ModelUserConfidenceLevelConditionInput
  ) {
    createUserConfidenceLevel(input: $input, condition: $condition) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserConfidenceLevel = /* GraphQL */ `
  mutation UpdateUserConfidenceLevel(
    $input: UpdateUserConfidenceLevelInput!
    $condition: ModelUserConfidenceLevelConditionInput
  ) {
    updateUserConfidenceLevel(input: $input, condition: $condition) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserConfidenceLevel = /* GraphQL */ `
  mutation DeleteUserConfidenceLevel(
    $input: DeleteUserConfidenceLevelInput!
    $condition: ModelUserConfidenceLevelConditionInput
  ) {
    deleteUserConfidenceLevel(input: $input, condition: $condition) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStudentVisitedLearningResource = /* GraphQL */ `
  mutation CreateStudentVisitedLearningResource(
    $input: CreateStudentVisitedLearningResourceInput!
    $condition: ModelStudentVisitedLearningResourceConditionInput
  ) {
    createStudentVisitedLearningResource(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudentVisitedLearningResource = /* GraphQL */ `
  mutation UpdateStudentVisitedLearningResource(
    $input: UpdateStudentVisitedLearningResourceInput!
    $condition: ModelStudentVisitedLearningResourceConditionInput
  ) {
    updateStudentVisitedLearningResource(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudentVisitedLearningResource = /* GraphQL */ `
  mutation DeleteStudentVisitedLearningResource(
    $input: DeleteStudentVisitedLearningResourceInput!
    $condition: ModelStudentVisitedLearningResourceConditionInput
  ) {
    deleteStudentVisitedLearningResource(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStudentFavouriteLearningResource = /* GraphQL */ `
  mutation CreateStudentFavouriteLearningResource(
    $input: CreateStudentFavouriteLearningResourceInput!
    $condition: ModelStudentFavouriteLearningResourceConditionInput
  ) {
    createStudentFavouriteLearningResource(
      input: $input
      condition: $condition
    ) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudentFavouriteLearningResource = /* GraphQL */ `
  mutation UpdateStudentFavouriteLearningResource(
    $input: UpdateStudentFavouriteLearningResourceInput!
    $condition: ModelStudentFavouriteLearningResourceConditionInput
  ) {
    updateStudentFavouriteLearningResource(
      input: $input
      condition: $condition
    ) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudentFavouriteLearningResource = /* GraphQL */ `
  mutation DeleteStudentFavouriteLearningResource(
    $input: DeleteStudentFavouriteLearningResourceInput!
    $condition: ModelStudentFavouriteLearningResourceConditionInput
  ) {
    deleteStudentFavouriteLearningResource(
      input: $input
      condition: $condition
    ) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLearningSelfReflection = /* GraphQL */ `
  mutation CreateLearningSelfReflection(
    $input: CreateLearningSelfReflectionInput!
    $condition: ModelLearningSelfReflectionConditionInput
  ) {
    createLearningSelfReflection(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLearningSelfReflection = /* GraphQL */ `
  mutation UpdateLearningSelfReflection(
    $input: UpdateLearningSelfReflectionInput!
    $condition: ModelLearningSelfReflectionConditionInput
  ) {
    updateLearningSelfReflection(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLearningSelfReflection = /* GraphQL */ `
  mutation DeleteLearningSelfReflection(
    $input: DeleteLearningSelfReflectionInput!
    $condition: ModelLearningSelfReflectionConditionInput
  ) {
    deleteLearningSelfReflection(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLearningSelfReflectionCount = /* GraphQL */ `
  mutation CreateLearningSelfReflectionCount(
    $input: CreateLearningSelfReflectionCountInput!
    $condition: ModelLearningSelfReflectionCountConditionInput
  ) {
    createLearningSelfReflectionCount(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLearningSelfReflectionCount = /* GraphQL */ `
  mutation UpdateLearningSelfReflectionCount(
    $input: UpdateLearningSelfReflectionCountInput!
    $condition: ModelLearningSelfReflectionCountConditionInput
  ) {
    updateLearningSelfReflectionCount(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLearningSelfReflectionCount = /* GraphQL */ `
  mutation DeleteLearningSelfReflectionCount(
    $input: DeleteLearningSelfReflectionCountInput!
    $condition: ModelLearningSelfReflectionCountConditionInput
  ) {
    deleteLearningSelfReflectionCount(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserResponses = /* GraphQL */ `
  mutation CreateUserResponses(
    $input: CreateUserResponsesInput!
    $condition: ModelUserResponsesConditionInput
  ) {
    createUserResponses(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const updateUserResponses = /* GraphQL */ `
  mutation UpdateUserResponses(
    $input: UpdateUserResponsesInput!
    $condition: ModelUserResponsesConditionInput
  ) {
    updateUserResponses(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const deleteUserResponses = /* GraphQL */ `
  mutation DeleteUserResponses(
    $input: DeleteUserResponsesInput!
    $condition: ModelUserResponsesConditionInput
  ) {
    deleteUserResponses(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const createBIQ = /* GraphQL */ `
  mutation CreateBIQ(
    $input: CreateBIQInput!
    $condition: ModelBIQConditionInput
  ) {
    createBIQ(input: $input, condition: $condition) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBIQ = /* GraphQL */ `
  mutation UpdateBIQ(
    $input: UpdateBIQInput!
    $condition: ModelBIQConditionInput
  ) {
    updateBIQ(input: $input, condition: $condition) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBIQ = /* GraphQL */ `
  mutation DeleteBIQ(
    $input: DeleteBIQInput!
    $condition: ModelBIQConditionInput
  ) {
    deleteBIQ(input: $input, condition: $condition) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSectionItem = /* GraphQL */ `
  mutation CreateSectionItem(
    $input: CreateSectionItemInput!
    $condition: ModelSectionItemConditionInput
  ) {
    createSectionItem(input: $input, condition: $condition) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const updateSectionItem = /* GraphQL */ `
  mutation UpdateSectionItem(
    $input: UpdateSectionItemInput!
    $condition: ModelSectionItemConditionInput
  ) {
    updateSectionItem(input: $input, condition: $condition) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const deleteSectionItem = /* GraphQL */ `
  mutation DeleteSectionItem(
    $input: DeleteSectionItemInput!
    $condition: ModelSectionItemConditionInput
  ) {
    deleteSectionItem(input: $input, condition: $condition) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAssessmentSection = /* GraphQL */ `
  mutation CreateAssessmentSection(
    $input: CreateAssessmentSectionInput!
    $condition: ModelAssessmentSectionConditionInput
  ) {
    createAssessmentSection(input: $input, condition: $condition) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const updateAssessmentSection = /* GraphQL */ `
  mutation UpdateAssessmentSection(
    $input: UpdateAssessmentSectionInput!
    $condition: ModelAssessmentSectionConditionInput
  ) {
    updateAssessmentSection(input: $input, condition: $condition) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const deleteAssessmentSection = /* GraphQL */ `
  mutation DeleteAssessmentSection(
    $input: DeleteAssessmentSectionInput!
    $condition: ModelAssessmentSectionConditionInput
  ) {
    deleteAssessmentSection(input: $input, condition: $condition) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const createInstitution = /* GraphQL */ `
  mutation CreateInstitution(
    $input: CreateInstitutionInput!
    $condition: ModelInstitutionConditionInput
  ) {
    createInstitution(input: $input, condition: $condition) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInstitution = /* GraphQL */ `
  mutation UpdateInstitution(
    $input: UpdateInstitutionInput!
    $condition: ModelInstitutionConditionInput
  ) {
    updateInstitution(input: $input, condition: $condition) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInstitution = /* GraphQL */ `
  mutation DeleteInstitution(
    $input: DeleteInstitutionInput!
    $condition: ModelInstitutionConditionInput
  ) {
    deleteInstitution(input: $input, condition: $condition) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserAssessmentState = /* GraphQL */ `
  mutation CreateUserAssessmentState(
    $input: CreateUserAssessmentStateInput!
    $condition: ModelUserAssessmentStateConditionInput
  ) {
    createUserAssessmentState(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const updateUserAssessmentState = /* GraphQL */ `
  mutation UpdateUserAssessmentState(
    $input: UpdateUserAssessmentStateInput!
    $condition: ModelUserAssessmentStateConditionInput
  ) {
    updateUserAssessmentState(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const deleteUserAssessmentState = /* GraphQL */ `
  mutation AssessmentState(
    $input: DeleteUserAssessmentStateInput!
    $condition: ModelUserAssessmentStateConditionInput
  ) {
    deleteUserAssessmentState(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const createUserScores = /* GraphQL */ `
  mutation CreateUserScores(
    $input: CreateUserScoresInput!
    $condition: ModelUserScoresConditionInput
  ) {
    createUserScores(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const updateUserScores = /* GraphQL */ `
  mutation UpdateUserScores(
    $input: UpdateUserScoresInput!
    $condition: ModelUserScoresConditionInput
  ) {
    updateUserScores(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const deleteUserScores = /* GraphQL */ `
  mutation DeleteUserScores(
    $input: DeleteUserScoresInput!
    $condition: ModelUserScoresConditionInput
  ) {
    deleteUserScores(input: $input, condition: $condition) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCutOffScores = /* GraphQL */ `
  mutation CreateCutOffScores(
    $input: CreateCutOffScoresInput!
    $condition: ModelCutOffScoresConditionInput
  ) {
    createCutOffScores(input: $input, condition: $condition) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCutOffScores = /* GraphQL */ `
  mutation UpdateCutOffScores(
    $input: UpdateCutOffScoresInput!
    $condition: ModelCutOffScoresConditionInput
  ) {
    updateCutOffScores(input: $input, condition: $condition) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCutOffScores = /* GraphQL */ `
  mutation DeleteCutOffScores(
    $input: DeleteCutOffScoresInput!
    $condition: ModelCutOffScoresConditionInput
  ) {
    deleteCutOffScores(input: $input, condition: $condition) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvents = /* GraphQL */ `
  mutation CreateEvents(
    $input: CreateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    createEvents(input: $input, condition: $condition) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateEvents = /* GraphQL */ `
  mutation UpdateEvents(
    $input: UpdateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    updateEvents(input: $input, condition: $condition) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteEvents = /* GraphQL */ `
  mutation DeleteEvents(
    $input: DeleteEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    deleteEvents(input: $input, condition: $condition) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createNarratives = /* GraphQL */ `
  mutation CreateNarratives(
    $input: CreateNarrativesInput!
    $condition: ModelNarrativesConditionInput
  ) {
    createNarratives(input: $input, condition: $condition) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNarratives = /* GraphQL */ `
  mutation UpdateNarratives(
    $input: UpdateNarrativesInput!
    $condition: ModelNarrativesConditionInput
  ) {
    updateNarratives(input: $input, condition: $condition) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNarratives = /* GraphQL */ `
  mutation DeleteNarratives(
    $input: DeleteNarrativesInput!
    $condition: ModelNarrativesConditionInput
  ) {
    deleteNarratives(input: $input, condition: $condition) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInstitutionNarratives = /* GraphQL */ `
  mutation CreateInstitutionNarratives(
    $input: CreateInstitutionNarrativesInput!
    $condition: ModelInstitutionNarrativesConditionInput
  ) {
    createInstitutionNarratives(input: $input, condition: $condition) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInstitutionNarratives = /* GraphQL */ `
  mutation UpdateInstitutionNarratives(
    $input: UpdateInstitutionNarrativesInput!
    $condition: ModelInstitutionNarrativesConditionInput
  ) {
    updateInstitutionNarratives(input: $input, condition: $condition) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInstitutionNarratives = /* GraphQL */ `
  mutation DeleteInstitutionNarratives(
    $input: DeleteInstitutionNarrativesInput!
    $condition: ModelInstitutionNarrativesConditionInput
  ) {
    deleteInstitutionNarratives(input: $input, condition: $condition) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEventTracking = /* GraphQL */ `
  mutation CreateEventTracking(
    $input: CreateEventTrackingInput!
    $condition: ModelEventTrackingConditionInput
  ) {
    createEventTracking(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEventTracking = /* GraphQL */ `
  mutation UpdateEventTracking(
    $input: UpdateEventTrackingInput!
    $condition: ModelEventTrackingConditionInput
  ) {
    updateEventTracking(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEventTracking = /* GraphQL */ `
  mutation DeleteEventTracking(
    $input: DeleteEventTrackingInput!
    $condition: ModelEventTrackingConditionInput
  ) {
    deleteEventTracking(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createQuickTips = /* GraphQL */ `
  mutation CreateQuickTips(
    $input: CreateQuickTipsInput!
    $condition: ModelQuickTipsConditionInput
  ) {
    createQuickTips(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateQuickTips = /* GraphQL */ `
  mutation UpdateQuickTips(
    $input: UpdateQuickTipsInput!
    $condition: ModelQuickTipsConditionInput
  ) {
    updateQuickTips(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteQuickTips = /* GraphQL */ `
  mutation DeleteQuickTips(
    $input: DeleteQuickTipsInput!
    $condition: ModelQuickTipsConditionInput
  ) {
    deleteQuickTips(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCandidateComments = /* GraphQL */ `
  mutation CreateCandidateComments(
    $input: CreateCandidateCommentsInput!
    $condition: ModelCandidateCommentsConditionInput
  ) {
    createCandidateComments(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCandidateComments = /* GraphQL */ `
  mutation UpdateCandidateComments(
    $input: UpdateCandidateCommentsInput!
    $condition: ModelCandidateCommentsConditionInput
  ) {
    updateCandidateComments(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCandidateComments = /* GraphQL */ `
  mutation DeleteCandidateComments(
    $input: DeleteCandidateCommentsInput!
    $condition: ModelCandidateCommentsConditionInput
  ) {
    deleteCandidateComments(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHeiComments = /* GraphQL */ `
  mutation CreateHeiComments(
    $input: CreateHeiCommentsInput!
    $condition: ModelHeiCommentsConditionInput
  ) {
    createHeiComments(input: $input, condition: $condition) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const updateHeiComments = /* GraphQL */ `
  mutation UpdateHeiComments(
    $input: UpdateHeiCommentsInput!
    $condition: ModelHeiCommentsConditionInput
  ) {
    updateHeiComments(input: $input, condition: $condition) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const deleteHeiComments = /* GraphQL */ `
  mutation DeleteHeiComments(
    $input: DeleteHeiCommentsInput!
    $condition: ModelHeiCommentsConditionInput
  ) {
    deleteHeiComments(input: $input, condition: $condition) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const createHeiBookmarks = /* GraphQL */ `
  mutation CreateHeiBookmarks(
    $input: CreateHeiBookmarksInput!
    $condition: ModelHeiBookmarksConditionInput
  ) {
    createHeiBookmarks(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHeiBookmarks = /* GraphQL */ `
  mutation UpdateHeiBookmarks(
    $input: UpdateHeiBookmarksInput!
    $condition: ModelHeiBookmarksConditionInput
  ) {
    updateHeiBookmarks(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHeiBookmarks = /* GraphQL */ `
  mutation DeleteHeiBookmarks(
    $input: DeleteHeiBookmarksInput!
    $condition: ModelHeiBookmarksConditionInput
  ) {
    deleteHeiBookmarks(input: $input, condition: $condition) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInsights = /* GraphQL */ `
  mutation CreateInsights(
    $input: CreateInsightsInput!
    $condition: ModelInsightsConditionInput
  ) {
    createInsights(input: $input, condition: $condition) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInsights = /* GraphQL */ `
  mutation UpdateInsights(
    $input: UpdateInsightsInput!
    $condition: ModelInsightsConditionInput
  ) {
    updateInsights(input: $input, condition: $condition) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInsights = /* GraphQL */ `
  mutation DeleteInsights(
    $input: DeleteInsightsInput!
    $condition: ModelInsightsConditionInput
  ) {
    deleteInsights(input: $input, condition: $condition) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

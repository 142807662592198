import { API } from "aws-amplify";
import * as queries from "../../../../graphql/queries";

const getBookmarkedCandidates = async (userId: string) => {
  let filterExpression = { userId: { eq: userId } };

  // get bookmarks
  const response = await API.graphql({
    query: queries.listHeiBookmarks,
    variables: { filter: filterExpression, limit: 100000 },
  });

  const responseObject = JSON.parse(JSON.stringify(response));
  const heiBookmarks = responseObject.data.listHeiBookmarks.items;
  let bookmarkedCandidates = heiBookmarks[0]?.candidateUserId;
  return bookmarkedCandidates;
};

export default getBookmarkedCandidates;

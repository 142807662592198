// normalizing traitName for use as a key.
const getNormalizedTraitName = (traitName) => {
  if (traitName === "effective communication") {
    return "effectiveCommunication";
  } else if (traitName === "innovative thinking") {
    return "innovativeThinking";
  } else {
    return traitName;
  }
};

const getMostRecentResponse = (items) => {
  let mostRecentResponse = {
    responseDate: "",
  };

  items.forEach((item) => {
    if (
      item.responseDate &&
      item.responseDate > mostRecentResponse.responseDate
    ) {
      mostRecentResponse = item;
    }
  });

  return mostRecentResponse;
};

const formatAWSDate = (dateString) => {
  if (dateString) {
    const truncatedDate = dateString.slice(0, 10);
    const formattedDate = truncatedDate.replace("-", "/");
    return formattedDate;
  }
};

const getFirstName = (name) => {
  const firstName = name.split(" ")[0];
  return firstName;
};

const sortRequestsIntoRows = (supportRequests) => {
  const rows = {};

  // organize rows by user
  supportRequests.forEach((request) => {
    const { studentUserSession, traitName, institutionResponses } = request;

    if (!studentUserSession) return;

    const { userId, user, userSessionId } = studentUserSession;
    const { name } = user;

    // create row for userId if one does not yet exist
    if (!rows[userId]) {
      rows[userId] = {};
      rows[userId].name = name;
      rows[userId].userId = userId;
      rows[userId].userSessionId = userSessionId;
    }

    // create object for populating supportRow components
    if (institutionResponses) {
      const { items } = institutionResponses;
      const normalizedTraitName = getNormalizedTraitName(traitName);

      if (items && items.length > 0) {
        const mostRecentResponse = getMostRecentResponse(items);
        const { institutionUser, responseDate } = mostRecentResponse;
        const formattedDate = formatAWSDate(responseDate);

        rows[userId][normalizedTraitName] = {
          sender: getFirstName(institutionUser.name),
          date: formattedDate,
          status: "sent",
        };
      } else {
        rows[userId][normalizedTraitName] = { status: "requested" };
      }
    }
  });

  return rows;
};

export default sortRequestsIntoRows;

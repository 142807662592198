// Packages
import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import Subheader from "../../../components/Subheader";
import { LazyInstitution } from "../../../models";
import { RootState } from "../../../redux/reducers";
import * as eventServices from "../../../services/EventService/event.service";

function MyAccount() {
  let navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state);
  const { email, name, enrollmentYear, enrollmentSemester, institution } = user;
  let firstName = name?.split(" ")[0];
  let lastName = name?.split(" ")[1];

  const [currentInstitution, setCurrentInstitution] =
    React.useState<LazyInstitution | null>(null);

  React.useEffect(() => {
    const setInitialInstitution = async () => {
      async function getInstitution(user: any) {
        return await user.institution;
      }
      const newInstitution = await getInstitution(user);
      setCurrentInstitution(newInstitution);
    };
    setInitialInstitution();
  }, []);

  let instituteName =
    currentInstitution && currentInstitution?.name
      ? currentInstitution.name
      : "";

  React.useEffect(() => {
    eventServices.page({
      page: "My Account",
    });

    return () => {
      eventServices.page({
        type: "exit",
        page: "My Account",
      });
    };
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Subheader text="My Account" back={goBack} />
      <section className="myAccount section bg-gradient-vertical">
        <div className="wrapper container pb-4">
          <div className="content">
            <Form>
              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  disabled
                  placeholder="name@example.com"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="*******"
                  readOnly
                  plaintext
                  disabled
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">First Name</Form.Label>
                <Form.Control
                  type="First Name"
                  value={firstName}
                  disabled
                  placeholder="First Name"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">Last Name</Form.Label>
                <Form.Control
                  type="Last Name"
                  value={lastName}
                  disabled
                  placeholder="Last Name"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">
                  Your institution that Requests PSQ
                </Form.Label>
                <Form.Control
                  disabled
                  value={instituteName}
                  // placeholder="University"
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">
                  Year of Enrollment
                </Form.Label>
                <Form.Control
                  type="Year of Enrollment"
                  value={enrollmentYear ? enrollmentYear : ""}
                  disabled
                  readOnly
                  plaintext
                ></Form.Control>
                <hr className="no-margin" />
              </Form.Group>

              <Form.Group
                className="mb-3 px-3"
                controlId="accountForm.controlInput1"
              >
                <Form.Label className="formlabel">
                  Semester of Enrollment
                </Form.Label>
                <Form.Control
                  type="Semster of Enrollment"
                  value={enrollmentSemester ? enrollmentSemester : ""}
                  disabled
                  readOnly
                  plaintext
                ></Form.Control>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}

export default MyAccount;

// Packages
import React from "react";
import { Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";

/**
 * VerificationNumberInputs.
 *
 * Component which displays 6 digit code form. It also handles resending the user a new code.
 *
 * @handleCodeChanges Function from parent component which holds the state of codes.
 * @isInvalid boolean. Whether or not the numbers are invalid.
 * @userIdentifier The userId or email of the user, different paras are needed due to the origin.
 * @origin Where this component is being rendered (forgot, reset, verify).
 * @resetValidity Enables the ability to reset the validity of the numbers.
 *
 */

export const VerificationNumberInputs = ({
  handleCodeChanges,
  isInvalid,
  userIdentifier,
  origin = "forgot",
  resetValidity,
  setFullCode,
  error,
}: any) => {
  const [codes, setCodes] = React.useState<any>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });

  const [resentCode, setResentCode] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (resentCode) {
      setTimeout(() => {
        setResentCode(false);
      }, 2000);
    }
  }, [resentCode]);

  React.useEffect(() => {
    if (isInvalid)
      setCodes({
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
      });

    handleCodeChanges("");
  }, [isInvalid]);

  const handleCodeChange = (event: any) => {
    let { value, dataset } = event.target;
    const { prop } = dataset;

    const number = prop.split("number")[1];
    const newCodes = { ...codes, [number]: value };
    handleCodeChanges(Object.values(newCodes).join(""));

    setCodes(newCodes);

    // focus next input
    if (value !== "" && value.length > 0) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
    }
  };

  const sendNewCode = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    switch (origin) {
      case "forgot":
        // non logged in user is trying to reset their password
        try {
          const attempt = await Auth.forgotPassword(userIdentifier);
          console.log(attempt);
        } catch (err) {
          console.log("error resending code: ", err);
        }
        break;

      case "reset":
        // logged in user is trying to reset their password
        try {
          const attempt = await Auth.forgotPassword(userIdentifier);
          console.log(attempt);
        } catch (err) {
          console.log("error resending code: ", err);
        }
        break;

      case "verify":
        // non logged in user is trying to verify their account
        try {
          await Auth.resendSignUp(userIdentifier);
          console.log("code resent successfully");
        } catch (err) {
          console.log("error resending code: ", err);
        }
        break;
    }

    setResentCode(true);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').slice(0, 6);
    const splitValues = pastedText.split('');
    const newCodesObject: { [key: number]: string } = {};
    let currentCodeKey = 1;

    while (currentCodeKey <= 6) {
      const currentIndex = currentCodeKey - 1;
      newCodesObject[currentCodeKey] = splitValues[currentIndex] || '';
      currentCodeKey++;
    }
  
    setCodes(newCodesObject);
    handleCodeChanges(pastedText);
    if (setFullCode) {
      setFullCode(pastedText);
    }
  
    const form = e.target.form;
    const nextInputIndex = splitValues.length;
    if (nextInputIndex < 6) {
      form.elements[nextInputIndex].focus();
    }
  };
  

  return (
    <div className="mb-5 mt-3 verification-number-inputs-block">
      <Form.Label className="labeling p-0" style={{justifyContent: "space-between"}}>
        <span style={{color: "#737582"}}>
          Enter Verification Code <span className="required">*</span>
        </span>
        <Button
          variant="link"
          className={`new-code-button ${resentCode ? "flash" : ""} p-0`}
          onClick={(e) => sendNewCode(e)}
        >
          {!resentCode ? "Send New Code" : "Sent!"}
        </Button>
      </Form.Label>

      <div className="verify-numbers">
        <Form.Group className="item" controlId="formNumber1">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number1"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[1]}
            onFocus={resetValidity}
            onPaste={(e) => handlePaste(e)}
            aria-label="Verification Code Input 1."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber2">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number2"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[2]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 2."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber3">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number3"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[3]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 3."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber4">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number4"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[4]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 4."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber5">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number5"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[5]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 5."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber6">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number6"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[6]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 6."
          />
        </Form.Group>
      </div>
      {isInvalid && (
        <div className="invalid" role="alert" aria-atomic="true">
          {error ? error : "Invalid code."}
        </div>
      )}
    </div>
  );
};

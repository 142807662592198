// Assets
import { ReactComponent as NavChevronLeft } from "../../../../assets/reskin/icons/nav-chevron-left-charcoal.svg";
import { TermsConView } from "../../../components/TermsConView/TermsConView";

// Styles
import "./TermsAndConditions.scss";

interface TermsAndConditionsProps {
  back: Function;
}

const TermsAndConditions = ({ back }: TermsAndConditionsProps) => {
  const BackButton = (
    <button className="back-button" onClick={() => back()}>
      <NavChevronLeft />
    </button>
  );

  return (
    <div className="onTop terms-and-conditions">
      <div className="page-header-wrapper">
        <h1 className="terms-page-header">SkillTrack</h1>
      </div>

      <div className="page-sub-header-wrapper">
        <p className="page-sub-header">
          SkillTrack will help you identify your strengths in employability
          skills
        </p>
      </div>

      <div className="terms-and-conditions-body">
        <section className="nav-header-wrapper">
          <div className="back-button-container">{BackButton}</div>
          <div className="terms-header-container">
            <h2 className="terms-header">{"Terms & Conditions"}</h2>
          </div>
        </section>

        <TermsConView />
      </div>
    </div>
  );
};

export default TermsAndConditions;

// Packages
import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
// Components
import Subheader from "../../../components/Subheader";

// Redux
import { RootState } from "../../../redux/reducers";

// Services
import EventService from "../../../services/EventService/event.service";

// Packages
import { useNavigate } from "react-router-dom";

// Actions
import {
  updateUserAssessmentState,
  updateUserAssessmentStateStatus,
} from "../../../redux/actions";

// Graphql
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import * as customsubscriptions from "../../../graphql/custom-subscriptions";
import { getOrList } from "../../../components/dynamicqueries";
import { listUserResponses } from "../../../graphql/queries.js";
import { updateUserResponses } from "../../../graphql/mutations.js";

// Models
import { AssessmentStatusType } from "../../../models";
import ReskinButton from "../../../reskin/components/Buttons/ReskinButton/ReskinButton";
import AssessmentHeader from "../../../reskin/components/Header/AssessmentHeader/AssessmentHeader";

type fetchreportType = {
  fetchreport: () => void;
};

function Feedback({ fetchreport }: fetchreportType) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [TripletStatusFailure, setTripletStatusFailure] =
    React.useState<boolean>(false);
  const [VideoStatusFailure, setVideoStatusFailure] =
    React.useState<boolean>(false);
  const {
    name,
    userId,
    assessmentState,
    assessmentState: { UserAssessmentStateId, sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);

  React.useEffect(() => {
    if (TripletStatusFailure && VideoStatusFailure) {
      assessmentFailed();
    }
  }, [TripletStatusFailure, VideoStatusFailure]);

  React.useEffect(() => {
    const EventTrackingOnCreate = API.graphql(
      graphqlOperation(customsubscriptions.onCreateEventTracking),
    );

    const EventTrackingOnUpdate = API.graphql(
      graphqlOperation(customsubscriptions.onUpdateEventTracking),
    );

    // @ts-ignore
    const createEventSubscriber = EventTrackingOnCreate.subscribe({
      next: ({ provider, value }: any) => {
        if (
          value.data.onCreateEventTracking.userSessionId ===
            assessmentState.sessionId &&
          value.data.onCreateEventTracking.eventStatus === "Error" &&
          value.data.onCreateEventTracking.eventName == "video"
        ) {
          setVideoStatusFailure(true);
        } else if (
          value.data.onCreateEventTracking.userSessionId ===
            assessmentState.sessionId &&
          value.data.onCreateEventTracking.eventStatus === "Error" &&
          value.data.onCreateEventTracking.eventName == "pairs"
        ) {
          setTripletStatusFailure(true);
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    // @ts-ignore
    const updateEventSubscriber = EventTrackingOnUpdate.subscribe({
      next: ({ provider, value }: any) => {
        if (
          value.data.onUpdateEventTracking.userSessionId ===
            assessmentState.sessionId &&
          value.data.onUpdateEventTracking.eventStatus === "Error" &&
          value.data.onUpdateEventTracking.eventName == "video"
        ) {
          setVideoStatusFailure(true);
        } else if (
          value.data.onUpdateEventTracking.userSessionId ===
            assessmentState.sessionId &&
          value.data.onUpdateEventTracking.eventStatus === "Error" &&
          value.data.onUpdateEventTracking.eventName == "pairs"
        ) {
          setTripletStatusFailure(true);
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    return () => {
      updateEventSubscriber.unsubscribe();
      createEventSubscriber.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    CheckVideoScoring();
  }, []);

  React.useEffect(() => {
    checkTripletScoring();
  }, []);

  React.useEffect(() => {
    EventService.track({
      event_type: "user",
      event_name: "insight_report_pending",
      user_type: "candidate",
      event_data: {},
    });
  }, []);

  async function assessmentFailed() {
    await dispatchAction();
    sessionStorage.setItem("rerecordingAttempt", "false");
    alert(
      "There was a problem scoring your video and triplet submission. You will now be redirected to resubmit.",
    );
    if (TripletStatusFailure) {
      await clearUserResponseTable();
      await changeTripletStatus();
    }
    navigate("/assessment");
  }

  const clearUserResponseTable = async () => {
    let variable = { filter: { userId: { eq: userId } }, limit: 10000 };
    const original = await getOrList(
      listUserResponses,
      "listUserResponses",
      variable,
    );
    if (original.length === 0) {
    } else {
      const input = {
        id: original[0].id,
        userId: userId,
        assessmentId: assessmentId,
        userSessionId: sessionId,
        tripletResponse: null,
        userResponsesUserAssessmentStateId: UserAssessmentStateId,
        _version: original[0]._version,
      };

      const resultData = await API.graphql(
        graphqlOperation(updateUserResponses, { input: input }),
      );
    }
  };

  const changeTripletStatus = async () => {
    let filter = {
      and: [
        { userSessionId: { eq: sessionId } },
        { eventName: { eq: "triplet" } },
      ],
    };
    const response = await API.graphql({
      query: queries.listEventTrackings,
      variables: { limit: 10000, filter: filter },
    });
    const parsedResponse = JSON.parse(JSON.stringify(response));
    if (parsedResponse.data.listEventTrackings?.items.length > 0) {
      const input = {
        eventStatus: "Retry",
        id: parsedResponse.data.listEventTrackings?.items[0]?.id,
        eventName: "triplet",
        _version: parsedResponse.data.listEventTrackings?.items[0]?._version,
      };

      await API.graphql({
        query: mutations.updateEventTracking,
        variables: { limit: 10000, input: input },
      });
    }
  };

  async function CheckVideoScoring() {
    let filterCondition = {
      and: [
        { userSessionId: { eq: sessionId } },
        { eventName: { eq: "video" } },
      ],
    };
    const response = await API.graphql({
      query: queries.listEventTrackings,
      variables: { limit: 10000, filter: filterCondition },
    });

    const responseObject = JSON.parse(JSON.stringify(response));

    if (responseObject.data.listEventTrackings.items) {
      if (
        "Error" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        setVideoStatusFailure(true);
      }
    }
  }

  async function checkTripletScoring() {
    let filterCondition = {
      and: [
        { userSessionId: { eq: sessionId } },
        { eventName: { eq: "triplet" } },
      ],
    };
    const response = await API.graphql({
      query: queries.listEventTrackings,
      variables: { limit: 10000, filter: filterCondition },
    });

    const responseObject = JSON.parse(JSON.stringify(response));

    if (responseObject.data.listEventTrackings.items) {
      if (
        "Error" ===
        responseObject.data.listEventTrackings?.items[0]?.eventStatus
      ) {
        setTripletStatusFailure(true);
      }
    }
  }

  const dispatchAction = async () => {
    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.ASSESSMENT_FAILED,
      }),
    );

    dispatch(
      updateUserAssessmentStateStatus({
        statusType: AssessmentStatusType.ASSESSMENT_FAILED,
      }),
    );
    return "done";
  };

  return (
    <>
      <AssessmentHeader isTransparent={false} />
      <Subheader variant="generating" text="Generating SkillTrack Report..." />
      <section className="report-pending section">
        <div className="wrapper container">
          <div className="text">
            <div className="loading-container">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>

            <p>
              <strong>Thank you, {name}!</strong>
            </p>

            <p>
              You have completed the activities and your SkillTrack Report will
              be available shortly.
            </p>
            <p>
              If this page does not auto-refresh, click the Refresh button
              below. Your SkillTrack Report will be displayed if it is ready.
            </p>
          </div>
          <ReskinButton
            text="Refresh"
            size="large"
            variant="primary"
            customClasses="refresh"
            onClick={() => fetchreport()}
          />
        </div>
      </section>
    </>
  );
}

export default Feedback;

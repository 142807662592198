import React from "react";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/caret-down.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/caret-up.svg";

// Services
import EventService from "../../../../services/EventService/event.service";

interface CollapsibleCarouselProps {
  text: string;
  content: JSX.Element;
  trait: string;
  isCollapsible: boolean;
  openByDefault: boolean;
  onCollapsibleToggle?: Function;
}

const CollapsibleCarousel = ({
  text,
  content,
  isCollapsible,
  openByDefault,
  onCollapsibleToggle,
}: CollapsibleCarouselProps) => {
  const [isOpen, setIsOpen] = React.useState(openByDefault);
  const RenderedIcon = isOpen ? <ArrowUp /> : <ArrowDown />;

  const collapsibleContentClass = isOpen ? "" : "closed";

  return (
    <div className="collapsible-carousel">
      <button
        className="d-flex icon-tab collapsible-toggler"
        onClick={() => {
          if (isCollapsible) {
            if (onCollapsibleToggle) {
              onCollapsibleToggle(!isOpen);
            }
            setIsOpen(!isOpen);
          }
        }}
      >
        <span className="tab-content-container d-flex w-100 h-100">
          <span className="d-flex text-start tab-text flex-grow-1">{text}</span>
          {isCollapsible && (
            <span className="d-flex align-items-center justify-content-center rendered-icon">
              {RenderedIcon}
            </span>
          )}
        </span>
      </button>
      <div
        className={`collapsible-content-container ${collapsibleContentClass}`}
        aria-hidden={!isOpen}
      >
        {isOpen && content}
      </div>
    </div>
  );
};

export default CollapsibleCarousel;

// Packages
import { combineReducers } from "redux";

// Reducers
import { userReducer } from "./user.reducer";
import { assessmentReducer } from "./assessment.reducer";
import { supportReducer } from "./support.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  assessment: assessmentReducer,
  support: supportReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

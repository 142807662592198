// Components
import ResourceGroup from "./ResourceGroup";

// Services
import EventService from "../../../../../services/EventService/event.service";

// Types
import { ResourceGroupProps } from "../utils/types";

interface ResourcesListProps {
  resources: any[];
  message_id: string;
}

const ResourcesList = ({ resources, message_id }: ResourcesListProps) => {
  const trackClick = (subtraitLink: any) => {
    const { traitName, description } = subtraitLink;

    EventService.track({
      event_type: "user",
      event_name: "support_resource_open",
      user_type: "student",
      event_data: {
        category: traitName,
        message_id: message_id,
        resource: description,
      },
    });
  };

  const RenderedResources = resources.map(
    (subtraitLinks: any, index: number) => {
      const { description, traitName, resourceLinks } = subtraitLinks;

      return (
        <div className="resource-link-container" key={`resource-link-${index}`}>
          <a
            className="resource-link"
            onClick={() => trackClick(subtraitLinks)}
            target="_NEW"
            href={resourceLinks[0]}
          >
            {description}
          </a>
        </div>
      );

      // return <ResourceGroup key={index} links={links} subtrait={subtrait} />;
    },
  );

  return (
    <section>
      <div className="resources-container">
        <p>Resources:</p>
      </div>

      <div className="resources-container">
        <section className="resource-group">{RenderedResources}</section>
      </div>
    </section>
  );
};

export default ResourcesList;

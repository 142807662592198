import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const colors: any = {
  leadership: {
    primary: "#30396C",
    secondary: "#959AB7",
    tertiary: "#DEE1EE",
  },
  "effective communication": {
    primary: "#5D8137",
    secondary: "#9DB088",
    tertiary: "#DEECCF",
  },
  perseverance: {
    primary: "#DD614A",
    secondary: "#E5B5AC",
    tertiary: "#EBDDDA",
  },
  "innovative thinking": {
    primary: "#8865A4",
    secondary: "#B2A3BF",
    tertiary: "#D3CCD9",
  },
};
function Graph({ counts, trait }: any) {
  console.log(counts);

  const centerNumberPlugin = {
    id: "trait",
    beforeDraw: function (chart: any) {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      const fontSize = (height / 100).toFixed(2);
      ctx.font = "bold " + fontSize + 'em "Open Sans"';
      ctx.fillStyle = "#737582";
      ctx.textBaseline = "top";

      const text = Math.round(counts.highPercentage) + "%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 3 + 10;

      ctx.fillText(text, textX, textY);

      ctx.save();
      ctx.restore();
      ctx.font = '.7em  "Open Sans"';
      ctx.textBaseline = "top";
      const text2 = "Demonstrating", // Assuming counts.total contains the number to display
        textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
        // textY2 = textY + 26;
        textY2 = Math.round(height / 2 + 10);

      ctx.fillText(text2, textX2, textY2);

      ctx.save();
    },
  };

  const data = {
    labels: ["Developing", "Approaching", "Demonstrating"],
    datasets: [
      {
        label: "% of Students",
        data: [
          counts.lowPercentage,
          counts.medPercentage,
          counts.highPercentage,
        ],
        backgroundColor: [
          colors[trait].tertiary,
          colors[trait].secondary,
          colors[trait].primary,
        ],
        borderWidth: 0,
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        cutout: "75%",
      },
    ],
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
        align: "center",
        labels: {
          boxWidth: 250,
          boxHeight: 20,
          margin: 20,
          font: {
            size: 10,
          },
          textAlign: "center",
          usePointStyle: true,
          pointStyleWidth: 30,
          stretch: 45,
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return context.label + ":" + Math.round(context.raw) + "%";
          },
        },
      },
    },
  };

  const formatNumber = (num: string) => {
    console.log(num.length);
    return num.length === 6 ? (
      <span>{Math.round(parseFloat(num)) + "%"}</span>
    ) : num.length === 5 ? (
      <span style={{ whiteSpace: "pre" }}>
        {" "}
        {" " + Math.round(parseFloat(num)) + "%"}
      </span>
    ) : (
      <span style={{ whiteSpace: "pre" }}>
        {"    " + Math.round(parseFloat(num)) + "%"}
      </span>
    );
  };
  return (
    <>
      <div id="trait">
        <Doughnut
          data={data}
          plugins={[centerNumberPlugin]}
          options={options}
        />
      </div>
      <div className="score-level">
        <div>
          {formatNumber(counts.lowPercentage)}
          <div
            className={`low-level ${trait.toLowerCase().split(" ").join("")}-background-developing`}
          ></div>
          <div>Developing</div>
        </div>
        <div>
          {formatNumber(counts.medPercentage)}
          <div
            className={`medium-level ${trait.toLowerCase().split(" ").join("")}-background-approaching`}
          ></div>
          <div> Approaching</div>
        </div>
        <div>
          {formatNumber(counts.highPercentage)}
          <div
            className={`high-level ${trait.toLowerCase().split(" ").join("")}-background`}
          ></div>
          <div> Demonstrating</div>
        </div>
      </div>
    </>
  );
}

export default Graph;

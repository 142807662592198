// Packages
import React from "react";

// Styles
import "./MenuItem.scss";

// Types
import { MenuItemPropType } from "../ReskinMenu";

const MenuItem = ({
  Icon,
  onClick,
  label,
  textColor,
  isFirstItem,
  isLastItem,
  tabIndex,
  tabOrderRef,
}: MenuItemPropType) => {
  const isLastItemClass = isLastItem ? "is-last-item" : "";
  const autoFocusStatus = isFirstItem ? true : false;

  React.useEffect(() => {
    if (isFirstItem) {
      tabOrderRef.current.focus();
    }
  }, [isFirstItem, tabOrderRef]);

  return (
    <>
      <div
        className={`reskin-header-menu-item active ${isLastItemClass}`}
        autoFocus={autoFocusStatus}
        tabIndex={tabIndex}
        ref={tabOrderRef}
        onClick={() => onClick()}
        style={{ color: textColor || "#737582" }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onClick();
          }
        }}
      >
        <div className="header-icon-container">{Icon ? Icon : null}</div>
        <div className="header-label-container">
          <p className="m-0">{label}</p>
        </div>
      </div>
      <div className="reskin-menu-pseudo-border" />
    </>
  );
};

export default MenuItem;

import { PrivacyPolView } from "../../../components/PrivacyPolView/PrivacyPolView";
import "./privacy-policy.scss";

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <PrivacyPolView />
    </div>
  );
}
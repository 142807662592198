// Packages
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

// Configs
import { DEFAULTS } from "../../enumerations";

// Queries
import * as listofUserscores from "../../graphql/custom-queries";

// Models
import { AssessmentStatusType, Usertype } from "../../models";

//Graphql
import createOrUpdate from "../../components/dynamicqueries";
import { getOrList } from "../../components/dynamicqueries";
import {
  listUserAssessmentStates,
  getUserAssessmentState,
  listAssessments,
  getUser,
} from "../../graphql/queries";
import { getInstitutionUser } from "../../graphql/queries";
import {
  createUserAssessmentState,
  updateUserAssessmentState,
} from "../../graphql/mutations";
import env from "../../configs/env";

class UserService {
  /**
   * getDSUser.
   *
   * Returns user details from backend.
   *
   * @userId the Cognito ID of the authorized user.
   */
  static getDSUser = async (userId: string) => {
    try {
      const userFilter = { userId: userId };
      const userGet = await getOrList(getUser, "getUser", userFilter);

      const assessmentFilter = {
        filter: { name: { eq: env.assessmentName } },
      };
      const assessment = await getOrList(
        listAssessments,
        "listAssessments",
        assessmentFilter,
      );

      if (typeof userGet !== "undefined" && Object.keys(userGet).length > 0) {
        // determine Assessment State for this user if candidate (to be implemented later)
        const assessmentState = await this.getAssessmentState({
          userId,
          assessmentId: assessment[0].id,
        });

        return { ...userGet, assessmentState };
      }

      return false;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * canUserProceed.
   *
   * Returns true if the user's type matches userType
   *
   * @param dsUser the user record
   * @param userType the user type (null, student, institution)
   */
  static canUserProceed = (dsUser: any, userType: Usertype) => {
    if (dsUser.userType === null && userType === Usertype.STUDENT) {
      return true;
    }

    if (dsUser.userType === userType) {
      return true;
    }

    return false;
  };

  static getAssessmentState = async ({ userId, assessmentId }: any) => {
    try {
      const variable = {
        limit: 10000,
        filter: {
          and: [{ assessmentId: { eq: assessmentId }, userId: { eq: userId } }],
        },
      };

      const assessmentState = await getOrList(
        listUserAssessmentStates,
        "listUserAssessmentStates",
        variable,
      );

      if (
        typeof assessmentState === "undefined" ||
        assessmentState.length === 0
      ) {
        // does not exist, create new
        var input = {
          userSessionId: uuidv4(),
          userId,
          assessmentId,
          assessmentStatus: AssessmentStatusType.ASSESSMENT_STARTED,
          assessmentStartDate: new Date().toISOString(),
          userAssessmentStateUserId: userId,
        };
        await createOrUpdate(createUserAssessmentState, input);
        const variable = {
          limit: 10000,
          filter: {
            userSessionId: { eq: input.userSessionId },
          },
        };

        const getAssessmentState = await getOrList(
          listUserAssessmentStates,
          "listUserAssessmentStates",
          variable,
        );

        return {
          UserAssessmentStateId: getAssessmentState[0].id,
          assessmentId,
          assessmentStatus: getAssessmentState[0].assessmentStatus,
          sessionId: getAssessmentState[0].userSessionId,
        };
      } else {
        return {
          UserAssessmentStateId: assessmentState[0].id,
          assessmentId,
          assessmentStatus: assessmentState[0].assessmentStatus,
          sessionId: assessmentState[0].userSessionId,
        };
      }
    } catch (error: unknown) {
      // typescript doesn't like those unknowns
      const customError = error as string;
      console.log(customError);
    }
  };

  /**
   * updateUserAssessmentState.
   *
   * Updates the specified user assessment state table.
   *
   * @param userAssessmentStateId The ID of the database row, found within Redux store.
   * @param status The new status, one from AssessmentStatusType.
   */

  static updateUserAssessmentState = async (
    userAssessmentStateId: string,
    status: AssessmentStatusType,
  ) => {
    const original = await getOrList(
      getUserAssessmentState,
      "getUserAssessmentState",
      { id: userAssessmentStateId },
    );
    if (original) {
      if (status === AssessmentStatusType.ASSESSMENT_COMPLETE) {
        const input = {
          id: userAssessmentStateId,
          assessmentEndDate: new Date().toISOString(),
          assessmentStatus: status,
          _version: original._version,
        };
        await createOrUpdate(updateUserAssessmentState, input);
      } else {
        const input = {
          id: userAssessmentStateId,
          assessmentStatus: status,
          _version: original._version,
        };
        await createOrUpdate(updateUserAssessmentState, input);
      }
    }
  };

  static getUserScores = async (userSessionId: string) => {
    let filter = {
      userSessionId: {
        eq: userSessionId, // filter priority = 1
      },
    };
    const response = await API.graphql({
      query: listofUserscores.listofUserscores,
      variables: { filter: filter },
    });
    if (response) {
      const result = JSON.parse(JSON.stringify(response));
      const items = result.data.listUserScores.items;
      if (items.length > 0) {
        return items[0];
      }
    }

    return [];
  };

  /**
   * isValidInstitutionUser.
   *
   * Checks if the supplied email and institution is a valid institution user.
   *
   * @param email
   * @param institution
   * @returns boolean
   */
  static isValidInstitutionUser = async (email: string, institution: any) => {
    if (process.env.REACT_APP_ENV !== "prod") return true;

    try {
      const variables = { emailId: email };
      const checkEmail = await getOrList(
        getInstitutionUser,
        "getInstitutionUser",
        variables,
      );

      if (checkEmail) {
        if (
          checkEmail.institution.toLowerCase() === institution.toLowerCase()
        ) {
          return true;
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }

    return false;
  };
}

export default UserService;

export const searchUserScoresCount = /* GraphQL */ `
  query SearchUserScores(
    $filter: SearchableUserScoresFilterInput
    $sort: [SearchableUserScoresSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserScores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        userId
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
      }
      total
    }
  }
`;

export const searchUserScoresOverallScore = /* GraphQL */ `
  query SearchUserScores(
    $filter: SearchableUserScoresFilterInput
    $sort: [SearchableUserScoresSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserScores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        userId
        userSessionId
      }
    }
  }
`;

export const searchOverviewsUserScore = /* GraphQL */ `
  query SearchUserScores(
    $filter: SearchableUserScoresFilterInput
    $sort: [SearchableUserScoresSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserScores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        userSessionId
        userAssessmentState {
          id
          assessmentId
          assessmentStatus
          user {
            name
            userId
          }
        }
      }
    }
  }
`;

export const searchUserNames = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        userId
        name
      }
    }
  }
`;
export const searchUsersByEmail = /* GraphQL */ `
  query searchUsers($filter: SearchableUserFilterInput) {
    searchUsers(filter: $filter) {
      items {
        userId
      }
      nextToken
    }
  }
`;

export const listofQuickTips = /* GraphQL */ `
  query listQuickTips(
    $filter: ModelQuickTipsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuickTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tips
      }
      nextToken
    }
  }
`;
export const listofUserscores = /* GraphQL */ `
  query listUserScores(
    $filter: ModelUserScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        assessmentId
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        dimensionNarratives
        userId
        userSessionId
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
      }
      nextToken
    }
  }
`;
export const listOfStudentsScores = /* GraphQL */ `
  query searchUserScores(
    $filter: SearchableUserScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchUserScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        userSessionId
        assessmentId
        perseveranceScore
        perseveranceScoreBand
        leadershipScore
        leadershipScoreBand
        innovativeThinkingScore
        innovativeThinkingScoreBand
        communicationScore
        communicationScoreBand
        userScoresUserAssessmentStateId
      }
      total
      nextToken
    }
  }
`;
export const listOfStudents = /* GraphQL */ `
  query searchUsers(
    $filter: SearchableUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        enrollmentYear
        ageGroup
        name
      }
      total
      nextToken
    }
  }
`;
export const listOfHEIComments = /* GraphQL */ `
  query searchHeiComments(
    $filter: SearchableHeiCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchHeiComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        candidateUserId
        comment
      }
      nextToken
    }
  }
`;

export const listOfStudentList = /* GraphQL */ `
  query SearchUserScores(
    $filter: SearchableUserScoresFilterInput
    $sort: [SearchableUserScoresSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserScores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        overallScore
        userSessionId
        assessmentId
        userScoresUserAssessmentStateId
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        userAssessmentState {
          user {
            userId
            enrollmentYear
            ageGroup
            name
          }
          heiComments {
            items {
              comment
              candidateUserId
              userId
            }
          }
          studentRequests {
            items {
              institutionId
              studentQuestion
              institutionResponses {
                items {
                  studentUserId
                }
              }
            }
          }
        }
      }
      total
    }
  }
`;

export const getIndividualStudent = /* GraphQL */ `
  query ListUserScores(
    $filter: ModelUserScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userSessionId
        assessmentId
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        userId
        userScoresUserAssessmentStateId
        userAssessmentState {
          user {
            name
            gender
            ageGroup
            enrollmentYear
            goal
            emplStatus
          }
          heiComments {
            items {
              id
              comment
              candidateUserId
              userId
              username
              timestamp
            }
          }
          comments {
            communicationComment
            declineCommentOnCommunication
            declineCommentOnInnovativeThinking
            declineCommentOnLeadership
            declineCommentOnPerserverance
            innovativeThinkingComment
            leadershipComment
            perseveranceComment
          }
          studentRequests {
            items {
              id
              institutionId
              studentQuestion
              requestDate
              traitName
              _version
              institutionResponses {
                items {
                  id
                  studentUserId
                  institutionUser {
                    name
                    userId
                  }
                }
              }
            }
          }
          quickTips {
            id
            tips
          }
        }
      }
    }
  }
`;

export const listCandidateComments = /* GraphQL */ `
  query ListCandidateComments(
    $filter: ModelCandidateCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        user {
          name
          gender
          ageGroup
          enrollmentYear
          goal
          emplStatus
          institutionId
          assessmentStates {
            items {
              userScores {
                overallScore
              }
            }
          }
        }
        leadershipComment
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentProfane
        declineCommentOnPerserverance
      }
      nextToken
      startedAt
    }
  }
`;

export const listStudentSupportRequests = /* GraphQL */ `
  query ListStudentRequests(
    $filter: ModelStudentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentUserSessionId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          user {
            name
          }
        }
        traitName
        responseSentToStudent
        institutionResponses {
          items {
            institutionUser {
              name
            }
            responseDate
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const searchStudentRequests = /* GraphQL */ `
  query SearchStudentRequests(
    $filter: SearchableStudentRequestFilterInput
    $sort: [SearchableStudentRequestSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStudentRequestAggregationInput]
  ) {
    searchStudentRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
      items {
        traitName
        studentUserSession {
          userSessionId
          user {
            name
            userId
          }
        }
      }
    }
  }
`;

export const listInstitutionUsers = /* GraphQL */ `
  query ListInstitutionUsers(
    $emailId: String
    $filter: ModelInstitutionUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstitutionUsers(
      emailId: $emailId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        emailId
        institution
        name
      }
      nextToken
      startedAt
    }
  }
`;

export const listStudentRequestUserIds = /* GraphQL */ `
  query ListStudentRequests(
    $filter: ModelStudentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
      }
    }
  }
`;

export const listStudentFavouriteLearningResources = /* GraphQL */ `
  query ListStudentFavouriteLearningResources(
    $filter: ModelStudentFavouriteLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentFavouriteLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          resourceCards {
            description
            content
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listLearningResources = /* GraphQL */ `
  query ListLearningResources(
    $filter: ModelLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listStudentVisitedLearningResources = /* GraphQL */ `
  query ListStudentVisitedLearningResources(
    $filter: ModelStudentVisitedLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentVisitedLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          resourceCards {
            description
            content
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getTotalStudentsByCategoryAndScore = /* GraphQL */ `
  query totalStudentsWithScore($filter: SearchableUserScoresFilterInput) {
    totalStudentsWithScore: searchUserScores(
      filter: { and: [{ overallScore: { gte: 0 } }, $filter] }
    ) {
      total: total
    }
    studentsWithLeadershipScoreAsLow: searchUserScores(
      filter: {
        and: [
          { leadershipScore: { eq: 0 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      leadershipLow: total
    }
    studentsWithLeadershipScoreAsMedium: searchUserScores(
      filter: {
        and: [
          { leadershipScore: { eq: 1 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      leadershipMedium: total
    }
    studentsWithLeadershipScoreAsDemonstrating: searchUserScores(
      filter: {
        and: [
          { leadershipScore: { eq: 2 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      leadershipDemonstrating: total
    }
    studentsWithPerseveranceScoreAsLow: searchUserScores(
      filter: {
        and: [
          { perseveranceScore: { eq: 0 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      perseveranceLow: total
    }
    studentsWithPerseveranceScoreAsMedium: searchUserScores(
      filter: {
        and: [
          { perseveranceScore: { eq: 1 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      perseveranceMedium: total
    }
    studentsWithPerseveranceScoreAsDemonstrating: searchUserScores(
      filter: {
        and: [
          { perseveranceScore: { eq: 2 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      perseveranceDemonstrating: total
    }
    studentsWithInnovativeThinkingScoreAsLow: searchUserScores(
      filter: {
        and: [
          { innovativeThinkingScore: { eq: 0 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      innovativeThinkingLow: total
    }
    studentsWithInnovativeThinkingAsMedium: searchUserScores(
      filter: {
        and: [
          { innovativeThinkingScore: { eq: 1 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      innovativeThinkingMedium: total
    }
    studentsWithInnovativeThinkingAsDemonstrating: searchUserScores(
      filter: {
        and: [
          { innovativeThinkingScore: { eq: 2 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      innovativeThinkingDemonstrating: total
    }
    studentsWithCommunicationScoreAsLow: searchUserScores(
      filter: {
        and: [
          { communicationScore: { eq: 0 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      communicationLow: total
    }
    studentsWithCommunicationAsMedium: searchUserScores(
      filter: {
        and: [
          { communicationScore: { eq: 1 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      communicationMedium: total
    }
    studentsWithCommunicationAsDemonstrating: searchUserScores(
      filter: {
        and: [
          { communicationScore: { eq: 2 } }
          { overallScore: { gte: 0 } }
          $filter
        ]
      }
    ) {
      communicationDemonstrating: total
    }
  }
`;

export const getTotalStudentsSubmittedLearningReflection = /* GraphQL */ `
  query totalStudentsSubmittedLearningSelfReflection(
    $filter: SearchableLearningSelfReflectionFilterInput
  ) {
    StudentsSubmittedLearningSelfReflection: searchLearningSelfReflections(
      filter: $filter
    ) {
      total: total
    }
  }
`;

export const getCombinedResourceCardDetails = /* GraphQL */ `
  query getCombinedResourceCardDetails(
    $learningResourceFilter: ModelLearningResourceFilterInput
    $favFilter: ModelStudentFavouriteLearningResourceFilterInput
    $resourceReactionFilter: ModelResourceReactionFilterInput
    $resourceRecommendationFilter: ModelStudentRecommendedResourcesFilterInput
    $limit: Int
  ) {
    resources: listLearningResources(
      filter: $learningResourceFilter
      limit: $limit
    ) {
      items {
        learningResourceId: id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
    favorites: listStudentFavouriteLearningResources(
      filter: $favFilter
      limit: $limit
    ) {
      items {
        isFavorited: preference
        learningResourceId
      }
    }
    reactions: listResourceReactions(
      filter: $resourceReactionFilter
      limit: $limit
    ) {
      items {
        isReacted: preference
        learningResourceId
      }
    }
    recommendations: listStudentRecommendedResources(
      filter: $resourceRecommendationFilter
      limit: $limit
    ) {
      items {
        sequence
        learningResourceId
      }
    }
  }
`;

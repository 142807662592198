// Packages
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VideoRecorder from "react-video-recorder";
import { v4 as uuidv4 } from "uuid";
import { API, Storage } from "aws-amplify";

// Actions
import {
  updateUserAssessmentState,
  userIsUploading,
} from "../../../../redux/actions";

// Components
import TipsControls from "./TipsControls/TipsControls";
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";

// import Question from "../../../../screens/assessment/communication/questions/question";
import QuestionBlock from "./QuestionBlock";
import Actions from "./Actions";
import ErrorView from "../../../../screens/assessment/communication/record/ErrorView";

// Models and Types
import { AssessmentStatusType, UserResponses } from "../../../../models";

// Services
import EventService from "../../../../services/EventService/event.service";
import MMAIService from "../../../../services/MMAI/mmai.service";

// Store
import { RootState } from "../../../../redux/reducers";
import ModalSubmitResponse from "../../../../components/Modals/SubmitResponse";
import { ModalContext } from "../../../../contexts/context";
import { createBrowserHistory } from "history";

// Utilities
import deviceInfo from "../../../../utilities/deviceInfo";

// Graphql
import * as mutations from "../../../../graphql/mutations";
import * as queries from "../../../../graphql/queries";

type RecordTypes = {
  userResponses?: UserResponses;
};

function VideoUpload({ userResponses }: RecordTypes) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let history = createBrowserHistory();
  const { state } = useLocation();
  const { showModal } = React.useContext(ModalContext);

  const {
    userId,
    email,
    assessmentState,
    assessmentState: { UserAssessmentStateId, sessionId },
  } = useSelector((state: RootState) => state.user);

  // uploading status and file info
  const [src, setSrc] = React.useState<string | undefined>();
  const [file, setFile] = React.useState<File>();
  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [isRecording, setRecording] = React.useState<boolean>(false);
  const [hideSelf, setHideSelf] = React.useState<boolean>(false);

  // on back arrao click handle here
  const goBack = () => {
    // todo: add or model class to call here
    if (isRecording) {
      showModal(ModalSubmitResponse);
    } else {
      navigate("/assessment/communication/questions");
    }
  };

  React.useEffect(() => {
    if (isRecording) {
      history.block(({ action, location, retry }) => {
        goBack();
      });
    }
  }, [isRecording]);

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "Effective Communication Record Response",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "Effective Communication Record Response",
      });
    };
  }, []);

  const changeVideoStatus = async () => {
    //                              QUERY starts here
    const userSessionId = assessmentState.sessionId;
    let filterCondition_eventStatus = {
      and: [
        { userSessionId: { eq: userSessionId } },
        { eventName: { eq: "video" } },
      ],
    };
    const response_QUERY = await API.graphql({
      query: queries.listEventTrackings,
      variables: { limit: 10000, filter: filterCondition_eventStatus },
    });
    const responseObject_QUERY = JSON.parse(JSON.stringify(response_QUERY));

    //                              QUERY ends here
    //                              MUTATION starts here

    if (responseObject_QUERY.data.listEventTrackings?.items.length > 0) {
      const input = {
        eventStatus: "Retry",
        id: responseObject_QUERY.data.listEventTrackings?.items[0]?.id,
        eventName:
          responseObject_QUERY.data.listEventTrackings?.items[0]?.eventName,
        _version:
          responseObject_QUERY.data.listEventTrackings?.items[0]?._version,
      };
      const response_MUTATION = await API.graphql({
        query: mutations.updateEventTracking,
        variables: { limit: 10000, input: input },
      });
      const responseObject_MUTATION = JSON.parse(
        JSON.stringify(response_MUTATION),
      );

      //                              MUTATION ends here

      // alert('eventStatus should be updated to `Retry`.')
    }
  };

  // KEPT THIS AS A HACK TO TEST changeVideoStatus FUNCTION
  // React.useEffect(() => {
  //   changeVideoStatus()
  // }, [])

  const handleUpload = async ({ attempts, duration }: any) => {
    if (file) {
      setUploading(true);

      const event = { event_type: "mmai_video", file_name: file.name };

      const apiName = "psqmmaiservice";
      const path = "/presigned-url";
      const myInit = {
        body: event,
      };
      const presigned = await API.post(apiName, path, myInit);

      // update for uploading progress tracking
      dispatch(userIsUploading(true));

      setUploading(false);

      // Date object for file S3 pathing.
      let date = new Date();
      const formattedDate = date
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("_");
      const formattedTime = `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}_${date.getMilliseconds()}`;
      const s3VideoResponseLocation = `video-responses/${formattedDate}/${formattedTime}/${file.name}`;

      // Upload 1/2
      const uploadData = MMAIService.Upload(
        file,
        presigned,
        attempts,
        duration,
        userResponses,
        UserAssessmentStateId,
        dispatch,
        sessionId,
        userId,
        s3VideoResponseLocation,
      );

      const serverSideEncryption = "AES256";

      // Upload 2/2
      const bucketUpload = Storage.put(s3VideoResponseLocation, file, {
        serverSideEncryption,
        metadata: {
          userId: userId,
          sessionId: sessionId,
          assessmentId: UserAssessmentStateId,
        },
        resumable: true,
        completeCallback: (event) => {
          console.log(`AMPLIFY MESSAGE:::Successfully uploaded ${event.key}`);
        },
        progressCallback: (progress) => {
          console.log(
            `AMPLIFY MESSAGE:::Uploaded: ${progress.loaded}/${progress.total}`,
          );
        },
        errorCallback: (err) => {
          console.error(
            "AMPLIFY MESSAGE:::Unexpected error while uploading",
            err,
          );
        },
      });

      changeVideoStatus();

      // update to inprogress for routing purposes
      dispatch(
        updateUserAssessmentState({
          userId,
          UserAssessmentStateId,
          statusType: AssessmentStatusType.VIDEO_COMPLETE,
        }),
      );

      navigate("/assessment/communication/submitted");

      //setUploading(false);
    } else {
      alert("no file");
    }
  };

  return (
    <>
      <AssessmentHeader isTransparent={false} withBackButton />
      <section className="reskin-recorder">
        <div className="wrapper container video-recorder-container shadowed">
          <QuestionBlock
            question={state}
            className="recorder-question"
            hideSelf={hideSelf}
          />
          <TipsControls />
          <VideoRecorder
            chunkSize={250}
            timeLimit={121000}
            countdownTime={0}
            renderActions={(props: any) => {
              return (
                <Actions
                  options={props}
                  handleUpload={handleUpload}
                  question={state}
                  isUploading={isUploading}
                  setRecording={setRecording}
                  hideSelf={hideSelf}
                  setHideSelf={setHideSelf}
                />
              );
            }}
            constraints={{
              audio: true,
              video: true,
            }}
            isOnInitially
            isFlipped
            showReplayControls
            renderUnsupportedView={() => <ErrorView />}
            renderErrorView={() => <ErrorView />}
            replayVideoAutoplayAndLoopOff={true}
            // mimeType={deviceInfo.mimeType}
            onRecordingComplete={(videoBlob: any) => {
              // Do something with the video...

              const [mimeType, codecs] = videoBlob.type.split(";");

              const [type, ext] = mimeType.split("/");

              let url = URL.createObjectURL(videoBlob);
              // let uuid = url.substring(url.lastIndexOf("/") + 1);
              let filename = `${uuidv4()}.${ext}`;

              let file = new File([videoBlob], `${filename}`, {
                type: videoBlob.type,
              });

              EventService.track({
                event_type: "user",
                event_name: "user_recording_complete",
                user_type: "student",
                event_data: {
                  section: "Communication",
                  file,
                },
              });
              console.log("file", file);
              setSrc(url);
              setFile(file);
            }}
            onError={(error: unknown) => {
              const customError = error as string;
              console.log(customError);
              if (
                customError.toString() ===
                  "NotAllowedError: Permission denied" ||
                customError.toString() === "DOMException: Permission denied"
              ) {
                EventService.track({
                  event_type: "user",
                  event_name: "device_access_denied",
                  user_type: "student",
                  event_data: {
                    section: "Communication",
                    item_id: "", // state.id,
                    item_guid: "", // state.itemGuid,
                  },
                });
              } else {
                EventService.track({
                  event_type: "user",
                  event_name: "recording_error_display",
                  user_type: "student",
                  event_data: {
                    section: "Communication",
                    item_id: "state.id",
                    item_guid: "state.itemGuid",
                    error_message: customError.toString(),
                  },
                });
              }
            }}
          />
        </div>
      </section>
    </>
  );
}

export default VideoUpload;

// Packages
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import PrimaryButton from "../../../components/Buttons/ReskinButton/ReskinButton";
// import ReskinNavBar from "../../../components/NavBars/ReskinNavBar";
import AssessmentHeader from "../../../components/Header/AssessmentHeader/AssessmentHeader";

// Actions
import { updateUserAssessmentState } from "../../../../redux/actions";

// Assets
import { ReactComponent as NavProfileCircle } from "../../../../assets/reskin/icons/nav-person-circle.svg";
import { ReactComponent as ParamountIcon } from "../../../../assets/reskin/icons/paramount.svg";
import { ReactComponent as CheckCircleIcon } from "../../../../assets/reskin/icons/check-circle.svg";

// Styles
import "./BIQCongratulationsPage.scss";
import { RootState } from "../../../../redux/reducers";

// Models
import { AssessmentStatusType } from "../../../../models";

const BIQCongratulationsPage = () => {
  // User Info
  const { user } = useSelector((state: RootState) => state);
  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = user;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <AssessmentHeader isTransparent={false} />
      <div className="congratulations-page">
        <div className="icon-container">
          <ParamountIcon className="paramount-icon" />
        </div>
        <div className="messages-container">
          <h3 className="congratulations-message">
            <p>{`Way to go, ${user.name}!`}</p>
          </h3>
          {/* <h3 className="congratulations-message">Way to go, John!</h3> */}
          <p className="you-completed">You've completed</p>
          <p className="section-name">Tell Us More About You</p>
        </div>

        <div className="icon-container check-circle">
          <CheckCircleIcon />
        </div>

        <div className="button-container">
          <PrimaryButton
            text="Next: Effective Communication"
            onClick={() => {
              dispatch(
                updateUserAssessmentState({
                  userId,
                  UserAssessmentStateId,
                  statusType: AssessmentStatusType.BIQ_COMPLETE,
                }),
              );

              navigate("/assessment/communication/intro");
            }}
            customClasses="next-nav-button"
          />
        </div>
      </div>
    </>
  );
};

export default BIQCongratulationsPage;

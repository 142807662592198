// Packages
import React from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as queries from "../../../graphql/queries";

// Components
import OverallInsights from "./OverallInsights";
import GraphInsights from "./GraphInsights";

// Models
import { InstitutionNarratives, Highleveltraittype } from "../../../models";

// Queries
import * as customQueries from "../../../graphql/custom-queries";

// Services
import EventService from "../../../services/EventService/event.service";

const LEADERSHIP_SCORE_NAME = "leadershipScore";
const COMMUNICATION_SCORE_NAME = "communicationScore";
const INNOVATIVE_THINKING_SCORE_NAME = "innovativeThinkingScore";
const PERSEVERANCE_SCORE_NAME = "perseveranceScore";

const HIGH_SCORE_BAND = 2;
const LOW_SCORE_BAND = 0;
const MEDIUM_SCORE_BAND = 1;

type WelcomeType = {
  studentCount: number;
  institutionId: any;
  setSelectedTab: (selected: string) => void;
};

function Welcome({ studentCount, setSelectedTab, institutionId }: WelcomeType) {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [leaderShipCounts, setLeaderShipCounts] = React.useState<any>(null);
  const [communicationCounts, setCommunicationCounts] =
    React.useState<any>(null);
  const [innovationThinkingCounts, setInnovationThinkingCounts] =
    React.useState<any>(null);
  const [perserveranceCounts, setPerserveranceCounts] =
    React.useState<any>(null);

  const [leadershipNarrative, setLeadershipNarrative] =
    React.useState<string>("");
  const [communicationNarrative, setCommunicationNarrative] =
    React.useState<string>("");
  const [innovativeThinkingNarrative, setInnovativeThinkingNarrative] =
    React.useState<string>("");
  const [perserveranceNarrative, setPerserveranceNarrative] =
    React.useState<string>("");

  const [leadershipBand, setLeadershipBand] = React.useState<string>("");
  const [communicationBand, setCommunicationBand] = React.useState<string>("");
  const [innovativeThinkingBand, setInnovativeThinkingBand] =
    React.useState<string>("");
  const [perserveranceBand, setPerserveranceBand] = React.useState<string>("");

  const [requestedSupportCount, setRequestedSupportCount] =
    React.useState<number>(0);

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "HEI Welcome",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "HEI Welcome",
      });
    };
  }, []);

  React.useEffect(() => {
    if (isLoading === true) {
      getCounts();
      populateRequestedSupportCount();
      setLoading(false);
    }

    async function getCounts() {
      setLeaderShipCounts(await getLeaderShipCounts());
      setCommunicationCounts(await getCommunicationCounts());
      setInnovationThinkingCounts(await getInnovationThinkingCounts());
      setPerserveranceCounts(await getPerserveranceCounts());
    }

    setSelectedTab("welcome");
  }, []);

  async function updateNarrative(highLevelTrait: any, scoreData: any) {
    let band = 0;
    let narrative = "";
    let traitBand = "";

    if (scoreData.medCount >= scoreData.lowCount) {
      band = 1;
      if (scoreData.highCount >= scoreData.medCount) {
        band = 2;
      }
    } else if (scoreData.highCount >= scoreData.lowCount) {
      band = 2;
    }

    const response = await API.graphql({
      query: queries.getInstitutionNarratives,
      variables: { highLevelTrait: highLevelTrait, limit: 100000 },
    });

    const responseObject = JSON.parse(JSON.stringify(response));
    const narratives = responseObject.data.getInstitutionNarratives;

    const {
      demonstratingNarrative,
      developingNarrative,
      approachingNarrative,
    } = narratives;

    if (band === 0) {
      narrative = developingNarrative;
      traitBand = "Developing";
    } else if (band === 1) {
      narrative = approachingNarrative;
      traitBand = "Approaching";
    } else if (band === 2) {
      narrative = demonstratingNarrative;
      traitBand = "Demonstrating";
    }

    EventService.track({
      event_type: "user",
      event_name: "hei_welcome_start",
      user_type: "hei",
      event_data: {
        trait: highLevelTrait,
        scoreData,
        narrative,
        band: traitBand,
      },
    });

    if (highLevelTrait === Highleveltraittype.LEADERSHIP) {
      setLeadershipNarrative(narrative);
      setLeadershipBand(traitBand);
    } else if (highLevelTrait === Highleveltraittype.INNOVATIVE_THINKING) {
      setInnovativeThinkingNarrative(narrative);
      setInnovativeThinkingBand(traitBand);
    } else if (highLevelTrait === Highleveltraittype.PERSEVERANCE) {
      setPerserveranceNarrative(narrative);
      setPerserveranceBand(traitBand);
    } else if (highLevelTrait === Highleveltraittype.COMMUNICATION) {
      setCommunicationNarrative(narrative);
      setCommunicationBand(traitBand);
    }
  }

  async function getLeaderShipCounts() {
    const response = await getUserScoresCount(
      LEADERSHIP_SCORE_NAME,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.leadershipScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.leadershipScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.leadershipScore === LOW_SCORE_BAND,
    ).length;
    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highSlice: ((highCount / totalCount) * 100) / 4,
      medSlice: ((medCount / totalCount) * 100) / 4,
      lowSlice: ((lowCount / totalCount) * 100) / 4,
    };

    updateNarrative(Highleveltraittype.LEADERSHIP, scoreData);
    return scoreData;
  }

  async function getCommunicationCounts() {
    const response = await getUserScoresCount(
      COMMUNICATION_SCORE_NAME,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.communicationScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.communicationScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.communicationScore === LOW_SCORE_BAND,
    ).length;

    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highSlice: ((highCount / totalCount) * 100) / 4,
      medSlice: ((medCount / totalCount) * 100) / 4,
      lowSlice: ((lowCount / totalCount) * 100) / 4,
    };
    updateNarrative(Highleveltraittype.COMMUNICATION, scoreData);
    return scoreData;
  }

  async function getInnovationThinkingCounts() {
    const response = await getUserScoresCount(
      INNOVATIVE_THINKING_SCORE_NAME,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.innovativeThinkingScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.innovativeThinkingScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.innovativeThinkingScore === LOW_SCORE_BAND,
    ).length;

    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highSlice: ((highCount / totalCount) * 100) / 4,
      medSlice: ((medCount / totalCount) * 100) / 4,
      lowSlice: ((lowCount / totalCount) * 100) / 4,
    };
    updateNarrative(Highleveltraittype.INNOVATIVE_THINKING, scoreData);
    return scoreData;
  }

  async function getPerserveranceCounts() {
    const response = await getUserScoresCount(
      PERSEVERANCE_SCORE_NAME,
      institutionId,
    );
    const result = response.items;
    const highCount = result.filter(
      (item: any) => item.perseveranceScore === HIGH_SCORE_BAND,
    ).length;
    const medCount = result.filter(
      (item: any) => item.perseveranceScore === MEDIUM_SCORE_BAND,
    ).length;
    const lowCount = result.filter(
      (item: any) => item.perseveranceScore === LOW_SCORE_BAND,
    ).length;

    const totalCount = highCount + medCount + lowCount;

    const scoreData = {
      highCount: highCount,
      medCount: medCount,
      lowCount: lowCount,
      totalCount: totalCount,
      highSlice: ((highCount / totalCount) * 100) / 4,
      medSlice: ((medCount / totalCount) * 100) / 4,
      lowSlice: ((lowCount / totalCount) * 100) / 4,
    };
    updateNarrative(Highleveltraittype.PERSEVERANCE, scoreData);
    return scoreData;
  }

  async function populateRequestedSupportCount() {
    let filterCondition = {
      and: [
        { institutionId: { eq: institutionId } },
        { responseSentToStudent: { eq: false } },
      ],
    };

    const response = await API.graphql({
      query: customQueries.listStudentRequestUserIds,
      variables: { limit: 10000, filter: filterCondition },
    });

    const responseObject = JSON.parse(JSON.stringify(response));

    let users = new Set<String>();

    responseObject.data.listStudentRequests.items.forEach((element: any) => {
      users.add(element.userId);
    });

    setRequestedSupportCount(users.size);
  }

  let navigate = useNavigate();

  const handleview = (data: string) => {
    EventService.track({
      event_type: "user",
      event_name: "hei_routechange",
      user_type: "hei",
      event_data: {
        route: data,
      },
    });

    setSelectedTab(data);
    navigate("/fullsummary/" + data);
  };

  return (
    <>
      {isLoading && <span>Loading...</span>}
      {!isLoading && (
        <section className="hei-welcome bg-gradient-vertical-section">
          <div className="wrapper container text-center welcome-report">
            <h1>Welcome to the SkillTrack Insights Report</h1>
            <p>
              This report represents the insights from{" "}
              {studentCount > 0 && studentCount} students in your program.
            </p>
            <Button
              bsPrefix="psq-btn"
              variant="primary"
              className="col-5"
              onClick={() => handleview("overview")}
            >
              View More Insights
            </Button>
          </div>

          <div className="wrapper welcome-wrapper">
            <div className="container">
              <OverallInsights
                narratives={{
                  leadershipNarrative,
                  perserveranceNarrative,
                  innovativeThinkingNarrative,
                  communicationNarrative,
                }}
                bands={{
                  leadershipBand,
                  perseveranceBand: perserveranceBand,
                  innovativeThinkingBand,
                  communicationBand,
                }}
                requestedSupportCount={requestedSupportCount}
              />
              <GraphInsights
                handleSelection={handleview}
                bands={{
                  leadershipBand,
                  perserveranceBand,
                  innovativeThinkingBand,
                  communicationBand,
                }}
                counts={{
                  leaderShipCounts,
                  perserveranceCounts,
                  innovationThinkingCounts,
                  communicationCounts,
                }}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Welcome;

async function getUserScoresCount(category: any, institutionId: any) {
  let filter = {
    or: [
      {
        and: [
          { [category]: { eq: LOW_SCORE_BAND } },
          { overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
      {
        and: [
          { [category]: { eq: MEDIUM_SCORE_BAND }, overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
      {
        and: [
          { [category]: { eq: HIGH_SCORE_BAND }, overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
    ],
  };

  const response = await API.graphql({
    query: customQueries.searchUserScoresCount,
    variables: { limit: 10000, filter: filter },
  });

  const responseObject = JSON.parse(JSON.stringify(response));
  return responseObject.data.searchUserScores;
}

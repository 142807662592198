// Packages
import React from "react";

// Components
import HorizontalScaleRadio from "../../../../components/Radio/HorizontalScaleRadio";
import { Alert } from "react-bootstrap";

// Assets
import { ReactComponent as BlueDot } from "../../../../assets/dot-green.svg";
import { ReactComponent as BlueBorderDot } from "../../../../assets/dot-unselected-lightgray.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/alert.svg";

export type ConfidenceResponseType = {
  perseveranceConfidenceScore: number;
  leadershipConfidenceScore: number;
  effectiveCommunicationConfidenceScore: number;
  innovativeThinkingConfidenceScore: number;
};

interface TraitConfidenceQuestionsProps {
  onChange: Function;
  checkIsValid: Function;
  defaultScores: ConfidenceResponseType;
  showError: boolean;
  setShowError: Function;
  currentConfidenceResponse: ConfidenceResponseType;
  questionNumber: Number;
}

const TraitConfidenceQuestions = ({
  onChange,
  defaultScores,
  checkIsValid,
  currentConfidenceResponse,
  setShowError,
  showError,
  questionNumber,
}: TraitConfidenceQuestionsProps) => {
  const handleErrorMessage = () => {
    if (showError && checkIsValid()) {
      setShowError(false);
    }
  };

  React.useEffect(() => {
    handleErrorMessage();
  }, [currentConfidenceResponse]);

  return (
    <section className="question trait-confidence-questions" aria-atomic={true}>
      {showError && (
        <Alert variant={"alert"}>
          <div className="icon">
            <AlertIcon />
          </div>
          <div className="text">{`Please answer question ${questionNumber}. Indicate your confidence levels for your Perseverance, Leadership, Effective Communication, and Innovative Thinking skills.`}</div>
        </Alert>
      )}
      <h3 className="question-text">{`${questionNumber}. How confident are you in your:`}</h3>
      <fieldset>
        <HorizontalScaleRadio
          headerText="Perseverance Skills"
          defaultValue={currentConfidenceResponse.perseveranceConfidenceScore}
          maximumValue={5}
          SelectedIcon={<BlueDot height="23" width="23" />}
          UnselectedIcon={<BlueBorderDot height="23" width="23" />}
          radioDescriptorId="Perseverance Skills"
          componentCustomClasses="confidence-scale-radio"
          onChange={(value: number) => {
            onChange({
              ...currentConfidenceResponse,
              perseveranceConfidenceScore: value,
            });
          }}
          labels={{
            0: "Help!",
            2: "So-So",
            4: "Great!",
          }}
        />

        <HorizontalScaleRadio
          headerText="Leadership Skills"
          defaultValue={currentConfidenceResponse.leadershipConfidenceScore}
          maximumValue={5}
          SelectedIcon={<BlueDot height="23" width="23" />}
          UnselectedIcon={<BlueBorderDot height="23" width="23" />}
          radioDescriptorId="Leadership Skills"
          componentCustomClasses="confidence-scale-radio"
          onChange={(value: number) => {
            onChange({
              ...currentConfidenceResponse,
              leadershipConfidenceScore: value,
            });
          }}
          labels={{
            0: "Help!",
            2: "So-So",
            4: "Great!",
          }}
        />

        <HorizontalScaleRadio
          headerText="Effective Communication Skills"
          defaultValue={
            currentConfidenceResponse.effectiveCommunicationConfidenceScore
          }
          maximumValue={5}
          SelectedIcon={<BlueDot height="23" width="23" />}
          UnselectedIcon={<BlueBorderDot height="23" width="23" />}
          radioDescriptorId="Effective Communication Skills"
          componentCustomClasses="confidence-scale-radio"
          onChange={(value: number) => {
            onChange({
              ...currentConfidenceResponse,
              effectiveCommunicationConfidenceScore: value,
            });
          }}
          labels={{
            0: "Help!",
            2: "So-So",
            4: "Great!",
          }}
        />

        <HorizontalScaleRadio
          headerText="Innovative Thinking Skills"
          defaultValue={
            currentConfidenceResponse.innovativeThinkingConfidenceScore
          }
          maximumValue={5}
          SelectedIcon={<BlueDot height="23" width="23" />}
          UnselectedIcon={<BlueBorderDot height="23" width="23" />}
          radioDescriptorId="Innovative Thinking Skills"
          componentCustomClasses="confidence-scale-radio"
          onChange={(value: number) => {
            onChange({
              ...currentConfidenceResponse,
              innovativeThinkingConfidenceScore: value,
            });
          }}
          labels={{
            0: "Help!",
            2: "So-So",
            4: "Great!",
          }}
        />
      </fieldset>
    </section>
  );
};

export default TraitConfidenceQuestions;

// Packages
import { Modal } from "react-bootstrap";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import "./help-modal.scss";

export const HelpModal = ({ onRequestClose }: any) => {
  const handleStay = () => {
    window.location.href = "mailto:skilltracksupport@ets.org";
    onRequestClose();
  };

  return (
    <Modal className="help-modal" show={true} onHide={onRequestClose}>
      <Modal.Header className="no-border" closeButton>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <hr className="border-line"></hr>

      <Modal.Body className="no-border">
        Need support? Reach out to us at:{" "}
        <span className="help-email">skilltracksupport@ets.org</span>
      </Modal.Body>

      <Modal.Footer className="no-border">
        <ReskinButton
          variant="primary"
          onClick={handleStay}
          text="Email Support"
        />
      </Modal.Footer>
    </Modal>
  );
};

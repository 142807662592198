// Packages
import React from "react";

// Types
import { ModalProviderType } from "./types";

// Contexts
import { ModalContext } from "./context";

const ModalProvider = ({ children }: ModalProviderType) => {
  const showModal = (component: any, props = {}) => {
    dispatch({ component, props, showModal, hideModal });
  };

  const hideModal = () => {
    dispatch({ component: null, props: {}, showModal, hideModal });
  };

  // Define a default state
  const DEFAULT_MODAL = {
    component: null,
    props: {},
    showModal,
    hideModal,
  };

  const [state, dispatch] = React.useState({ ...DEFAULT_MODAL });

  return (
    <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;

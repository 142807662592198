import "./CircleBadge.scss";

interface CircleBadgeProps {
  label: string | Number;
}

const CircleBadge = ({ label }: CircleBadgeProps) => {
  return (
    <div className="circle-badge m-0">
      <p>{label}</p>
    </div>
  );
};

export default CircleBadge;

import ReactJson from "react-json-view";
import { API, graphqlOperation } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { RootState } from "../../redux/reducers";
import { useNavigate } from "react-router-dom";
import { updateUserAssessmentState } from "../../redux/actions";
import { AssessmentStatusType } from "../../models";

import deviceInfo from "../../utilities/deviceInfo";
import pkg from "../../../package.json";
import env from "../../configs/env";

//Graphql
import { searchUserResponses } from "../../graphql/queries.js";
import {
  updateUserResponses,
  deleteUserResponses,
} from "../../graphql/mutations.js";

function UserState() {
  const { user } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = useSelector((state: RootState) => state.user);

  const handleDelete = async () => {
    let filter = { userId: { eq: userId } };
    const response = await API.graphql({
      query: searchUserResponses,
      variables: { filter: filter },
    });
    const responseObject = JSON.parse(JSON.stringify(response));
    const original = responseObject.data.searchUserResponses.items;

    if (original.length > 0) {
      await API.graphql({
        query: deleteUserResponses,
        variables: { input: { id: original[0].id } },
      });
    }

    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.ASSESSMENT_STARTED,
      }),
    );

    navigate("/assessment");
  };

  const handleTriplets = async () => {
    let filter = { userId: { eq: userId } };
    const response = await API.graphql({
      query: searchUserResponses,
      variables: { filter: filter },
    });
    const responseObject = JSON.parse(JSON.stringify(response));
    const original = responseObject.data.searchUserResponses.items;

    const input = {
      id: original[0].id,
      tripletResponse: [],
      _version: original[0]._version,
    };
    await API.graphql(graphqlOperation(updateUserResponses, { input: input }));

    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.VIDEO_COMPLETE,
      }),
    );
  };

  const types = [
    "video/webm",
    "video/webm;codecs=h264",
    "video/webm;codecs=vp8",
    'video/webm;codecs="h264"',
    "video/mp4",
    "video/mp4;codecs=h264",
    "video/mp4;codecs=vp8",
    'video/mp4;codecs="h264"',
  ];

  return (
    <div>
      <h1>
        {pkg.name} {pkg.version} {env.stage}
      </h1>
      <hr />
      <strong>Device Info</strong>
      <ReactJson src={deviceInfo} theme="monokai" />
      <hr />
      <strong>Codecs Available</strong>
      <br />
      <ul>
        {types.map((mType, index) => {
          return (
            <li key={index}>
              {mType} : {MediaRecorder.isTypeSupported(mType) ? "Yes" : "No"}
            </li>
          );
        })}
      </ul>
      <hr />
      <strong>User State Info (Redux)</strong>&nbsp;
      <Button onClick={handleDelete}>Reset User Responses &amp; Scores</Button>
      &nbsp;
      <Button onClick={handleTriplets}>Reset to Triplets</Button>
      <hr />
      <ReactJson src={user} theme="monokai" />
    </div>
  );
}

export default UserState;

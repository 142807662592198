// Components
import CategoryReskin from "./CategoryReskin";

function StudentByCategory({ counts, performers, setSelectedTab }: any) {
  return (
    <div className="student-category">
      <div className="wrapper container p-0">
        <div className="categories">
          <CategoryReskin
            counts={counts.leadership}
            trait="leadership"
            performers={performers.leadership}
            setSelectedTab={setSelectedTab}
          />
          <CategoryReskin
            counts={counts.communication}
            trait="effective communication"
            performers={performers.communication}
            setSelectedTab={setSelectedTab}
          />
          <CategoryReskin
            counts={counts.perseverance}
            trait="perseverance"
            performers={performers.perseverance}
            setSelectedTab={setSelectedTab}
          />
          <CategoryReskin
            counts={counts.innovativeThinking}
            trait="innovative thinking"
            performers={performers.innovativeThinking}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    </div>
  );
}

export default StudentByCategory;

// Components
import { Table } from "react-bootstrap";
import HeaderRow from "./Header/HeaderRow";
import SupportPanelBody from "./Body";

// Utils
import sortRequestsIntoRows from "./utils/sortRequestsIntoRows";
import handleSorting from "./utils/handleSorting";

// GraphQL
import { listStudentSupportRequests } from "../../../../graphql/custom-queries";
import { getOrList } from "../../../../components/dynamicqueries";

// Packages
import React from "react";
import { useSelector } from "react-redux";

// Redux
import { RootState } from "../../../../redux/reducers";
import Loading from "../../../../components/loading";

// Services
import EventService from "../../../../services/EventService/event.service";

const Panel = () => {
  // To be used until backend integration
  const { user } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = React.useState(true);
  const [requestRows, setRequestRows] = React.useState({});
  const [sortingAlgorithm, setSortingAlgorithm] = React.useState({
    propertyKey: "",
    sortDirection: "",
  });
  const studentCount = Object.keys(requestRows).length;

  // HANDLE SORTING
  React.useEffect(() => {
    // No need to run this on the first render
    if (requestRows) {
      const { propertyKey, sortDirection } = sortingAlgorithm;
      const sortedRows = handleSorting({
        studentsList: requestRows,
        propertyKey,
        sortDirection,
      });

      setRequestRows(sortedRows);
    }
  }, [sortingAlgorithm]);

  // INITIAL DATA HANDLING
  React.useEffect(() => {
    const filter = {
      limit: 10000,
      filter: {
        and: [{ institutionId: { eq: user.institutionId } }],
      },
    };

    const getData = async () => {
      const data = await getOrList(
        listStudentSupportRequests,
        "listStudentRequests",
        filter,
      );

      if (data) {
        const rowsOfRequests = sortRequestsIntoRows(data);
        setRequestRows(rowsOfRequests);
        setIsLoading(false);
      }
    };

    getData();

    EventService.track({
      event_type: "user",
      event_name: "hei_support_list_start",
      user_type: "hei",
      event_data: {},
    });

    return () => {
      EventService.track({
        event_type: "user",
        event_name: "hei_support_list_end",
        user_type: "hei",
        event_data: {},
      });
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <nav className="breadcrumb-container">
        <span className="breadcrumb">
          <a className="breadcrumb-active-link" href="/fullsummary/students">
            Student List
          </a>
          <p className="m-0">&nbsp;</p>
          <p className="m-0"> {" > Student Resource Hub"}</p>
        </span>
      </nav>
      <section className="text-container">
        <p>{`${studentCount} students have requested additional support - quickly send them some notes and references`}</p>
      </section>
      <Table striped className="panelTable">
        <HeaderRow sortingHandler={setSortingAlgorithm} />
        <SupportPanelBody rows={requestRows} />
      </Table>
    </div>
  );
};

export default Panel;

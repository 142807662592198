// Packages
import { useState, useEffect } from "react";

// Services
import EventService from "../../../../services/EventService/event.service";
import Accordion from "react-bootstrap/Accordion";
import Stack from "react-bootstrap/Stack";

import { ReactComponent as DevelopingBar } from "../../../../assets/icons/bar/developing-bar-reskin.svg";
import { ReactComponent as ApproachingBar } from "../../../../assets/icons/bar/approaching-bar-reskin.svg";
import { ReactComponent as DemonstratingBar } from "../../../../assets/icons/bar/demonstrating-bar-reskin.svg";
// Types
import { DIMENSIONS } from "../../../../enumerations";

function TraitCard({ narratives, traitName }: any) {
  const [openItem, setOpenItem] = useState(null);

  let subtraits = [
    {
      type: "leadership",
      number: ["f08", "f03", "f09", "f12", "f11", "f07", "f02"],
      dimension: [
        DIMENSIONS.F08,
        DIMENSIONS.F03,
        DIMENSIONS.F09,
        DIMENSIONS.F12,
        DIMENSIONS.F11,
        DIMENSIONS.F07,
        DIMENSIONS.F02,
      ],
    },
    {
      type: "perseverance",
      number: ["f01", "f06", "f05", "f04"],
      dimension: [
        DIMENSIONS.F01,
        DIMENSIONS.F06,
        DIMENSIONS.F05,
        DIMENSIONS.F04,
      ],
    },
    {
      type: "effectivecommunication",
      number: [
        "headBodyPosture",
        "speechFluency",
        "effectivePauses",
        "eyeContact",
      ],
      dimension: [
        DIMENSIONS.headBodyPosture,
        DIMENSIONS.speechFluency,
        DIMENSIONS.effectivePauses,
        DIMENSIONS.eyecontact,
      ],
    },
    {
      type: "innovativethinking",
      number: ["f14", "f15", "f13"],
      dimension: [DIMENSIONS.F14, DIMENSIONS.F15, DIMENSIONS.F13],
    },
  ];

  subtraits = subtraits.filter((subtrait: any) => subtrait.type === traitName);
  const dimensionId = subtraits[0].number;
  const dimensionName = subtraits[0].dimension;

  const sequenceNarrative: any = [];

  dimensionId.forEach((number: any) => {
    const narrative = narratives.filter(
      (narrative: any) => narrative.dimensionId === number,
    );
    sequenceNarrative.push(narrative[0]);
  });

  const handleAccordionEvent = (subtraitIndex: number) => {
    const subtrait = dimensionName[subtraitIndex];
    EventService.track({
      event_type: "user",
      event_name: "hei_subtrait_open",
      user_type: "institution",
      event_data: {
        trait: traitName,
        subtrait: subtrait,
      },
    });
  };

  const toggleAccordion = (eventKey: any) => {
    const currentKey = eventKey === openItem ? null : eventKey;
    setOpenItem(currentKey);
    if (currentKey !== null) {
      handleAccordionEvent(currentKey);
    }
  };

  useEffect(() => {
    EventService.track({
      event_type: "user",
      event_name: "hei_trait_improve",
      user_type: "hei",
      event_data: {
        trait: traitName,
        narratives: sequenceNarrative,
      },
    });
  }, [traitName]);

  return (
    <>
      <div className="improve">
        {/* <p className="improve-title">Ways for Your Students to Improve</p> */}
        {/* <div className="insight">
          <p>Insights Your Students Received</p>
          <p>How You Can Help Your Students Improve</p>
        </div> */}
        <Accordion flush={false} onSelect={toggleAccordion}>
          <Stack gap={3}>
            {sequenceNarrative.map((traitData: any, index: number) => {
              return (
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>
                    <span className={`improve-header trait-color-${traitName}`}>
                      {dimensionName[index]}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="improve-content">
                      <div className="improve-trait">
                        {/* <strong className="traitName">{dimensionName[index]}</strong> */}
                        <p className="traitDescription">
                          {traitData.definition}
                        </p>
                        <p className="insightsHeader">
                          Insights Your Students Received
                        </p>
                        <ul className="traitDescription">
                          {traitData.improvementNarrative.map(
                            (content: string, index: number) => {
                              return <li key={index}>{content}</li>;
                            },
                          )}
                        </ul>
                      </div>
                      {/* <p>How You Can Help Your Students Improve</p> */}
                      <p
                        className={`improve-section-header trait-color-${traitName}`}
                      >
                        Ways for Your Students to Improve
                      </p>
                      <div className="improve-cards-container">
                        <div className="improve-card">
                          <div className="improve-card-header">
                            <p className="improve-trait">
                              <strong>
                                {" "}
                                Developing <br />
                              </strong>
                              {/* <strong>{dimensionName[index]}</strong> */}
                            </p>
                            <p className="improve-score improve-score-developing">
                              {/* Score <br /> <strong>Developing</strong> */}
                              <DevelopingBar />
                            </p>
                          </div>
                          <div className="improve-card-body">
                            <div className="narrative">
                              {traitData.lowScoreNarrative}
                            </div>

                            <div className="tips">
                              <strong>Tips:</strong>
                              <br />
                              {traitData.developingTips}
                            </div>
                          </div>
                        </div>
                        <div className="improve-card">
                          <div className="improve-card-header">
                            <p className="improve-trait">
                              <strong>
                                Approaching <br />
                              </strong>
                              {/* <strong>{dimensionName[index]}</strong> */}
                            </p>
                            <p className="improve-score  improve-score-approaching">
                              {/* Score <br /> <strong>Approaching</strong> */}
                              <ApproachingBar />
                            </p>
                          </div>
                          <div className="improve-card-body">
                            <div className="narrative">
                              {traitData.mediumScoreNarrative}
                            </div>

                            <div className="tips">
                              <strong>Tips:</strong>
                              <br />
                              {traitData.approachingTips}
                            </div>
                          </div>
                        </div>
                        <div className="improve-card">
                          <div className="improve-card-header">
                            <p className="improve-trait">
                              <strong>
                                Demonstrating <br />
                              </strong>
                              {/* <strong>{dimensionName[index]}</strong> */}
                            </p>
                            <p className="improve-score  improve-score-demonstrating">
                              {/* Score <br /> <strong>Demonstrating</strong> */}
                              <DemonstratingBar />
                            </p>
                          </div>
                          <div className="improve-card-body">
                            <div className="narrative">
                              {traitData.highScoreNarrative}
                            </div>

                            <div className="tips">
                              <strong>Tips:</strong>
                              <br />
                              {traitData.demonstratingTips}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Stack>
        </Accordion>
      </div>
    </>
  );
}

export default TraitCard;

// Packages
import React from "react";

// Components
import TraitSelectDropdownOption from "./TraitSelectDropdownOption";

// Assets
import { ReactComponent as CaretUp } from "../../../../assets/reskin/icons/caret-up-white.svg";
import { ReactComponent as CaretDown } from "../../../../assets/reskin/icons/caret-down-white.svg";

interface TraitOptionType {
  leftIcon: JSX.Element;
  rightIcon?: JSX.Element;
  label: string;
  iconBackground: string;
  labelBackground: string;
  isFirstOption?: boolean;
  value: any;
}

interface TraitSelectDropdownProps {
  options: TraitOptionType[];
  selectedOption: TraitOptionType;
  onChange: Function;
  customClasses?: string;
}

const TraitSelectDropdown = ({
  options,
  selectedOption,
  onChange,
  customClasses,
}: TraitSelectDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const DropdownRightIcon = isDropdownOpen ? <CaretUp /> : <CaretDown />;

  const selectableOptionsToggle = isDropdownOpen ? "" : "closed";
  const renderedSelectableOptions = options.map((option) => {
    const { value } = option;
    const { value: currentlySelectedValue } = selectedOption;

    if (value === currentlySelectedValue) return null;
    else {
      return (
        <TraitSelectDropdownOption
          {...option}
          onClick={() => {
            onChange(value);
            setIsDropdownOpen(false);
          }}
        />
      );
    }
  });

  return (
    <div className={`trait-select-dropdown ${customClasses}`}>
      <TraitSelectDropdownOption
        key={selectedOption.label}
        {...selectedOption}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        rightIcon={DropdownRightIcon}
      />
      <div className={`selectable-options-wrapper ${selectableOptionsToggle}`}>
        {renderedSelectableOptions}
      </div>
    </div>
  );
};

export default TraitSelectDropdown;

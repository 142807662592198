// Packages
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import { useNavigate } from "react-router-dom";
import { Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

// Model
import { HeiBookmarks, HeiComments } from "../../../../../models";

// Components
import {
  IconCell,
  ProfileImageLeftCell,
  TextCell,
  EditableCell,
} from "../../../../../components/Table/dataCells";
import LinesEllipsis from "react-lines-ellipsis";
import "../index.scss";

// Images
import { ReactComponent as Bookmark } from "../../../../../assets/fullsummary/bookmark.svg";
import { ReactComponent as SelectedBookmark } from "../../../../../assets/fullsummary/SelectedBookmark.svg";
import { ReactComponent as ApproachingBar } from "../../../../../assets/icons/bar/approaching-bar-reskin.svg";
import { ReactComponent as DemonstratingBar } from "../../../../../assets/icons/bar/demonstrating-bar-reskin.svg";
import { ReactComponent as DevelopingBar } from "../../../../../assets/icons/bar/developing-bar-reskin.svg";

// Types
import { StudentRowType } from "../../types";

// Redux
import { RootState } from "../../../../../redux/reducers";

// Services
import EventService from "../../../../../services/EventService/event.service";

// graphql

import {
  createHeiBookmarks,
  updateHeiBookmarks,
  createHeiComments,
  updateHeiComments,
} from "../../../../../graphql/mutations";
import {
  listHeiBookmarks,
  listHeiComments,
} from "../../../../../graphql/queries";
import createOrUpdate, {
  getOrList,
} from "../../../../../components/dynamicqueries";

interface StudentRowProps {
  student: any;
  allStudentsList: StudentRowType[];
  setStudentsList: Function;
  setRefreshingCSVData: Function;
  index: number;
  propKey: any;
}

const getCellIcon = (value: string): JSX.Element => {
  if (value === "HIGH" || value === "Demonstrating") {
    return <DemonstratingBar />;
  } else if (value === "MEDIUM" || value === "Approaching") {
    return <ApproachingBar />;
  } else return <DevelopingBar />;
};

export function StudentRow({
  student,
  allStudentsList,
  setStudentsList,
  setRefreshingCSVData,
  index,
  propKey,
}: StudentRowProps) {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const { userId } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const {
    leadershipScoreBand,
    perseveranceScoreBand,
    communicationScoreBand,
    innovativeThinkingScoreBand,
    userSessionId,
    assessmentId,
    userScoresUserAssessmentStateId,
  } = student;

  const { ageGroup, enrollmentYear, name } = student.userAssessmentState.user;
  const { studentRequests } = student.userAssessmentState;

  let supportText = "none";

  if (studentRequests.items.length > 0) {
    supportText = "requested";
    let listofRequests = studentRequests.items;
    listofRequests.map((value: any) => {
      if (value.institutionResponses.items.length > 0) {
        supportText = "sent";
      } else {
        supportText = "requested";
        return false;
      }
    });
  }

  const comments =
    student.userAssessmentState.heiComments.items.length > 0
      ? student.userAssessmentState.heiComments.items.filter(
          (comment: any) => comment.userId === userId,
        )
      : [];

  const comment = comments.length > 0 ? comments[0].comment : "";

  const candidateUserId = student.userAssessmentState.user.userId;

  useEffect(() => {
    const setInitialBookmarkedStatus = async () => {
      const filter = { filter: { userId: { eq: userId } } };
      const heiBookmark = await getOrList(
        listHeiBookmarks,
        "listHeiBookmarks",
        filter,
      );

      if (heiBookmark.length > 0) {
        let bookmarkedCandidates: any = heiBookmark[0].candidateUserId;
        const currentCandidateBookmark = bookmarkedCandidates.filter(
          (item: any) => item === candidateUserId,
        );
        if (currentCandidateBookmark.length > 0) {
          setIsBookmarked(true);
        }
      }
    };
    setInitialBookmarkedStatus();
    getS3PresignedUrl();
  }, []);

  const handleBookmark = async (boolvalue: any) => {
    const filter = { filter: { userId: { eq: userId } } };
    const original = await getOrList(
      listHeiBookmarks,
      "listHeiBookmarks",
      filter,
    );

    if (original.length > 0) {
      const bookmarkedCandidates: any = original[0].candidateUserId;
      let candidateData: any = bookmarkedCandidates;

      if (boolvalue) {
        candidateData = [...bookmarkedCandidates, candidateUserId];

        EventService.track({
          event_type: "user",
          event_name: "hei_list_bookmark",
          user_type: "hei",
          event_data: {
            student,
          },
        });

        setIsBookmarked(true);
      } else {
        candidateData = bookmarkedCandidates.filter(
          (item: any) => item !== candidateUserId,
        );

        EventService.track({
          event_type: "user",
          event_name: "hei_list_unbookmark",
          user_type: "hei",
          event_data: {
            student,
          },
        });

        setIsBookmarked(false);
      }
      const input = {
        id: original[0].id,
        userId: userId,
        userSessionId: userSessionId,
        assessmentId: assessmentId,
        candidateUserId: candidateData,
        _version: original[0]._version,
      };
      await createOrUpdate(updateHeiBookmarks, input);
    } else {
      const input = {
        userId: userId,
        userSessionId: userSessionId,
        assessmentId: assessmentId,
        candidateUserId: [candidateUserId],
      };
      await createOrUpdate(createHeiBookmarks, input);

      EventService.track({
        event_type: "user",
        event_name: "hei_list_bookmark",
        user_type: "hei",
        event_data: {
          student,
        },
      });

      setIsBookmarked(true);
    }
  };

  const updateStudentsListComments = (newComment: string) => {
    const newStudentsList = allStudentsList.map((student: any) => {
      const currentId = student.userAssessmentState.user.userId;
      if (currentId === candidateUserId) {
        if (comments.length > 0) {
          const index = student.userAssessmentState.heiComments.items.findIndex(
            (x: any) => x.userId === userId,
          );
          student.userAssessmentState.heiComments.items[index].comment =
            newComment;
        } else {
          student.userAssessmentState.heiComments.items.push({
            comment: newComment,
            candidateUserId: userId,
            userId: userId,
          });
        }
      }

      return student;
    });

    setStudentsList(newStudentsList);
  };

  const saveComment = async (comment: string) => {
    EventService.track({
      event_type: "user",
      event_name: "hei_list_update_comment",
      user_type: "hei",
      event_data: {
        student,
        comment,
      },
    });

    const filter = {
      filter: {
        userId: { eq: userId },
        candidateUserSessionId: { eq: userSessionId },
      },
    };
    const original = await getOrList(
      listHeiComments,
      "listHeiComments",
      filter,
    );

    if (original.length > 0) {
      const input = {
        id: original[0].id,
        comment: comment,
        _version: original[0]._version,
      };
      await createOrUpdate(updateHeiComments, input);
    } else {
      let input: any = {
        userId: userId,
        userSessionId: userSessionId,
        assessmentId: assessmentId,
        candidateUserId: candidateUserId,
        candidateUserSessionId: userSessionId,
        userAssessmentId: assessmentId,
        comment,
      };
      if (userScoresUserAssessmentStateId !== null) {
        input["userAssessmentStateHeiCommentsId"] =
          userScoresUserAssessmentStateId;
      }

      await createOrUpdate(createHeiComments, input);
    }
  };

  const getS3PresignedUrl = async () => {
    let preSignedUrl: any;
    let filePath = "photos/" + student.userId + ".PNG";
    preSignedUrl = await Storage.get(filePath, {
      level: "public",
      download: false,
      expires: 300,
    });
    setImageUrl(preSignedUrl);
  };

  const StudentProfilePicture = (
    <img
      src={imageUrl}
      className="profilePicture"
      alt="student-profile"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          require("../../../../../assets/fullsummary/user-avatar.png").default;
      }}
    />
  );

  const commentDisplay = comment ? comment : "No comment.";
  const StudentLink = (
    // <Link className="studentLink" to={`/fullsummary/students/${userSessionId}`}>
    //   <LinesEllipsis text={name} maxLine="1" />
    // </Link>
    <button
      className="studentLink"
      aria-label={`Student Profile: ${name}`}
      onClick={() =>
        handleStudentClick(`/fullsummary/students/${userSessionId}`)
      }
    >
      {/* <LinesEllipsis text={name} maxLine="1" /> */}
      {name.slice(name.lastIndexOf(" ") + 1) +
        ", " +
        name.slice(0, name.lastIndexOf(" "))}
    </button>
  );

  const BookmarkIcon = isBookmarked ? (
    <SelectedBookmark className="active" />
  ) : (
    <Bookmark className="active" />
  );

  const RenderedBookmark = (
    <button
      onClick={() => {
        handleBookmark(!isBookmarked);
        setRefreshingCSVData({ status: true });
      }}
      className="unstyled-button"
      tabIndex={0}
      aria-label={"bookmark student"}
    >
      {BookmarkIcon}
    </button>
  );

  const handleStudentClick = (route: string) => {
    EventService.track({
      event_type: "user",
      event_name: "hei_list_click_student",
      user_type: "hei",
      event_data: {
        student,
      },
    });

    navigate(route);
  };

  const renderSupportBtn = () => {
    return (
      <Button
        bsPrefix="psq-btn"
        variant="primary"
        type="submit"
        className="btn-sendSupport"
        aria-label="Send support"
        onClick={() => {
          navigate(`/fullsummary/support/send/${student.userSessionId}`);
        }}
      >
        Send
      </Button>
    );
  };

  return (
    <tr className=" tableRow flex-nowrap" key={propKey}>
      <IconCell size="col-1" icon={RenderedBookmark} />
      <ProfileImageLeftCell
        renderedImage={StudentProfilePicture}
        size="col-2"
        renderedText={StudentLink}
        supportStatus={supportText}
      />
      <TextCell
        size="col-1"
        text={ageGroup}
        ariaLabel={`Age Range: ${ageGroup}`}
      />
      <TextCell
        size="col-1"
        text={`${enrollmentYear}`}
        ariaLabel={`Enrolled Since: ${enrollmentYear}`}
      />
      <IconCell
        size="col-1"
        icon={getCellIcon(leadershipScoreBand)}
        ariaLabel={`Leadership Score: ${leadershipScoreBand}`}
      />
      <IconCell
        size="col-1 wideIconCell"
        icon={getCellIcon(communicationScoreBand)}
        ariaLabel={`Effective Communication Score: ${communicationScoreBand}`}
      />
      <IconCell
        size="col-1 wideIconCell"
        icon={getCellIcon(perseveranceScoreBand)}
        ariaLabel={`Perseverance Score: ${perseveranceScoreBand}`}
      />

      <IconCell
        size="col-1"
        icon={getCellIcon(innovativeThinkingScoreBand)}
        ariaLabel={`Innovative Thinking Score: ${innovativeThinkingScoreBand}`}
      />
      {/* <EditableCell
        size="col-3 comment-cell"
        text={commentDisplay}
        onSave={(newComment: string) => {
          saveComment(newComment);
          updateStudentsListComments(newComment);
        }}
      />
      <TextCell size="col-1" text={renderSupportBtn()} /> */}
    </tr>
  );
}

// Packages
import React from "react";
import Carousel from "react-multi-carousel";

// Assets
import { ReactComponent as CardDot } from "../../assets/dot.svg";
import { ReactComponent as CardSelectedDot } from "../../assets/dot-selected.svg";

interface PSQCarouselProps {
  content: JSX.Element[];
  handleChange?: Function;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 0.95, // optional, default to 1.
    partialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 0.95, // optional, default to 1.
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 0.95, // optional, default to 1.
    partialVisibilityGutter: 0,
  },
};

const CustomDot = ({
  index,
  onClick,
  active,
  carouselState,
  handleChange,
}: any) => {
  const [loaded, setLoaded] = React.useState<any>(false);

  React.useEffect(() => {
    if (loaded) {
      if (active && handleChange) {
        handleChange(index, carouselState);
      }
    } else {
      setLoaded(true);
    }
  }, [active]);

  return (
    <button
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      className={`custom-dot`}
    >
      {active ? <CardSelectedDot /> : <CardDot />}
    </button>
  );
};

const PSQCarousel = ({ content, handleChange }: PSQCarouselProps) => {
  const carouselRef = React.useRef<any>(null);

  return (
    <Carousel
      ref={(el) => (carouselRef.current = el)}
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      autoPlay={false}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="psq-carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      customDot={<CustomDot handleChange={handleChange} />}
    >
      {content}
    </Carousel>
  );
};

export default PSQCarousel;

// Packages
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Assets
import { ReactComponent as BarsNone } from "../../../../assets/reskin/icons/bars-none.svg";
import { ReactComponent as BarsLow } from "../../../../assets/reskin/icons/bars-low.svg";
import { ReactComponent as BarsMedium } from "../../../../assets/reskin/icons/bars-medium.svg";
import { ReactComponent as BarsHigh } from "../../../../assets/reskin/icons/bars-high.svg";
import { ReactComponent as ExclamationCircle } from "../../../../assets/re-skin/InsightsReportIcons/exclamation-circle-green.svg";

// Components
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { RootState } from "../../../../redux/reducers";

// Actions
import { updateUserAssessmentState } from "../../../../redux/actions";

interface InsightsReportCardPropType {
  TraitIcon: JSX.Element;
  traitName: string;
  traitClass: string;
  subtraitsCount: number;
  traitScore?: string;
  status?: string;
  navLink: string;
}

const ScoreDisplay = ({ traitScore, subtraitsCount, navLink }: any) => {
  let ScoreIcon: JSX.Element;
  let ScoreLabel = "";

  switch (traitScore) {
    case "LOW":
      ScoreIcon = <BarsLow width="56" height="50" />;
      ScoreLabel = "Developing";
      break;
    case "MEDIUM":
      ScoreIcon = <BarsMedium width="56" height="50" />;
      ScoreLabel = "Approaching";
      break;
    case "HIGH":
      ScoreIcon = <BarsHigh width="56" height="50" />;
      ScoreLabel = "Demonstrating";
      break;
    default:
      ScoreIcon = <BarsNone width="56" height="50" />;
  }

  return (
    <>
      <div className="card-subheader standard">
        <p className="subtraits-count">{`${subtraitsCount} Traits`}</p>
      </div>
      <div className="score-display-body insight-report-card-body">
        <div className="bars-container">{ScoreIcon}</div>
        <div className="score-label-container">
          <p>{ScoreLabel}</p>
        </div>
      </div>
      <div className="insight-report-card-footer">
        <Link className="card-link" to={navLink}>
          Go to my trait scores
        </Link>
      </div>
    </>
  );
};

const SkippedDisplay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = useSelector((state: RootState) => state.user);

  return (
    <>
      <div className="card-subheader error">
        <strong className="bold-subheader">No Data Available</strong>
      </div>
      <div className="error-display-body insight-report-card-body">
        <div className="error-icon-container">
          <ExclamationCircle />
        </div>
        <div>
          <p className="error-text">
            In order to receive these insights, you must first complete the
            effective communication assessment.
          </p>
        </div>
      </div>
      <div className="insight-report-card-footer">
        <a
          className="card-link"
          href="#"
          onClick={async () => {
            sessionStorage.setItem("rerecordingAttempt", "true");
            await dispatch(
              updateUserAssessmentState({
                userId,
                UserAssessmentStateId,
                statusType: "VIDEO_INPROGRESS",
              }),
            );
            navigate("/assessment/communication/intro");
          }}
        >
          Begin Assessment
        </a>
        <p className="card-link no-underline">{" >"}</p>
      </div>
    </>
  );
};

const LoadingDisplay = () => {
  return (
    <>
      <div className="card-subheader error">
        <strong className="bold-subheader">No Data Available</strong>
      </div>
      <div className="insight-report-card-body loading-body">
        <Spinner animation="border" />
      </div>
      <div className="insight-report-card-footer">
        <p className="card-link">Loading...</p>
      </div>
    </>
  );
};

const InsightsReportCard = ({
  TraitIcon,
  traitName,
  subtraitsCount,
  traitScore,
  traitClass,
  status,
  navLink,
}: InsightsReportCardPropType) => {
  let BodyContent: JSX.Element;
  switch (status?.toLowerCase()) {
    case "inprocess":
      BodyContent = <LoadingDisplay />;
      break;

    case "skipped":
      BodyContent = <SkippedDisplay />;
      break;

    case "accepted":
      BodyContent = (
        <ScoreDisplay
          traitScore={traitScore}
          subtraitsCount={subtraitsCount}
          navLink={navLink}
        />
      );
      break;
    default:
      BodyContent = (
        <ScoreDisplay
          traitScore={traitScore}
          subtraitsCount={subtraitsCount}
          navLink={navLink}
        />
      );
  }

  return (
    <div className="reskin-insights-report-card">
      <div className={`insight-report-card-header ${traitClass}`}>
        <div>{TraitIcon}</div>
        <div>
          <p className="mt-3 trait-title">{traitName}</p>
        </div>
      </div>
      {BodyContent}
    </div>
  );
};

export default InsightsReportCard;

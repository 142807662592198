// Packages
import React from "react";
import { Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";

// Services
import EventService from "../../../services/EventService/event.service";

// Styles
import "./ForgotPassword.scss";
import { validateEmail } from "../../../utilities/emailVerification";

/**
 * ForgotPassword.
 *
 * Handles non-logged in user forgot password feature. Prompts users for an email address and sends verification code.
 *
 * @changeAuthState Allows for us to change the state in the CustomAuthenticator.
 */

function ForgotPassword({ changeAuthState }: any) {
  const [errors, setErrors] = React.useState<any>({ email: "" });
  const [formData, setFormData] = React.useState<any>({
    email: "",
  });
  const emailInput = React.useRef<any>(null);

  const handleInputChange = (e: any) => {
    let { value, dataset } = e.target;
    const { prop } = dataset;

    setFormData({
      ...formData,
      [prop]: value,
    });

    // reset specific prop user is in
    setErrors({ ...errors, [prop]: "" });
  };

  const checkValidity = () => {
    let errors = 0;
    let errorValues = { email: "" };

    if (!validateEmail(formData.email.trim())) {
      errorValues.email = "Please enter a valid email address.";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }

    return true;
  };

  const handleContinue = async () => {
    if (checkValidity()) {
      // store email for late ruse

      EventService.track({
        event_type: "user",
        event_name: "user_request_password_reset",
        user_type: "student",
        event_data: {
          email: formData.email,
        },
      });
      sessionStorage.setItem("email", formData.email);

      try {
        await Auth.forgotPassword(formData.email).then((data) => {
          console.log(data);
          changeAuthState({ screen: "ResetPassword" });
        });
      } catch (error: unknown) {
        console.log(error);

        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];

        switch (errorHandling) {
          case "LimitExceededException":
            setErrors({ email: "Attempt limit exceeded, try again later." });
            break;

          case "UserNotFoundException":
            setErrors({ email: "User not found, try again." });
            break;

          default:
            setErrors({ email: "An error occured, try again." });
            break;
        }
      }
    }
  };

  const goBack = () => changeAuthState({ screen: "SignIn" });

  React.useEffect(() => {
    if (emailInput && emailInput.current) emailInput.current.focus();
  });

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "User Forgot Password",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "User Forgot Password",
      });
    };
  }, []);

  return (
    <div className="forgot-password-container">
      <div className="ImageContainer">
        <div className="TopContainer">
          <div className="text-container header-container">
            <p className="header-text m-0">SkillTrack</p>
          </div>
          <div className="text-container subtext-container">
            <p className="subtitle-text m-0">
              SkillTrack will help you identify your strengths in employability
              skills.
            </p>
          </div>
        </div>
        <div className="BottomContainer">
          <p className="boldText">Reset Password</p>
          <div className="InputContainer">
            <section className="forgot">
              <div className="wrapper container pb-4">
                <Form className="form" noValidate>
                  <div>
                    <div className="prompt-container">
                      <p>
                        Enter the email associated with your account. A link
                        will be sent to your email for you to reset your
                        password.
                      </p>
                    </div>

                    <Form.Group className="mb-3 item" controlId="formEmail">
                      <Form.Label>
                        Email &nbsp; <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        className="TextInputContainer"
                        type="email"
                        placeholder=""
                        data-prop={"email"}
                        onChange={handleInputChange}
                        required
                        isInvalid={errors.email !== ""}
                        autoComplete="off"
                        ref={emailInput}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="controls-container">
                    <ReskinButton
                      variant="primary"
                      text="Send"
                      customClasses="send-button button-control"
                      onClick={handleContinue}
                    />

                    <ReskinButton
                      variant="secondary"
                      text="Cancel"
                      customClasses="cancel-button button-control"
                      onClick={goBack}
                    />
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

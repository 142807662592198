// Packages
// import DonutChart from "react-svg-donut-chart";
import React from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

function Ring({ counts }: any) {
  // let totalCount = 0;
  // for (const [key, value] of Object.entries(counts)) {
  //   totalCount += counts[key].totalCount as number;
  // }

  const data = {
    datasets: [
      {
        data: [
          counts.leaderShipCounts.lowSlice,
          counts.leaderShipCounts.medSlice,
          counts.leaderShipCounts.highSlice,
          counts.perserveranceCounts.lowSlice,
          counts.perserveranceCounts.medSlice,
          counts.perserveranceCounts.highSlice,
          counts.innovationThinkingCounts.lowSlice,
          counts.innovationThinkingCounts.medSlice,
          counts.innovationThinkingCounts.highSlice,
          counts.communicationCounts.lowSlice,
          counts.communicationCounts.medSlice,
          counts.communicationCounts.highSlice,
        ],
        backgroundColor: [
          "#FF8577",
          "#FFD671",
          "#00DEBC",
          "#FF8577",
          "#FFD671",
          "#00DEBC",
          "#FF8577",
          "#FFD671",
          "#00DEBC",
          "#FF8577",
          "#FFD671",
          "#00DEBC",
        ],
        borderWidth: 4,
        borderColor: ["#015478"],
      },
    ],
  };

  const options = {
    cutout: 175,
  };

  // const data = [
  //   {
  //     label: "leadership-low",
  //     value: counts.leaderShipCounts.lowSlice,
  //     stroke: "#FF8577",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "leadership-med",
  //     value: counts.leaderShipCounts.medSlice,
  //     stroke: "#FFD671",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "leadership-high",
  //     value: counts.leaderShipCounts.highSlice,
  //     stroke: "#00DEBC",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "perserverance-low",
  //     value: counts.perserveranceCounts.lowSlice,
  //     stroke: "#FF8577",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "perserverance-med",
  //     value: counts.perserveranceCounts.medSlice,
  //     stroke: "#FFD671",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "perserverance-high",
  //     value: counts.perserveranceCounts.highSlice,
  //     stroke: "#00DEBC",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "innovative-low",
  //     value: counts.innovationThinkingCounts.lowSlice,
  //     stroke: "#FF8577",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "innovative-med",
  //     value: counts.innovationThinkingCounts.medSlice,
  //     stroke: "#FFD671",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "innovative-high",
  //     value: counts.innovationThinkingCounts.highSlice,
  //     stroke: "#00DEBC",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "communication-low",
  //     value: counts.communicationCounts.lowSlice,
  //     stroke: "#FF8577",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "communication-med",
  //     value: counts.communicationCounts.medSlice,
  //     stroke: "#FFD671",
  //     strokeWidth: 4,
  //   },
  //   {
  //     label: "communication-high",
  //     value: counts.communicationCounts.highSlice,
  //     stroke: "#00DEBC",
  //     strokeWidth: 4,
  //   },
  // ];

  // let totalPie = 0;

  // // dataPie2.forEach((value, index) => {
  // //   totalPie += value.value;
  // // });

  return (
    <>
      <div className="testchart">
        <Doughnut data={data} options={options} />
        {/* <DonutChart data={dataPie2} spacing={0.25} /> */}
      </div>
      {/* <div className="testchart">
        <DonutChart data={dataPie} spacing={0.5} />
      </div> */}
      {/* <svg
        className={`ActivityRings ${className ? className : ""}`}
        viewBox="0 0 37 37"
      >
        <g className={`ring ring1 scoring`}>
          <circle
            strokeWidth={strokeWidth}
            r="14"
            cx="50%"
            cy="50%"
            className="completed"
            strokeDasharray={`${percentages.lowPercentage}, 100`}
          />
        </g>

        <g className={`ring ring1 approaching`}>
          <circle
            strokeWidth={strokeWidth}
            r="14"
            cx="50%"
            cy="50%"
            className="completed"
            strokeDasharray={`${percentages.medPercentage}, 100`}
          />
        </g>

        <g className={`ring ring1 demonstrating`}>
          <circle
            strokeWidth={strokeWidth}
            r="14"
            cx="50%"
            cy="50%"
            className="completed"
            strokeDasharray={`${percentages.highPercentage}, 100`}
          />
        </g>
      </svg> */}
    </>
  );
}

export default Ring;

// Utils
import { getOrList } from "../../../components/dynamicqueries";
import {
  listStudentFavouriteLearningResources,
  listResourceReactions,
  confidenceLevelByUserIdTraitPhaseAndSelectionDate,
} from "../../../graphql/queries";
import { getCombinedResourceCardDetails } from "../../../graphql/custom-queries";

// GraphQL
import { API, graphqlOperation } from "aws-amplify";
import {
  createStudentFavouriteLearningResource,
  updateStudentFavouriteLearningResource,
  deleteStudentFavouriteLearningResource,
  createResourceReaction,
  updateResourceReaction,
  createUserConfidenceLevel,
} from "../../../graphql/mutations.js";

export type GetFavoritesParams = {
  userId: string;
  assessId: number | string;
  assessmentId: string;
  sessionId: number | string;
  resourceCardId: string;
  resourceCardtrait: string;
};

export type CreateOrRemoveParams = {
  isFavorite: boolean;
  resourceTrait: string;
  sessionId: string | number;
  assessId: string | number;
  cardId: string | number;
  userId: string | number;
};

export type IsFavoriteParams = {
  cardId: string | number;
  existingFavoritesList: [];
};

type FavoriteCardType = {
  id: string;
  userId: string;
  trait: string;
  assessmentId: string;
  userSessionId: string;
  learningResourceId: string;
  createdAt: string;
  updatedAt: string;
  _deleted: boolean;
};

export type GetReactionsParams = {
  resourceCardId: string;
  resourceCardtrait: string | number;
  userId: string | number;
  sessionId: string | number;
  assessmentId: string | number;
};

export type CreateOrUpdateReactionsParams = {
  id: string;
  userId: string;
  trait: string;
  assessmentId: string;
  sessionId: string;
  learningResourceId: string;
  reactionStatus: string;
  resourceCardId: string;
  resourceCardtrait: string;
};

type consolidatedResourcesParams = [
  {
    userId: string;
  },
];
export const resourceActions = {
  createFavorite: async ({
    isSaved,
    resourceCardId,
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
  }: any) => {
    const requestSchema = {
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      learningResourceId: resourceCardId,
      trait: resourceCardtrait,
      preference: isSaved === true ? "FAVORITED" : "UNSELECTED",
    };

    const res = await API.graphql(
      graphqlOperation(createStudentFavouriteLearningResource, {
        input: requestSchema,
      }),
    );

    return res;
  },

  updateFavs: async ({
    isSaved,
    resourceCardId,
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
    id,
    _version,
  }: any) => {
    const requestSchema = {
      id: id,
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      learningResourceId: resourceCardId,
      trait: resourceCardtrait,
      preference: isSaved === true ? "FAVORITED" : "UNSELECTED",
      _version: _version,
    };

    const res = await API.graphql(
      graphqlOperation(updateStudentFavouriteLearningResource, {
        input: requestSchema,
      }),
    );

    return res;
  },

  listfavs: async ({
    resourceCardId,
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
  }: any) => {
    const queryFilter = {
      and: [
        { userId: { eq: userId } },
        { assessmentId: { eq: assessmentId } },
        { userSessionId: { eq: sessionId } },
        { learningResourceId: { eq: resourceCardId } },
        { trait: { eq: resourceCardtrait } },
      ],
    };
    const resourceList = await API.graphql(
      graphqlOperation(listStudentFavouriteLearningResources, {
        filter: queryFilter,
        limit: 10000,
      }),
    );

    return resourceList;
  },

  isFavorite: ({ cardId, existingFavoritesList }: IsFavoriteParams) => {
    const res =
      existingFavoritesList.filter(
        (card: FavoriteCardType) =>
          card.learningResourceId === cardId && !card._deleted,
      ).length > 0;

    return res;
  },

  listReactions: async ({
    resourceCardId,
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
  }: GetReactionsParams) => {
    const requestSchema = {
      and: [
        { userId: { eq: userId } },
        { assessmentId: { eq: assessmentId } },
        { userSessionId: { eq: sessionId } },
        { learningResourceId: { eq: resourceCardId } },
        { trait: { eq: resourceCardtrait } },
      ],
    };

    const res = await API.graphql(
      graphqlOperation(listResourceReactions, {
        filter: requestSchema,
        limit: 10000,
      }),
    );
    return res;
  },

  createReactions: async ({
    reactionStatus,
    resourceCardId,
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
  }: any) => {
    const requestSchema = {
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      learningResourceId: resourceCardId,
      trait: resourceCardtrait,
      preference: reactionStatus === null ? "UNSELECTED" : reactionStatus,
    };
    const res = await API.graphql(
      graphqlOperation(createResourceReaction, { input: requestSchema }),
    );
    return res;
  },

  updateReactions: async ({
    reactionStatus,
    resourceCardId,
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
    id,
    _version,
  }: any) => {
    const requestSchema = {
      id: id,
      userId: userId,
      assessmentId: assessmentId,
      userSessionId: sessionId,
      learningResourceId: resourceCardId,
      trait: resourceCardtrait,
      preference: reactionStatus === null ? "UNSELECTED" : reactionStatus,
      _version: _version,
    };

    const res = await API.graphql(
      graphqlOperation(updateResourceReaction, { input: requestSchema }),
    );

    return res;
  },

  listResourcesAndItsConnections: async ({
    resourceCardtrait,
    userId,
    sessionId,
    assessmentId,
  }: any) => {
    let filter;
    let filterForLearningResourceTable;

    if (resourceCardtrait === "all") {
      filter = {
        and: [
          { userId: { eq: userId } },
          { assessmentId: { eq: assessmentId } },
          { userSessionId: { eq: sessionId } },
        ],
      };
      filterForLearningResourceTable = {};
    } else {
      filter = {
        and: [
          { userId: { eq: userId } },
          { assessmentId: { eq: assessmentId } },
          { userSessionId: { eq: sessionId } },
          { trait: { eq: resourceCardtrait.toLowerCase() } },
        ],
      };
      filterForLearningResourceTable = {
        trait: { eq: resourceCardtrait },
      };
    }

    const res = await API.graphql(
      graphqlOperation(getCombinedResourceCardDetails, {
        learningResourceFilter: filterForLearningResourceTable,
        favFilter: filter,
        resourceReactionFilter: filter,
        resourceRecommendationFilter: filter,
        limit: 10000,
      }),
    );
    return res;
  },

  createUserConfidenceScale: async ({
    segmentValue,
    trait,
    phase,
    userId,
  }: any) => {
    const input = {
      userId: userId,
      trait: trait,
      phase: phase,
      selectedDate: new Date().toISOString().split("T")[0],
      selectedTime: new Date().toISOString().split("T")[1],
      confidenceLevel: segmentValue,
    };
    const userConfidenceLevel = await API.graphql(
      graphqlOperation(createUserConfidenceLevel, { input: input }),
    );
    return userConfidenceLevel;
  },

  listUserConfidenceLevels: async ({ category, phase, userId }: any) => {
    const requestSchema = {
      and: [
        { trait: { eq: category } },
        { phase: { eq: phase } },
        { selectedDate: { eq: new Date().toISOString().split("T")[0] } },
      ],
    };
    const userConfidenceLevel = await API.graphql(
      graphqlOperation(confidenceLevelByUserIdTraitPhaseAndSelectionDate, {
        filter: requestSchema,
        userId: userId,
        sortDirection: "DESC",
        limit: 1000,
      }),
    );
    return userConfidenceLevel;
  },
};

//Packages
import { Storage } from "aws-amplify";
import React from "react";
import { useNavigate } from "react-router-dom";

//Component
import Loading from "../../../loading";
import Graph from "../Graph";
import { ucWords, dateConversion } from "../../../../../utilities";

// Services
import EventService from "../../../../../services/EventService/event.service";

function SupportRequests({
  candidateId,
  graphData,
  userScores,
  userSessionId,
}: any) {
  const [userInfo, setUserInfo] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tips, setTips] = React.useState<any>([]);
  const [imageUrl, setimage] = React.useState<any>([]);
  const [studentRequests, setStudentRequests] = React.useState<any>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    // getUserInfo();
    setUserInfo(userScores.userAssessmentState.user);
    setStudentRequests(userScores.userAssessmentState.studentRequests);
    getS3PresignedUrl();
    setLoading(false);

    const requests = userScores.userAssessmentState.studentRequests;

    EventService.track({
      event_type: "user",
      event_name: "hei_support_student_start",
      user_type: "hei",
      event_data: {
        student_id: userScores.userId,
        student_sessionId: userScores.userSessionId,
        request_flag_leadership: requests.items.filter((request: any) => {
          return (
            request.traitName.toLowerCase() === "leadership" &&
            request.institutionResponses.items.length < 1
          );
        }).length,
        request_flag_perseverance: requests.items.filter((request: any) => {
          return (
            request.traitName.toLowerCase() === "perseverance" &&
            request.institutionResponses.items.length < 1
          );
        }).length,
        request_flag_communication: requests.items.filter((request: any) => {
          return (
            request.traitName.toLowerCase() === "effective communication" &&
            request.institutionResponses.items.length < 1
          );
        }).length,
        request_flag_innovative: requests.items.filter((request: any) => {
          return (
            request.traitName.toLowerCase() === "innovative thinking" &&
            request.institutionResponses.items.length < 1
          );
        }).length,
      },
    });
  }, [userScores]);

  const getS3PresignedUrl = async () => {
    let preSignedUrl: any;
    let filePath = "photos/" + candidateId + ".PNG";
    preSignedUrl = await Storage.get(filePath, {
      level: "public",
      download: false,
      expires: 300,
    });
    setimage(preSignedUrl);
  };

  const goToIndividualInsights = () => {
    navigate(`/fullsummary/students/${userSessionId}`);
  };

  const goToStudentList = () => {
    navigate("/fullsummary/students");
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className="support-requests">
          <div className="container wrapper student">
            <div className="title">
              <h1>
                <a className="student-list-link" onClick={goToStudentList}>
                  Student List
                </a>
                <p>&nbsp;</p>
                {">"}
                <p>&nbsp;</p>
                <a
                  className="student-list-link"
                  onClick={goToIndividualInsights}
                >
                  Individual SkillTrack Insights
                </a>
                <p>&nbsp;</p>
                {">"} Send Support
              </h1>
            </div>
          </div>
          <div className="container student-detail">
            <div className="content info">
              <div className="image-wrapper">
                <div className="userImage">
                  <img
                    src={imageUrl}
                    alt={`Student ${userInfo.name}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src =
                        require("../../../../../assets/fullsummary/user-avatar.png").default;
                    }}
                  />
                </div>

                <div className="student-description">
                  <div>
                    <strong>{userInfo.name}</strong>
                  </div>
                  <p className="demographics">
                    {userInfo.gender}, Age {userInfo.ageGroup}, Enrolled since{" "}
                    {userInfo.enrollmentYear}{" "}
                  </p>
                </div>
              </div>

              <div className="content-header goals">
                <p className="request-list-header">
                  <b className="goal-text">Student Support Requests</b>
                </p>
              </div>
              {studentRequests.items.length === 0 ? (
                <p style={{ padding: "10px" }}>No requests from Students</p>
              ) : (
                ""
              )}
              {studentRequests.items.map((value: any, index: any) => {
                return (
                  <div key={index} className="request-list-items">
                    <div className="date">
                      {dateConversion(value.requestDate)}
                    </div>
                    <div className="content">
                      <div>
                        Requested support from{" "}
                        <span className="strong">
                          {ucWords(value.traitName)}
                        </span>
                      </div>
                      <div>{value.studentQuestion}</div>
                    </div>

                    <div className="response-status">
                      {value.institutionResponses.items.length > 0
                        ? value.institutionResponses.items[0].institutionUser
                            .name + "  replied"
                        : "No reply from HEI"}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="content tips">
              <strong className="title">
                Student Individual Insights Summary
              </strong>

              <div className="support-request-graph">
                <Graph userScores={graphData} />
              </div>

              <ul>
                {tips.map((tip: string, index: number) => {
                  return <li key={index}>{tip} </li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SupportRequests;

import { Button } from "react-bootstrap";

interface OceanButtonProps {
  text: string;
  onClick: Function;
  customClasses?: string;
  disabled?: boolean;
}

const OceanButton = ({
  text,
  onClick,
  customClasses,
  disabled,
}: OceanButtonProps) => {
  return (
    <Button
      bsPrefix="psq-btn"
      className={`ocean-button ${customClasses || ""}`}
      disabled={disabled}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {text}
    </Button>
  );
};

export default OceanButton;

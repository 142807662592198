/* eslint-disable jsx-a11y/alt-text */
// Packages
import React from "react";
import { Badge } from "react-bootstrap";

import "./FavoriteCard.scss";

// Components
import VerticalTextIcon from "../../../components/IconButton/VerticalTextIcon";

// Assets
import { ReactComponent as VideoType } from "../../../assets/re-skin/VideoType.svg";
import { ReactComponent as ArticleType } from "../../../assets/re-skin/ArticleType.svg";
import { ReactComponent as PodCastType } from "../../../assets/re-skin/PodCastType.svg";
import { ReactComponent as MyFavorites } from "../../../assets/re-skin/FulliedFavorite.svg";
import { ReactComponent as ThumbsUpFilled } from "../../../assets/re-skin/ThumbsUpFilled.svg";
import { ReactComponent as ThumbsDownFilled } from "../../../assets/re-skin/ThumbsDownFilled.svg";
import { ReactComponent as ThumbsUpUnfilled } from "../../../assets/re-skin/ThumbsUpUnfilled.svg";
import { ReactComponent as ThumbsDownUnfilled } from "../../../assets/re-skin/ThumbsDownUnfilled.svg";

interface FavoriteCardProps {
  resourceLink: string;
  imageLocation: string;
  duration: string;
  type: string;
  trait: string;
  title: string;
  credit: string;
  resourceCardId: string;
  resourceCardtrait: string;
  handleThumbnailClick: Function;
  unSave: Function;
  reactionStatus: null;
  onReactionsClick: Function;
}

const FavoriteCard = ({
  resourceLink,
  imageLocation,
  type,
  duration,
  trait,
  title,
  resourceCardId,
  resourceCardtrait,
  handleThumbnailClick,
  unSave,
  reactionStatus,
  onReactionsClick,
}: FavoriteCardProps) => {
  const [usefulStatus, setUsefulStatus] = React.useState<string | null>(
    reactionStatus,
  );

  // Useful button
  const onUsefulClick = () => {
    if (usefulStatus === "USEFUL") {
      setUsefulStatus(null);
      onReactionsClick(null, resourceCardId, resourceCardtrait);
    } else {
      setUsefulStatus("USEFUL");
      onReactionsClick("USEFUL", resourceCardId, resourceCardtrait);
    }
  };
  const UsefulButtonIcon =
    usefulStatus === "USEFUL" ? (
      <ThumbsUpFilled
        width="24"
        height="24"
        role="button"
        className="active"
        onClick={() => onUsefulClick()}
      />
    ) : (
      <ThumbsUpUnfilled
        width="24"
        height="24"
        role="button"
        className="active"
        onClick={() => onUsefulClick()}
      />
    );

  const UsefulButton = (
    <VerticalTextIcon
      Icon={UsefulButtonIcon}
      text="Useful"
      buttonLabel="useful"
    />
  );

  // Not useful button
  const onNotUsefulClick = () => {
    if (usefulStatus === "NOTUSEFUL") {
      setUsefulStatus(null);
      onReactionsClick(null, resourceCardId, resourceCardtrait);
    } else {
      setUsefulStatus("NOTUSEFUL");
      onReactionsClick("NOTUSEFUL", resourceCardId, resourceCardtrait);
    }
  };

  const NotUsefulIcon =
    usefulStatus === "NOTUSEFUL" ? (
      <ThumbsDownFilled
        width="24"
        height="24"
        role="button"
        className="active"
        onClick={() => onNotUsefulClick()}
      />
    ) : (
      <ThumbsDownUnfilled
        width="24"
        height="24"
        role="button"
        className="active"
        onClick={() => onNotUsefulClick()}
      />
    );

  const NotUsefulButton = (
    <VerticalTextIcon
      Icon={NotUsefulIcon}
      text="Not Useful"
      buttonLabel="not-useful"
    />
  );

  const getResourceType = (resourceType: any) => {
    switch (resourceType) {
      case "Video":
        return (
          <span>
            <VideoType />
          </span>
        );
      case "Podcast":
        return (
          <span>
            <PodCastType />
          </span>
        );
      case "Article":
        return (
          <span>
            <ArticleType />
          </span>
        );
      case "Cards":
        return null;
    }
  };

  const getBackgroundColor = () => {
    switch (trait) {
      case "leadership":
        return "#30396C";
      case "effective communication":
        return "#5D8137";
      case "perseverance":
        return "#397F93";
      case "innovative thinking":
        return "#737582";
    }
  };

  const getBackgroundColorLowerCard = () => {
    switch (trait) {
      case "leadership":
        return "#1E2442";
      case "effective communication":
        return "#446028";
      case "perseverance":
        return "#255E6E";
      case "innovative thinking":
        return "#60616A";
    }
  };

  const ConditionalDuration = duration && (
    <div className="bottom-right-duration-wrapper">
      <Badge pill bsPrefix="duration-badge">
        {duration}
      </Badge>
    </div>
  );

  return (
    <div className="reskin-favorite-card">
      <li className="cards favorite-card-list-item">
        <section className="image-wrapper thumbnail-container">
          <a
            href={resourceLink}
            target="_blank"
            onMouseUp={(e) => handleThumbnailClick(e)}
          >
            <img
              className="imageContainer"
              src={imageLocation}
              width="100%"
              height="100%"
            />
            <div className="top-right-type-wrapper">
              {/* {getResourceType(type)} */}
              {ConditionalDuration}
            </div>
          </a>
        </section>

        <section style={{ backgroundColor: getBackgroundColor() }}>
          <div>
            <div>
              <Badge bg="light" className="card-label trait-description">
                {trait}
              </Badge>
            </div>
            <div className="title-save-container">
              <p className="title-description">
                <strong>{title}</strong>
              </p>
              <div className="ms-4">
                <div>
                  <span
                    className="ms-1"
                    onClick={() => {
                      unSave();
                    }}
                  >
                    <MyFavorites className="active" width="21" height="18" />
                  </span>
                </div>
                <div>
                  <p className="save-text">Save</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="lowerCard"
            style={{ backgroundColor: getBackgroundColorLowerCard() }}
          >
            <div className="resourceType">{getResourceType(type)}</div>
            <div className="thumb-icons">
              {UsefulButton}
              {NotUsefulButton}
            </div>
          </div>
        </section>
      </li>
    </div>
  );
};

export default FavoriteCard;

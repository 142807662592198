import { Storage } from "aws-amplify";

const getS3PresignedUrl = async (imageLocation: any): Promise<string> => {
  let preSignedUrl: any;
  preSignedUrl = await Storage.get(imageLocation, {
    level: "public",
    download: false,
    expires: 900,
  });

  return preSignedUrl;
};

export default getS3PresignedUrl;

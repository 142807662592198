// Components
import PSQCarousel from "../../../../../components/Carousel";
import TwoHeaderCard from "../../../../../components/Card";
import CardHeaderContent from "./CardHeaderContent";
import CardBodyContent from "./CardBodyContent";

interface SupportCarouselProps {
  narratives: any;
  traitScore: any;
}

const SupportCarousel = ({ narratives, traitScore }: SupportCarouselProps) => {
  const traitScoreCleaned = traitScore.data.filter(
    (subtraitData: any, index: number) => {
      return subtraitData.value >= 0;
    },
  );
  const dynamicNarratives = traitScore.dimensionNarratives;
  const narrative = traitScoreCleaned.map(
    (subtraitData: any, index: number) => {
      let dynamicNarrative = [];

      if (
        dynamicNarratives !== null &&
        dynamicNarratives !== "" &&
        dynamicNarratives.length > 0
      )
        dynamicNarrative = dynamicNarratives.filter(
          (dN: any) => dN.dimensionId === subtraitData.number,
        );

      let narrative = narratives.filter(
        (narrative: any) => narrative.dimensionId === subtraitData.number,
      )[0];

      if (dynamicNarrative.length > 0) {
        narrative = {
          ...narrative,
          improvementNarrative: dynamicNarrative[0].improvementNarrative,
        };
      }
      return narrative;
    },
  );
  const RenderedCards = traitScoreCleaned.map(
    (subtraitData: any, index: number) => {
      let dynamicNarrative = [];

      if (
        dynamicNarratives !== null &&
        dynamicNarratives !== "" &&
        dynamicNarratives.length > 0
      )
        dynamicNarrative = dynamicNarratives.filter(
          (dN: any) => dN.dimensionId === subtraitData.number,
        );

      let narrative = narratives.filter(
        (narrative: any) => narrative.dimensionId === subtraitData.number,
      )[0];

      if (dynamicNarrative.length > 0) {
        narrative = {
          ...narrative,
          improvementNarrative: dynamicNarrative[0].improvementNarrative,
        };
      }

      const LeftHeaderContent = (
        <CardHeaderContent
          subHeaderText="How to improve"
          headerText={subtraitData.dimension}
        />
      );
      const score: any = convertNumericScore(subtraitData.value);
      const RightHeaderContent = (
        <CardHeaderContent subHeaderText="My Score" headerText={score} />
      );

      const BodyContent = <CardBodyContent narrative={narrative} />;

      return (
        <TwoHeaderCard
          key={index}
          leftHeaderContent={LeftHeaderContent}
          rightHeaderContent={RightHeaderContent}
          bodyContent={BodyContent}
          rightHeaderBgColor={score}
        />
      );
    },
  );

  return <PSQCarousel content={RenderedCards} />;
};

function convertNumericScore(value: number) {
  switch (value) {
    case 0:
      return "Developing";
    case 1:
      return "Approaching";
    case 2:
      return "Demonstrating";
  }
}

export default SupportCarousel;

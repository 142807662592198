import React from "react";
import { Button, ProgressBar, Spinner } from "react-bootstrap";

// Components
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";

// import Tips from "./Tips";
import HideSelf from "./HideSelf";
import Attempts from "./Attempts";
import Timer from "./Timer";
import ModalDurationTooShort from "../../../../components/Modals/DurationTooShort";

// Configs
import { DEFAULTS } from "../../../../enumerations";

// Contexts
import { ModalContext } from "../../../../contexts/context";

// Assets
import { ReactComponent as FaceOverlay } from "../../../../assets/face.svg";
import { ReactComponent as RecorderSelfIcon } from "../../../../assets/icons/recording-self.svg";
import { ReactComponent as RecorderSelfIconActive } from "../../../../assets/icons/recording-self-active.svg";

import { ReactComponent as RecorderTipsIcon } from "../../../../assets/icons/recording-tips.svg";
import { ReactComponent as RecorderTipsIconActive } from "../../../../assets/icons/recording-tips-active.svg";

import { ReactComponent as PlayIcon } from "../../../../assets/reskin/icons/play-button.svg";
// import { ReactComponent as PlayIcon } from "../../../../assets/icons/video-play.svg";
import { ReactComponent as PlayIconActive } from "../../../../assets/icons/video-play-active.svg";

import { ReactComponent as StopIcon } from "../../../../assets/reskin/icons/stop-button.svg";
// import { ReactComponent as StopIcon } from "../../../../assets/icons/video-stop.svg";
import { ReactComponent as StopIconActive } from "../../../../assets/icons/video-stop-active.svg";

// Services
import EventService from "../../../../services/EventService/event.service";

const Actions = (props: any) => {
  const {
    handleUpload,
    isUploading,
    options,
    question,
    setRecording,
    hideSelf,
    setHideSelf,
  } = props;
  const {
    isInlineRecordingSupported,
    isVideoInputSupported,
    thereWasAnError,
    isCameraOn,
    isConnecting,
    isRecording,
    isReplayingVideo,
    isRunningCountdown,
    onStartRecording,
    onStopRecording,
    onStopReplaying,
    streamIsReady,
  } = options;

  // overlays
  const [showTips, setShowTips] = React.useState<boolean>(!thereWasAnError);
  // const [hideSelf, setHideSelf] = React.useState<boolean>(false);

  // swapping icons on hover/focus
  const [activeIconStatuses, setActiveIconStatuses] = React.useState({
    recorderSelf: false,
    recorderTips: false,
    playIcon: false,
    stopIcon: false,
  });

  const handleIconFocus = (statusKey: string) => {
    setActiveIconStatuses({
      recorderSelf: false,
      recorderTips: false,
      playIcon: false,
      stopIcon: false,
      [statusKey]: true,
    });
  };

  const handleIconUnfocus = () => {
    setActiveIconStatuses({
      recorderSelf: false,
      recorderTips: false,
      playIcon: false,
      stopIcon: false,
    });
  };

  const RenderedRecorderSelfIcon = activeIconStatuses.recorderSelf ? (
    <RecorderSelfIconActive />
  ) : (
    <RecorderSelfIcon />
  );

  const RenderedRecorderTipsIcon = activeIconStatuses.recorderTips ? (
    <RecorderTipsIconActive />
  ) : (
    <RecorderTipsIcon />
  );

  const RenderedPlayIcon = activeIconStatuses.playIcon ? (
    <PlayIcon />
  ) : (
    <PlayIcon />
  );

  const RenderedStopIcon = activeIconStatuses.stopIcon ? (
    <StopIcon />
  ) : (
    <StopIcon />
  );

  React.useEffect(() => {
    setShowTips(!thereWasAnError);
  }, [thereWasAnError]);
  const { showModal } = React.useContext(ModalContext);

  // attempts
  const _maxAttempts = DEFAULTS.MAX_RECORDING_ATTEMPTS;
  const [attempts, setAttempts] = React.useState<number>(1);

  // timer
  const _maxLength = DEFAULTS.MAX_RECORDING_LENGTH;
  let [duration, setDuration] = React.useState<number>(0);

  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (props.options.isRecording) {
      interval = setInterval(() => {
        const newDuration = duration++;

        if (newDuration / 60 > _maxLength) {
          props.options.onStopRecording();
        }

        setDuration(newDuration);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [props.options.isRecording]);

  React.useEffect(() => {
    if (isReplayingVideo && duration < 15) {
      showModal(ModalDurationTooShort);
      handleTryAgain({ overrideAttempts: true });
    }
  }, [isReplayingVideo]);

  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <button
          type="button"
          onClick={onStopReplaying}
          data-qa="start-replaying"
        >
          redo
        </button>
      );
    }

    if (isRecording) {
      return (
        <>
          <button
            type="button"
            onMouseDown={handleStopRecording}
            onFocus={() => handleIconFocus("stopIcon")}
            onBlur={() => handleIconUnfocus()}
            tabIndex={0}
            data-qa="stop-recording"
          >
            {RenderedStopIcon}
          </button>
          <br />
          <span>Stop Recording</span>
        </>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <>
          <button
            type="button"
            onMouseDown={handleStartRecording}
            onFocus={() => handleIconFocus("playIcon")}
            onBlur={() => handleIconUnfocus()}
            tabIndex={0}
            aria-label="Start Recording"
            id="start-recording-button"
            data-qa="start-recording"
          >
            {RenderedPlayIcon}
          </button>
          <br />
          <span>
            <label htmlFor="start-recording-button">Start Recording</label>
          </span>
        </>
      );
    }
  };

  const handleStopRecording = () => {
    EventService.track({
      event_type: "user",
      event_name: "user_ends_recording",
      user_type: "student",
      event_data: {
        section: "Communication",
        item_id: question.id,
        item_guid: question.itemGuid,
        record_attempt: attempts,
        record_length: duration,
      },
    });

    setRecording(false);
    onStopRecording();
  };

  const handleStartRecording = () => {
    EventService.track({
      event_type: "user",
      event_name: "user_starts_recording",
      user_type: "student",
      event_data: {
        section: "Communication",
        item_id: question.id,
        item_guid: question.itemGuid,
        record_attempt: attempts,
      },
    });
    setRecording(true);
    onStartRecording();
  };

  const handleTryAgain = ({ overrideAttempts = false }: any) => {
    if (isReplayingVideo) {
      setDuration(0);
      setAttempts(!overrideAttempts ? 1 + attempts : 2);
      onStopReplaying();
    }
  };

  const handleSubmit = () => {
    handleUpload({ attempts, duration });
  };

  return (
    <>
      {isReplayingVideo && (
        <>
          <div className="ActionsWrapper replay-view">
            <div className="replayOptions">
              {/* <Button
                bsPrefix="psq-btn"
                variant="secondary"
                disabled={isUploading}
                onClick={() => {
                  if (attempts < _maxAttempts)
                    handleTryAgain({ overrideAttempts: false });
                }}
                className={`${attempts >= _maxAttempts ? "disabled" : ""}`}
              >
                Try Again
              </Button> */}

              <ReskinButton
                variant="secondary"
                size="small"
                disabled={isUploading || attempts >= _maxAttempts}
                text="Try Again"
                onClick={() => {
                  if (attempts < _maxAttempts)
                    handleTryAgain({ overrideAttempts: false });
                }}
              />

              <ReskinButton
                variant="primary"
                size="small"
                disabled={isUploading}
                onClick={handleSubmit}
                text={isUploading ? "Submitting..." : "Submit"}
              />
              {/* <Button
                bsPrefix="psq-btn"
                variant="primary"
                aria-label="submit-video"
                onClick={handleSubmit}
                disabled={isUploading}
                className={`${isUploading ? "submitting" : ""}`}
              >
                {!isUploading ? (
                  "Submit"
                ) : (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Submitting...</span>
                  </>
                )}
              </Button> */}
            </div>
          </div>
        </>
      )}
      {!isReplayingVideo && (
        <>
          <div className="FaceOverlay">
            <FaceOverlay />
          </div>

          {hideSelf && (
            <div className="HideSelfOverlay">
              <HideSelf question={question} />
            </div>
          )}

          {/* <div className="TipsOverlay">
            {showTips && <Tips show={showTips} setShow={setShowTips} />}
          </div> */}

          <div className="ReskinActionsWrapper bottom-curved">
            <div className="overlayOptions">
              <div className="spacer">
                {/* <label className="hidden-label" htmlFor="show-tips-button">Show Tips</label>
                <button
                  className="show-tips"
                  id="show-tips-button"
                  tabIndex={0}
                  aria-label="Show Tips"
                  onFocus={() => handleIconFocus("recorderTips")}
                  onBlur={() => handleIconUnfocus()}
                  onMouseDown={() => {
                    setShowTips(true);
                  }}
                >
                  {RenderedRecorderTipsIcon}
                </button> */}
              </div>

              <div>
                <label className="hidden-label" htmlFor="show-hide-self-button">
                  Switch Camera
                </label>
                <button
                  className="show-hideself"
                  id="show-hide-self-button"
                  aria-label="Switch Camera"
                  tabIndex={0}
                  onFocus={() => handleIconFocus("recorderSelf")}
                  onBlur={() => handleIconUnfocus()}
                  onClick={() => {
                    setHideSelf(!hideSelf);
                  }}
                >
                  {RenderedRecorderSelfIcon}
                </button>
              </div>
            </div>

            <div className="progressWrapper">
              <ProgressBar now={(duration / (_maxLength * 60)) * 100} />
            </div>

            <div className="controllers">
              <Attempts max={_maxAttempts} attempt={attempts} />
              <div className="player-controller controller">
                {renderContent()}
              </div>
              <Timer max={_maxLength} duration={duration} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Actions;

interface TwoHeaderCardProps {
  leftHeaderContent: JSX.Element;
  rightHeaderContent: JSX.Element;
  bodyContent: JSX.Element;
  rightHeaderBgColor?: string;
}

const TwoHeaderCard = ({
  leftHeaderContent,
  rightHeaderContent,
  bodyContent,
  rightHeaderBgColor,
}: TwoHeaderCardProps) => {
  return (
    <div className="two-header-card carousel-card">
      <div className="card-header-container d-flex">
        <div className="left-header col-7">{leftHeaderContent}</div>
        <div
          className={`right-header col-5 ${rightHeaderBgColor || "demonstrating"}`}
        >
          {rightHeaderContent}
        </div>
      </div>
      <div className="card-body">{bodyContent}</div>
    </div>
  );
};

export default TwoHeaderCard;

/* eslint-disable jsx-a11y/alt-text */
// Packages
import React from "react";
import { Badge } from "react-bootstrap";

// Styles
import "./ResourceCard.scss";

// Components
import VerticalTextIcon from "../../../../components/IconButton/VerticalTextIcon";

// Assets
import { ReactComponent as VideoType } from "../../../../assets/re-skin/VideoType.svg";
import { ReactComponent as ArticleType } from "../../../../assets/re-skin/ArticleType.svg";
import { ReactComponent as PodCastType } from "../../../../assets/re-skin/PodCastType.svg";
import { ReactComponent as FilledHeart } from "../../../../assets/re-skin/FulliedFavorite.svg";
import { ReactComponent as UnfilledHeart } from "../../../../assets/reskin/icons/unfilled-heart-white.svg";
import { ReactComponent as ThumbsUpFilled } from "../../../../assets/re-skin/ThumbsUpFilled.svg";
import { ReactComponent as ThumbsDownFilled } from "../../../../assets/re-skin/ThumbsDownFilled.svg";
import { ReactComponent as ThumbsUpUnfilled } from "../../../../assets/re-skin/ThumbsUpUnfilled.svg";
import { ReactComponent as ThumbsDownUnfilled } from "../../../../assets/re-skin/ThumbsDownUnfilled.svg";

// Utilities
import { ucWords } from "../../../../utilities/strings";
import EventService from "../../../../services/EventService/event.service";

interface ResourceCardProps {
  resourceLink: string;
  imageLocation: string;
  duration: string;
  type: string;
  trait: string;
  title: string;
  credit: string;
  resourceCardId: string;
  resourceCardtrait: string;
  handleThumbnailClick: Function;
  onSaveClick: Function;
  reactionStatus: null;
  isFavorited: string | null;
  onReactionsClick: Function;
  sourcePage?: string;
  isLandingPage?: boolean;
}

const ResourceCard = ({
  resourceLink,
  imageLocation,
  type,
  duration,
  trait,
  title,
  resourceCardId,
  resourceCardtrait,
  handleThumbnailClick,
  onSaveClick,
  reactionStatus,
  onReactionsClick,
  isFavorited,
  sourcePage,
  isLandingPage,
}: ResourceCardProps) => {
  const [usefulStatus, setUsefulStatus] = React.useState<string | null>(
    reactionStatus,
  );
  const [isCardFavorited, setIsCardFavorited] = React.useState<string | null>(
    isFavorited,
  );

  const thumbnailEventFire = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    EventService.track({
      event_type: "user",
      event_name: "resource_open",
      user_type: "student",
      event_data: {
        category: trait,
        type: type,
        content: title,
        resource_id: resourceCardId,
        context: `${sourcePage ? sourcePage : "Resource Hub"} - ${isLandingPage ?  "Landing Page" : resourceCardtrait}`,
      },
    });
  }

  // Useful button
  const onUsefulClick = () => {
    if (usefulStatus === "USEFUL") {
      setUsefulStatus(null);
      onReactionsClick(null, resourceCardId, resourceCardtrait);
    } else {
      setUsefulStatus("USEFUL");
      onReactionsClick("USEFUL", resourceCardId, resourceCardtrait);
    }
  };
  const UsefulButtonIcon =
    usefulStatus === "USEFUL" ? (
      <ThumbsUpFilled
        width="17"
        height="17"
        role="button"
        className="active"
        onClick={() => onUsefulClick()}
      />
    ) : (
      <ThumbsUpUnfilled
        width="17"
        height="17"
        role="button"
        className="active"
        onClick={() => onUsefulClick()}
      />
    );

  const UsefulButton = (
    <VerticalTextIcon
      Icon={UsefulButtonIcon}
      text="Useful"
      buttonLabel="useful"
    />
  );

  // Not useful button
  const onNotUsefulClick = () => {
    if (usefulStatus === "NOTUSEFUL") {
      setUsefulStatus(null);
      onReactionsClick(null, resourceCardId, resourceCardtrait);
    } else {
      setUsefulStatus("NOTUSEFUL");
      onReactionsClick("NOTUSEFUL", resourceCardId, resourceCardtrait);
    }
  };

  const NotUsefulIcon =
    usefulStatus === "NOTUSEFUL" ? (
      <ThumbsDownFilled
        width="17"
        height="17"
        role="button"
        className="active"
        onClick={() => onNotUsefulClick()}
      />
    ) : (
      <ThumbsDownUnfilled
        width="17"
        height="17"
        role="button"
        className="active"
        onClick={() => onNotUsefulClick()}
      />
    );

  const NotUsefulButton = (
    <VerticalTextIcon
      Icon={NotUsefulIcon}
      text="Not Useful"
      buttonLabel="not-useful"
    />
  );

  const FavoritedIcon =
    isCardFavorited === "FAVORITED" ? (
      <FilledHeart className="active" width="21" height="18" />
    ) : (
      <UnfilledHeart className="active" width="21" height="18" />
    );

  const getResourceType = (resourceType: any) => {
    switch (resourceType) {
      case "Video":
        return (
          <span>
            <VideoType />
          </span>
        );
      case "Podcast":
        return (
          <span>
            <PodCastType />
          </span>
        );
      case "Article":
        return (
          <span>
            <ArticleType />
          </span>
        );
      case "Cards":
        return null;
    }
  };

  const getBackgroundColor = () => {
    switch (trait) {
      case "leadership":
        return "#30396c";
      case "effective communication":
        return "#5d8137";
      case "perseverance":
        return "#dd614a";
      case "innovative thinking":
        return "#8865a4";
    }
  };

  const getBackgroundColorLowerCard = () => {
    switch (trait) {
      case "leadership":
        return "#30396c";
      case "effective communication":
        return "#5d8137";
      case "perseverance":
        return "#dd614a";
      case "innovative thinking":
        return "#8865a4";
    }
  };

  const ConditionalDuration = duration && (
    <div className="bottom-right-duration-wrapper">
      <Badge pill bsPrefix="duration-badge">
        {duration}
      </Badge>
    </div>
  );

  const handleFavoriteClick = () => {
    if (isCardFavorited === "FAVORITED") {
      onSaveClick(false);
      setIsCardFavorited("UNFAVORITED");
    } else {
      onSaveClick(true);
      setIsCardFavorited("FAVORITED");
    }
  };

  return (
    <div
      className="reskin-resource-card"
      style={{ backgroundColor: getBackgroundColor() }}
    >
      <li className="cards resource-card-list-item">
        <section className="image-wrapper thumbnail-container">
          <a
            href={resourceLink}
            target="_blank"
            rel="noreferrer"
            onMouseUp={(e) => {
              handleThumbnailClick(e);
              thumbnailEventFire(e);
            }}
          >
            <img
              className="imageContainer"
              src={imageLocation}
              width="100%"
              height="100%"
            />
            <div className="top-right-type-wrapper">
              {/* {getResourceType(type)} */}
              {ConditionalDuration}
            </div>
          </a>
        </section>

        <section style={{ backgroundColor: getBackgroundColor() }}>
          <div>
            <div className="trait-description-container">
              <p className="card-label">{ucWords(trait)}</p>
              <div className="ms-4 saveContainer">
                <div className="save-icon-container">
                  <span
                    className="ms-1"
                    onClick={() => {
                      handleFavoriteClick();
                    }}
                  >
                    {FavoritedIcon}
                  </span>
                </div>
                <div>
                  <p className="save-text">Save</p>
                </div>
              </div>
            </div>
            <div className="title-save-container">
              <a
                href={resourceLink}
                target="_blank"
                rel="noreferrer"
                onMouseUp={(e) => {
                  handleThumbnailClick(e);
                  thumbnailEventFire(e);
                }}
                className="title-description"
              >
                {title}
              </a>
            </div>
          </div>
          <div
            className="lowerCard"
            style={{ backgroundColor: getBackgroundColorLowerCard() }}
          >
            <div className="resourceType">{getResourceType(type)}</div>
            <div className="thumb-icons">
              {UsefulButton}
              {NotUsefulButton}
            </div>
          </div>
        </section>
      </li>
    </div>
  );
};

export default ResourceCard;

// Packages
import React from "react";

// Components
import Radio from "../../../components/Radios/Radio";
import BIQErrorMessage from "../../../components/Messages/BIQErrorMessage";
import BottomBorderInput from "../../../components/Input/BottomBorderInput";

// Style
import "./RadioSet.scss";

interface RadioSetProps {
  questionText: string;
  questionNumber: number;
  responseKey: string;
  // onChange function should take responseKey, and value as parameters.
  onChange: Function;
  options: string[];
  isRequired?: boolean;
  selectedOption: any;
  errorMessage?: string;
}

const RadioSet = ({
  questionText,
  options,
  responseKey,
  errorMessage,
  onChange,
  isRequired,
  questionNumber,
  selectedOption,
}: RadioSetProps) => {
  const isRequiredAsterisk = isRequired ? (
    <span className="error-asterisk">{"*"}</span>
  ) : null;
  const optionsFlowDirection =
    options.length > 4 && options.length < 6 ? "many-options" : "";

  // User Specified Input Controllers
  const [showUserSpecifiedInput, setShowUserSpecifiedInput] =
    React.useState(false);
  const [userSpecifyLabel, setUserSpecifyLabel] = React.useState("");
  const [userSpecifiedValue, setUserSpecifiedValue] = React.useState("");
  const userSpecifyInputClass = showUserSpecifiedInput
    ? "user-specified-input"
    : "user-specified-input hidden";

  const handleUserSpecifiedInputChange = (value: string) => {
    setUserSpecifiedValue(value);
    onChange(responseKey, `prefer to self-describe: ${value}`);
  };

  const handleUserSpecifiedInputToggle = () => {
    const lowercaseOption = selectedOption.toLowerCase();
    if (
      lowercaseOption.includes("prefer to self-describe") ||
      lowercaseOption.includes("other")
    ) {
      setUserSpecifyLabel("Please specify");
      setShowUserSpecifiedInput(true);
    } else {
      setUserSpecifyLabel("");
      setShowUserSpecifiedInput(false);
      setUserSpecifiedValue("");
    }
  };

  React.useEffect(() => {
    handleUserSpecifiedInputToggle();
  }, [selectedOption]);

  // used where options must be self specified
  const isSelfSpecifiedOptionSelected = (option: string) => {
    return selectedOption.toLowerCase().includes(option.toLowerCase());
  };

  const isOptionSelected = (option: string) => {
    return selectedOption.toLowerCase() === option.toLowerCase();
  };

  const getOptionStatus = (option: string) => {
    // add cases for all self specified options
    if (
      selectedOption.toLowerCase().includes("prefer to self-describe") ||
      selectedOption.toLowerCase().includes("prefer to self describe") ||
      selectedOption.toLowerCase().includes("other (please specify)")
    ) {
      return isSelfSpecifiedOptionSelected(option);
    } else {
      // normal options
      return isOptionSelected(option);
    }
  };

  // Other options
  const RenderedOptions = options.map((option, index) => {
    return (
      <div className="option-wrapper">
        <Radio
          key={`${responseKey}-radio-${index}`}
          checked={getOptionStatus(option)}
          onClick={() => {
            onChange(responseKey, option);
          }}
          label={option}
        />
      </div>
    );
  });

  return (
    <div className="radio-set-question">
      <div className="question-text-wrapper">
        <div className="number-container">
          <h3>{questionNumber}. </h3>
        </div>
        <h3 className="question-text">
          {" "}
          {questionText}{" "}
          <span className="error-text">{isRequiredAsterisk}</span>
        </h3>
      </div>
      {errorMessage && (
        <div className="error-message-container">
          <BIQErrorMessage text={errorMessage} />
        </div>
      )}
      <fieldset className={`options-block ${optionsFlowDirection}`}>
        {RenderedOptions}
      </fieldset>

      <BottomBorderInput
        label={userSpecifyLabel}
        onChange={(value: string) => handleUserSpecifiedInputChange(value)}
        id="test-input"
        value={userSpecifiedValue}
        customClasses={`${userSpecifyInputClass}`}
      />
    </div>
  );
};

export default RadioSet;

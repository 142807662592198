import { API } from "aws-amplify";
import { HeiBookmarks } from "../../../../models";
import * as queries from "../../../../graphql/queries";

const getSortByStringAscending = (property) => {
  return (a, b) => {
    const valueA = a["userAssessmentState"]["user"][property];
    const valueB = b["userAssessmentState"]["user"][property];

    // Null checking values. Null values go to the back
    if (!valueA) return 1;
    if (!valueB) return -1;

    var normalizedValueA = valueA.toUpperCase(); // ignore upper and lowercase
    var normalizedValueB = valueB.toUpperCase(); // ignore upper and lowercase

    if (!normalizedValueA) {
      return 1;
    }

    if (!normalizedValueB) {
      return -1;
    }

    if (normalizedValueA < normalizedValueB) {
      return -1;
    }
    if (normalizedValueA > normalizedValueB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
};

const getSortByStringDescending = (property) => {
  return (a, b) => {
    const valueA = a["userAssessmentState"]["user"][property];
    const valueB = b["userAssessmentState"]["user"][property];

    // Null checking values. Null values go to the back

    if (!valueA) return 1;
    if (!valueB) return -1;

    var normalizedValueA = valueA.toUpperCase(); // ignore upper and lowercase
    var normalizedValueB = valueB.toUpperCase();

    if (!normalizedValueA) {
      return 1;
    }

    if (!normalizedValueB) {
      return -1;
    }

    if (normalizedValueA < normalizedValueB) {
      return 1;
    }
    if (normalizedValueA > normalizedValueB) {
      return -1;
    }

    // names must be equal
    return 0;
  };
};

// used to get the sorting function for enrollmentYear and ageGroup, leadership, perseverance,
// communication, and innovative thinking properties
const getSortByNumberAscending = (property) => {
  return (a, b) => {
    if (property === "enrollmentYear") {
      var valueA = a["userAssessmentState"]["user"][property]; // ignore upper and lowercase
      var valueB = b["userAssessmentState"]["user"][property];
    } else {
      var valueA = a[property];
      var valueB = b[property];
    }

    if (!valueA && valueA !== 0) {
      return 1;
    }

    if (!valueB && valueB !== 0) {
      return -1;
    }

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }

    // numbers must be equal
    return 0;
  };
};

const getSortByNumberDescending = (property) => {
  return (a, b) => {
    if (property === "enrollmentYear") {
      var valueA = a["userAssessmentState"]["user"][property]; // ignore upper and lowercase
      var valueB = b["userAssessmentState"]["user"][property];
    } else {
      var valueA = a[property];
      var valueB = b[property];
    }

    if (!valueA && valueA !== 0) {
      return 1;
    }

    if (!valueB && valueB !== 0) {
      return -1;
    }

    if (valueA < valueB) {
      return 1;
    }
    if (valueA > valueB) {
      return -1;
    }

    // names must be equal
    return 0;
  };
};

const getSortByScoreBandAscending = (property) => {
  return (a, b) => {
    const getNumericalScore = (scoreBand) => {
      let numericalScore;

      switch (scoreBand) {
        case "HIGH":
          numericalScore = 3;
          break;
        case "MEDIUM":
          numericalScore = 2;
          break;
        case "LOW":
          numericalScore = 1;
          break;
      }

      return numericalScore;
    };

    const valueA = getNumericalScore(a[property]);
    const valueB = getNumericalScore(b[property]);

    if (!valueA && valueA !== 0) {
      return 1;
    }

    if (!valueB && valueB !== 0) {
      return -1;
    }

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }

    // numbers must be equal
    return 0;
  };
};

const getSortByScoreBandDescending = (property) => {
  return (a, b) => {
    const getNumericalScore = (scoreBand) => {
      let numericalScore;

      switch (scoreBand) {
        case "HIGH":
          numericalScore = 3;
          break;
        case "MEDIUM":
          numericalScore = 2;
          break;
        case "LOW":
          numericalScore = 1;
          break;
      }

      return numericalScore;
    };

    const valueA = getNumericalScore(a[property]);
    const valueB = getNumericalScore(b[property]);

    if (!valueA && valueA !== 0) {
      return 1;
    }

    if (!valueB && valueB !== 0) {
      return -1;
    }

    if (valueA < valueB) {
      return 1;
    }
    if (valueA > valueB) {
      return -1;
    }

    // names must be equal
    return 0;
  };
};

const numberPropertyKeys = ["enrollmentYear"];

const scoreBandPropertyKeys = [
  "leadershipScoreBand",
  "perseveranceScoreBand",
  "communicationScoreBand",
  "innovativeThinkingScoreBand",
];

const handleSorting = async ({
  studentsList,
  propertyKey,
  sortDirection,
  userId,
}) => {
  let sortedList = [...studentsList];

  // Handle Name and Agegroup using sortByString functions.
  if (propertyKey === "name" || propertyKey === "ageGroup") {
    if (sortDirection === "ascending") {
      const sortingHandler = getSortByStringAscending(propertyKey);
      const result = sortedList.sort(sortingHandler);
      return result;
    } else {
      const sortingHandler = getSortByStringDescending(propertyKey);
      const result = sortedList.sort(sortingHandler);
      return result;
    }

    // Handle any numerical properties using sortByNumber functions.
  } else if (propertyKey === "enrollmentYear") {
    if (sortDirection === "ascending") {
      const sortingHandler = getSortByNumberAscending(propertyKey);
      const result = sortedList.sort(sortingHandler);
      return result;
    } else {
      const sortingHandler = getSortByNumberDescending(propertyKey);
      const result = sortedList.sort(sortingHandler);
      return result;
    }

    // Handle sorting by bookmark
  } else if (scoreBandPropertyKeys.includes(propertyKey)) {
    if (sortDirection === "ascending") {
      const sortingHandler = getSortByScoreBandAscending(propertyKey);
      const result = sortedList.sort(sortingHandler);
      return result;
    } else {
      const sortingHandler = getSortByScoreBandDescending(propertyKey);
      const result = sortedList.sort(sortingHandler);
      return result;
    }
  } else if (propertyKey === "bookmarked") {
    let filterExpression = { userId: { eq: userId } };

    const response = await API.graphql({
      query: queries.listHeiBookmarks,
      variables: { filter: filterExpression, limit: 100000 },
    });

    const responseObject = JSON.parse(JSON.stringify(response));
    const heiBookmark = responseObject.data.listHeiBookmarks.items;

    if (heiBookmark.length > 0) {
      let bookmarkedCandidates = heiBookmark[0].candidateUserId;

      const sortByBookMark = (a, b) => {
        const firstCandidateId = a.userId;
        const secondCandidateId = b.userId;
        const isCandidateBookmarked = (id) =>
          bookmarkedCandidates.filter((item) => item === id);

        const isFirstCandidateBookmarked =
          isCandidateBookmarked(firstCandidateId).length > 0;
        const isSecondCandidateBookmarked =
          isCandidateBookmarked(secondCandidateId).length > 0;

        if (isFirstCandidateBookmarked && !isSecondCandidateBookmarked) {
          return -1;
        }

        if (isFirstCandidateBookmarked === isSecondCandidateBookmarked) {
          return 0;
        }

        if (!isFirstCandidateBookmarked && isSecondCandidateBookmarked) {
          return 1;
        }
      };
      const result = sortedList.sort(sortByBookMark);
      return result;
    }
  }

  return sortedList;
};

export default handleSorting;

// Packages
import React from "react";
import { Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import EventService from "../../../../services/EventService/event.service";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import { VerificationCodeEmail, VerificationNumberInputs } from "../../components";


export const VerifyAccount = ({ changeAuthState }: any) => {
  // previously stored from create
  const email = sessionStorage.getItem("email") || "";
  const userId = sessionStorage.getItem("userId") || "";

  const [codes, setCodes] = React.useState<string>("");

  // error handling
  const [error, setError] = React.useState<boolean>(
    email === "" || userId === "" ? true : false,
  );

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "User Account Verification",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "User Account Verification",
      });
    };
  }, []);

  const handleVerify = async (event: any) => {
    let eventTrack = {
      event_type: "user",
      event_name: "user_email_verify",
      user_type: "student",
      event_data: { verify_succeeded: false },
    };

    try {
      const confirmStatus = await Auth.confirmSignUp(userId, codes);

      if (confirmStatus === "SUCCESS") {
        EventService.track({
          ...eventTrack,
          event_data: { verify_succeeded: true },
        });

        sessionStorage.setItem("AuthState", "Verify");
        changeAuthState({ screen: "Success" });
      
      } else {
        EventService.track({ ...eventTrack });

        setError(true);
      }
    } catch (error) {
      console.log("error confirming", error);
      EventService.track({ ...eventTrack });
      setError(true);
    }
  };

  const resetCodeValidity = () => {
    if (error) {
      setError(false);
    }
  };

  const handleCodeChanges = (code: string) => {
    setCodes(code);
  };

  return (
    <div className="form-container">
      <h1 className="form-title mb-4">Verify Account</h1>
      <Form noValidate>
        <Form.Group className="mb-5">
          <VerificationCodeEmail email={email} />
          <VerificationNumberInputs
            userIdentifier={userId}
            isInvalid={error}
            codes={codes}
            handleCodeChanges={handleCodeChanges}
            origin="verify"
            resetValidity={resetCodeValidity}
          />
        </Form.Group>

        <Form.Group>
          <ReskinButton
            variant="primary"
            onClick={handleVerify}
            text="Verify"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

//Packages
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { useParams } from "react-router-dom";

//Graphql
import {
  listofUserscores,
  getIndividualStudent,
} from "../../../graphql/custom-queries";
import { getOrList } from "../../../components/dynamicqueries";
import * as customsubscriptions from "../../../graphql/custom-subscriptions";

//Component
import Graph from "./Graph";
import Tips from "./Tips";
import HeiComment from "./Heicomments";
import Loading from "../loading";

//Image
import { ReactComponent as LeadershipImage } from "../../../assets/fullsummary/Leadership.svg";
import { ReactComponent as PerseveranceImage } from "../../../assets/fullsummary/Perseverance.svg";
import { ReactComponent as CommunicationImage } from "../../../assets/fullsummary/Communication.svg";
import { ReactComponent as InnovativeImage } from "../../../assets/fullsummary/Innovative.svg";
import { ReactComponent as ManWithFlag } from "../../../assets/reskin/icons/man-with-flag.svg";
import { ReactComponent as ChatBubble } from "../../../assets/reskin/icons/chat-bubble.svg";
import { ReactComponent as SmallMountain } from "../../../assets/reskin/icons/small-mountain.svg";
import { ReactComponent as LightBulb } from "../../../assets/reskin/icons/light-bulb.svg";

// Services
import EventService from "../../../services/EventService/event.service";
import UserService from "../../../services/User/user.service";

//Types
import { DIMENSIONS } from "../../../enumerations";

function IndividualInsight({ setSelectedTab }: any) {
  const { userSessionId = "" } = useParams();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [subTrait, setSubTrait] = React.useState<any>([]);
  const [assessmentID, setassessmentId] = React.useState<string>("");
  const [candidateId, setCandidateId] = React.useState<string>("");
  const [assessmentStateID, setAssessmentStateID] = React.useState<string>("");
  const [listUserScore, setListUserScore] = React.useState<any>([]);
  // const candidateId = "85a7730c-4228-4113-96ee-0e928cc31438";
  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: `HEI Individual Insight`,
    });

    getUserScores();
    // getStudentdata();
    setSelectedTab("student");

    return () => {
      EventService.page({
        type: "exit",
        page: `HEI Individual Insight`,
      });
    };
  }, []);

  React.useEffect(() => {
    const fetchUserScores = async () => {
      const filterVariable = {
        limit: 10000,
        filter: {
          userSessionId: {
            eq: userSessionId, // filter priority = 1
          },
        },
      };
      const result = await getOrList(
        getIndividualStudent,
        "listUserScores",
        filterVariable,
      );
      setListUserScore(result);
    };
    const heiCommentsSubscriptionOnCreate = API.graphql(
      graphqlOperation(customsubscriptions.onCreateHeiComments),
    );
    // @ts-ignore
    //prettier-ignore
    const heiCommentsOnCreateSubscriber =  heiCommentsSubscriptionOnCreate.subscribe({
      next: ({ provider, value }: any) => {
        fetchUserScores();
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    return () => {
      heiCommentsOnCreateSubscriber.unsubscribe();
    };
  }, []);

  const getUserScores = async () => {
    const filterVariable = {
      limit: 10000,
      filter: {
        userSessionId: {
          eq: userSessionId, // filter priority = 1
        },
      },
    };
    const result = await getOrList(
      getIndividualStudent,
      "listUserScores",
      filterVariable,
    );
    setListUserScore(result);

    if (result) {
      const items = result;
      if (items.length > 0) {
        const {
          assessmentId,
          f01,
          f02,
          f03,
          f04,
          f05,
          f06,
          f07,
          f08,
          f09,
          f11,
          f12,
          f13,
          f14,
          f15,
          eyeContact,
          headBodyPosture,
          speechFluency,
          effectivePauses,
          leadershipScore,
          perseveranceScore,
          communicationScore,
          innovativeThinkingScore,
          userId,
          userScoresUserAssessmentStateId,
        } = items[0];
        const userScores = [
          {
            name: "Leadership",
            value: leadershipScore,
            image: <ManWithFlag />,
            data: [
              { number: "f08", value: f08, dimension: DIMENSIONS.F08 },
              { number: "f03", value: f03, dimension: DIMENSIONS.F03 },
              { number: "f09", value: f09, dimension: DIMENSIONS.F09 },
              { number: "f12", value: f12, dimension: DIMENSIONS.F12 },
              { number: "f11", value: f11, dimension: DIMENSIONS.F11 },
              { number: "f07", value: f07, dimension: DIMENSIONS.F07 },
              { number: "f02", value: f02, dimension: DIMENSIONS.F02 },
            ],
          },
          {
            name: "Effective Communication",
            value: communicationScore,
            image: <ChatBubble />,
            data: [
              {
                number: "",
                value: eyeContact,
                dimension: DIMENSIONS.eyecontact,
              },
              {
                number: "",
                value: headBodyPosture,
                dimension: DIMENSIONS.headBodyPosture,
              },
              {
                number: "",
                value: speechFluency,
                dimension: DIMENSIONS.speechFluency,
              },
              {
                number: "",
                value: effectivePauses,
                dimension: DIMENSIONS.effectivePauses,
              },
            ],
          },
          {
            name: "Perseverance",
            value: perseveranceScore,
            image: <SmallMountain />,
            data: [
              { number: "f01", value: f01, dimension: DIMENSIONS.F01 },
              { number: "f06", value: f06, dimension: DIMENSIONS.F06 },
              { number: "f05", value: f05, dimension: DIMENSIONS.F05 },
              { number: "f04", value: f04, dimension: DIMENSIONS.F04 },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
            ],
          },
          {
            name: "Innovative Thinking",
            value: innovativeThinkingScore,
            image: <LightBulb />,
            data: [
              { number: "f14", value: f14, dimension: DIMENSIONS.F14 },
              { number: "f15", value: f15, dimension: DIMENSIONS.F15 },
              { number: "f13", value: f13, dimension: DIMENSIONS.F13 },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
              { number: "", value: -1, dimension: "" },
            ],
          },
        ];
        setSubTrait(userScores);
        setassessmentId(assessmentId);
        setCandidateId(userId);
        setAssessmentStateID(userScoresUserAssessmentStateId);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <section
          className="individual container"
          style={{
            width: "100vw",
            // backgroundColor: 'teal',
            paddingRight: "0px",
            marginRight: "-100px !important",
          }}
        >
          <div className="individual-insights">
            <Tips
              assessmentID={assessmentID}
              candidateId={candidateId}
              userSessionId={userSessionId}
              userscores={listUserScore}
            />
            <div className="content graph container">
              <strong style={{ paddingLeft: "1.5rem" }}>
                SkillTrack Results
              </strong>
              <div className="graph-content">
                <div className="graph-wrapper">
                  {subTrait.map((subtrait: any, index: number) => {
                    return <Graph key={index} traitScore={subtrait} />;
                  })}
                </div>
              </div>
            </div>
          </div>

          <br />
          <HeiComment
            assessmentID={assessmentID}
            candidateId={candidateId}
            userSessionId={userSessionId}
            assessmentStateID={assessmentStateID}
            userscores={listUserScore}
          />
        </section>
      )}
    </>
  );
}

export default IndividualInsight;

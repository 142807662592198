import Subheader from "../../../../components/Subheader";
import { useNavigate } from "react-router-dom";

// styles
import "./PrivacyPolicy.scss";
import { PrivacyPolView } from "../../../components/PrivacyPolView/PrivacyPolView";

function PrivacyPolicy() {
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="reskin-privacy-policy">
      <Subheader back={goBack} text="Privacy Policy" />
      <PrivacyPolView />
    </div>
  );
}

export default PrivacyPolicy;

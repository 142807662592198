// Routing
import { Routes, Route, Navigate } from "react-router-dom";

// Screens
import FullSummary from "../screens/full-summary-reskin";
import UserState from "../screens/userstate";
import Profile from "../screens/profile/my-account";
import { PrivacyPolicy } from "../reskin/institution/pages/PrivacyPolicy/PrivacyPolicy";
import { TermsConditions } from "../reskin/institution/pages/TermsConditions/TermsConditions";

function RoutesInstitution() {
  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/fullsummary" />} />
      <Route path="/fullsummary/*" element={<FullSummary />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/" element={<Navigate replace to="/fullsummary" />} />
      <Route path="/userstate" element={<UserState />} />
      <Route path="/privacypolicy/*" element={<PrivacyPolicy />} />
      <Route path="/termsconditions/*" element={<TermsConditions />} />
    </Routes>
  );
}

export default RoutesInstitution;

import "./ReskinIntroPage.scss";

interface ReskinIntroPagePropTypes {
  HeaderIcon: JSX.Element;
  headerText: string | JSX.Element;
  bodyText: string | JSX.Element;
  Controls: JSX.Element;
}

const ReskinIntroPage = ({
  HeaderIcon,
  headerText,
  Controls,
  bodyText,
}: ReskinIntroPagePropTypes) => {
  return (
    <div className="reskin-intro-page">
      <section className="icon-container">{HeaderIcon}</section>
      <section className="header-text-wrapper">
        <h2 className="header-text">{headerText}</h2>
      </section>
      <section className="body-text-wrapper">
        <p className="body-text">{bodyText}</p>
      </section>
      <section className="controls-container">{Controls}</section>
    </div>
  );
};

export default ReskinIntroPage;

export enum DEFAULTS {
  ASSESSMENT_NAME = "SampleAssessment",
  MAX_RECORDING_ATTEMPTS = 2,
  MAX_RECORDING_LENGTH = 2, // minutes
}

export enum TRAIT_SCORES {
  LOW = "Developing",
  MEDIUM = "Approaching",
  HIGH = "Demonstrating",
}

export enum TRAIT_DEFINITIONS {
  "leadership" = "Leadership is the ability to influence or guide other individuals or teams in the accomplishment of a common task that supports the execution a broader strategic vision.",
  "effective communication" = "Effective communication involves the verbal and nonverbal cues that help your ideas and concepts get across to others. It is also your ability to listen, comprehend, and act on what other people say.",
  "innovative thinking" = "Innovative thinking is the ability to come up with new ideas and novel approaches to problems. This often requires being creative, being flexible, and being willing to take risks.",
  "perseverance" = "Perseverance is the continued effort to stick to a plan without giving up despite facing some level of difficulties, opposition, or potential failure.",
}

export enum TRAIT_DEFINITIONS_RESKIN {
  "leadership" = " is the ability to influence or guide other individuals or teams in the accomplishment of a common task that supports the execution a broader strategic vision.",
  "effective communication" = " involves the verbal and nonverbal cues that help your ideas and concepts get across to others. It is also your ability to listen, comprehend, and act on what other people say.",
  "innovative thinking" = " thinking is the ability to come up with new ideas and novel approaches to problems. This often requires being creative, being flexible, and being willing to take risks.",
  "perseverance" = " is the continued effort to stick to a plan without giving up despite facing some level of difficulties, opposition, or potential failure.",
}

export enum TRAIT_WAYTOGO_MEDIUM {
  "Leadership" = "a great leader!",
  "Innovative Thinking" = "an innovator!",
  "Effective Communication" = "a great communicator!",
  "Perseverance" = "persistant!",
}

export enum TRAIT_WAYTOGO_HIGH {
  "Leadership" = "You're going to make a great leader!",
  "Innovative Thinking" = "You think outside of the box!",
  "Effective Communication" = "You're a great communicator!",
  "Perseverance" = "You've got grit!",
}

export enum HIGHLEVELTRAITTYPE {
  "leadership" = "LEADERSHIP",
  "innovative thinking" = "INNOVATIVE_THINKING",
  "effective communication" = "COMMUNICATION",
  "perseverance" = "PERSEVERANCE",
}

export enum DIMENSIONS {
  F01 = "Persistence",
  F02 = "Organization",
  F03 = "Responsibility",
  F04 = "Rule-Following ",
  F05 = "Self-Discipline",
  F06 = "Resiliency",
  F07 = "Even-Temperedness",
  F08 = "Influence",
  F09 = "Sociability",
  F10 = "Passion",
  F11 = "Collaboration",
  F12 = "Trust",
  F13 = "Artistic Appreciation",
  F14 = "Curiosity",
  F15 = "Creativity",
  headBodyPosture = "Head/Body Posture",
  speechFluency = "Speech Fluency",
  effectivePauses = "Effective Pauses",
  eyecontact = "Eye Contact",
  HEADBODYPOSTURE = "Head/Body Posture",
  SPEECHFLUENCY = "Speech Fluency",
  EFFECTIVEPAUSES = "Effective Pauses",
  EYECONTACT = "Eye Contact",
}

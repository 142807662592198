// Packages
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../redux/actions";
import ReskinButton from "../../../components/Buttons/ReskinButton/ReskinButton";
import "./sign-out-modal.scss";

export const SignOutModal = ({ onRequestClose }: any) => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(logoutUser());
    // onRequestClose();
  };

  const handleStay = () => {
    onRequestClose();
  };

  return (
    <Modal className="sign-out-modal" show={true} onHide={onRequestClose}>
      <Modal.Header className="no-border" closeButton>
        <Modal.Title>Sign Out</Modal.Title>
      </Modal.Header>
      <hr className="border-line"></hr>

      <Modal.Body className="no-border">
        Are you sure you want to sign out?sdf
      </Modal.Body>

      <Modal.Footer className="no-border">
        <ReskinButton
          variant="secondary"
          onClick={handleStay}
          text="Stay Signed In"
        />
        <ReskinButton
          variant="primary"
          onClick={handleSignOut}
          text="Sign Out"
          loadingIndicator={true}
        />
      </Modal.Footer>
    </Modal>
  );
};

import { User } from "../../models";

export type UserType = {
  loggedIn?: boolean;
  assessmentState?: any;
  userType?: string;
  email?: any;
  isUploading?: boolean;
  institutionId?: any;
  userId?: string;
  enrollmentStatus?: string;
  programYear?: string;
  fieldOfStudy?: string;
  transferToFourYear?: string;
  ageGroup?: String;
  gender?: String;
  goal?: String;
  emplStatus?: String;
  ethnicity?: String;
  perseveranceConfidenceScore?: number;
  leadershipConfidenceScore?: number;
  effectiveCommunicationConfidenceScore?: number;
  innovativeThinkingConfidenceScore?: number;
} & Omit<User, "id">;

export type UserBIQ = {
  userId?: string;
  enrollmentStatus?: string;
  programYear?: string;
  fieldOfStudy?: string;
  transferToFourYear?: string;
  ageGroup?: String;
  gender?: String;
  goal?: String;
  emplStatus?: String;
  ethnicity?: String;
};

export type confidenceResponseType = {
  userId?: string;
  effectiveCommunicationConfidenceScore?: number;
  innovativeThinkingConfidenceScore?: number;
  leadershipConfidenceScore?: number;
  perseveranceConfidenceScore?: number;
};

export const CREATE_USER = "CREATE_USER";
export const GET_USER = "GET_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USERBIQ = "UPDATE_USERBIQ";
export const UPDATE_USERASSESSMENTSTATE = "UPDATE_USERASSESSMENTSTATE";
export const SET_ISUPLOADING = "SET_ISUPLOADING";
export const UPDATE_USERTYPE = "UPDATE_USERTYPE";

export type UserActionTypes =
  | CreateUserAction
  | GetUserAction
  | LoginUserAction
  | LogoutUserAction
  | UpdateUserAction
  | UpdateUserBIQAction
  | UpdateUserAssessmentStateAction
  | SetUserIsUploading
  | UpdateUsertypeAction;

export interface CreateUserAction {
  type: typeof CREATE_USER;
  payload: any;
}

export interface LoginUserAction {
  type: typeof LOGIN_USER;
  payload: any;
}

export interface LogoutUserAction {
  type: typeof LOGOUT_USER;
  payload: any;
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: any;
}

export interface UpdateUserBIQAction {
  type: typeof UPDATE_USERBIQ;
  payload: any;
}

export interface UpdateUserAssessmentStateAction {
  type: typeof UPDATE_USERASSESSMENTSTATE;
  payload: any;
}

export interface GetUserAction {
  type: typeof GET_USER;
  payload: any;
}

export interface SetUserIsUploading {
  type: typeof SET_ISUPLOADING;
  payload: any;
}

export interface UpdateUsertypeAction {
  type: typeof UPDATE_USERTYPE;
  payload: any;
}

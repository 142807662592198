// Amplify Components
import ErrorMessage from "../../../ui-components/ErrorMessage";

// Assets
import { ReactComponent as RedExclamation } from "../../../assets/reskin/icons/small-red-exclamation.svg";

// Styles
import "./BIQErrorMessage.scss";

interface BIQErrorMessageProps {
  text: string;
}

const BIQErrorMessage = ({ text }: BIQErrorMessageProps) => {
  return (
    <div className="biq-error-message">
      <div className="icon-container">
        <RedExclamation />
      </div>
      <p>{text}</p>
    </div>
  );
};

export default BIQErrorMessage;

// Packages
import React from "react";

// Components
import Checkbox from "../../../components/Checkboxes/Checkbox";
import BIQErrorMessage from "../../../components/Messages/BIQErrorMessage";
import BottomBorderInput from "../../../components/Input/BottomBorderInput";

// Utils
import { isUserSpecifiedOption } from "./utils/hasUserSpecifiedOption";

// Style
import "./CheckboxSet.scss";

interface CheckboxSetProps {
  questionText: string;
  questionNumber: number;
  responseKey: string;
  // onChange function should take key, and value as parameters.
  onChange: Function;
  options: string[];
  isRequired?: boolean;
  selectedOptions: any;
  errorMessage?: string;
}

const CheckboxSet = ({
  questionText,
  options,
  responseKey,
  errorMessage,
  onChange,
  isRequired,
  questionNumber,
  selectedOptions,
}: CheckboxSetProps) => {
  const isRequiredAsterisk = isRequired ? (
    <span className="error-asterisk">{"*"}</span>
  ) : null;

  // User Specified Input Controllers
  const [showUserSpecifiedInput, setShowUserSpecifiedInput] =
    React.useState(false);
  const [userSpecifyLabel, setUserSpecifyLabel] = React.useState("");
  const [userSpecifiedValue, setUserSpecifiedValue] = React.useState("");
  const userSpecifyInputClass = showUserSpecifiedInput
    ? "user-specified-input"
    : "user-specified-input hidden";

  // as of now, backend is not returning valid json for selectedOptions
  const getFixedSelectedOptions = () => {
    if (typeof selectedOptions === "string") {
      let fixedOptions = selectedOptions
        .split(/[\[\],]|(, )/g)
        .filter(
          (optionItem: any) => optionItem !== "" && optionItem !== undefined,
        );

      return fixedOptions;
    }

    return selectedOptions;
  };

  const handleUserSpecifiedInputChange = (value: string) => {
    const currentSelectedOptions = getFixedSelectedOptions();

    setUserSpecifiedValue(value);
    const filteredOptions = currentSelectedOptions.filter(
      (optionStr: string) => {
        return !isUserSpecifiedOption(optionStr);
      },
    );

    const updatedOptions = [
      ...filteredOptions,
      `prefer to self-describe: ${value}`,
    ];
    onChange(responseKey, updatedOptions);
  };

  const handleUserSpecifiedInputToggle = () => {
    let hasUserSpecifiedOption = false;
    const currentSelectedOptions = getFixedSelectedOptions();

    if (currentSelectedOptions) {
      currentSelectedOptions.forEach((option: string) => {
        const lowercaseOption = option.toLowerCase();
        if (
          lowercaseOption.includes("prefer to self-describe") ||
          lowercaseOption.slice(0, 6).includes("other")
        ) {
          hasUserSpecifiedOption = true;
          setUserSpecifyLabel("Please specify");
          setShowUserSpecifiedInput(true);
        }
      });
    }

    if (!hasUserSpecifiedOption) {
      setUserSpecifyLabel("");
      setShowUserSpecifiedInput(false);
      setUserSpecifiedValue("");
    }
  };

  React.useEffect(() => {
    handleUserSpecifiedInputToggle();
  }, [selectedOptions]);

  const RenderedOptions = options.map((option, index) => {
    let currentSelectedOptions = getFixedSelectedOptions();

    const selectedCurrentOption = selectedOptions
      ? currentSelectedOptions.findIndex((selectedOption: string) =>
          selectedOption.toLowerCase().includes(option.toLowerCase()),
        )
      : -1;
    const isOptionSelected = selectedCurrentOption > -1;

    return (
      <div className="option-wrapper">
        <Checkbox
          key={`${responseKey}-checkbox-${index}`}
          checked={isOptionSelected}
          onClick={() => {
            if (isOptionSelected) {
              const updatedOptions = currentSelectedOptions.filter(
                (selectedOption: any) =>
                  !selectedOption.toLowerCase().includes(option.toLowerCase()),
              );
              onChange(responseKey, updatedOptions);
            } else {
              const updatedOptions = [...currentSelectedOptions, option];
              onChange(responseKey, updatedOptions);
            }
          }}
          label={option}
        />
      </div>
    );
  });

  return (
    <div className="checkbox-set-question">
      <div className="question-text-wrapper">
        <div className="number-container">
          <h3>{questionNumber}. </h3>
        </div>
        <h3 className="question-text">
          {" "}
          {questionText}{" "}
          <span className="error-text">{isRequiredAsterisk}</span>
        </h3>
      </div>
      {errorMessage && (
        <div className="error-message-container">
          <BIQErrorMessage text={errorMessage} />
        </div>
      )}
      <fieldset className="options-block">{RenderedOptions}</fieldset>

      <BottomBorderInput
        label={userSpecifyLabel}
        onChange={(value: string) => handleUserSpecifiedInputChange(value)}
        id="test-input"
        value={userSpecifiedValue}
        customClasses={`${userSpecifyInputClass}`}
      />
    </div>
  );
};

export default CheckboxSet;

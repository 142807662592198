// Packages
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Redux
import { RootState } from "../../../redux/reducers";

// Components
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";
import TraitSectionHeader from "./TraitSectionHeader/TraitSectionHeader";
import { Spinner } from "react-bootstrap";
import ResourceCarousel from "./ResourceCarousel/ResourceCarousel";

// Utils
import useResourceApi from "../../../hooks/useResourceAPI";

// Styles
import "./ResourceHub.scss";

// GraphQL
import { getOrList } from "../../../components/dynamicqueries";
import { listUserScores } from "../../../graphql/queries";
import EventService from "../../../services/EventService/event.service";

const ResourceHub = () => {
  // Get the Resources
  const {
    userId,
    assessmentState: { sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const [areResourceCardsLoading, setAreResourceCardsLoading] = useState(true);
  const [, setResourceCardsData] = useState<any>();
  const [leadershipCardsData, setLeadershipCardsData] = useState<any[]>();
  const [perseveranceCardsData, setPerseveranceCardsData] = useState<any[]>();
  const [effectiveCommunicationCardsData, setEffectiveCommunicationCardsData] =
    useState<any[]>();
  const [innovativeThinkingCardsData, setInnovativeThinkingCardsData] =
    useState<any[]>();
  const [traitScores, setTraitScores] = useState({
    leadershipScore: 0,
    effectiveCommunicationScore: 0,
    perseveranceScore: 0,
    innovativeThinkingScore: 0,
  });

  const resourceServices = useResourceApi();

  const getLearningResources = async () => {
    const resourcesAndItsConnections =
      await resourceServices.getLearningResourcesWithAllTheConnections(
        "all",
        userId,
        sessionId,
        assessmentId,
      );
    const consolidatedResourceCardInfo =
      await resourceServices.consolidatedEachResourceCardInformation(
        resourcesAndItsConnections,
      );

    const leadershipCards: any = [];
    const effectiveCommunicationCards: any = [];
    const perseveranceCards: any = [];
    const innovativeThinkingCards: any = [];

    // Sort Cards
    consolidatedResourceCardInfo.forEach((card: any) => {
      const cardTrait = card.trait;

      switch (cardTrait) {
        case "leadership":
          leadershipCards.push(card);
          break;
        case "effective communication":
          effectiveCommunicationCards.push(card);
          break;
        case "perseverance":
          perseveranceCards.push(card);
          break;
        case "innovative thinking":
          innovativeThinkingCards.push(card);
          break;
      }
    });

    setLeadershipCardsData(leadershipCards);
    setPerseveranceCardsData(perseveranceCards);
    setEffectiveCommunicationCardsData(effectiveCommunicationCards);
    setInnovativeThinkingCardsData(innovativeThinkingCards);

    setResourceCardsData(consolidatedResourceCardInfo);
    setAreResourceCardsLoading(false);
  };

  const getTraitScores = async () => {
    const filterVariables = {
      limit: 10000,
      filter: {
        userId: { eq: userId },
        userSessionId: { eq: sessionId },
        assessmentId: { eq: assessmentId },
      },
    };

    const studentScores = await getOrList(
      listUserScores,
      "listUserScores",
      filterVariables,
    );
    const traitScoresResult = {
      leadershipScore: studentScores[0]?.leadershipScore,
      perseveranceScore: studentScores[0]?.perseveranceScore,
      effectiveCommunicationScore: studentScores[0]?.communicationScore,
      innovativeThinkingScore: studentScores[0]?.innovativeThinkingScore,
    };

    setTraitScores(traitScoresResult);
  };

  useEffect(() => {
    getLearningResources();
    getTraitScores();
  }, []);

  useEffect(() => {
    EventService.page({
      type: "enter",
      page: "Resource Hub",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "Resource Hub",
      });
    };
  }, []);

  const resourceHubBlurb =
    "The resources below have been selected for you based on how you performed in each assessment. These resources will help you grow in the areas you are approaching or developing in employability and communication skills.";

  if (areResourceCardsLoading) {
    return (
      <div className="reskin-resource-hub">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className="reskin-resource-hub">
      <section className="page-header">
        <h1 className="page-header-text">Resource Hub</h1>
        <p className="resource-hub-blurb">{resourceHubBlurb}</p>
      </section>

      {/* Leadership Section */}
      <section className="leadership-section trait-section">
        <TraitSectionHeader
          variant="light"
          header="Leadership"
          score={traitScores.leadershipScore}
        />

        <ResourceCarousel cards={leadershipCardsData} trait="leadership" />

        <div className="button-footer">
          <ReskinButton
            text="View Trait Score"
            onClick={() => navigate("/report/trait/leadership")}
            variant="primary"
            size="large"
            customClasses="m-0 resource-hub-button"
          />
        </div>
      </section>

      {/* Effective Communication Section */}
      <section className="effective-communication-section trait-section">
        <TraitSectionHeader
          variant="dark"
          header="Effective Communication"
          isTwoLineHeader={true}
          score={traitScores.effectiveCommunicationScore}
        />

        <ResourceCarousel
          cards={effectiveCommunicationCardsData}
          trait="effective communication"
        />

        <div className="button-footer">
          <ReskinButton
            text="View Trait Score"
            onClick={() => navigate("/report/trait/effective%20communication")}
            variant="primary"
            size="large"
            customClasses="m-0 resource-hub-button"
          />
        </div>
      </section>

      {/* Perseverance Section */}
      <section className="perseverAnce-section trait-section">
        <TraitSectionHeader
          variant="light"
          header="Perseverance"
          score={traitScores.perseveranceScore}
        />

        <ResourceCarousel cards={perseveranceCardsData} trait="perseverance" />

        <div className="button-footer">
          <ReskinButton
            text="View Trait Score"
            onClick={() => navigate("/report/trait/perseverance")}
            variant="primary"
            size="large"
            customClasses="m-0 resource-hub-button"
          />
        </div>
      </section>

      {/* Innovative Thinking Section */}
      <section className="innovative-thinking-section trait-section">
        <TraitSectionHeader
          variant="dark"
          header="Innovative Thinking"
          score={traitScores.innovativeThinkingScore}
        />

        <ResourceCarousel
          cards={innovativeThinkingCardsData}
          trait="innovative thinking"
        />

        <div className="button-footer">
          <ReskinButton
            text="View Trait Score"
            onClick={() => navigate("/report/trait/innovative%20thinking")}
            variant="primary"
            size="large"
            customClasses="m-0 resource-hub-button"
          />
        </div>
      </section>

      <section className="reflections-section short-section light-section">
        <div className="header-wrapper">
          <h2>Reflections</h2>
        </div>

        <div className="section-text">
          <p>You've explored resources! Keep going and make time to reflect.</p>
        </div>

        <div className="button-footer">
          <ReskinButton
            text="Reflect Now"
            onClick={() => navigate("/report/reflections")}
            variant="primary"
            size="large"
            customClasses="m-0 resource-hub-button"
          />
        </div>
      </section>

      <section className="favorites-section short-section dark-section">
        <div className="header-wrapper">
          <h2>Favorites</h2>
        </div>

        <div className="section-text">
          <p>
            Don’t forget to save your favorite learning resources by tapping the
            heart icon in each resource that you want to save.
          </p>
        </div>

        <div className="button-footer">
          <ReskinButton
            text="View My Favorites"
            onClick={() => navigate("/profile/my-favorites")}
            variant="primary"
            size="large"
            customClasses="m-0 resource-hub-button"
          />
        </div>
      </section>
    </div>
  );
};

export default ResourceHub;

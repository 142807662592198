import "./_index.scss";

interface TableHeaderProps {
  size?: string;
  text: string;
}

export function TableHeader({ size, text }: TableHeaderProps) {
  const sizeClass = size ? size : "col";

  return (
    <th
      scope="col"
      className={`${sizeClass} blue-variant header-cell text-center`}
    >
      <div className="wrapper">{text}</div>
    </th>
  );
}

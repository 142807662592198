// Packages
import React from "react";
import { Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";

// Components
import VerificationCodeEmail from "../../../components/CustomAuthenticator/VerificationCodeEmail";
import VerificationNumberInputs from "../../../components/CustomAuthenticator/VerificationNumberInputs";
import BottomBorderInput from "../../components/Input/BottomBorderInput";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";
import { ReactComponent as Eye } from "../../../assets/eye.svg";
import { ReactComponent as EyeSlash } from "../../../assets/eyeSlash.svg";

// Styles
import "./ResetPassword.scss";

/**
 * ResetPassword.
 *
 * Handles when a user wants to reset their password; either logged in or not logged in. Prompts user to specify the verification code sent to them and a new password.
 *
 * @changeAuthState Allows for us to change the state in the CustomAuthenticator.
 * @email The users email address.
 * @origin Either 'forgot' or 'reset'. Default is 'forgot'. This param drives the 'Send New Code' feature within the form.
 */

function ResetPassword({
  changeAuthState,
  // email = sessionStorage.getItem("email"),
  origin = "forgot",
}: any) {
  // FROM VERIFY
  const [newPasswordValue, setNewPasswordValue] = React.useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState<string>("");

  // previously stored from create
  const email = sessionStorage.getItem("email") || "";

  // set so we avoid losing on refresh
  sessionStorage.setItem("AuthState", "ResetPassword");
  const [errors, setErrors] = React.useState<any>({ code: "", passwords: "" });

  const checkValidity = () => {
    let errors = 0;
    let errorValues = { passwords: "", code: "" };

    if (newPasswordValue !== confirmPasswordValue) {
      errorValues.passwords = "Passwords do not match.";
      errors++;
    }

    if (newPasswordValue.trim() === "") {
      errorValues.passwords = "Please specify a valid password";
      errors++;
    }

    if (verificationCode.length !== 6) {
      errorValues.code = "Please enter a valid code.";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (checkValidity()) {
      try {
        const attempt = await Auth.forgotPasswordSubmit(
          email,
          verificationCode,
          newPasswordValue,
        );
        console.log(attempt);
        changeAuthState({ screen: "Success" });
      } catch (error: unknown) {
        console.log(error);

        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];
        const errorHandling2 = customError
          .toString()
          .toString()
          .split(":")
          .slice(1)
          .join(":");

        switch (errorHandling) {
          case "CodeMismatchException":
            setErrors({ ...errors, code: "Enter the code sent to you." });
            break;

          case "LimitExceededException":
            setErrors({ ...errors, code: "Limit exceed, try again later." });
            break;

          case "InvalidPasswordException":
            setErrors({ ...errors, passwords: errorHandling2 });
            break;

          default:
            setErrors({ code: "An error occured, try again.", password: "" });
            break;
        }
      }
    }
  };

  const resetCodeValidity = () => {
    if (errors.code.length > 0) {
      setErrors({ ...errors, code: "", passwords: "" });
    }
  };

  const handleCodeChanges = (code: string) => {
    setVerificationCode(code);
  };

  const NewPasswordLabel = (
    <span>
      <p className="password-label">{"Enter new password "}</p>
      <p className="password-label error-asterisk">*</p>
    </span>
  );
  const ConfirmPasswordLabel = (
    <span>
      <p className="password-label">{"Confirm password "}</p>
      <p className="password-label error-asterisk">*</p>
    </span>
  );

  return (
    <div className="reskin-reset-password-container">
      <div className="ImageContainer">
        <div className="TopContainer">
          <div className="text-container header-container">
            <p className="header-text m-0">SkillTrack</p>
          </div>
          <div className="text-container subtext-container">
            <p className="subtitle-text m-0">
              SkillTrack will help you identify your strengths in employability
              skills.
            </p>
          </div>
        </div>
        <div className="BottomContainer">
          <p className="boldText">Reset Password</p>
          <div className="InputContainer">
            <section className="reset">
              <div className="wrapper container pb-4">
                <Form className="form" noValidate>
                  <div className="items">
                    <VerificationCodeEmail email={email} />
                    <VerificationNumberInputs
                      isInvalid={errors.code.length > 0 ? true : false}
                      codes={verificationCode}
                      handleCodeChanges={handleCodeChanges}
                      origin={origin}
                      userIdentifier={email}
                      resetValidity={resetCodeValidity}
                      setFullCode={setVerificationCode}
                      error={errors.code}
                    />
                  </div>

                  <div className="password-inputs-container">
                    <BottomBorderInput
                      value={newPasswordValue}
                      onChange={(value: any) => {
                        setNewPasswordValue(value);
                        resetCodeValidity();
                      }}
                      label={NewPasswordLabel}
                      id="new-password-input"
                      name="Enter new password"
                      customClasses="new-password-input"
                      type="password"
                    />

                    <BottomBorderInput
                      value={confirmPasswordValue}
                      onChange={(value: any) => {
                        setConfirmPasswordValue(value);
                        resetCodeValidity();
                      }}
                      label={ConfirmPasswordLabel}
                      id="confirm-password-input"
                      name="Confirm password"
                      type="password"
                    />

                    <p className="error-text">{errors.passwords}</p>
                  </div>

                  <div className="controls-container">
                    <ReskinButton
                      variant="primary"
                      text="Save"
                      customClasses="verify-button button-control"
                      onClick={handleSave}
                      disabled={!!!newPasswordValue || !!!confirmPasswordValue}
                    />

                    <ReskinButton
                      variant="secondary"
                      text="Cancel"
                      customClasses="cancel-button button-control"
                      onClick={() => changeAuthState("SignIn")}
                    />
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

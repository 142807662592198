import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Assets
import { ReactComponent as LeftArrowWhite } from "../../../assets/icons/arrow-left-white.svg";
import { ReactComponent as BookmarkOutlineWhite } from "../../../assets/icons/bookmark-outline-white.svg";
import { ReactComponent as SelectedBookmark } from "../../../assets/fullsummary/SelectedBookmark.svg";
import { ReskinCarousel } from "../../../reskin/components/ReskinCarousel/ReskinCarousel";
import { Carousel } from "react-bootstrap";
import { TraitCard } from "../../../reskin/components/TraitCard/TraitCard";

// Services
import * as eventServices from "../../../services/EventService/event.service";

// Utils
import useResourceApi from "../../../hooks/useResourceAPI";

/**
 *
 * @summary
 * requires use of useResourceApi and exposure of fields documented in its source file. See useResourceAPI.ts
 */

function ResourceCardCarousel() {
  const {
    state: {
      parentTitle,
      cards,
      resourceTrait,
      userId,
      assessId,
      sessionId,
      cardId,
      isFavorite,
    },
  }: any = useLocation();
  const [isSaved, setSaveStatus] = useState(isFavorite);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const resourceServices = useResourceApi();
  React.useEffect(() => {
    eventServices.page({
      page: `What is ${resourceTrait}`,
      user_type: "student",
    });

    return () => {
      eventServices.page({
        type: "exit",
        page: `What is ${resourceTrait}`,
        user_type: "student",
      });
    };
  }, []);

  const resourceList = cards.map((card: any, cardIdx: number) => (
    <Carousel.Item
      key={`${resourceTrait}-carousel-item-${cardIdx}`}
      className="carousel-resource"
    >
      <TraitCard
        title={resourceTrait}
        subtitle={card.description}
        content={card.content}
      />
    </Carousel.Item>
  ));

  resourceList.length = 7;

  const handleSave = () => {
    resourceServices
      .updateFavs(!isSaved, cardId, resourceTrait, userId, sessionId, assessId)
      .then((res: any) => {
        console.log("rresponse", res);
      });
    eventServices.track({
      event_type: "user",
      event_name: `resource_${isSaved ? "unsave" : "save"}`,
      user_type: "student",
      event_data: {
        category: `${resourceTrait}`,
        type: "cards",
        content: `${parentTitle}`,
        resource_id: `${cardId}`,
        context: `What is ${resourceTrait}`,
      },
    });
    setSaveStatus(!isSaved);
  };

  const DynamicBookmark = isSaved ? (
    <SelectedBookmark />
  ) : (
    <BookmarkOutlineWhite />
  );

  const handleSlideChange = (slideIdx: number): void => {
    setIndex(slideIdx);

    eventServices.track({
      event_type: "user",
      event_name: "resource_card_slide",
      user_type: "student",
      event_data: {
        category: `${resourceTrait}`,
        Index_total: `${resourceList.length}`,
        Index_current: `${slideIdx + 1}`,
        context: `What is ${resourceTrait}`,
      },
    });
  };

  return (
    <div className="resource-card-carousel-wrapper">
      <section className="nav-subheader-wrapper">
        <div className="nav-subheader-sub-wrapper">
          <nav
            onClick={() => navigate(-1)}
            className="resource-card-carousel-arrow-svg-wrapper"
          >
            <LeftArrowWhite />
          </nav>
          <div
            onClick={handleSave}
            className="resource-card-carousel-bookmark-svg-wrapper"
          >
            <span>Save{isSaved ? "d" : ""}</span>
            {DynamicBookmark}
          </div>
        </div>
      </section>
      <h2 className="resource-specific-subheader">What is {resourceTrait}?</h2>
      {resourceList.length > 0 && (
        <ReskinCarousel
          content={resourceList}
          activeIndex={index}
          onSelect={handleSlideChange}
        />
      )}
    </div>
  );
}

export default ResourceCardCarousel;

// Components
import { Modal } from "react-bootstrap";
import NoBorderIconButton from "../../../../components/Buttons/NoBorderIconButton/NoBorderIconButton";

// Assets
import { ReactComponent as Close } from "../../../../../assets/icons/close.svg";
import { ReactComponent as InfoCircle } from "../../../../../assets/reskin/icons/white-info-circle.svg";

// Styles
import "./Tips.scss";

function Tips({ show, setShow }: any) {
  const InfoIcon = <InfoCircle />;
  const CloseIcon = <Close />;

  return (
    <>
      {show && (
        <div className="tips-display">
          <section className="tips-display-header">
            <div className="info-icon-container pt-1 me-2 ms-3">{InfoIcon}</div>
            <div className="title-container">
              <div>
                <h3 className="pt-1">Recording Tips</h3>
              </div>
              <NoBorderIconButton
                icon={CloseIcon}
                onClick={() => setShow(false)}
              />
            </div>
          </section>
          <section className="tips-display-body">
            <ul>
              <li>You have 2 attempts to record your response.</li>
              <li>Turn self view on (tap the camera icon if it's off).</li>
              <li>Face inside the oval area.</li>
              <li>Avoid wearing glasses with a glare.</li>
              <li>Check for adequate lighting.</li>
              <li>Speak for 1 to 2 minutes.</li>
            </ul>
          </section>
        </div>
      )}
    </>
  );
}

export default Tips;

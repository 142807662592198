import React from "react";
import { Provider } from "react-redux";
import { store } from "../redux/store";

export function withRedux(WrappedComponent: React.ComponentType) {
  return ({ children }: { children?: React.ReactNode }) => {
    return (
      <Provider store={store}>
        <WrappedComponent>{children}</WrappedComponent>
      </Provider>
    );
  };
}

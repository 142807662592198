// Packages
import React from "react";
import { useIdleTimer } from "react-idle-timer";

// Components
import ReskinButton from "../../Buttons/ReskinButton/ReskinButton";
import TwoButtonModal from "../TwoButtonModal/TwoButtonModal";
import SessionTimeoutModal from "../SessionTimeoutModal/SessionTimeoutModal";

// Redux
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../redux/actions";

interface SignOutModalProptype {
  handleClose: Function;
  show: boolean;
}

const SignOutModal = ({ handleClose, show }: SignOutModalProptype) => {
  const [isUserInactive, setIsUserInactive] = React.useState(false);
  const dispatch = useDispatch();

  useIdleTimer({
    onIdle: () => setIsUserInactive(true),
    timeout: 60000,
    startOnMount: true,
  });

  const SignOutModalBody = (
    <>
      <p>Are you sure you want to sign out?</p>
    </>
  );
  const SignOutCancelButton = (
    <ReskinButton
      variant="secondary"
      size="small"
      onClick={handleClose}
      text="Stay Signed In"
    />
  );
  const SignOutButton = (
    <ReskinButton
      variant="primary"
      size="small"
      onClick={() => dispatch(logoutUser())}
      text="Sign Out"
      loadingIndicator={true}
    />
  );

  if (isUserInactive) {
    return <SessionTimeoutModal show={show} handleClose={handleClose} />;
  }

  return (
    <TwoButtonModal
      onClose={handleClose}
      show={show}
      headerText="Sign Out"
      bodyContent={SignOutModalBody}
      LeftButton={SignOutCancelButton}
      RightButton={SignOutButton}
    />
  );
};

export default SignOutModal;

// Packages
import React from "react";
import { Form } from "react-bootstrap";
import { Auth } from "aws-amplify";

/**
 * VerificationNumberInputs.
 *
 * Component which displays 6 digit code form. It also handles resending the user a new code.
 *
 * @handleCodeChanges Function from parent component which holds the state of codes.
 * @isInvalid boolean. Whether or not the numbers are invalid.
 * @userIdentifier The userId or email of the user, different paras are needed due to the origin.
 * @origin Where this component is being rendered (forgot, reset, verify).
 * @resetValidity Enables the ability to reset the validity of the numbers.
 *
 */

function VerificationNumberInputs({
  handleCodeChanges,
  isInvalid,
  userIdentifier,
  origin = "forgot",
  resetValidity,
  setFullCode,
}: any) {
  const [codes, setCodes] = React.useState<any>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });

  const [resentCode, setResentCode] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (resentCode) {
      setTimeout(() => {
        setResentCode(false);
      }, 2000);
    }
  }, [resentCode]);

  React.useEffect(() => {
    if (isInvalid)
      setCodes({
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
      });

    handleCodeChanges("");
  }, [isInvalid]);

  const handleCodeChange = (event: any) => {
    let { value, dataset } = event.target;
    const { prop } = dataset;

    const number = prop.split("number")[1];
    const newCodes = { ...codes, [number]: value };
    handleCodeChanges(Object.values(newCodes).join(""));

    setCodes(newCodes);

    // focus next input
    if (value !== "" && value.length > 0) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
    }
  };

  const sendNewCode = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    switch (origin) {
      case "forgot":
        // non logged in user is trying to reset their password
        try {
          const attempt = await Auth.forgotPassword(userIdentifier);
          console.log(attempt);
        } catch (err) {
          console.log("error resending code: ", err);
        }
        break;

      case "reset":
        // logged in user is trying to reset their password
        try {
          const attempt = await Auth.forgotPassword(userIdentifier);
          console.log(attempt);
        } catch (err) {
          console.log("error resending code: ", err);
        }
        break;

      case "verify":
        // non logged in user is trying to verify their account
        try {
          await Auth.resendSignUp(userIdentifier);
          console.log("code resent successfully");
        } catch (err) {
          console.log("error resending code: ", err);
        }
        break;
    }

    setResentCode(true);
  };

  const handlePaste = (e: any) => {
    const pastedText = e.clipboardData.getData("text");
    let updatedCode = pastedText;

    if (setFullCode) {
      setFullCode(updatedCode);
    }

    const splitValues = pastedText.split("");
    let currentCodeKey = 1;
    const newCodesObject: any = {};

    while (currentCodeKey <= 6) {
      const currentIndex = currentCodeKey - 1;
      if (splitValues[currentIndex]) {
        newCodesObject[currentCodeKey] = splitValues[currentIndex];
      } else {
        newCodesObject[currentCodeKey] = "";
      }

      currentCodeKey++;
    }

    setCodes(newCodesObject);
  };

  return (
    <div className="mb-3 verification-number-inputs-block">
      <div className="new-code-block">
        <button
          className={`${resentCode ? "flash" : ""} new-code-button`}
          onClick={(e) => sendNewCode(e)}
        >
          <span className="new-code-text">
            {!resentCode ? "Send New Code" : "Sent!"}
          </span>
        </button>
      </div>
      <div className="labeling">
        <label className="enter-verification-label">
          Enter Verification Code *
        </label>
      </div>

      <div className="verify-numbers">
        <Form.Group className="item" controlId="formNumber1">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number1"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[1]}
            onFocus={resetValidity}
            onPaste={(e) => handlePaste(e)}
            aria-label="Verification Code Input 1."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber2">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number2"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[2]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 2."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber3">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number3"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[3]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 3."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber4">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number4"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[4]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 4."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber5">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number5"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[5]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 5."
          />
        </Form.Group>

        <Form.Group className="item" controlId="formNumber6">
          <Form.Control
            type="tel"
            placeholder=""
            className="verification-code-input"
            data-prop={"number6"}
            onChange={handleCodeChange}
            required
            autoComplete="off"
            maxLength={1}
            isInvalid={isInvalid}
            value={codes[6]}
            onFocus={resetValidity}
            aria-label="Verification Code Input 6."
          />
        </Form.Group>
      </div>
      {isInvalid && (
        <div className="invalid" role="alert" aria-atomic="true">
          Invalid code.
        </div>
      )}
    </div>
  );
}

export default VerificationNumberInputs;

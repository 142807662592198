// Components
import ProgressBarFooter from "../../../ui-components/ProgressBarFooter";

// Styles
import "./HireupProgressBarFooter.scss";

interface HireUpProgressBarProps {
  itemsCompleted: number;
  itemsTotal: number;
  itemName?: string; // the name of the item being counted for labeling purposes. i.e. Question 1 of 20
}

const HireupProgressBarFooter = ({
  itemsCompleted,
  itemsTotal,
  itemName,
}: HireUpProgressBarProps) => {
  // Null checks and defaults

  const completionPercentage =
    itemsCompleted < itemsTotal
      ? Math.round((itemsCompleted / itemsTotal) * 100)
      : 100;

  const displayedItemName = itemName ? itemName : "item";

  return (
    <div className="hireup-footer-progress-bar">
      <div className="bars-indicator-container">
        <div className="bars-container">
          <div className="gray-bar" />
          <div
            className="green-bar"
            style={{ width: `${completionPercentage}%` }}
          />
        </div>
        <div className="percentage-indicator-container">
          <p className="percentage-indicator">{`${completionPercentage}%`}</p>
        </div>
      </div>
      <div className="question-indicator-container">
        <h3 className="question-indicator-label">{`${displayedItemName} ${itemsCompleted} of ${itemsTotal}`}</h3>
      </div>
    </div>
  );

  // return (
  //     <ProgressBarFooter
  //         overrides={{
  //             ProgressBarFooter: { width: '100%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: "20px" },
  //             GreenBar: { width: `${completionPercentage}%` },
  //             PercentageLabel: { children: `${completionPercentage}%`},
  //             Label: {
  //                 children: `${displayedItemName} ${itemsCompleted} of ${itemsTotal}`,
  //                 width: "100%",
  //                 textAlign: "left"
  //             },
  //             ContentContainer: {
  //                 marginLeft: "auto",
  //                 marginRight: "auto",
  //             }
  //         }}
  //     />
  // );
};

export default HireupProgressBarFooter;

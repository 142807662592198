//Packages

import { useSelector } from "react-redux";
import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import imageUrl from "../../../../../assets/fullsummary/user-avatar.png";
import { Button } from "react-bootstrap";

// Redux
import { RootState } from "../../../../../redux/reducers";

//components
import ModalStudentResource from "../../../../../components/Modals/StudentResource";
import createOrUpdate from "../../../../../components/dynamicqueries";

// Contexts
import { ModalContext } from "../../../../../contexts/context";

//graphql
import {
  createInstitutionResponse,
  updateStudentRequest,
} from "../../../../../graphql/mutations";

// Services
import EventService from "../../../../../services/EventService/event.service";

//Image

import { ReactComponent as CheckMark } from "../../../../../assets/fullsummary/support/checkmark.svg";

type messageType = {
  studentResources: any;
  userScore: any;
  getUserScores: () => void;
};

function Messages({ studentResources, userScore, getUserScores }: messageType) {
  const { userId } = useSelector((state: RootState) => state.user);
  const { showModal } = React.useContext(ModalContext);
  const [checkMark, setCheckMark] = React.useState<any>([
    {
      perseverance: false,
      leadership: false,
      effectiveCommunication: false,
      innovativeThinking: false,
    },
  ]);
  const [leadershipMessage, setLeadershipMessage] = React.useState<any>("");
  const [leadershipRescorcecheck, setLeadershipRescorcecheck] =
    React.useState<any>("");
  const [leadershipRescorceSelectedData, setLeadershipRescorceSelectedData] =
    React.useState<any>([]);
  const [perseveranceMessage, setPerseveranceMessage] = React.useState<any>("");
  const [perseveranceRescorcecheck, setPerseveranceRescorcecheck] =
    React.useState<any>("");
  const [
    perseveranceRescorceSelectedData,
    setPerseveranceRescorceSelectedData,
  ] = React.useState<any>([]);
  const [effectivecommunicationMessage, setEffectivecommunicationMessage] =
    React.useState<any>("");
  const [
    effectivecommunicationRescorcecheck,
    setEffectivecommunicationRescorcecheck,
  ] = React.useState<any>("");
  const [
    effectivecommunicationRescorceSelectedData,
    setEffectivecommunicationRescorceSelectedData,
  ] = React.useState<any>([]);
  const [innovativethinkingMessage, setInnovativethinkingMessage] =
    React.useState<any>("");
  const [innovativethinkingRescorcecheck, setInnovativethinkingRescorcecheck] =
    React.useState<any>("");
  const [
    innovativethinkingRescorceSelectedData,
    setInnovativethinkingRescorceSelectedData,
  ] = React.useState<any>([]);

  const leadershipResources: any = studentResources.filter(
    (traitName: any) => traitName.trait === "leadership",
  );

  const perseveranceResource: any = studentResources.filter(
    (traitName: any) => traitName.trait === "perseverance",
  );

  const effectivecommunicationResource: any = studentResources.filter(
    (traitName: any) => traitName.trait === "effectiveCommunication",
  );

  const innovativethinkingResource: any = studentResources.filter(
    (traitName: any) => traitName.trait === "innovativeThinking",
  );

  React.useEffect(() => {}, []);

  const leadershipMessageChange = (event: any): void => {
    setLeadershipMessage(event.target.value);
  };

  const perseveranceMessageChange = (event: any): void => {
    setPerseveranceMessage(event.target.value);
  };

  const effectivecommunicationMessageChange = (event: any): void => {
    setEffectivecommunicationMessage(event.target.value);
  };

  const innovativethinkingMessageChange = (event: any): void => {
    setInnovativethinkingMessage(event.target.value);
  };

  const leadershipResourcesChange = (studentResource: any): void => {
    let checkboxData = leadershipRescorcecheck;
    let selectedData = leadershipRescorceSelectedData;
    if (!checkboxData.includes(studentResource.id)) {
      checkboxData = [studentResource.id, ...checkboxData];
      selectedData = [
        {
          traitName: studentResource.trait,
          description: studentResource.description,
          resourceLinks: [studentResource.resourceLink],
        },
        ...selectedData,
      ];
    } else {
      checkboxData = checkboxData.filter(
        (id: any) => id !== studentResource.id,
      );
      selectedData = selectedData.filter(
        (val: any) => val.description !== studentResource.description,
      );
    }
    setLeadershipRescorcecheck(checkboxData);
    setLeadershipRescorceSelectedData(selectedData);
  };

  const perseveranceResourcesChange = (studentResource: any): void => {
    let checkboxData = perseveranceRescorcecheck;
    let selectedData = perseveranceRescorceSelectedData;
    if (!checkboxData.includes(studentResource.id)) {
      checkboxData = [studentResource.id, ...checkboxData];
      selectedData = [
        {
          traitName: studentResource.trait,
          description: studentResource.description,
          resourceLinks: [studentResource.resourceLink],
        },
        ...selectedData,
      ];
    } else {
      checkboxData = checkboxData.filter(
        (id: any) => id !== studentResource.id,
      );
      selectedData = selectedData.filter(
        (val: any) => val.description !== studentResource.description,
      );
    }
    setPerseveranceRescorcecheck(checkboxData);
    setPerseveranceRescorceSelectedData(selectedData);
  };

  const effectivecommunicationResourcesChange = (
    studentResource: any,
  ): void => {
    let checkboxData = effectivecommunicationRescorcecheck;
    let selectedData = effectivecommunicationRescorceSelectedData;
    if (!checkboxData.includes(studentResource.id)) {
      checkboxData = [studentResource.id, ...checkboxData];
      selectedData = [
        {
          traitName: studentResource.trait,
          description: studentResource.description,
          resourceLinks: [studentResource.resourceLink],
        },
        ...selectedData,
      ];
    } else {
      checkboxData = checkboxData.filter(
        (id: any) => id !== studentResource.id,
      );
      selectedData = selectedData.filter(
        (val: any) => val.description !== studentResource.description,
      );
    }
    setEffectivecommunicationRescorcecheck(checkboxData);
    setEffectivecommunicationRescorceSelectedData(selectedData);
  };

  const innovativethinkingResourcesChange = (studentResource: any): void => {
    let checkboxData = innovativethinkingRescorcecheck;
    let selectedData = innovativethinkingRescorceSelectedData;
    if (!checkboxData.includes(studentResource.id)) {
      checkboxData = [studentResource.id, ...checkboxData];
      selectedData = [
        {
          traitName: studentResource.trait,
          description: studentResource.description,
          resourceLinks: [studentResource.resourceLink],
        },
        ...selectedData,
      ];
    } else {
      checkboxData = checkboxData.filter(
        (id: any) => id !== studentResource.id,
      );
      selectedData = selectedData.filter(
        (val: any) => val.description !== studentResource.description,
      );
    }
    setInnovativethinkingRescorcecheck(checkboxData);
    setInnovativethinkingRescorceSelectedData(selectedData);
  };

  const handleSendSupport = async () => {
    let popup = false;
    if (leadershipMessage !== "" || leadershipRescorceSelectedData.length > 0) {
      await saveData(
        leadershipMessage,
        leadershipRescorceSelectedData,
        "leadership",
      );
      popup = true;
    }

    if (
      perseveranceMessage !== "" ||
      perseveranceRescorceSelectedData.length > 0
    ) {
      await saveData(
        perseveranceMessage,
        perseveranceRescorceSelectedData,
        "perseverance",
      );
      popup = true;
    }

    if (
      effectivecommunicationMessage !== "" ||
      effectivecommunicationRescorceSelectedData.length > 0
    ) {
      await saveData(
        effectivecommunicationMessage,
        effectivecommunicationRescorceSelectedData,
        "effective communication",
      );
      popup = true;
    }

    if (
      innovativethinkingMessage !== "" ||
      innovativethinkingRescorceSelectedData.length > 0
    ) {
      await saveData(
        innovativethinkingMessage,
        innovativethinkingRescorceSelectedData,
        "innovative thinking",
      );
      popup = true;
    }

    if (popup) showModal(ModalStudentResource, { closePopUp });
  };

  const saveData = async (message: any, resources: any, traitName: any) => {
    const { userSessionId } = userScore;
    let { studentRequests } = userScore.userAssessmentState;
    studentRequests = studentRequests.items.filter(
      (studentRequest: any, index: any) =>
        studentRequest.traitName === traitName,
    );
    const input: any = {
      studentUserId: userScore.userId,
      studentUserSessionId: userSessionId,
      traitName: traitName,
      response: message,
      institutionUserId: userId,
      responseRead: false, //studentRequests.length > 0 ? true : false,
      resourceInfo: resources,
      responseDate: new Date().toISOString(),
    };
    if (studentRequests.length > 0) {
      input["studentRequestId"] = studentRequests[0].id;
      const studentRequestInput = {
        id: studentRequests[0].id,
        responseSentToStudent: true,
        _version: studentRequests[0]._version,
      };
      await createOrUpdate(updateStudentRequest, studentRequestInput);
    }

    await createOrUpdate(createInstitutionResponse, input);

    EventService.track({
      event_type: "user",
      event_name: "hei_support_student_send",
      user_type: "hei",
      event_data: {
        student_id: userScore.userId,
        student_sessionId: userScore.userSessionId,
        category: traitName,
        message_text: message,
        resources: resources,
      },
    });
  };

  const closePopUp = () => {
    getUserScores();
    resetStateValue();
    // window.location.reload();
  };
  const resetStateValue = () => {
    setInnovativethinkingRescorceSelectedData([]);
    setInnovativethinkingRescorcecheck("");
    setInnovativethinkingMessage("");
    setEffectivecommunicationRescorceSelectedData([]);
    setEffectivecommunicationRescorcecheck("");
    setEffectivecommunicationMessage("");
    setPerseveranceRescorceSelectedData([]);
    setPerseveranceRescorcecheck("");
    setPerseveranceMessage("");
    setLeadershipRescorceSelectedData([]);
    setLeadershipRescorcecheck("");
    setLeadershipMessage("");
    setCheckMark([
      {
        perseverance: false,
        leadership: false,
        effectiveCommunication: false,
        innovativeThinking: false,
      },
    ]);
  };

  const tabCheckMark = (eventKey: any) => {
    const leadership =
      (leadershipMessage.length > 0 ||
        leadershipRescorceSelectedData.length > 0) &&
      eventKey !== "leadership"
        ? true
        : false;
    const preservence =
      (perseveranceMessage.length > 0 ||
        perseveranceRescorceSelectedData.length > 0) &&
      eventKey !== "perseverance"
        ? true
        : false;
    const communication =
      (effectivecommunicationMessage.length > 0 ||
        effectivecommunicationRescorceSelectedData.length > 0) &&
      eventKey !== "effectiveCommunication"
        ? true
        : false;
    const innovativeThinking =
      (innovativethinkingMessage.length > 0 ||
        innovativethinkingRescorceSelectedData.length > 0) &&
      eventKey !== "innovativeThinking"
        ? true
        : false;

    setCheckMark([
      {
        perseverance: preservence,
        leadership: leadership,
        effectiveCommunication: communication,
        innovativeThinking: innovativeThinking,
      },
    ]);
  };

  return (
    <>
      <div className="support-message">
        <div className="comment-title">
          <strong>Support Message and Resources</strong>
          <Button
            bsPrefix="psq-btn"
            variant="primary"
            className="btn-sendAll"
            onClick={handleSendSupport}
          >
            Send All
          </Button>
        </div>
        <br />
        <Tabs
          defaultActiveKey="leadership"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={tabCheckMark}
        >
          <Tab
            eventKey="leadership"
            title={
              <span>
                Leadership {checkMark[0].leadership ? <CheckMark /> : ""}
              </span>
            }
            tabClassName="tab"
          >
            <div className="messageContent">
              <div className="content-input">
                <textarea
                  name="comments"
                  value={leadershipMessage}
                  onChange={leadershipMessageChange}
                ></textarea>
              </div>
            </div>

            <br />

            <div className="leadership-resources">
              Leadership Resources
              <br />
              <span className="subtitle">
                Select resources to send to student
              </span>
              <br />
              <br />
              <br />
              <div>
                {leadershipResources.map((value: any, index: any) => {
                  return (
                    <div key={index}>
                      {value.data.length > 0 ? (
                        <div className="trait">{value.subtrait}</div>
                      ) : (
                        ""
                      )}
                      {value.data.map((val: any, key: any) => {
                        return (
                          <div key={key} className="button">
                            <Form.Check
                              aria-label={`option ${key}`}
                              onChange={() => leadershipResourcesChange(val)}
                              className="checkbox"
                              checked={leadershipRescorcecheck.includes(val.id)}
                            />
                            <div className="label">
                              <a
                                className="resourceLink"
                                href={val.resourceLink}
                                target="_blank"
                              >
                                {val.description}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="perseverance"
            title={
              <span>
                Perseverance{" "}
                {checkMark[0].perseverance > 0 ? <CheckMark /> : ""}
              </span>
            }
            tabClassName="tab"
          >
            <div className="messageContent">
              <div className="content-input">
                <textarea
                  // ref={commentsRef}
                  name="comments"
                  value={perseveranceMessage}
                  onChange={perseveranceMessageChange}
                ></textarea>
              </div>

              <br />
              <br />
              <br />
            </div>

            <br />

            <div className="leadership-resources">
              Perseverance Resources
              <br />
              <span className="subtitle">
                Select resources to send to student
              </span>
              <br />
              <br />
              <br />
              <div>
                {perseveranceResource.map((value: any, index: any) => {
                  return (
                    <div key={index}>
                      {value.data.length > 0 ? (
                        <div className="trait">{value.subtrait}</div>
                      ) : (
                        ""
                      )}
                      {value.data.map((val: any, key: any) => {
                        return (
                          <div key={key} className="button">
                            <Form.Check
                              aria-label={`option ${key}`}
                              onChange={() => perseveranceResourcesChange(val)}
                              className="checkbox"
                              checked={perseveranceRescorcecheck.includes(
                                val.id,
                              )}
                            />
                            <div className="label">
                              {" "}
                              <a
                                className="resourceLink"
                                href={val.resourceLink}
                                target="_blank"
                              >
                                {val.description}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="effectiveCommunication"
            title={
              <span>
                Effective Communication{" "}
                {checkMark[0].effectiveCommunication ? <CheckMark /> : ""}
              </span>
            }
            tabClassName="tab"
          >
            <div className="messageContent">
              <div className="content-input">
                <textarea
                  name="comments"
                  value={effectivecommunicationMessage}
                  onChange={effectivecommunicationMessageChange}
                ></textarea>
              </div>

              <br />
              <br />
              <br />
            </div>

            <br />

            <div className="leadership-resources">
              Effective Communication Resources
              <br />
              <span className="subtitle">
                Select resources to send to student
              </span>
              <br />
              <br />
              <br />
              <div>
                {effectivecommunicationResource.map(
                  (value: any, index: any) => {
                    return (
                      <div key={index}>
                        {value.data.length > 0 ? (
                          <div className="trait">{value.subtrait}</div>
                        ) : (
                          ""
                        )}
                        {value.data.map((val: any, key: any) => {
                          return (
                            <div key={key} className="button">
                              <Form.Check
                                aria-label={`option ${key}`}
                                onChange={() =>
                                  effectivecommunicationResourcesChange(val)
                                }
                                className="checkbox"
                                checked={effectivecommunicationRescorcecheck.includes(
                                  val.id,
                                )}
                              />
                              <div className="label">
                                {" "}
                                <a
                                  className="resourceLink"
                                  href={val.resourceLink}
                                  target="_blank"
                                >
                                  {val.description}
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="innovativeThinking"
            title={
              <span>
                Innovative Thinking{" "}
                {checkMark[0].innovativeThinking ? <CheckMark /> : ""}
              </span>
            }
            tabClassName="tab"
          >
            <div className="messageContent">
              <div className="content-input">
                <textarea
                  name="comments"
                  value={innovativethinkingMessage}
                  onChange={innovativethinkingMessageChange}
                ></textarea>
              </div>

              <br />
              <br />
              <br />
            </div>

            <br />

            <div className="leadership-resources">
              Innovative Thinking Resources
              <br />
              <span className="subtitle">
                Select resources to send to student
              </span>
              <br />
              <br />
              <br />
              <div>
                {innovativethinkingResource.map((value: any, index: any) => {
                  return (
                    <div key={index}>
                      {value.data.length > 0 ? (
                        <div className="trait">{"Innovative Thinking"}</div>
                      ) : (
                        ""
                      )}
                      {value.data.map((val: any, key: any) => {
                        return (
                          <div key={key} className="button">
                            <Form.Check
                              aria-label={`option ${key}`}
                              onChange={() =>
                                innovativethinkingResourcesChange(val)
                              }
                              className="checkbox"
                              checked={innovativethinkingRescorcecheck.includes(
                                val.id,
                              )}
                            />
                            <div className="label">
                              {" "}
                              <a
                                className="resourceLink"
                                href={val.resourceLink}
                                target="_blank"
                              >
                                {val.description}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Tab>
        </Tabs>
        <br /> <br /> <br />
      </div>
    </>
  );
}

export default Messages;

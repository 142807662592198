import React from "react";
import "./SuccessScreen.scss";
import SuccessTick from "../../../assets/re-skin/successTick.svg";
import ReskinButton from "../../components/Buttons/ReskinButton/ReskinButton";

function SuccessScreen({ changeAuthState }: any) {
  const email = sessionStorage.getItem("email") || "";
  const screen = sessionStorage.getItem("AuthState");

  const obscureEmail = (value: string) => {
    if (value === "") return "********@*****.***";
    const [name, domain] = value.split("@");
    return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
  };

  const Verification = () => {
    return (
      <p>Your account has been created using <br></br>{obscureEmail(email)}</p>
  )};

  const Reset = () => {
    return (
      <p>Your password has been reset</p>
  )};

  const returnToSignIn = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("AuthState");
    changeAuthState({ screen: "SignIn" });
  }

  return (
    <div className="reskin-success-screen">
      <div className="ImageContainer">
        <div className="TopContainer">
          <p className="Header">SkillTrack</p>
          <p className="SubTitleText">
            SkillTrack will help you identify your strengths in employability
            skills.
          </p>
        </div>
        <div className="BottomContainer">
          <div className="SucessEncircled">
            <img src={SuccessTick} alt="Tick Icon" />
            <p className="subTitle">{screen === "Verify" ? "Account Verified" : "Success"}</p>
          </div>
          <div className="text">
            {screen === "Verify" ? <Verification /> : <Reset />}
          </div>
          <div className="signInButton">
            <ReskinButton
              variant="primary"
              text="Sign In"
              customClasses="verify-button button-control"
              onClick={returnToSignIn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessScreen;

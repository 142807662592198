// Types
import { StudentSupportRowType } from "../utils/types";

// Packages
import { v4 as uuidV4 } from "uuid";

// Components
import StudentSupportRow from "./StudentSupportRow";

interface SupportPanelBodyProps {
  rows: {
    [key: string]: StudentSupportRowType;
  };
}

const SupportPanelBody = ({ rows }: SupportPanelBodyProps) => {
  const arrayOfRows = Object.values(rows);

  const RenderedRows = arrayOfRows.map((row, index) => {
    return (
      <StudentSupportRow key={`support-row-${index}-${uuidV4()}`} row={row} />
    );
  });

  return <tbody>{RenderedRows}</tbody>;
};

export default SupportPanelBody;

import { ReactComponent as DevelopingPie } from "../../../../assets/icons/pie/developing-pie.svg";
import { ReactComponent as ApproachingPie } from "../../../../assets/icons/pie/approaching-pie.svg";
import { ReactComponent as DemonstratingPie } from "../../../../assets/icons/pie/demonstrating-pie.svg";
import { ReactComponent as ApproachingBar } from "../../../../assets/icons/bar/approaching-bar-reskin.svg";
import { ReactComponent as DemonstratingBar } from "../../../../assets/icons/bar/demonstrating-bar-reskin.svg";
import { ReactComponent as DevelopingBar } from "../../../../assets/icons/bar/developing-bar-reskin.svg";
import "./_index.scss";

export function SkillsLegend() {
  return (
    <div className="d-flex legendKeyContainer">
      <div className="legendKey ps-4">
        <span style={{ verticalAlign: "super", paddingRight: "2px" }}>
          <DevelopingBar />
        </span>
        &nbsp;Developing
      </div>
      <div className="legendKey ps-4">
        <span style={{ verticalAlign: "super", paddingRight: "2px" }}>
          <ApproachingBar />
        </span>
        &nbsp;Approaching
      </div>
      <div className="legendKey ps-4">
        <span style={{ verticalAlign: "super", paddingRight: "2px" }}>
          <DemonstratingBar />
        </span>
        &nbsp;Demonstrating
      </div>
    </div>
  );
}

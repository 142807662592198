// Packages
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router-dom";

// Assets
import { ReactComponent as CardDot } from "../../../../assets/dot.svg";
import { ReactComponent as CardSelectedDot } from "../../../../assets/dot-selected.svg";

// Components
import SubTraitCard from "./SubTraitCard";

// Services
import EventService from "../../../../services/EventService/event.service";

function SubTraitCarousel({
  carouselRef,
  traitScore,
  narratives,
  dynamicNarratives,
}: any) {
  const responsive = {
    // desktop: {
    //   breakpoint: { max: 3000, min: 1024 },
    //   items: 3,
    //   slidesToSlide: 3, // optional, default to 1.
    //   partialVisibilityGutter: 20,
    // },
    // tablet: {
    //   breakpoint: { max: 1024, min: 464 },
    //   items: 2,
    //   slidesToSlide: 2, // optional, default to 1.
    //   partialVisibilityGutter: 20,
    // },
    mobile: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
  };

  const { trait } = useParams();

  const traitScoreCleaned = traitScore.data.filter(
    (subtraitData: any, index: number) => {
      return subtraitData.value >= 0;
    },
  );

  useEffect(() => {
    const carouselContainer = document.getElementsByClassName(
      "react-multi-carousel-track",
    )[0];

    // handle card indentation when 3 or less cards
    if (traitScoreCleaned.length < 4) {
      carouselContainer.classList.add("short-carousel");
    } else {
      carouselContainer.classList.add("long-carousel");
    }
  }, []);

  const renderCards = () => {
    return traitScoreCleaned.map((subtraitData: any, index: number) => {
      let dynamicNarrative = [];

      if (
        dynamicNarratives !== null &&
        dynamicNarratives !== "" &&
        dynamicNarratives.length > 0
      )
        dynamicNarrative = dynamicNarratives.filter(
          (dN: any) => dN.dimensionId === subtraitData.number,
        );

      let narrative = narratives.filter(
        (narrative: any) => narrative.dimensionId === subtraitData.number,
      )[0];

      if (dynamicNarrative.length > 0) {
        narrative = {
          ...narrative,
          improvementNarrative: dynamicNarrative[0].improvementNarrative,
        };
      }

      return (
        <SubTraitCard
          narrative={narrative}
          subtrait={subtraitData.dimension}
          userScore={subtraitData}
          key={index}
        />
      );
    });
  };

  const handleChange = (next: any, current: any) => {
    EventService.track({
      event_type: "user",
      event_name: "insight_trait_description_show",
      user_type: "student",
      event_data: {
        category: trait?.toLowerCase(),
        index: next,
      },
    });
  };

  return (
    <Carousel
      ref={(el) => (carouselRef.current = el)}
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      autoPlay={false}
      keyBoardControl={true}
      transitionDuration={500}
      partialVisible={true}
      containerClass="subtrait-carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      customDot={<CustomDot />}
      beforeChange={(nextSlide, { currentSlide }) =>
        handleChange(nextSlide, currentSlide)
      }
    >
      {renderCards()}
    </Carousel>
  );
}

const CustomDot = ({ index, onClick, active }: any) => {
  return (
    <button
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      className={`custom-dot`}
    >
      {active ? <CardSelectedDot /> : <CardDot />}
    </button>
  );
};

export default SubTraitCarousel;

// Routing
import { Route, Routes } from "react-router-dom";

// Pages
// import MyAccount from "./my-account";
import MyAccount from "../../reskin/pages/Profile/MyAccount/MyAccount";
import MyFavorites from "../../reskin/pages/My-Favorites/MyFavorites";
import MainAppHeader from "../../reskin/components/Header/MainAppHeader/MainAppHeader";
import PrivacyPolicy from "../../reskin/pages/Profile/PrivacyPolicy/PrivacyPolicy";
import Copyright from "../../reskin/pages/Profile/Copyright/Copyright";
import TermsAndConditions from "../../reskin/pages/Profile/TermsAndConditions/TermsAndConditions";

function Profile() {
  return (
    <>
      <Routes>
        <Route path="/my-account" element={<MyAccount />} />
        <Route
          path="/my-favorites"
          element={
            <>
              <MainAppHeader />
              <MyFavorites />
            </>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/copyright" element={<Copyright />} />
      </Routes>
    </>
  );
}

export default Profile;

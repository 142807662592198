// Components
import CircleBadge from "../Badges/CircleBadge/CircleBadge";

// Assets
import { ReactComponent as SmallClock } from "../../../assets/reskin/icons/small-clock.svg";

// Styles
import "./NumberedStepSection.scss";

interface NumberedStepSectionProps {
  stepNumber: Number | string;
  headerText: string;
  bodyText: string;
  footerText: string;
  isLast?: boolean;
}

const NumberedStepSection = ({
  stepNumber,
  headerText,
  bodyText,
  footerText,
  isLast,
}: NumberedStepSectionProps) => {
  return (
    <div className="numbered-step-section ps-2 pe-2 pb-2">
      <div className="numbering-column m-0">
        <CircleBadge label={stepNumber} />
        {isLast ? null : <div className="vertical-bar" />}
      </div>

      <div className="content-section ps-2 pt-2">
        <div>
          <h3 className="content-header m-0">{headerText}</h3>
        </div>
        <div className="m-0">
          <p className="content-body m-0">{bodyText}</p>
        </div>
        <div className="step-content-footer">
          <div className="icon-container">
            <SmallClock />
          </div>
          <div className="footer-text-container">
            <p className="m-0">{footerText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberedStepSection;

// Components
import QuestionCard from "../../../../ui-components/QuestionCard";
import NoBorderButton from "../../Buttons/NoBorderButton/NoBorderButton";
import { ReactComponent as Question } from "../../../../assets/icons/question.svg";
import "./reskin-question-card.scss";

interface ReskinQuestionCardProps {
  text: string;
  backgroundColor: string;
  onButtonClick: Function;
}

const ReskinQuestionCard = ({
  text,
  backgroundColor,
  onButtonClick,
}: ReskinQuestionCardProps) => {
  const RenderedButton = (
    <NoBorderButton text="Respond" onClick={onButtonClick} />
  );
  return (
    <div className="reskin-question-card" style={{backgroundColor: backgroundColor}}>
      <div className="reskin-question-card-header">
        <Question /> <span>Question</span>
      </div>
      <div className="reskin-question-card-body">
        <div className="reskin-question-card-text">{text}</div>
      </div>
      <div className="reskin-question-card-footer">{RenderedButton}</div>
    </div>
  );
};

export default ReskinQuestionCard;

// Packages
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { updateUserAssessmentState } from "../../redux/actions";

// Models
import { AssessmentStatusType } from "../../models";

// Components
import CongratulationsPage from "../../ui-components/CongratulationsPage";
import ReskinButton from "./Buttons/ReskinButton/ReskinButton";
import ReskinNavBar from "../components/NavBars/ReskinNavBar";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./Header/AssessmentHeader/AssessmentHeader";

// Assets
import { ReactComponent as ParamountIcon } from "../../assets/reskin/icons/paramount.svg";
import { ReactComponent as NavProfileCircle } from "../../assets/reskin/icons/nav-person-circle.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/reskin/icons/check-circle.svg";

// Styles
import "./CongratulationsPage.scss";
import { RootState } from "../../redux/reducers";

const CongratulationsPageAdjusted = () => {
  const { user } = useSelector((state: RootState) => state);
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = useSelector((state: RootState) => state.user);

  const handleAssessmentCompletion = () => {
    dispatch(
      updateUserAssessmentState({
        userId,
        UserAssessmentStateId,
        statusType: AssessmentStatusType.ASSESSMENT_COMPLETE,
      }),
    );
  };

  return (
    <>
      <AssessmentHeader isTransparent={false} />
      <div className="congratulations-page">
        {/* <ReskinNavBar rightIcon={<NavProfileCircle />} /> */}
        {/* <ReskinNavBar /> */}
        <div className="icon-container">
          <ParamountIcon className="paramount-icon" />
        </div>
        <div className="messages-container">
          <h3 className="congratulations-message">
            <p>{`Way to go, ${user.name}!`}</p>
          </h3>
          <p className="you-completed">You've completed</p>
          <p className="section-name">Employability Skills</p>
        </div>

        <div className="icon-container check-circle">
          <CheckCircleIcon />
        </div>

        <div className="button-container">
          <ReskinButton
            text="View Insights Report"
            onClick={() => {
              handleAssessmentCompletion();
              navigate("/report/summary");
            }}
            customClasses="next-nav-button"
          />
        </div>
      </div>
    </>
  );
};

export default CongratulationsPageAdjusted;

import React from "react";
import { ReactComponent as DarkArrowDown } from "../../../../../../../assets/icons/dark-caret-down.svg";
import { ReactComponent as DarkArrowUp } from "../../../../../../../assets/icons/dark-caret-up.svg";

interface ReflectionPromptProps {
  header: string;
  prompt: string;
  handleChange: Function;
}

const ReflectionPromptMobile = ({
  header,
  prompt,
  handleChange,
}: ReflectionPromptProps) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const collapsibleContentClass = isOpen ? "" : "closed";
  const RenderedIcon = isOpen ? <DarkArrowUp /> : <DarkArrowDown />;

  return (
    <div className="collapsible-resource-reaction-prompt d-flex flex-column">
      <button
        className="prompt-header collapsible-toggler d-flex justify-content-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          <h3>{header}</h3>
        </span>
        <span>{RenderedIcon}</span>
      </button>
      <section className={`prompt-body flex-grow-1 ${collapsibleContentClass}`}>
        <div className="prompt-text">
          <p>{prompt}</p>
        </div>
        <div className="response-container">
          <textarea
            className="response"
            placeholder="Type Here"
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </section>
    </div>
  );
};

export default ReflectionPromptMobile;

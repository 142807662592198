/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchStudentRequests = /* GraphQL */ `
  query SearchStudentRequests(
    $filter: SearchableStudentRequestFilterInput
    $sort: [SearchableStudentRequestSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStudentRequestAggregationInput]
  ) {
    searchStudentRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchInstitutionResponses = /* GraphQL */ `
  query SearchInstitutionResponses(
    $filter: SearchableInstitutionResponseFilterInput
    $sort: [SearchableInstitutionResponseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInstitutionResponseAggregationInput]
  ) {
    searchInstitutionResponses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        studentUserId
        studentUserSessionId
        studentRequestId
        studentRequest {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        traitName
        response
        responseDate
        institutionUserId
        institutionUser {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        responseRead
        resourceInfo {
          traitName
          description
          resourceLinks
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchLearningSelfReflections = /* GraphQL */ `
  query SearchLearningSelfReflections(
    $filter: SearchableLearningSelfReflectionFilterInput
    $sort: [SearchableLearningSelfReflectionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableLearningSelfReflectionAggregationInput]
  ) {
    searchLearningSelfReflections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        takeawayComment
        applicationComment
        commentDate
        institution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getStudentRequest = /* GraphQL */ `
  query GetStudentRequest($id: ID!) {
    getStudentRequest(id: $id) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudentRequests = /* GraphQL */ `
  query ListStudentRequests(
    $filter: ModelStudentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentRequests = /* GraphQL */ `
  query SyncStudentRequests(
    $filter: ModelStudentRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentResource = /* GraphQL */ `
  query GetStudentResource($id: ID!) {
    getStudentResource(id: $id) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudentResources = /* GraphQL */ `
  query ListStudentResources(
    $filter: ModelStudentResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        trait
        resourceLink
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentResources = /* GraphQL */ `
  query SyncStudentResources(
    $filter: ModelStudentResourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        trait
        resourceLink
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInstitutionResponse = /* GraphQL */ `
  query GetInstitutionResponse($id: ID!) {
    getInstitutionResponse(id: $id) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInstitutionResponses = /* GraphQL */ `
  query ListInstitutionResponses(
    $filter: ModelInstitutionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstitutionResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentUserId
        studentUserSessionId
        studentRequestId
        studentRequest {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        traitName
        response
        responseDate
        institutionUserId
        institutionUser {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        responseRead
        resourceInfo {
          traitName
          description
          resourceLinks
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInstitutionResponses = /* GraphQL */ `
  query SyncInstitutionResponses(
    $filter: ModelInstitutionResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstitutionResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        studentUserId
        studentUserSessionId
        studentRequestId
        studentRequest {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        traitName
        response
        responseDate
        institutionUserId
        institutionUser {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        responseRead
        resourceInfo {
          traitName
          description
          resourceLinks
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInstitutionUser = /* GraphQL */ `
  query GetInstitutionUser($emailId: String!) {
    getInstitutionUser(emailId: $emailId) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInstitutionUsers = /* GraphQL */ `
  query ListInstitutionUsers(
    $emailId: String
    $filter: ModelInstitutionUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstitutionUsers(
      emailId: $emailId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        emailId
        institution
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInstitutionUsers = /* GraphQL */ `
  query SyncInstitutionUsers(
    $filter: ModelInstitutionUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstitutionUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        emailId
        institution
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLearningResource = /* GraphQL */ `
  query GetLearningResource($id: ID!) {
    getLearningResource(id: $id) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLearningResources = /* GraphQL */ `
  query ListLearningResources(
    $filter: ModelLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLearningResources = /* GraphQL */ `
  query SyncLearningResources(
    $filter: ModelLearningResourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentRecommendedResources = /* GraphQL */ `
  query GetStudentRecommendedResources($id: ID!) {
    getStudentRecommendedResources(id: $id) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudentRecommendedResources = /* GraphQL */ `
  query ListStudentRecommendedResources(
    $filter: ModelStudentRecommendedResourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentRecommendedResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        sequence
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentRecommendedResources = /* GraphQL */ `
  query SyncStudentRecommendedResources(
    $filter: ModelStudentRecommendedResourcesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentRecommendedResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        sequence
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getResourceReaction = /* GraphQL */ `
  query GetResourceReaction($id: ID!) {
    getResourceReaction(id: $id) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listResourceReactions = /* GraphQL */ `
  query ListResourceReactions(
    $filter: ModelResourceReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceReactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        preference
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncResourceReactions = /* GraphQL */ `
  query SyncResourceReactions(
    $filter: ModelResourceReactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResourceReactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        preference
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserConfidenceLevel = /* GraphQL */ `
  query GetUserConfidenceLevel($id: ID!) {
    getUserConfidenceLevel(id: $id) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserConfidenceLevels = /* GraphQL */ `
  query ListUserConfidenceLevels(
    $filter: ModelUserConfidenceLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConfidenceLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        trait
        phase
        confidenceLevel
        selectedDate
        selectedTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserConfidenceLevels = /* GraphQL */ `
  query SyncUserConfidenceLevels(
    $filter: ModelUserConfidenceLevelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserConfidenceLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        trait
        phase
        confidenceLevel
        selectedDate
        selectedTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentVisitedLearningResource = /* GraphQL */ `
  query GetStudentVisitedLearningResource($id: ID!) {
    getStudentVisitedLearningResource(id: $id) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudentVisitedLearningResources = /* GraphQL */ `
  query ListStudentVisitedLearningResources(
    $filter: ModelStudentVisitedLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentVisitedLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentVisitedLearningResources = /* GraphQL */ `
  query SyncStudentVisitedLearningResources(
    $filter: ModelStudentVisitedLearningResourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentVisitedLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentFavouriteLearningResource = /* GraphQL */ `
  query GetStudentFavouriteLearningResource($id: ID!) {
    getStudentFavouriteLearningResource(id: $id) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudentFavouriteLearningResources = /* GraphQL */ `
  query ListStudentFavouriteLearningResources(
    $filter: ModelStudentFavouriteLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentFavouriteLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        preference
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentFavouriteLearningResources = /* GraphQL */ `
  query SyncStudentFavouriteLearningResources(
    $filter: ModelStudentFavouriteLearningResourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentFavouriteLearningResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        preference
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLearningSelfReflection = /* GraphQL */ `
  query GetLearningSelfReflection($id: ID!) {
    getLearningSelfReflection(id: $id) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLearningSelfReflections = /* GraphQL */ `
  query ListLearningSelfReflections(
    $filter: ModelLearningSelfReflectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningSelfReflections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        takeawayComment
        applicationComment
        commentDate
        institution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLearningSelfReflections = /* GraphQL */ `
  query SyncLearningSelfReflections(
    $filter: ModelLearningSelfReflectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearningSelfReflections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        takeawayComment
        applicationComment
        commentDate
        institution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLearningSelfReflectionCount = /* GraphQL */ `
  query GetLearningSelfReflectionCount($id: ID!) {
    getLearningSelfReflectionCount(id: $id) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLearningSelfReflectionCounts = /* GraphQL */ `
  query ListLearningSelfReflectionCounts(
    $filter: ModelLearningSelfReflectionCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningSelfReflectionCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        selfReflectionCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLearningSelfReflectionCounts = /* GraphQL */ `
  query SyncLearningSelfReflectionCounts(
    $filter: ModelLearningSelfReflectionCountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearningSelfReflectionCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        selfReflectionCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const studentRequestsByUserAssessmentIdAndRequestDate = /* GraphQL */ `
  query StudentRequestsByUserAssessmentIdAndRequestDate(
    $userAssessmentId: ID!
    $requestDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRequestsByUserAssessmentIdAndRequestDate(
      userAssessmentId: $userAssessmentId
      requestDate: $requestDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const institutionResponsesByStudentRequestIdAndResponseDate = /* GraphQL */ `
  query InstitutionResponsesByStudentRequestIdAndResponseDate(
    $studentRequestId: ID!
    $responseDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInstitutionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    institutionResponsesByStudentRequestIdAndResponseDate(
      studentRequestId: $studentRequestId
      responseDate: $responseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentUserId
        studentUserSessionId
        studentRequestId
        studentRequest {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        traitName
        response
        responseDate
        institutionUserId
        institutionUser {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        responseRead
        resourceInfo {
          traitName
          description
          resourceLinks
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const institutionResponsesByInstitutionUserIdAndResponseDate = /* GraphQL */ `
  query InstitutionResponsesByInstitutionUserIdAndResponseDate(
    $institutionUserId: String!
    $responseDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInstitutionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    institutionResponsesByInstitutionUserIdAndResponseDate(
      institutionUserId: $institutionUserId
      responseDate: $responseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentUserId
        studentUserSessionId
        studentRequestId
        studentRequest {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        traitName
        response
        responseDate
        institutionUserId
        institutionUser {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        responseRead
        resourceInfo {
          traitName
          description
          resourceLinks
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const learingResourcesByTraitAndTitle = /* GraphQL */ `
  query LearingResourcesByTraitAndTitle(
    $trait: String!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learingResourcesByTraitAndTitle(
      trait: $trait
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recommendedResourcesByUserAndResourceTitle = /* GraphQL */ `
  query RecommendedResourcesByUserAndResourceTitle(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecommendedResourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendedResourcesByUserAndResourceTitle(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        sequence
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recommendedByResourceIdandUserId = /* GraphQL */ `
  query RecommendedByResourceIdandUserId(
    $learningResourceId: ID!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecommendedResourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RecommendedByResourceIdandUserId(
      learningResourceId: $learningResourceId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        sequence
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const resourceReactionsByUser = /* GraphQL */ `
  query ResourceReactionsByUser(
    $userId: String!
    $learningResourceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceReactionsByUser(
      userId: $userId
      learningResourceId: $learningResourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        preference
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const resourceReactionsByIdandUserId = /* GraphQL */ `
  query ResourceReactionsByIdandUserId(
    $learningResourceId: ID!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ResourceReactionsByIdandUserId(
      learningResourceId: $learningResourceId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        preference
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const confidenceLevelByUserIdTraitPhaseAndSelectionDate = /* GraphQL */ `
  query ConfidenceLevelByUserIdTraitPhaseAndSelectionDate(
    $userId: String!
    $traitPhaseSelectedDateSelectedTime: ModelUserConfidenceLevelByUserIdTraitPhaseAndSelectedDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserConfidenceLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    confidenceLevelByUserIdTraitPhaseAndSelectionDate(
      userId: $userId
      traitPhaseSelectedDateSelectedTime: $traitPhaseSelectedDateSelectedTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        trait
        phase
        confidenceLevel
        selectedDate
        selectedTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const visitedResourcesByUserAndResourceTitle = /* GraphQL */ `
  query VisitedResourcesByUserAndResourceTitle(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentVisitedLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visitedResourcesByUserAndResourceTitle(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const visitedByResourceIdandUserId = /* GraphQL */ `
  query VisitedByResourceIdandUserId(
    $learningResourceId: ID!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentVisitedLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    VisitedByResourceIdandUserId(
      learningResourceId: $learningResourceId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const favouriteResourcesByUserAndResourceId = /* GraphQL */ `
  query FavouriteResourcesByUserAndResourceId(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFavouriteLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favouriteResourcesByUserAndResourceId(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        preference
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const favoritedByResourceIdandUserId = /* GraphQL */ `
  query FavoritedByResourceIdandUserId(
    $learningResourceId: ID!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFavouriteLearningResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    FavoritedByResourceIdandUserId(
      learningResourceId: $learningResourceId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userSessionId
        trait
        preference
        learningResourceId
        learningResource {
          id
          title
          trait
          resourceLink
          imageLocation
          duration
          type
          credit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfReflectionsByUserAndCommentDate = /* GraphQL */ `
  query SelfReflectionsByUserAndCommentDate(
    $userId: String!
    $commentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLearningSelfReflectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfReflectionsByUserAndCommentDate(
      userId: $userId
      commentDate: $commentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        takeawayComment
        applicationComment
        commentDate
        institution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfReflectionCountByUserId = /* GraphQL */ `
  query SelfReflectionCountByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLearningSelfReflectionCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfReflectionCountByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        selfReflectionCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserResponses = /* GraphQL */ `
  query GetUserResponses($id: ID!) {
    getUserResponses(id: $id) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const listUserResponses = /* GraphQL */ `
  query ListUserResponses(
    $filter: ModelUserResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserResponses = /* GraphQL */ `
  query SyncUserResponses(
    $filter: ModelUserResponsesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userResponsesByUserSessionId = /* GraphQL */ `
  query UserResponsesByUserSessionId(
    $userSessionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userResponsesByUserSessionId(
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userResponsesByUserIdAndUserSessionId = /* GraphQL */ `
  query UserResponsesByUserIdAndUserSessionId(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userResponsesByUserIdAndUserSessionId(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userResponsesByVideoResponseLocation = /* GraphQL */ `
  query UserResponsesByVideoResponseLocation(
    $videoResponseLocation: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userResponsesByVideoResponseLocation(
      videoResponseLocation: $videoResponseLocation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchUserResponses = /* GraphQL */ `
  query SearchUserResponses(
    $filter: SearchableUserResponsesFilterInput
    $sort: [SearchableUserResponsesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserResponsesAggregationInput]
  ) {
    searchUserResponses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getBIQ = /* GraphQL */ `
  query GetBIQ($id: ID!) {
    getBIQ(id: $id) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBIQS = /* GraphQL */ `
  query ListBIQS(
    $filter: ModelBIQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBIQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        content
        profileAttribute
        displayType
        options
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBIQS = /* GraphQL */ `
  query SyncBIQS(
    $filter: ModelBIQFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBIQS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sequence
        content
        profileAttribute
        displayType
        options
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userId: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const usersByInstitutionIdAndUserTypeAndUserId = /* GraphQL */ `
  query UsersByInstitutionIdAndUserTypeAndUserId(
    $institutionId: ID!
    $userTypeUserId: ModelUserUsersByInstitutionAndTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByInstitutionIdAndUserTypeAndUserId(
      institutionId: $institutionId
      userTypeUserId: $userTypeUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncItems = /* GraphQL */ `
  query SyncItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSections = /* GraphQL */ `
  query SyncSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSectionItem = /* GraphQL */ `
  query GetSectionItem($id: ID!) {
    getSectionItem(id: $id) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const listSectionItems = /* GraphQL */ `
  query ListSectionItems(
    $filter: ModelSectionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSectionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sectionItemItemId
        sectionItemSectionId
        section {
          id
          name
          description
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        item {
          id
          itemGuid
          accnum
          version
          type
          content
          dimension
          dimensionDesc
          name
          alphaStatValue
          dltaStatValue
          tauStatValue
          sclStatValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        itemSequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        itemSectionsId
        sectionItemsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSectionItems = /* GraphQL */ `
  query SyncSectionItems(
    $filter: ModelSectionItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSectionItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sectionItemItemId
        sectionItemSectionId
        section {
          id
          name
          description
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        item {
          id
          itemGuid
          accnum
          version
          type
          content
          dimension
          dimensionDesc
          name
          alphaStatValue
          dltaStatValue
          tauStatValue
          sclStatValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        itemSequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        itemSectionsId
        sectionItemsId
      }
      nextToken
      startedAt
    }
  }
`;
export const sectionItemsBySectionItemSectionIdAndItemSequence = /* GraphQL */ `
  query SectionItemsBySectionItemSectionIdAndItemSequence(
    $sectionItemSectionId: ID!
    $itemSequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSectionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionItemsBySectionItemSectionIdAndItemSequence(
      sectionItemSectionId: $sectionItemSectionId
      itemSequence: $itemSequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sectionItemItemId
        sectionItemSectionId
        section {
          id
          name
          description
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        item {
          id
          itemGuid
          accnum
          version
          type
          content
          dimension
          dimensionDesc
          name
          alphaStatValue
          dltaStatValue
          tauStatValue
          sclStatValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        itemSequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        itemSectionsId
        sectionItemsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAssessments = /* GraphQL */ `
  query SyncAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAssessments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAssessmentSection = /* GraphQL */ `
  query GetAssessmentSection($id: ID!) {
    getAssessmentSection(id: $id) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const listAssessmentSections = /* GraphQL */ `
  query ListAssessmentSections(
    $filter: ModelAssessmentSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentSectionAssessmentId
        assessmentSectionSectionId
        assessment {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        section {
          id
          name
          description
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sectionSequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sectionAssessmentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAssessmentSections = /* GraphQL */ `
  query SyncAssessmentSections(
    $filter: ModelAssessmentSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAssessmentSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        assessmentSectionAssessmentId
        assessmentSectionSectionId
        assessment {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        section {
          id
          name
          description
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sectionSequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sectionAssessmentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const assessmentSectionsByAssessmentSectionAssessmentIdAndSectionSequence = /* GraphQL */ `
  query AssessmentSectionsByAssessmentSectionAssessmentIdAndSectionSequence(
    $assessmentSectionAssessmentId: ID!
    $sectionSequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assessmentSectionsByAssessmentSectionAssessmentIdAndSectionSequence(
      assessmentSectionAssessmentId: $assessmentSectionAssessmentId
      sectionSequence: $sectionSequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentSectionAssessmentId
        assessmentSectionSectionId
        assessment {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        section {
          id
          name
          description
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sectionSequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sectionAssessmentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getInstitution = /* GraphQL */ `
  query GetInstitution($id: ID!) {
    getInstitution(id: $id) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInstitutions = /* GraphQL */ `
  query ListInstitutions(
    $filter: ModelInstitutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstitutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInstitutions = /* GraphQL */ `
  query SyncInstitutions(
    $filter: ModelInstitutionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstitutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserAssessmentState = /* GraphQL */ `
  query GetUserAssessmentState($id: ID!) {
    getUserAssessmentState(id: $id) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const listUserAssessmentStates = /* GraphQL */ `
  query ListUserAssessmentStates(
    $filter: ModelUserAssessmentStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserAssessmentStates = /* GraphQL */ `
  query SyncUserAssessmentStates(
    $filter: ModelUserAssessmentStateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserAssessmentStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAssessmentStatesByUserSessionId = /* GraphQL */ `
  query UserAssessmentStatesByUserSessionId(
    $userSessionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAssessmentStatesByUserSessionId(
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAssessmentStatesByUserIdAndAssessmentStartDate = /* GraphQL */ `
  query UserAssessmentStatesByUserIdAndAssessmentStartDate(
    $userId: String!
    $assessmentStartDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAssessmentStatesByUserIdAndAssessmentStartDate(
      userId: $userId
      assessmentStartDate: $assessmentStartDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAssessmentStatesByUserAssessmentStateUserIdAndUserSessionId = /* GraphQL */ `
  query UserAssessmentStatesByUserAssessmentStateUserIdAndUserSessionId(
    $userAssessmentStateUserId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAssessmentStatesByUserAssessmentStateUserIdAndUserSessionId(
      userAssessmentStateUserId: $userAssessmentStateUserId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchUserAssessmentStates = /* GraphQL */ `
  query SearchUserAssessmentStates(
    $filter: SearchableUserAssessmentStateFilterInput
    $sort: [SearchableUserAssessmentStateSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAssessmentStateAggregationInput]
  ) {
    searchUserAssessmentStates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUserScores = /* GraphQL */ `
  query GetUserScores($id: ID!) {
    getUserScores(id: $id) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const listUserScores = /* GraphQL */ `
  query ListUserScores(
    $filter: ModelUserScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserScores = /* GraphQL */ `
  query SyncUserScores(
    $filter: ModelUserScoresFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserScores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userScoresByUserSessionId = /* GraphQL */ `
  query UserScoresByUserSessionId(
    $userSessionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userScoresByUserSessionId(
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userScoresByUserIdAndUserSessionId = /* GraphQL */ `
  query UserScoresByUserIdAndUserSessionId(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userScoresByUserIdAndUserSessionId(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchUserScores = /* GraphQL */ `
  query SearchUserScores(
    $filter: SearchableUserScoresFilterInput
    $sort: [SearchableUserScoresSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserScoresAggregationInput]
  ) {
    searchUserScores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($adminId: String!) {
    getAdmin(adminId: $adminId) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $adminId: String
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdmins(
      adminId: $adminId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        adminId
        startDate
        endDate
        assessmentId {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdmins = /* GraphQL */ `
  query SyncAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        adminId
        startDate
        endDate
        assessmentId {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCutOffScores = /* GraphQL */ `
  query GetCutOffScores($id: ID!) {
    getCutOffScores(id: $id) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCutOffScores = /* GraphQL */ `
  query ListCutOffScores(
    $filter: ModelCutOffScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCutOffScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminId
        dimensionId
        cutScore1
        cutScore2
        cutScore3
        cutScore4
        cutScore5
        cutScore6
        cutScore7
        cutScore8
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCutOffScores = /* GraphQL */ `
  query SyncCutOffScores(
    $filter: ModelCutOffScoresFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCutOffScores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        adminId
        dimensionId
        cutScore1
        cutScore2
        cutScore3
        cutScore4
        cutScore5
        cutScore6
        cutScore7
        cutScore8
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const cutOffScoresByAdminIdAndDimensionId = /* GraphQL */ `
  query CutOffScoresByAdminIdAndDimensionId(
    $adminId: String!
    $dimensionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCutOffScoresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cutOffScoresByAdminIdAndDimensionId(
      adminId: $adminId
      dimensionId: $dimensionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminId
        dimensionId
        cutScore1
        cutScore2
        cutScore3
        cutScore4
        cutScore5
        cutScore6
        cutScore7
        cutScore8
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvents = /* GraphQL */ `
  query GetEvents($eventId: String!) {
    getEvents(eventId: $eventId) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $eventId: String
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      eventId: $eventId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        eventType
        eventName
        eventDate
        eventData
        userSessionId
        userId
        userType
        assessmentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventId
        eventType
        eventName
        eventDate
        eventData
        userSessionId
        userId
        userType
        assessmentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const eventsByUserSessionIdAndEventDate = /* GraphQL */ `
  query EventsByUserSessionIdAndEventDate(
    $userSessionId: String!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByUserSessionIdAndEventDate(
      userSessionId: $userSessionId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventType
        eventName
        eventDate
        eventData
        userSessionId
        userId
        userType
        assessmentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getNarratives = /* GraphQL */ `
  query GetNarratives($dimensionId: String!) {
    getNarratives(dimensionId: $dimensionId) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNarratives = /* GraphQL */ `
  query ListNarratives(
    $dimensionId: String
    $filter: ModelNarrativesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNarratives(
      dimensionId: $dimensionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dimensionId
        definition
        highScoreNarrative
        mediumScoreNarrative
        lowScoreNarrative
        improvementNarrative
        improvementDynamicNarrative
        developingTips
        demonstratingTips
        approachingTips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNarratives = /* GraphQL */ `
  query SyncNarratives(
    $filter: ModelNarrativesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNarratives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dimensionId
        definition
        highScoreNarrative
        mediumScoreNarrative
        lowScoreNarrative
        improvementNarrative
        improvementDynamicNarrative
        developingTips
        demonstratingTips
        approachingTips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInstitutionNarratives = /* GraphQL */ `
  query GetInstitutionNarratives($highLevelTrait: HIGHLEVELTRAITTYPE!) {
    getInstitutionNarratives(highLevelTrait: $highLevelTrait) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInstitutionNarratives = /* GraphQL */ `
  query ListInstitutionNarratives(
    $highLevelTrait: HIGHLEVELTRAITTYPE
    $filter: ModelInstitutionNarrativesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstitutionNarratives(
      highLevelTrait: $highLevelTrait
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        highLevelTrait
        demonstratingNarrative
        developingNarrative
        approachingNarrative
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInstitutionNarratives = /* GraphQL */ `
  query SyncInstitutionNarratives(
    $filter: ModelInstitutionNarrativesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstitutionNarratives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        highLevelTrait
        demonstratingNarrative
        developingNarrative
        approachingNarrative
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventTracking = /* GraphQL */ `
  query GetEventTracking($id: ID!) {
    getEventTracking(id: $id) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEventTrackings = /* GraphQL */ `
  query ListEventTrackings(
    $filter: ModelEventTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventTrackings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userSessionId
        assessmentId
        eventName
        eventStatus
        errorMessage
        eventData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEventTrackings = /* GraphQL */ `
  query SyncEventTrackings(
    $filter: ModelEventTrackingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventTrackings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        eventName
        eventStatus
        errorMessage
        eventData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const eventTrackingsByUserIdAndUserSessionId = /* GraphQL */ `
  query EventTrackingsByUserIdAndUserSessionId(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventTrackingsByUserIdAndUserSessionId(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        eventName
        eventStatus
        errorMessage
        eventData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const eventTrackingsByUserSessionIdAndEventNameAndEventStatus = /* GraphQL */ `
  query EventTrackingsByUserSessionIdAndEventNameAndEventStatus(
    $userSessionId: String!
    $eventNameEventStatus: ModelEventTrackingEventTrackingByUserSesionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventTrackingsByUserSessionIdAndEventNameAndEventStatus(
      userSessionId: $userSessionId
      eventNameEventStatus: $eventNameEventStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        eventName
        eventStatus
        errorMessage
        eventData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchEventTrackings = /* GraphQL */ `
  query SearchEventTrackings(
    $filter: SearchableEventTrackingFilterInput
    $sort: [SearchableEventTrackingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventTrackingAggregationInput]
  ) {
    searchEventTrackings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        eventName
        eventStatus
        errorMessage
        eventData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getQuickTips = /* GraphQL */ `
  query GetQuickTips($id: ID!) {
    getQuickTips(id: $id) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQuickTips = /* GraphQL */ `
  query ListQuickTips(
    $filter: ModelQuickTipsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuickTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuickTips = /* GraphQL */ `
  query SyncQuickTips(
    $filter: ModelQuickTipsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuickTips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const quickTipsByUserIdAndUserSessionId = /* GraphQL */ `
  query QuickTipsByUserIdAndUserSessionId(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuickTipsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quickTipsByUserIdAndUserSessionId(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCandidateComments = /* GraphQL */ `
  query GetCandidateComments($id: ID!) {
    getCandidateComments(id: $id) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCandidateComments = /* GraphQL */ `
  query ListCandidateComments(
    $filter: ModelCandidateCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCandidateComments = /* GraphQL */ `
  query SyncCandidateComments(
    $filter: ModelCandidateCommentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCandidateComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const candidateCommentsByUserIdAndUserSessionId = /* GraphQL */ `
  query CandidateCommentsByUserIdAndUserSessionId(
    $userId: String!
    $userSessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    candidateCommentsByUserIdAndUserSessionId(
      userId: $userId
      userSessionId: $userSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchCandidateComments = /* GraphQL */ `
  query SearchCandidateComments(
    $filter: SearchableCandidateCommentsFilterInput
    $sort: [SearchableCandidateCommentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCandidateCommentsAggregationInput]
  ) {
    searchCandidateComments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHeiComments = /* GraphQL */ `
  query GetHeiComments($id: ID!) {
    getHeiComments(id: $id) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const listHeiComments = /* GraphQL */ `
  query ListHeiComments(
    $filter: ModelHeiCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeiComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        username
        userSessionId
        assessmentId
        candidateUserId
        candidateUserSessionId
        userAssessmentId
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        comment
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateHeiCommentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHeiComments = /* GraphQL */ `
  query SyncHeiComments(
    $filter: ModelHeiCommentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHeiComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        username
        userSessionId
        assessmentId
        candidateUserId
        candidateUserSessionId
        userAssessmentId
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        comment
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateHeiCommentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const heiCommentsByUserIdAndCandidateUserId = /* GraphQL */ `
  query HeiCommentsByUserIdAndCandidateUserId(
    $userId: String!
    $candidateUserId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHeiCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    heiCommentsByUserIdAndCandidateUserId(
      userId: $userId
      candidateUserId: $candidateUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        username
        userSessionId
        assessmentId
        candidateUserId
        candidateUserSessionId
        userAssessmentId
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        comment
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateHeiCommentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const heiCommentsByUserAssessmentIdAndUserId = /* GraphQL */ `
  query HeiCommentsByUserAssessmentIdAndUserId(
    $userAssessmentId: ID!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHeiCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    heiCommentsByUserAssessmentIdAndUserId(
      userAssessmentId: $userAssessmentId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        username
        userSessionId
        assessmentId
        candidateUserId
        candidateUserSessionId
        userAssessmentId
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        comment
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateHeiCommentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const searchHeiComments = /* GraphQL */ `
  query SearchHeiComments(
    $filter: SearchableHeiCommentsFilterInput
    $sort: [SearchableHeiCommentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHeiCommentsAggregationInput]
  ) {
    searchHeiComments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        username
        userSessionId
        assessmentId
        candidateUserId
        candidateUserSessionId
        userAssessmentId
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        comment
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateHeiCommentsId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHeiBookmarks = /* GraphQL */ `
  query GetHeiBookmarks($id: ID!) {
    getHeiBookmarks(id: $id) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHeiBookmarks = /* GraphQL */ `
  query ListHeiBookmarks(
    $filter: ModelHeiBookmarksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeiBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userSessionId
        assessmentId
        candidateUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHeiBookmarks = /* GraphQL */ `
  query SyncHeiBookmarks(
    $filter: ModelHeiBookmarksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHeiBookmarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        candidateUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const heiBookmarksByUserIdAndAssessmentId = /* GraphQL */ `
  query HeiBookmarksByUserIdAndAssessmentId(
    $userId: String!
    $assessmentId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHeiBookmarksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    heiBookmarksByUserIdAndAssessmentId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSessionId
        assessmentId
        candidateUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInsights = /* GraphQL */ `
  query GetInsights($dimensionId: String!) {
    getInsights(dimensionId: $dimensionId) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInsights = /* GraphQL */ `
  query ListInsights(
    $dimensionId: String
    $filter: ModelInsightsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsights(
      dimensionId: $dimensionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dimensionId
        developingScoreInsights
        approachingScoreInsights
        demonstratingScoreInsights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInsights = /* GraphQL */ `
  query SyncInsights(
    $filter: ModelInsightsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInsights(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dimensionId
        developingScoreInsights
        approachingScoreInsights
        demonstratingScoreInsights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

// Packages
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import ResourceCard from "../../../components/Cards/ResourceCard/ResourceCard";
import { Carousel, Spinner } from "react-bootstrap";

// Redux
import { RootState } from "../../../../redux/reducers";

// Utils
import useResourceApi from "../../../../hooks/useResourceAPI";

// Styles
import "./ResourceCarousel.scss";
import { ReskinCarousel } from "../../../components/ReskinCarousel/ReskinCarousel";

interface ResourceCarouselProps {
  cards: any;
  trait: string;
  isLoading?: boolean;
}

const ResourceCarousel = ({
  cards,
  trait,
  isLoading,
}: ResourceCarouselProps) => {
  const resourceServices = useResourceApi();
  let navigate = useNavigate();

  const {
    userId,
    assessmentState: { sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);

  const handleThumbnailClick = (e: any, resource: any) => {
    // Refer to navigation logic in resourceCarousel to see required fields for resourceCards screen. -D
    if (resource.type === "Cards")
      navigate("/report/resourceCards", {
        state: {
          parentTitle: resource.title,
          cards: resource.resourceCards,
          resourceTrait: resource.trait,
          userId: userId,
          assessId: assessmentId,
          sessionId: sessionId,
          cardId: resource.learningResourceId,
          isFavorite: false,
        },
      });
  };

  const handleFavoritesClick = async (
    isFavoritedState: boolean,
    resource: any,
  ) => {
    const { learningResourceId: resourceCardId, trait: resourceCardtrait } =
      resource;
    const isSaved = isFavoritedState;
    await resourceServices.updateFavs(
      isSaved,
      resourceCardId,
      resourceCardtrait,
      userId,
      sessionId,
      assessmentId,
    );
  };

  const handleReactionClick = async (
    reactionStatus: string,
    resourceCardId: string,
    resourceCardtrait: string,
  ) => {
    await resourceServices.createOrRemoveReactions(
      reactionStatus,
      resourceCardId,
      resourceCardtrait,
      userId,
      sessionId,
      assessmentId,
    );
  };

  if (isLoading) return <Spinner animation="border" />;

  const resourceList = cards.map((card: any, index: number) => {
    const {
      learningResourceId: id,
      resourceLink,
      title,
      imageLocation,
      type,
      duration,
      isReacted,
      isFavorited,
      credit,
      learningResourceId,
    } = card;

    return (
      <Carousel.Item key={`${trait}-carousel-item-${id}`} className="carousel-resource">
        <ResourceCard
          resourceLink={resourceLink}
          imageLocation={imageLocation}
          duration={duration}
          title={title}
          type={type}
          trait={trait}
          resourceCardtrait={trait}
          resourceCardId={learningResourceId}
          credit={credit}
          reactionStatus={isReacted}
          isFavorited={isFavorited}
          isLandingPage={false}
          handleThumbnailClick={(e: any) => {
            handleThumbnailClick(e, card);
          }}
          onSaveClick={(isFavoritedState: boolean) =>
            handleFavoritesClick(isFavoritedState, card)
          }
          onReactionsClick={(
            reactionStatus: any,
            resourceCardId: any,
            resourceCardtrait: any,
          ) => {
            handleReactionClick(
              reactionStatus,
              resourceCardId,
              resourceCardtrait,
            );
          }}
        />
      </Carousel.Item>
    );
  });

  return (
    <ReskinCarousel content={resourceList} />
  );
};

export default ResourceCarousel;

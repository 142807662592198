interface VerticalTextIconProps {
  Icon: JSX.Element;
  text: string;
  buttonLabel: string;
}

const VerticalTextIcon = ({
  Icon,
  text,
  buttonLabel,
}: VerticalTextIconProps) => {
  return (
    <div className="vertical-text-icon">
      <div className="icon-button-wrapper">{Icon}</div>
      <label htmlFor={buttonLabel} className="button-icon-label">
        {text}
      </label>
    </div>
  );
};

export default VerticalTextIcon;

// Packages
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//Redux
import { RootState } from "../../../../redux/reducers";

// Components
import Subheader from "../../../../components/Subheader";
import CollapsibleCarousel from "./CollapsibleCarousel";
import IconTab from "./IconTab";
import SupportCarousel from "./Carousel/SupportCarousel";
import ModalSupportCareers from "../../../../components/Modals/SupportCareers";
import ResourceCarousel from "./ResourceCarousel/ResourceCarousel";
import HorizontalScaleRadio from "../../../../components/Radio/HorizontalScaleRadio";

// Contexts
import { ModalContext } from "../../../../contexts/context";

// Icons
import { ReactComponent as CircleCaretRight } from "../../../../assets/icons/circle-caret-right.svg";
import { ReactComponent as CircleCaretRightDisabled } from "../../../../assets/icons/circle-caret-right-disabled.svg";
import { ReactComponent as RightArrowBlue } from "../../../../assets/icons/arrow-right-blue.svg";

// Enums
import { TRAIT_SCORES, HIGHLEVELTRAITTYPE } from "../../../../enumerations";

// Services
import EventService from "../../../../services/EventService/event.service";

// Assets
import { ReactComponent as BlueDot } from "../../../../assets/dot-ocean.svg";
import { ReactComponent as BlueBorderDot } from "../../../../assets/dot-blue-border.svg";

// Utils
import { mockCarouselData } from "./utils";
import { ucWords } from "../../../../utilities";
import useResourceApi from "../../../../hooks/useResourceAPI";

import { getOrList } from "../../../../components/dynamicqueries";
import { listLearningResources } from "../../../../graphql/queries";

const SingleTraitSupport = ({
  userScores,
  narratives,
  userSupportRequests,
  HEISupportResponses,
}: any) => {
  const {
    userId,
    assessmentState: { sessionId, assessmentId },
  } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const resourceServices = useResourceApi();
  const { trait = "leadership" } = useParams();
  const { showModal } = React.useContext(ModalContext);

  const [traitSupportRequests, setTraitSupportRequests] = React.useState<any>(
    [],
  );

  const [hasHEISupport, setHEISupport] = React.useState<any[]>([]);
  const [areResourceCardsLoading, setAreResourceCardsLoading] =
    React.useState(true);
  const [resourceCardsData, setResourceCardsData] = React.useState<any>();
  const [defaultValue, setDefaultValue] = React.useState<number>(0);
  const category = HIGHLEVELTRAITTYPE[trait as keyof typeof HIGHLEVELTRAITTYPE];
  const phase = "POSTTEST";
  const traitScore = userScores.filter((userScore: any) => {
    return userScore.trait.toLowerCase() === trait;
  })[0];

  const handleConfidenceScaleActions = async (segmentValue: any) => {
    await resourceServices.createConfidenceScaleInput(
      segmentValue,
      category,
      phase,
      userId,
    );
    EventService.track({
      event_type: "user",
      event_name: "user_rate_confidence",
      user_type: "student",
      event_data: {
        category: category,
        rating: segmentValue,
        screen: "Resource Hub - " + category,
      },
    });
  };

  const handleTipsEventLogging = (isCollapsibleOpen: boolean) => {
    EventService.track({
      event_type: "user",
      event_name: "support_category_open_tips",
      user_type: "student",
      event_data: {
        category: trait,
        isOpen: !isCollapsibleOpen,
      },
    });
  };

  React.useEffect(() => {
    EventService.page({
      page: "Resource Hub - " + category,
      user_type: "student",
    });

    getLearningResources();

    return () => {
      EventService.page({
        type: "exit",
        page: "Resource Hub - " + category,
        user_type: "student",
      });
    };
  }, []);

  React.useEffect(() => {
    const getUserConfidenceScale = async () => {
      const confidenceLevels = await resourceServices.listConfidenceScale(
        category,
        phase,
        userId,
      );
      if (
        confidenceLevels.data.confidenceLevelByUserIdTraitPhaseAndSelectionDate
          .items.length > 0
      ) {
        setDefaultValue(
          confidenceLevels.data
            .confidenceLevelByUserIdTraitPhaseAndSelectionDate.items[0]
            .confidenceLevel,
        );
      }
    };
    getUserConfidenceScale();
  }, [trait]);

  React.useEffect(() => {

    EventService.track({
      event_type: "user",
      event_name: "support_category_start",
      user_type: "student",
      event_data: {
        category: trait,
      },
    });

    return () => {
      EventService.track({
        event_type: "user",
        event_name: "support_category_end",
        user_type: "student",
        event_data: {
          category: trait,
        },
      });
    };
  }, []);

  React.useEffect(() => {
    const filteredTraitSupportRequests = userSupportRequests.filter(
      (userSupportRequest: any) => {
        return userSupportRequest.traitName.toLowerCase() === trait;
      },
    );

    EventService.track({
      event_type: "user",
      event_name: "support_category_user_requests",
      user_type: "student",
      event_data: {
        category: trait,
        requested: filteredTraitSupportRequests.length > 0 ? true : false,
      },
    });

    setTraitSupportRequests(filteredTraitSupportRequests);
  }, [userSupportRequests]);

  React.useEffect(() => {
    const heiTraitResponses = HEISupportResponses.filter(
      (institutionResponse: any) => {
        return institutionResponse.traitName.toLowerCase() === trait;
      },
    );

    EventService.track({
      event_type: "user",
      event_name: "support_category_institution_responses",
      user_type: "student",
      event_data: {
        category: trait,
        responses: heiTraitResponses.length,
      },
    });

    setHEISupport(heiTraitResponses);
  }, [HEISupportResponses]);

  const getLearningResources = async () => {
    const resourcesAndItsConnections =
      await resourceServices.getLearningResourcesWithAllTheConnections(
        trait,
        userId,
        sessionId,
        assessmentId,
      );
    const consolidatedResourceCardInfo =
      await resourceServices.consolidatedEachResourceCardInformation(
        resourcesAndItsConnections,
      );
    setResourceCardsData(consolidatedResourceCardInfo);
    setAreResourceCardsLoading(false);
  };

  const goBack = () => {
    navigate("/report/support");
  };

  const goToInsightsReport = () => {
    navigate(`/report/trait/${trait}`);
  };

  const ResourceCollapsibleContent = (
    <div className="collapsible-content">
      <ResourceCarousel
        cardsData={resourceCardsData}
        sourcePage="Resource Hub"
        trait={trait}
        isLoading={areResourceCardsLoading}
      />

      <div className="confidence-scale-wrapper">
        <HorizontalScaleRadio
          headerText={`How confident are you in your ${trait} skills today?`}
          defaultValue={defaultValue}
          maximumValue={5}
          SelectedIcon={<BlueDot height="20" width="20" />}
          UnselectedIcon={<BlueBorderDot height="20" width="20" />}
          radioDescriptorId={`How confident are you in your ${trait} skills today?`}
          componentCustomClasses="confidence-scale-radio"
          onChange={(segmentValue: any) => {
            handleConfidenceScaleActions(segmentValue);
          }}
          labels={{
            0: "Help!",
            2: "So-So",
            4: "Great!",
          }}
        />
      </div>
    </div>
  );

  const CollapsibleContent = (
    <div className="collapsible-content">
      <SupportCarousel narratives={narratives} traitScore={traitScore} />
    </div>
  );

  const handleSupportRequest = () => {
    navigate(`/report/support/request/${trait.toLowerCase()}`);
  };

  const handleViewSupport = () => {
    navigate(`/report/support/inbox/${trait.toLowerCase()}`);
  };

  const determineSupportStatus = () => {
    // HEI has sent user support
    const heiDatasupport: any = hasHEISupport;
    if (heiDatasupport.length > 0 && traitSupportRequests.length === 0) {
      // Response from institution,no request from student
      return "bothA&D";
    }
    if (
      heiDatasupport.length > 0 &&
      traitSupportRequests.length > 0 &&
      traitSupportRequests[0].responseSentToStudent !== true
    ) {
      //first response from HEI,student request is done, no response from HEI for that request
      return "bothR&A";
    }
    if (hasHEISupport.length > 0) return "available";

    if (
      traitSupportRequests.length > 0 &&
      traitSupportRequests.responseSentToStudent !== true
    ) {
      // user has submitted request, no response from institution
      return "requested";
    }

    if (
      traitSupportRequests.length > 0 &&
      traitSupportRequests.responseSentToStudent === true
    ) {
      // user submitted request, response available from institution
      return "available";
    }

    return "default";
  };

  const renderRequestStatus = () => {
    const requestStatus = determineSupportStatus();

    switch (requestStatus) {
      default:
      case "default":
        return (
          <IconTab
            text={`Request additional support developing your ${trait} traits`}
            renderedIcon={<CircleCaretRight />}
            onClick={() => handleSupportRequest()}
          />
        );

      // student has requested support
      case "requested":
        return (
          <IconTab
            text={`You’ll be notified when your student success team sends additional support`}
            renderedIcon={<CircleCaretRightDisabled />}
            onClick={() => handleSupportRequest()}
            disabled={true}
          />
        );

      case "available":
        return (
          <IconTab
            text={`View your additional support`}
            renderedIcon={<CircleCaretRight />}
            onClick={() => handleViewSupport()}
          />
        );
      case "bothA&D":
        return (
          <>
            {" "}
            <IconTab
              text={`Request additional support developing your ${trait} traits`}
              renderedIcon={<CircleCaretRight />}
              onClick={() => handleSupportRequest()}
            />
            <IconTab
              text={`View your additional support`}
              renderedIcon={<CircleCaretRight />}
              onClick={() => handleViewSupport()}
            />
          </>
        );
      case "bothR&A":
        return (
          <>
            {" "}
            <IconTab
              text={`You’ll be notified when your student success team sends additional support`}
              renderedIcon={<CircleCaretRightDisabled />}
              onClick={() => handleSupportRequest()}
              disabled={true}
            />
            <IconTab
              text={`View your additional support`}
              renderedIcon={<CircleCaretRight />}
              onClick={() => handleViewSupport()}
            />
          </>
        );
    }
  };

  return (
    <div className="single-trait-support bg-gradient-vertical">
      <Subheader text={`Resource Hub - ${ucWords(trait)}`} back={goBack} />
      <div
        className={`performance-declaration text-center ${TRAIT_SCORES[
          traitScore.score as keyof typeof TRAIT_SCORES
        ].toLowerCase()} d-flex justify-content-center align-items-center`}
      >
        <div>
          How I performed:{" "}
          <span className="performance-score">
            {TRAIT_SCORES[traitScore.score as keyof typeof TRAIT_SCORES]}
          </span>
        </div>
      </div>
      {/* <Subheader text="How I Performed: Demonstrating" /> */}
      <div className="instructions">
        <p>Explore the supports below to continue improving your skills!</p>
      </div>
      <CollapsibleCarousel
        trait={trait}
        text="Your Recommended Resources"
        content={ResourceCollapsibleContent}
        isCollapsible={true}
        openByDefault={true}
      />
      <CollapsibleCarousel
        trait={trait}
        text={`View your personalized tips to improve your ${trait} traits`}
        content={CollapsibleContent}
        onCollapsibleToggle={handleTipsEventLogging}
        isCollapsible={true}
        openByDefault={false}
      />

      {renderRequestStatus()}

      <IconTab
        text={`View some careers that would require strong ${trait}`}
        renderedIcon={<CircleCaretRight />}
        onClick={() => {
          EventService.track({
            event_type: "user",
            event_name: "support_category_open_careers",
            user_type: "student",
            event_data: {
              category: trait,
            },
          });

          showModal(ModalSupportCareers);
        }}
      />
      <button
        className="trait-insights-container"
        onClick={() => goToInsightsReport()}
      >
        <span className="trait-insights-link">
          View Your {ucWords(trait)} Insights Report <RightArrowBlue />
        </span>
      </button>
    </div>
  );
};

export default SingleTraitSupport;

// Packages
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import pkg from "../../../package.json";

// Configs
import env from "../../configs/env";

// Utilities
import deviceInfo from "../../utilities/deviceInfo";

//Graphql
import createOrUpdate from "../../components/dynamicqueries";
import { createEvents } from "../../graphql/mutations";

type DIIEvent = {
  event_id: string;
  event_type: string;
  event_name: string;
  event_version: string;
  event_timestamp: number;
  app_version?: string;
  event_data?: any;
  session: string;
  user_id: string;
  user_name?: string;
  user_type: string;
  env: string;
  schema_version: string;
};

type UserEvent = {
  event_name: string;
  event_data?: any;
  event_timestamp?: number;
  event_version?: string;
  event_type?: string;
  user_type?: string;
  user_id?: string;
  event_id?: string;
  session_id?: string;
  assessment_id?: string;
};

type SessionTraits = {
  sessionId?: string;
  assessmentId?: string;
  userGroup?: string;
};
/**
 * Track.
 *
 * The track method sends events to the PSQ database and then to DII.
 * @param userEvent
 */

export const track = async (userEvent: UserEvent): Promise<any> => {
  const eventId = uuidv4();

  // PSQ Events
  try {
    const data = [userEvent.event_data];
    data.push(
      { url: window.location.href },
      { deviceInfo: deviceInfo },
      { userAgent: navigator.userAgent },
    );
    const input = {
      eventId: eventId,
      eventType: userEvent.event_type || "",
      eventName: userEvent.event_name || "",
      eventDate: new Date().toISOString(),
      eventData: JSON.stringify(data),
      userSessionId: sessionStorage.getItem("userSessionId") || "Unauthorized",
      userId:
        sessionStorage.getItem("userId") ||
        "00000000-0000-1000-8000-000000000000",
      userType: sessionStorage.getItem("userGroup") || "",
      assessmentId: sessionStorage.getItem("assessmentId") || "",
    };
    const psqEvent = await createOrUpdate(createEvents, input);
  } catch (error: unknown) {
    const customError = error as string;
    console.warn(customError);
  }

  // DII Events
  const event = {
    event_type: userEvent?.event_type || "user",
    event_id: eventId,
    event_timestamp: userEvent?.event_timestamp || Date.now(),
    event_version: userEvent?.event_version || "1.0.0",
    event_name: userEvent?.event_name || "user",
    app_version: pkg.version,
    event_data: {
      assessmentId: sessionStorage.getItem("assessmentId") || "",
      userAgent: navigator.userAgent,
      ...userEvent.event_data,
    },
    session: sessionStorage.getItem("userSessionId") || uuidv4(),
    user_id:
      sessionStorage.getItem("userId") ||
      "00000000-0000-1000-8000-000000000000",
    user_type: sessionStorage.getItem("userGroup") || "",
    env: env.stage === "prd" ? "prod" : env.stage,
    schema_version: "1.1.0",
  };

  try {
    let response = await postDataToDII(event);
    // let data = await response.json();
    // console.group();
    // console.groupEnd();
    // if (data.error) throw data;
  } catch (error: unknown) {
    const customError = error as string;
    console.warn(customError);
  }
};

/**
 * sends the event to DII
 *
 * @param event
 */
async function postDataToDII(event: DIIEvent) {
  const apiName = "Events";
  const path = "/events";
  const myInit = {
    body: { events: [event] },
  };

  return await API.post(apiName, path, myInit);
}

/**
 * Identify.
 *
 * Use the 'identify' method to link users to their actions
 * @param userId The database ID for the user
 * @param sessionTraits An object with details about current session (id, assessmentId, etc)
 * @returns
 */
export const identify = async (
  userId: string,
  sessionTraits: SessionTraits,
) => {
  sessionStorage.setItem("userId", userId);

  if (sessionTraits) {
    if (sessionTraits.assessmentId)
      sessionStorage.setItem("assessmentId", sessionTraits.assessmentId);

    if (sessionTraits.sessionId)
      sessionStorage.setItem("userSessionId", sessionTraits.sessionId);

    if (sessionTraits.userGroup)
      sessionStorage.setItem("userGroup", sessionTraits.userGroup);
  }
};

export const deidentify = async () => {
  sessionStorage.removeItem("userId");
  sessionStorage.removeItem("assessmentId");
  sessionStorage.removeItem("userSessionId");
  sessionStorage.removeItem("userGroup");
};

/**
 * Page.
 *
 * Use the 'page' method to specify when a user is entering or exiting a specific page.
 *
 * @param type The type of conversion (enter, exit)
 * @param page The page doing the conversion
 * @param user_type The type of user (candidate, institution)
 */
export const page = async ({
  type = "enter",
  page = "No page specified",
}: any): Promise<any> => {
  let event_name = type === "enter" ? "user_enters_page" : "user_exit_page";
  let user_type = sessionStorage.getItem("userGroup") || "";
  track({
    event_type: "user",
    event_name,
    user_type: user_type,
    event_data: {
      page,
      url: window.location.href,
    },
  });
};

const EventService = {
  identify,
  deidentify,
  track,
  page,
};

export default EventService;

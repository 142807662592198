// Packages
import React, { useEffect, useState } from "react";
import { Amplify, Hub } from "aws-amplify";
import WebFont from "webfontloader";

// Configs
import config from "./aws-exports";

// Redux
import { withRedux } from "./redux/withRedux";

import { ReskinAuthenticator } from "./reskin/components/ReskinAuthenticator/ReskinAuthenticator";
import ModalProvider from "./contexts/provider";
import ModalRoot from "./components/Modals/Root";
import AppContent from "./AppContent";
import "@aws-amplify/ui-react/styles.css";
import { InstitutionHeader } from "./reskin/institution/components/Header/InstitutionHeader";
import { Usertype } from "./models";
import { RootState } from "./redux/reducers";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { InstitutionFooter } from "./reskin/institution/components/Footer/InstitutionFooter";
import { BrowserRouter } from "react-router-dom";

Amplify.configure({
  ...config,
});

// Amplify.Logger.LOG_LEVEL = "DEBUG";

function App() {
  const [isReady, setReady] = useState<boolean>(false);
  const { userType, loggedIn } = useSelector((state: RootState) => state.user);

  // determine usertype and store in session storage

  Hub.listen("auth", async (hubData) => {
    const { event } = hubData.payload;
    if (event === "signOut") {
      //place holder to log any events
    } else if (event === "signIn") {
      //place holder to log any events
    }
  });

  useEffect(() => {
    loadFonts();

    async function loadFonts() {
      await WebFont.load({
        google: {
          families: ["Open Sans", "Roboto"],
        },
      });

      setReady(true);
    }
  }, []);

  // to force user to stay on account verification

  return (
    <BrowserRouter>
      <ModalProvider>
        {(userType === Usertype.INSTITUTION && loggedIn) && <InstitutionHeader />}
        <div className="app-wrapper">
          {!isReady && <Spinner className="spinner" animation="border" role="status" />}
          {isReady && (
            <>
              <ReskinAuthenticator>
                <AppContent />
              </ReskinAuthenticator>
              <ModalRoot />
            </>
          )}
        </div>
        {(userType === Usertype.INSTITUTION && loggedIn) && <InstitutionFooter />}
      </ModalProvider>
    </BrowserRouter>
  );
}

export default withRedux(App);

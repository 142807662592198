/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateStudentRequest = /* GraphQL */ `
  subscription OnCreateStudentRequest(
    $filter: ModelSubscriptionStudentRequestFilterInput
  ) {
    onCreateStudentRequest(filter: $filter) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStudentRequest = /* GraphQL */ `
  subscription OnUpdateStudentRequest(
    $filter: ModelSubscriptionStudentRequestFilterInput
  ) {
    onUpdateStudentRequest(filter: $filter) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStudentRequest = /* GraphQL */ `
  subscription OnDeleteStudentRequest(
    $filter: ModelSubscriptionStudentRequestFilterInput
  ) {
    onDeleteStudentRequest(filter: $filter) {
      id
      studentUserSessionId
      userId
      userAssessmentId
      studentUserSession {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      traitName
      studentQuestion
      requestDate
      institutionId
      responseSentToStudent
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateStudentResource = /* GraphQL */ `
  subscription OnCreateStudentResource(
    $filter: ModelSubscriptionStudentResourceFilterInput
  ) {
    onCreateStudentResource(filter: $filter) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStudentResource = /* GraphQL */ `
  subscription OnUpdateStudentResource(
    $filter: ModelSubscriptionStudentResourceFilterInput
  ) {
    onUpdateStudentResource(filter: $filter) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStudentResource = /* GraphQL */ `
  subscription OnDeleteStudentResource(
    $filter: ModelSubscriptionStudentResourceFilterInput
  ) {
    onDeleteStudentResource(filter: $filter) {
      id
      description
      trait
      resourceLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInstitutionResponse = /* GraphQL */ `
  subscription OnCreateInstitutionResponse(
    $filter: ModelSubscriptionInstitutionResponseFilterInput
  ) {
    onCreateInstitutionResponse(filter: $filter) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInstitutionResponse = /* GraphQL */ `
  subscription OnUpdateInstitutionResponse(
    $filter: ModelSubscriptionInstitutionResponseFilterInput
  ) {
    onUpdateInstitutionResponse(filter: $filter) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInstitutionResponse = /* GraphQL */ `
  subscription OnDeleteInstitutionResponse(
    $filter: ModelSubscriptionInstitutionResponseFilterInput
  ) {
    onDeleteInstitutionResponse(filter: $filter) {
      id
      studentUserId
      studentUserSessionId
      studentRequestId
      studentRequest {
        id
        studentUserSessionId
        userId
        userAssessmentId
        studentUserSession {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        traitName
        studentQuestion
        requestDate
        institutionId
        responseSentToStudent
        institutionResponses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      traitName
      response
      responseDate
      institutionUserId
      institutionUser {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      responseRead
      resourceInfo {
        traitName
        description
        resourceLinks
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInstitutionUser = /* GraphQL */ `
  subscription OnCreateInstitutionUser(
    $filter: ModelSubscriptionInstitutionUserFilterInput
  ) {
    onCreateInstitutionUser(filter: $filter) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInstitutionUser = /* GraphQL */ `
  subscription OnUpdateInstitutionUser(
    $filter: ModelSubscriptionInstitutionUserFilterInput
  ) {
    onUpdateInstitutionUser(filter: $filter) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInstitutionUser = /* GraphQL */ `
  subscription OnDeleteInstitutionUser(
    $filter: ModelSubscriptionInstitutionUserFilterInput
  ) {
    onDeleteInstitutionUser(filter: $filter) {
      id
      emailId
      institution
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLearningResource = /* GraphQL */ `
  subscription OnCreateLearningResource(
    $filter: ModelSubscriptionLearningResourceFilterInput
  ) {
    onCreateLearningResource(filter: $filter) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLearningResource = /* GraphQL */ `
  subscription OnUpdateLearningResource(
    $filter: ModelSubscriptionLearningResourceFilterInput
  ) {
    onUpdateLearningResource(filter: $filter) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLearningResource = /* GraphQL */ `
  subscription OnDeleteLearningResource(
    $filter: ModelSubscriptionLearningResourceFilterInput
  ) {
    onDeleteLearningResource(filter: $filter) {
      id
      title
      trait
      resourceLink
      imageLocation
      duration
      type
      credit
      resourceCards {
        description
        content
      }
      studentFavourites {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          preference
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentVisited {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ResourceReaction {
        items {
          id
          userId
          assessmentId
          userSessionId
          preference
          trait
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      studentRecommended {
        items {
          id
          userId
          assessmentId
          userSessionId
          trait
          sequence
          learningResourceId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateStudentRecommendedResources = /* GraphQL */ `
  subscription OnCreateStudentRecommendedResources(
    $filter: ModelSubscriptionStudentRecommendedResourcesFilterInput
  ) {
    onCreateStudentRecommendedResources(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStudentRecommendedResources = /* GraphQL */ `
  subscription OnUpdateStudentRecommendedResources(
    $filter: ModelSubscriptionStudentRecommendedResourcesFilterInput
  ) {
    onUpdateStudentRecommendedResources(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStudentRecommendedResources = /* GraphQL */ `
  subscription OnDeleteStudentRecommendedResources(
    $filter: ModelSubscriptionStudentRecommendedResourcesFilterInput
  ) {
    onDeleteStudentRecommendedResources(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      sequence
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateResourceReaction = /* GraphQL */ `
  subscription OnCreateResourceReaction(
    $filter: ModelSubscriptionResourceReactionFilterInput
  ) {
    onCreateResourceReaction(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateResourceReaction = /* GraphQL */ `
  subscription OnUpdateResourceReaction(
    $filter: ModelSubscriptionResourceReactionFilterInput
  ) {
    onUpdateResourceReaction(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteResourceReaction = /* GraphQL */ `
  subscription OnDeleteResourceReaction(
    $filter: ModelSubscriptionResourceReactionFilterInput
  ) {
    onDeleteResourceReaction(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      preference
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserConfidenceLevel = /* GraphQL */ `
  subscription OnCreateUserConfidenceLevel(
    $filter: ModelSubscriptionUserConfidenceLevelFilterInput
  ) {
    onCreateUserConfidenceLevel(filter: $filter) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserConfidenceLevel = /* GraphQL */ `
  subscription OnUpdateUserConfidenceLevel(
    $filter: ModelSubscriptionUserConfidenceLevelFilterInput
  ) {
    onUpdateUserConfidenceLevel(filter: $filter) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserConfidenceLevel = /* GraphQL */ `
  subscription OnDeleteUserConfidenceLevel(
    $filter: ModelSubscriptionUserConfidenceLevelFilterInput
  ) {
    onDeleteUserConfidenceLevel(filter: $filter) {
      id
      userId
      trait
      phase
      confidenceLevel
      selectedDate
      selectedTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateStudentVisitedLearningResource = /* GraphQL */ `
  subscription OnCreateStudentVisitedLearningResource(
    $filter: ModelSubscriptionStudentVisitedLearningResourceFilterInput
  ) {
    onCreateStudentVisitedLearningResource(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStudentVisitedLearningResource = /* GraphQL */ `
  subscription OnUpdateStudentVisitedLearningResource(
    $filter: ModelSubscriptionStudentVisitedLearningResourceFilterInput
  ) {
    onUpdateStudentVisitedLearningResource(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStudentVisitedLearningResource = /* GraphQL */ `
  subscription OnDeleteStudentVisitedLearningResource(
    $filter: ModelSubscriptionStudentVisitedLearningResourceFilterInput
  ) {
    onDeleteStudentVisitedLearningResource(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateStudentFavouriteLearningResource = /* GraphQL */ `
  subscription OnCreateStudentFavouriteLearningResource(
    $filter: ModelSubscriptionStudentFavouriteLearningResourceFilterInput
  ) {
    onCreateStudentFavouriteLearningResource(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStudentFavouriteLearningResource = /* GraphQL */ `
  subscription OnUpdateStudentFavouriteLearningResource(
    $filter: ModelSubscriptionStudentFavouriteLearningResourceFilterInput
  ) {
    onUpdateStudentFavouriteLearningResource(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStudentFavouriteLearningResource = /* GraphQL */ `
  subscription OnDeleteStudentFavouriteLearningResource(
    $filter: ModelSubscriptionStudentFavouriteLearningResourceFilterInput
  ) {
    onDeleteStudentFavouriteLearningResource(filter: $filter) {
      id
      userId
      assessmentId
      userSessionId
      trait
      preference
      learningResourceId
      learningResource {
        id
        title
        trait
        resourceLink
        imageLocation
        duration
        type
        credit
        resourceCards {
          description
          content
        }
        studentFavourites {
          nextToken
          startedAt
        }
        studentVisited {
          nextToken
          startedAt
        }
        ResourceReaction {
          nextToken
          startedAt
        }
        studentRecommended {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLearningSelfReflection = /* GraphQL */ `
  subscription OnCreateLearningSelfReflection(
    $filter: ModelSubscriptionLearningSelfReflectionFilterInput
  ) {
    onCreateLearningSelfReflection(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLearningSelfReflection = /* GraphQL */ `
  subscription OnUpdateLearningSelfReflection(
    $filter: ModelSubscriptionLearningSelfReflectionFilterInput
  ) {
    onUpdateLearningSelfReflection(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLearningSelfReflection = /* GraphQL */ `
  subscription OnDeleteLearningSelfReflection(
    $filter: ModelSubscriptionLearningSelfReflectionFilterInput
  ) {
    onDeleteLearningSelfReflection(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      takeawayComment
      applicationComment
      commentDate
      institution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLearningSelfReflectionCount = /* GraphQL */ `
  subscription OnCreateLearningSelfReflectionCount(
    $filter: ModelSubscriptionLearningSelfReflectionCountFilterInput
  ) {
    onCreateLearningSelfReflectionCount(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLearningSelfReflectionCount = /* GraphQL */ `
  subscription OnUpdateLearningSelfReflectionCount(
    $filter: ModelSubscriptionLearningSelfReflectionCountFilterInput
  ) {
    onUpdateLearningSelfReflectionCount(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLearningSelfReflectionCount = /* GraphQL */ `
  subscription OnDeleteLearningSelfReflectionCount(
    $filter: ModelSubscriptionLearningSelfReflectionCountFilterInput
  ) {
    onDeleteLearningSelfReflectionCount(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      selfReflectionCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserResponses = /* GraphQL */ `
  subscription OnCreateUserResponses(
    $filter: ModelSubscriptionUserResponsesFilterInput
    $owner: String
  ) {
    onCreateUserResponses(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const onUpdateUserResponses = /* GraphQL */ `
  subscription OnUpdateUserResponses(
    $filter: ModelSubscriptionUserResponsesFilterInput
    $owner: String
  ) {
    onUpdateUserResponses(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const onDeleteUserResponses = /* GraphQL */ `
  subscription OnDeleteUserResponses(
    $filter: ModelSubscriptionUserResponsesFilterInput
    $owner: String
  ) {
    onDeleteUserResponses(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      tripletResponse {
        itemGuid
        itemSeq
        response
      }
      tripletScorableResponse {
        TestCode
        Response
      }
      pairsScorableResponse {
        calibration_name
        responses
      }
      selectedVideoQuestionId
      videoResponseLocation
      s3VideoResponseLocation
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userResponsesUserAssessmentStateId
      owner
    }
  }
`;
export const onCreateBIQ = /* GraphQL */ `
  subscription OnCreateBIQ($filter: ModelSubscriptionBIQFilterInput) {
    onCreateBIQ(filter: $filter) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBIQ = /* GraphQL */ `
  subscription OnUpdateBIQ($filter: ModelSubscriptionBIQFilterInput) {
    onUpdateBIQ(filter: $filter) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBIQ = /* GraphQL */ `
  subscription OnDeleteBIQ($filter: ModelSubscriptionBIQFilterInput) {
    onDeleteBIQ(filter: $filter) {
      id
      sequence
      content
      profileAttribute
      displayType
      options
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      userId
      name
      enrollmentStatus
      programYear
      fieldOfStudy
      transferToFourYear
      ageGroup
      gender
      goal
      emplStatus
      ethnicity
      institutionId
      institution {
        id
        name
        desc
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      enrollmentYear
      email
      enrollmentSemester
      userRole
      userType
      lastLogin
      assessmentStates {
        items {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        nextToken
        startedAt
      }
      institutionResponses {
        items {
          id
          studentUserId
          studentUserSessionId
          studentRequestId
          traitName
          response
          responseDate
          institutionUserId
          responseRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      candidateComments {
        items {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userConfidenceLevel {
        items {
          id
          userId
          trait
          phase
          confidenceLevel
          selectedDate
          selectedTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem($filter: ModelSubscriptionItemFilterInput) {
    onCreateItem(filter: $filter) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem($filter: ModelSubscriptionItemFilterInput) {
    onUpdateItem(filter: $filter) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem($filter: ModelSubscriptionItemFilterInput) {
    onDeleteItem(filter: $filter) {
      id
      itemGuid
      accnum
      version
      type
      content
      dimension
      dimensionDesc
      name
      alphaStatValue
      dltaStatValue
      tauStatValue
      sclStatValue
      sections {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSection = /* GraphQL */ `
  subscription OnCreateSection($filter: ModelSubscriptionSectionFilterInput) {
    onCreateSection(filter: $filter) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSection = /* GraphQL */ `
  subscription OnUpdateSection($filter: ModelSubscriptionSectionFilterInput) {
    onUpdateSection(filter: $filter) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSection = /* GraphQL */ `
  subscription OnDeleteSection($filter: ModelSubscriptionSectionFilterInput) {
    onDeleteSection(filter: $filter) {
      id
      name
      description
      type
      items {
        items {
          id
          sectionItemItemId
          sectionItemSectionId
          itemSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          itemSectionsId
          sectionItemsId
        }
        nextToken
        startedAt
      }
      assessments {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSectionItem = /* GraphQL */ `
  subscription OnCreateSectionItem(
    $filter: ModelSubscriptionSectionItemFilterInput
  ) {
    onCreateSectionItem(filter: $filter) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const onUpdateSectionItem = /* GraphQL */ `
  subscription OnUpdateSectionItem(
    $filter: ModelSubscriptionSectionItemFilterInput
  ) {
    onUpdateSectionItem(filter: $filter) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const onDeleteSectionItem = /* GraphQL */ `
  subscription OnDeleteSectionItem(
    $filter: ModelSubscriptionSectionItemFilterInput
  ) {
    onDeleteSectionItem(filter: $filter) {
      id
      sectionItemItemId
      sectionItemSectionId
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      item {
        id
        itemGuid
        accnum
        version
        type
        content
        dimension
        dimensionDesc
        name
        alphaStatValue
        dltaStatValue
        tauStatValue
        sclStatValue
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      itemSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      itemSectionsId
      sectionItemsId
    }
  }
`;
export const onCreateAssessment = /* GraphQL */ `
  subscription OnCreateAssessment(
    $filter: ModelSubscriptionAssessmentFilterInput
  ) {
    onCreateAssessment(filter: $filter) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAssessment = /* GraphQL */ `
  subscription OnUpdateAssessment(
    $filter: ModelSubscriptionAssessmentFilterInput
  ) {
    onUpdateAssessment(filter: $filter) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAssessment = /* GraphQL */ `
  subscription OnDeleteAssessment(
    $filter: ModelSubscriptionAssessmentFilterInput
  ) {
    onDeleteAssessment(filter: $filter) {
      id
      name
      description
      sections {
        items {
          id
          assessmentSectionAssessmentId
          assessmentSectionSectionId
          sectionSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          sectionAssessmentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAssessmentSection = /* GraphQL */ `
  subscription OnCreateAssessmentSection(
    $filter: ModelSubscriptionAssessmentSectionFilterInput
  ) {
    onCreateAssessmentSection(filter: $filter) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const onUpdateAssessmentSection = /* GraphQL */ `
  subscription OnUpdateAssessmentSection(
    $filter: ModelSubscriptionAssessmentSectionFilterInput
  ) {
    onUpdateAssessmentSection(filter: $filter) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const onDeleteAssessmentSection = /* GraphQL */ `
  subscription OnDeleteAssessmentSection(
    $filter: ModelSubscriptionAssessmentSectionFilterInput
  ) {
    onDeleteAssessmentSection(filter: $filter) {
      id
      assessmentSectionAssessmentId
      assessmentSectionSectionId
      assessment {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      section {
        id
        name
        description
        type
        items {
          nextToken
          startedAt
        }
        assessments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sectionSequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sectionAssessmentsId
    }
  }
`;
export const onCreateInstitution = /* GraphQL */ `
  subscription OnCreateInstitution(
    $filter: ModelSubscriptionInstitutionFilterInput
  ) {
    onCreateInstitution(filter: $filter) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInstitution = /* GraphQL */ `
  subscription OnUpdateInstitution(
    $filter: ModelSubscriptionInstitutionFilterInput
  ) {
    onUpdateInstitution(filter: $filter) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInstitution = /* GraphQL */ `
  subscription OnDeleteInstitution(
    $filter: ModelSubscriptionInstitutionFilterInput
  ) {
    onDeleteInstitution(filter: $filter) {
      id
      name
      desc
      users {
        items {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserAssessmentState = /* GraphQL */ `
  subscription OnCreateUserAssessmentState(
    $filter: ModelSubscriptionUserAssessmentStateFilterInput
    $owner: String
  ) {
    onCreateUserAssessmentState(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const onUpdateUserAssessmentState = /* GraphQL */ `
  subscription OnUpdateUserAssessmentState(
    $filter: ModelSubscriptionUserAssessmentStateFilterInput
    $owner: String
  ) {
    onUpdateUserAssessmentState(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const onDeleteUserAssessmentState = /* GraphQL */ `
  subscription OnDeleteUserAssessmentState(
    $filter: ModelSubscriptionUserAssessmentStateFilterInput
    $owner: String
  ) {
    onDeleteUserAssessmentState(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      assessmentStatus
      assessmentStartDate
      assessmentEndDate
      userAssessmentStateUserId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userScores {
        id
        userSessionId
        userId
        assessmentId
        rawTripletScores
        rawVideoScores
        f01
        f02
        f03
        f04
        f05
        f06
        f07
        f08
        f09
        f10
        f11
        f12
        f13
        f14
        f15
        eyeContact
        headBodyPosture
        speechFluency
        effectivePauses
        pacePitchVolume
        leadershipScoreBand
        perseveranceScoreBand
        communicationScoreBand
        innovativeThinkingScoreBand
        leadershipScore
        perseveranceScore
        communicationScore
        innovativeThinkingScore
        overallScore
        dimensionNarratives
        institution
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userScoresUserAssessmentStateId
        owner
      }
      userResponses {
        id
        userSessionId
        userId
        assessmentId
        tripletResponse {
          itemGuid
          itemSeq
          response
        }
        tripletScorableResponse {
          TestCode
          Response
        }
        pairsScorableResponse {
          calibration_name
          responses
        }
        selectedVideoQuestionId
        videoResponseLocation
        s3VideoResponseLocation
        userAssessmentState {
          id
          userSessionId
          userId
          assessmentId
          assessmentStatus
          assessmentStartDate
          assessmentEndDate
          userAssessmentStateUserId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateUserScoresId
          userAssessmentStateUserResponsesId
          userAssessmentStateQuickTipsId
          userAssessmentStateCommentsId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userResponsesUserAssessmentStateId
        owner
      }
      heiComments {
        items {
          id
          userId
          username
          userSessionId
          assessmentId
          candidateUserId
          candidateUserSessionId
          userAssessmentId
          comment
          timestamp
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userAssessmentStateHeiCommentsId
        }
        nextToken
        startedAt
      }
      quickTips {
        id
        userId
        userSessionId
        assessmentId
        tips
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      comments {
        id
        userId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userSessionId
        assessmentId
        comment
        leadershipComment
        leadershipCommentFromModel
        leadershipCommentProfane
        declineCommentOnLeadership
        communicationComment
        communicationCommentFromModel
        communicationCommentProfane
        declineCommentOnCommunication
        innovativeThinkingComment
        innovativeThinkingCommentFromModel
        innovativeThinkingCommentProfane
        declineCommentOnInnovativeThinking
        perseveranceComment
        perseveranceCommentFromModel
        perseveranceCommentProfane
        declineCommentOnPerserverance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentRequests {
        items {
          id
          studentUserSessionId
          userId
          userAssessmentId
          traitName
          studentQuestion
          requestDate
          institutionId
          responseSentToStudent
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateUserScoresId
      userAssessmentStateUserResponsesId
      userAssessmentStateQuickTipsId
      userAssessmentStateCommentsId
      owner
    }
  }
`;
export const onCreateUserScores = /* GraphQL */ `
  subscription OnCreateUserScores(
    $filter: ModelSubscriptionUserScoresFilterInput
    $owner: String
  ) {
    onCreateUserScores(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const onUpdateUserScores = /* GraphQL */ `
  subscription OnUpdateUserScores(
    $filter: ModelSubscriptionUserScoresFilterInput
    $owner: String
  ) {
    onUpdateUserScores(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const onDeleteUserScores = /* GraphQL */ `
  subscription OnDeleteUserScores(
    $filter: ModelSubscriptionUserScoresFilterInput
    $owner: String
  ) {
    onDeleteUserScores(filter: $filter, owner: $owner) {
      id
      userSessionId
      userId
      assessmentId
      rawTripletScores
      rawVideoScores
      f01
      f02
      f03
      f04
      f05
      f06
      f07
      f08
      f09
      f10
      f11
      f12
      f13
      f14
      f15
      eyeContact
      headBodyPosture
      speechFluency
      effectivePauses
      pacePitchVolume
      leadershipScoreBand
      perseveranceScoreBand
      communicationScoreBand
      innovativeThinkingScoreBand
      leadershipScore
      perseveranceScore
      communicationScore
      innovativeThinkingScore
      overallScore
      dimensionNarratives
      institution
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userScoresUserAssessmentStateId
      owner
    }
  }
`;
export const onCreateAdmin = /* GraphQL */ `
  subscription OnCreateAdmin($filter: ModelSubscriptionAdminFilterInput) {
    onCreateAdmin(filter: $filter) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAdmin = /* GraphQL */ `
  subscription OnUpdateAdmin($filter: ModelSubscriptionAdminFilterInput) {
    onUpdateAdmin(filter: $filter) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAdmin = /* GraphQL */ `
  subscription OnDeleteAdmin($filter: ModelSubscriptionAdminFilterInput) {
    onDeleteAdmin(filter: $filter) {
      id
      adminId
      startDate
      endDate
      assessmentId {
        id
        name
        description
        sections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCutOffScores = /* GraphQL */ `
  subscription OnCreateCutOffScores(
    $filter: ModelSubscriptionCutOffScoresFilterInput
  ) {
    onCreateCutOffScores(filter: $filter) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCutOffScores = /* GraphQL */ `
  subscription OnUpdateCutOffScores(
    $filter: ModelSubscriptionCutOffScoresFilterInput
  ) {
    onUpdateCutOffScores(filter: $filter) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCutOffScores = /* GraphQL */ `
  subscription OnDeleteCutOffScores(
    $filter: ModelSubscriptionCutOffScoresFilterInput
  ) {
    onDeleteCutOffScores(filter: $filter) {
      id
      adminId
      dimensionId
      cutScore1
      cutScore2
      cutScore3
      cutScore4
      cutScore5
      cutScore6
      cutScore7
      cutScore8
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEvents = /* GraphQL */ `
  subscription OnCreateEvents(
    $filter: ModelSubscriptionEventsFilterInput
    $owner: String
  ) {
    onCreateEvents(filter: $filter, owner: $owner) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateEvents = /* GraphQL */ `
  subscription OnUpdateEvents(
    $filter: ModelSubscriptionEventsFilterInput
    $owner: String
  ) {
    onUpdateEvents(filter: $filter, owner: $owner) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteEvents = /* GraphQL */ `
  subscription OnDeleteEvents(
    $filter: ModelSubscriptionEventsFilterInput
    $owner: String
  ) {
    onDeleteEvents(filter: $filter, owner: $owner) {
      id
      eventId
      eventType
      eventName
      eventDate
      eventData
      userSessionId
      userId
      userType
      assessmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateNarratives = /* GraphQL */ `
  subscription OnCreateNarratives(
    $filter: ModelSubscriptionNarrativesFilterInput
  ) {
    onCreateNarratives(filter: $filter) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNarratives = /* GraphQL */ `
  subscription OnUpdateNarratives(
    $filter: ModelSubscriptionNarrativesFilterInput
  ) {
    onUpdateNarratives(filter: $filter) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNarratives = /* GraphQL */ `
  subscription OnDeleteNarratives(
    $filter: ModelSubscriptionNarrativesFilterInput
  ) {
    onDeleteNarratives(filter: $filter) {
      id
      dimensionId
      definition
      highScoreNarrative
      mediumScoreNarrative
      lowScoreNarrative
      improvementNarrative
      improvementDynamicNarrative
      developingTips
      demonstratingTips
      approachingTips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInstitutionNarratives = /* GraphQL */ `
  subscription OnCreateInstitutionNarratives(
    $filter: ModelSubscriptionInstitutionNarrativesFilterInput
  ) {
    onCreateInstitutionNarratives(filter: $filter) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInstitutionNarratives = /* GraphQL */ `
  subscription OnUpdateInstitutionNarratives(
    $filter: ModelSubscriptionInstitutionNarrativesFilterInput
  ) {
    onUpdateInstitutionNarratives(filter: $filter) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInstitutionNarratives = /* GraphQL */ `
  subscription OnDeleteInstitutionNarratives(
    $filter: ModelSubscriptionInstitutionNarrativesFilterInput
  ) {
    onDeleteInstitutionNarratives(filter: $filter) {
      id
      highLevelTrait
      demonstratingNarrative
      developingNarrative
      approachingNarrative
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEventTracking = /* GraphQL */ `
  subscription OnCreateEventTracking(
    $filter: ModelSubscriptionEventTrackingFilterInput
  ) {
    onCreateEventTracking(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEventTracking = /* GraphQL */ `
  subscription OnUpdateEventTracking(
    $filter: ModelSubscriptionEventTrackingFilterInput
  ) {
    onUpdateEventTracking(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEventTracking = /* GraphQL */ `
  subscription OnDeleteEventTracking(
    $filter: ModelSubscriptionEventTrackingFilterInput
  ) {
    onDeleteEventTracking(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      eventName
      eventStatus
      errorMessage
      eventData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateQuickTips = /* GraphQL */ `
  subscription OnCreateQuickTips(
    $filter: ModelSubscriptionQuickTipsFilterInput
  ) {
    onCreateQuickTips(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateQuickTips = /* GraphQL */ `
  subscription OnUpdateQuickTips(
    $filter: ModelSubscriptionQuickTipsFilterInput
  ) {
    onUpdateQuickTips(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteQuickTips = /* GraphQL */ `
  subscription OnDeleteQuickTips(
    $filter: ModelSubscriptionQuickTipsFilterInput
  ) {
    onDeleteQuickTips(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      tips
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCandidateComments = /* GraphQL */ `
  subscription OnCreateCandidateComments(
    $filter: ModelSubscriptionCandidateCommentsFilterInput
  ) {
    onCreateCandidateComments(filter: $filter) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCandidateComments = /* GraphQL */ `
  subscription OnUpdateCandidateComments(
    $filter: ModelSubscriptionCandidateCommentsFilterInput
  ) {
    onUpdateCandidateComments(filter: $filter) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCandidateComments = /* GraphQL */ `
  subscription OnDeleteCandidateComments(
    $filter: ModelSubscriptionCandidateCommentsFilterInput
  ) {
    onDeleteCandidateComments(filter: $filter) {
      id
      userId
      user {
        id
        userId
        name
        enrollmentStatus
        programYear
        fieldOfStudy
        transferToFourYear
        ageGroup
        gender
        goal
        emplStatus
        ethnicity
        institutionId
        institution {
          id
          name
          desc
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        enrollmentYear
        email
        enrollmentSemester
        userRole
        userType
        lastLogin
        assessmentStates {
          nextToken
          startedAt
        }
        institutionResponses {
          nextToken
          startedAt
        }
        candidateComments {
          nextToken
          startedAt
        }
        userConfidenceLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userSessionId
      assessmentId
      comment
      leadershipComment
      leadershipCommentFromModel
      leadershipCommentProfane
      declineCommentOnLeadership
      communicationComment
      communicationCommentFromModel
      communicationCommentProfane
      declineCommentOnCommunication
      innovativeThinkingComment
      innovativeThinkingCommentFromModel
      innovativeThinkingCommentProfane
      declineCommentOnInnovativeThinking
      perseveranceComment
      perseveranceCommentFromModel
      perseveranceCommentProfane
      declineCommentOnPerserverance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateHeiComments = /* GraphQL */ `
  subscription OnCreateHeiComments(
    $filter: ModelSubscriptionHeiCommentsFilterInput
  ) {
    onCreateHeiComments(filter: $filter) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const onUpdateHeiComments = /* GraphQL */ `
  subscription OnUpdateHeiComments(
    $filter: ModelSubscriptionHeiCommentsFilterInput
  ) {
    onUpdateHeiComments(filter: $filter) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const onDeleteHeiComments = /* GraphQL */ `
  subscription OnDeleteHeiComments(
    $filter: ModelSubscriptionHeiCommentsFilterInput
  ) {
    onDeleteHeiComments(filter: $filter) {
      id
      userId
      username
      userSessionId
      assessmentId
      candidateUserId
      candidateUserSessionId
      userAssessmentId
      userAssessmentState {
        id
        userSessionId
        userId
        assessmentId
        assessmentStatus
        assessmentStartDate
        assessmentEndDate
        userAssessmentStateUserId
        user {
          id
          userId
          name
          enrollmentStatus
          programYear
          fieldOfStudy
          transferToFourYear
          ageGroup
          gender
          goal
          emplStatus
          ethnicity
          institutionId
          enrollmentYear
          email
          enrollmentSemester
          userRole
          userType
          lastLogin
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        userScores {
          id
          userSessionId
          userId
          assessmentId
          rawTripletScores
          rawVideoScores
          f01
          f02
          f03
          f04
          f05
          f06
          f07
          f08
          f09
          f10
          f11
          f12
          f13
          f14
          f15
          eyeContact
          headBodyPosture
          speechFluency
          effectivePauses
          pacePitchVolume
          leadershipScoreBand
          perseveranceScoreBand
          communicationScoreBand
          innovativeThinkingScoreBand
          leadershipScore
          perseveranceScore
          communicationScore
          innovativeThinkingScore
          overallScore
          dimensionNarratives
          institution
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userScoresUserAssessmentStateId
          owner
        }
        userResponses {
          id
          userSessionId
          userId
          assessmentId
          selectedVideoQuestionId
          videoResponseLocation
          s3VideoResponseLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userResponsesUserAssessmentStateId
          owner
        }
        heiComments {
          nextToken
          startedAt
        }
        quickTips {
          id
          userId
          userSessionId
          assessmentId
          tips
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        comments {
          id
          userId
          userSessionId
          assessmentId
          comment
          leadershipComment
          leadershipCommentFromModel
          leadershipCommentProfane
          declineCommentOnLeadership
          communicationComment
          communicationCommentFromModel
          communicationCommentProfane
          declineCommentOnCommunication
          innovativeThinkingComment
          innovativeThinkingCommentFromModel
          innovativeThinkingCommentProfane
          declineCommentOnInnovativeThinking
          perseveranceComment
          perseveranceCommentFromModel
          perseveranceCommentProfane
          declineCommentOnPerserverance
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentRequests {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAssessmentStateUserScoresId
        userAssessmentStateUserResponsesId
        userAssessmentStateQuickTipsId
        userAssessmentStateCommentsId
        owner
      }
      comment
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAssessmentStateHeiCommentsId
    }
  }
`;
export const onCreateHeiBookmarks = /* GraphQL */ `
  subscription OnCreateHeiBookmarks(
    $filter: ModelSubscriptionHeiBookmarksFilterInput
  ) {
    onCreateHeiBookmarks(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateHeiBookmarks = /* GraphQL */ `
  subscription OnUpdateHeiBookmarks(
    $filter: ModelSubscriptionHeiBookmarksFilterInput
  ) {
    onUpdateHeiBookmarks(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteHeiBookmarks = /* GraphQL */ `
  subscription OnDeleteHeiBookmarks(
    $filter: ModelSubscriptionHeiBookmarksFilterInput
  ) {
    onDeleteHeiBookmarks(filter: $filter) {
      id
      userId
      userSessionId
      assessmentId
      candidateUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInsights = /* GraphQL */ `
  subscription OnCreateInsights($filter: ModelSubscriptionInsightsFilterInput) {
    onCreateInsights(filter: $filter) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInsights = /* GraphQL */ `
  subscription OnUpdateInsights($filter: ModelSubscriptionInsightsFilterInput) {
    onUpdateInsights(filter: $filter) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInsights = /* GraphQL */ `
  subscription OnDeleteInsights($filter: ModelSubscriptionInsightsFilterInput) {
    onDeleteInsights(filter: $filter) {
      id
      dimensionId
      developingScoreInsights
      approachingScoreInsights
      demonstratingScoreInsights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

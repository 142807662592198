import ReflectionPrompt from "./ReflectionPrompt/ReflectionPromptDesktop";

interface DesktopContentViewProps {
  prompt1Handler: Function;
  prompt2Handler: Function;
}

const DesktopContentView = ({
  prompt1Handler,
  prompt2Handler,
}: DesktopContentViewProps) => {
  return (
    <div className="modal-body-content desktop-view-content pt-2">
      <div className="prompt-1-container">
        <ReflectionPrompt
          header="Reflection Prompt 1"
          prompt="What is a key takeaway from your learning today? Try to be specific!"
          handleChange={prompt1Handler}
        />
      </div>
      <div className="prompt-2-container">
        <ReflectionPrompt
          header="Reflection Prompt 2"
          prompt="How can you apply what you've learned today? Think about ways to practice these skills within your coursework, at work, or in your community!"
          handleChange={prompt2Handler}
        />
      </div>
    </div>
  );
};

export default DesktopContentView;

interface CardHeaderContentProps {
  subHeaderText: string;
  headerText: string;
}

const CardHeaderContent = ({
  subHeaderText,
  headerText,
}: CardHeaderContentProps) => {
  return (
    <div className="pt-2 ps-3">
      <p className="card-subheader-text">{subHeaderText}</p>
      <strong className="card-header-text">{headerText}</strong>
    </div>
  );
};

export default CardHeaderContent;
